@if (managedJob().status === 'new') {
	<fixify-track-information-card
		statusText="To do"
		statusColor="bright-orange"
		title="Review job details"
		subtitle="Assign service providers to accept this maintenance request."
		primaryActionText="Assign service providers"
		tertiaryActionText="Reject job"
		(primaryPress)="assignServiceProviders()"
		(tertiaryPress)="rejectJob()"
	></fixify-track-information-card>
} @else if (managedJob().status === 'cancelled') {
	<fixify-job-reject-detail-item
		[reason]="managedJob().statusReason ?? ''"
		title="Job cancelled"
	></fixify-job-reject-detail-item>
} @else if (managedJob().status === 'ongoing') {
	<div class="flex flex-col gap-6 items-center">
		@if (waitingForAcceptance()) {
			<fixify-track-information-card
				class="w-full"
				title="Waiting for acceptance"
				subtitle="Service providers have been notified of the job. You'll be notified once it's been accepted."
			></fixify-track-information-card>
		}
		@if (waitingForQuotes()) {
			<fixify-track-information-card
				class="w-full"
				title="Waiting for quotes"
				subtitle="Service providers are preparing your quotes. You'll be notified once it's submitted."
			></fixify-track-information-card>
		}
		@if (waitingForPaymentResponsibilty()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				title="Assign payment responsibility"
				subtitle="Choose who will pay for this maintenance."
				primaryActionText="Assign"
				secondaryActionText="Skip"
			></fixify-track-information-card>
		}
		@if (reviewQuote()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				title="Review Quote"
				subtitle="Review pending quotes"
			></fixify-track-information-card>
		}
		@if (waitingForInvoices()) {
			<fixify-track-information-card
				class="w-full"
				title="Waiting for invoices"
				subtitle="Service providers are preparing to fulfil the job. You'll be notified once invoices are submitted."
			></fixify-track-information-card>
		}
		@if (canMarkForCompletion()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				title="Mark job as complete"
				subtitle="Once you are satisfied with your uploaded invoices, you should mark your job as complete. Note - Once you mark this job as complete you will no longer be able to request additional work."
				primaryActionText="Mark as complete"
				(primaryPress)="markAsComplete()"
			></fixify-track-information-card>
		}
		<p
			(click)="cancelJob()"
			class="text-action py-2 text-sm font-medium tetx-gray-600"
		>
			Cancel job
		</p>
	</div>
} @else if (managedJob().status === 'completed') {
	<div class="flex flex-col gap-4">
		<fixify-track-information-card
			titleIcon="check-circle"
			title="Job complete!"
			subtitle="Completed on ???"
		></fixify-track-information-card>
		<div class="w-full rounded bg-gray-100 p-8">
			<!-- TODO - Only show this pill if there are some SPs that have not yet been rated -->
			<fixify-status-pill class="inline-block mb-4" color="bright-orange">
				To do
			</fixify-status-pill>

			<p class="mb-4 text-xl text-gray-900 font-medium">
				Rate service providers
			</p>
			<div class="flex flex-col gap-3">
				@for (sp of acceptedServiceProviders; track $index) {
					<div
						(click)="tappedServiceProvider(sp)"
						class="flex items-center gap-2 justify-between my-2"
						[ngClass]="{ 'cursor-pointer': sp.rating }"
					>
						<div class="flex items-center gap-2">
							<div class="w-12 h-12">
								<fixify-avatar
									[placeholder]="shortened(sp)"
									[source]="
										sp.companyDetails?.companyLogo ?? ''
									"
								></fixify-avatar>
							</div>
							<p
								class="text-lg font-medium text-gray-900 line-clamp-2"
							>
								{{ sp.companyDetails?.companyName }}
							</p>
						</div>
						@if (!sp.rating) {
							<button
								(click)="rateServiceProvider(sp)"
								class="btn btn-outline small"
							>
								Rate
							</button>
						} @else {
							<div class="flex gap-1">
								<fixify-icon
									class="w-5 h-5 text-yellow-300 stroke-2"
									name="star-01"
									symbol="filled"
								></fixify-icon>
								<p class="text-sm font-medium text-gray-900">
									{{ overallRating(sp.rating) }} / 5
								</p>
							</div>
						}
					</div>
					@if (sp.rating && sp.rating.expand) {
						<div class="flex flex-col gap-3">
							<fixify-rating-item
								title="Quality of work"
								[stars]="sp.rating.qualityOfWork"
								[totalStars]="5"
							></fixify-rating-item>
							<fixify-rating-item
								title="Quality of quote"
								[stars]="sp.rating.qualityOfQuote"
								[totalStars]="5"
							></fixify-rating-item>
							<fixify-rating-item
								title="Communication"
								[stars]="sp.rating.communication"
								[totalStars]="5"
							></fixify-rating-item>
							<fixify-rating-item
								title="Reliability"
								[stars]="sp.rating.reliability"
								[totalStars]="5"
							></fixify-rating-item>
							<div>
								<p
									class="text-sm font-medium text-gray-900 mb-1"
								>
									Review
								</p>
								<p class="text-base text-gray-900 font-normal">
									{{
										sp.rating.review ?? 'No review comments'
									}}
								</p>
							</div>
						</div>
						<fixify-divider></fixify-divider>
					}
				}
			</div>
		</div>
	</div>
}

