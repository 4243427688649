import { Component, input } from '@angular/core';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { ViewUserModalComponent } from '../../../../../shared/ui/modals/view-user-modal/view-user-modal.component';
import { Stakeholder } from '../../../../../shared/data-access/agency/agency.types';
import { nameToInitials } from '../../../../../shared/utils/string-manipulation';
import { RefinedStakeholder } from '../../../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-job-user-detail-tile',
	standalone: true,
	imports: [AvatarComponent, IconComponent],
	templateUrl: './job-user-detail-tile.component.html',
	styleUrl: './job-user-detail-tile.component.css'
})
export class JobUserDetailTileComponent {
	stakeholder = input.required<Stakeholder | RefinedStakeholder>();
	type = input.required<
		'landlord' | 'trustee' | 'tenant' | 'estate manager'
	>();
	canEdit = input<boolean>(false);

	constructor(private modalService: ModalService) {}

	onTileClick() {
		this.modalService
			.showModal(ViewUserModalComponent, {
				stakeholder: this.stakeholder(),
				type: this.type(),
				editVisible: this.canEdit()
			})
			.then(result => {
				if (result) {
					// Handle result
				}
			});
	}

	shortened() {
		return nameToInitials(this.stakeholder().fullName ?? '');
	}
}
