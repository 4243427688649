import { Component, model, signal } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import {
	ITabItem,
	TabsComponent
} from '../../../../../shared/ui/tabs/tabs.component';
import { DatePipe, NgClass, TitleCasePipe } from '@angular/common';
import {
	StatusPillColor,
	StatusPillComponent
} from '../../../../../shared/ui/status-pill/status-pill.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { GenericRejectModalComponent } from '../../../../../shared/ui/modals/generic-reject-modal/generic-reject-modal.component';
import { RejectQuoteModalComponent } from '../modals/reject-quote-modal/reject-quote-modal.component';
import {
	DocumentView,
	ManagedJobs,
	ManagedJobsDetails,
	Document,
	DocumentStatus,
	UploadedDocument
} from '../../../../../shared/data-access/agency/agency.types';
import { ToastService } from '../../../../../shared/data-access/toast.service';
import { EditServiceProviderModalComponent } from '../modals/edit-service-provider-modal/edit-service-provider-modal.component';
import { AgencyJobsService } from '../../../../../shared/data-access/agency/agency.jobs.service';

@Component({
	selector: 'fixify-job-documents',
	standalone: true,
	imports: [
		IconComponent,
		TabsComponent,
		DatePipe,
		StatusPillComponent,
		NgClass,
		TitleCasePipe
	],
	templateUrl: './job-documents.component.html',
	styleUrl: './job-documents.component.css'
})
export class JobDocumentsComponent {
	documentFilters: Array<ITabItem> = [
		{
			label: 'Quotes',
			value: '-',
			active: true
		},
		{
			label: 'Photos',
			value: '-',
			active: false
		},
		{
			label: 'Invoices',
			value: '-',
			active: false
		}
	];

	documents = signal<Array<DocumentView>>([]);

	documentsToView = signal<Array<DocumentView>>([]);
	managedJob = model.required<ManagedJobsDetails>();

	constructor(
		private modalService: ModalService,
		private toastService: ToastService,
		private agencyJobsService: AgencyJobsService
	) {}

	ngOnInit() {
		this.documents.set([
			...this.managedJob().quotes.map(quote => {
				return {
					type: 'quote' as const,
					status: quote.status,
					createdAt: quote.date,
					name: quote.name,
					rejectedReason: quote.reason,
					companyName: quote.companyName,
					url: quote.url,
					serviceProviderId: quote.serviceProviderId
				};
			}),
			...this.managedJob().invoices.map(invoice => {
				return {
					type: 'invoice' as const,
					status: invoice.status,
					createdAt: invoice.date,
					name: invoice.name,
					rejectedReason: invoice.reason,
					companyName: invoice.companyName,
					url: invoice.url,
					serviceProviderId: invoice.serviceProviderId
				};
			}),
			...this.managedJob().supportingMediaUrls.map(url => {
				return {
					type: 'photo' as const,
					url
				};
			})
		]);

		this.documentFilters[0].value = this.managedJob().quotes.length ?? 0;
		this.documentFilters[1].value =
			this.managedJob().supportingMediaUrls.length ?? 0;
		this.documentFilters[2].value = this.managedJob().invoices.length ?? 0;

		switch (this.managedJob().status) {
			case 'completed':
				this.onDocumentItemChange(this.documentFilters[2]);
				break;
			case 'cancelled':
				this.onDocumentItemChange(this.documentFilters[0]);
				break;
		}
		this.documentsToView.set(
			this.documents().filter(
				doc =>
					doc.type ===
					(this.managedJob().status === 'completed'
						? 'invoice'
						: 'quote')
			)
		);
	}

	activeTabItem() {
		return this.documentFilters.find(filter => filter.active);
	}

	approveQuote(doc: DocumentView) {
		if (!doc.createdAt || !doc.serviceProviderId || !doc.name) {
			this.toastService.add('Could not identify quote', 3000, 'error');
			return;
		}

		const quoteDoc: UploadedDocument = {
			date: doc.createdAt,
			serviceProviderId: doc.serviceProviderId,
			name: doc.name,
			reason: null,
			status: 'approve',
			url: doc.url
		};
		this.agencyJobsService
			.manageQuote(this.managedJob().id, quoteDoc)
			.subscribe({
				next: data => {
					const quote = data.body.quote as Partial<Document>;
					doc.status = quote.status;
					const foundQuote = this.managedJob().quotes.find(
						quote =>
							quote.serviceProviderId == doc.serviceProviderId &&
							quote.name == doc.name &&
							quote.date == doc.createdAt
					);
					if (foundQuote && quote.status)
						foundQuote.status = quote.status;

					this.toastService.add('Quote approved', 5000, 'success');
				},
				error: error => {
					console.error(error);
				}
			});
	}

	openDocument(doc: DocumentView) {
		window.open(doc.url, '_blank');
	}

	getColor(status: DocumentStatus = 'pending'): StatusPillColor {
		switch (status) {
			case 'approved':
				return 'green';
			case 'rejected':
				return 'red';
			case 'outdated':
				return 'yellow';
			case 'requote':
				return 'purple';
			default:
				return '';
		}
	}

	disputeInvoice(doc: DocumentView) {
		this.modalService
			.showModal(GenericRejectModalComponent, {
				formTitle: 'Reason for dispute',
				subtitle:
					'Provide a reason for disputing this invoice. The service provider will be required to submit a new invoice.',
				title: 'Dispute invoice',
				rejectButtonText: 'Submit dispute',
				hideTopBar: true
			})
			.then(reason => {
				if (!reason) {
					this.toastService.add('Reason required', 3000, 'error');
					return;
				}

				if (!doc.createdAt || !doc.serviceProviderId || !doc.name) {
					this.toastService.add(
						'Could not identify invoice',
						3000,
						'error'
					);
					return;
				}

				const invoiceDoc: UploadedDocument = {
					date: doc.createdAt,
					serviceProviderId: doc.serviceProviderId,
					name: doc.name,
					reason: reason,
					status: 'dispute',
					url: doc.url
				};

				this.agencyJobsService
					.manageInvoice(this.managedJob().id, invoiceDoc)
					.subscribe({
						next: data => {
							const invoice = data.body
								.invoice as Partial<Document>;
							doc.status = invoice.status;
							doc.rejectedReason = reason;

							const foundInvoice =
								this.managedJob().invoices.find(
									invoice =>
										invoice.serviceProviderId ==
											doc.serviceProviderId &&
										invoice.name == doc.name &&
										invoice.date == doc.createdAt
								);
							if (foundInvoice && invoice.status)
								foundInvoice.status = invoice.status;

							this.toastService.add(
								'Disputed submitted',
								5000,
								'success'
							);
						},
						error: error => {
							console.error(error);
						}
					});
			});
	}

	documentText() {
		const label = this.documentFilters.find(filter => filter.active)?.label;

		switch (label) {
			case 'Quotes':
				return 'quotes';
			case 'Photos':
				return 'photos';
			case 'Invoices':
				return 'invoices';
			default:
				return 'documents';
		}
	}

	markOutdated(doc: DocumentView) {
		doc.status = 'outdated';

		const activeTab = this.activeTabItem();

		this.onDocumentItemChange(activeTab ?? this.documentFilters[0]);
	}

	showPill(document: DocumentView) {
		switch (document.type) {
			case 'quote':
				return document.status !== 'pending';
			case 'photo':
				return false;
			case 'invoice':
				return document.status;
			default:
				return false;
		}
	}

	onDocumentItemChange(item: ITabItem) {
		this.documentFilters.forEach(filter => {
			filter.active = filter.label === item.label;
		});

		// Use computed to keep in sync with documents signal
		this.documentsToView.set(
			this.documents().filter(doc => {
				switch (item.label) {
					case 'Quotes':
						return doc.type === 'quote';
					case 'Photos':
						return doc.type === 'photo';
					case 'Invoices':
						return doc.type === 'invoice';
					default:
						return true;
				}
			})
		);
	}

	openImage(url: string) {
		window.open(url, '_blank');
	}

	rejectQuote(doc: DocumentView) {
		this.modalService
			.showModal(RejectQuoteModalComponent, {
				jobId: this.managedJob().id,
				doc
			})
			.then(result => {
				if (!result) {
					return;
				}

				doc.status = result.quote.status;
				doc.rejectedReason = result.quote.reason;

				const foundQuote = this.managedJob().quotes.find(
					quote =>
						quote.serviceProviderId == doc.serviceProviderId &&
						quote.name == doc.name &&
						quote.date == doc.createdAt
				);
				if (foundQuote && result.quote.status)
					foundQuote.status = result.quote.status;

				this.toastService.add('Quote rejected', 5000, 'success');

				switch (result.option) {
					case 'Assign new service provider': {
						this.modalService
							.showModal(
								EditServiceProviderModalComponent,
								this.managedJob()
							)
							.then(newAssignments => {
								if (!newAssignments) {
									return;
								}

								this.toastService.add(
									JSON.stringify(
										'TODO: Update the managed job with the new assignments'
									),
									6000,
									'success'
								);
								// Update the managed job with the new assignments, it's a model, so we can just update it here directly
							});
						break;
					}
					case 'Cancel job': {
						this.modalService
							.showModal(GenericRejectModalComponent, {
								formTitle: 'Reason for cancellation',
								subtitle:
									'Provide a reason for cancelling this job.',
								title: 'Cancel job',
								rejectButtonText: 'Cancel job'
							})
							.then(reason => {
								if (!reason) {
									this.toastService.add(
										'Reason required',
										3000,
										'error'
									);
									return;
								}

								this.agencyJobsService
									.cancelJob(this.managedJob().id, reason)
									.subscribe({
										next: data => {
											const updatedJob =
												data.body as ManagedJobs;
											const { statusReason, status } =
												updatedJob;
											this.managedJob.update(job => {
												job.status = status;
												job.statusReason = statusReason;
												return job;
											});
											this.toastService.add(
												'Job cancelled',
												3000,
												'success'
											);
										},
										error: error => {
											console.error(error);
										}
									});
							});

						break;
					}
				}
			});
	}

	requestRequote(doc: DocumentView) {
		this.modalService
			.showModal(GenericRejectModalComponent, {
				formTitle: 'Reason for requote',
				subtitle:
					'Provide a reason for request for a requote. This will reject this quote and prompt the service provider to send a new or updated quote for your review.',
				title: 'Request a requote',
				rejectButtonText: 'Request requote',
				hideTopBar: true
			})
			.then(reason => {
				if (!reason) {
					return;
				}

				if (!doc.createdAt || !doc.serviceProviderId || !doc.name) {
					this.toastService.add(
						'Could not identify quote',
						3000,
						'error'
					);
					return;
				}

				const quoteDoc: UploadedDocument = {
					date: doc.createdAt,
					serviceProviderId: doc.serviceProviderId,
					name: doc.name,
					reason: reason,
					status: 'requote',
					url: doc.url
				};
				this.agencyJobsService
					.manageQuote(this.managedJob().id, quoteDoc)
					.subscribe({
						next: data => {
							const quote = data.body.quote as Partial<Document>;

							const foundQuote = this.managedJob().quotes.find(
								quote =>
									quote.serviceProviderId ==
										doc.serviceProviderId &&
									quote.name == doc.name &&
									quote.date == doc.createdAt
							);
							if (foundQuote && quote.status)
								foundQuote.status = quote.status;

							doc.status = quote.status;
							doc.rejectedReason = quote.reason;

							this.toastService.add(
								'Requote requested',
								5000,
								'success'
							);
						},
						error: error => {
							console.error(error);
						}
					});
			});
	}
}
