import { Injectable, signal } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginatedResponse } from '../../utils/pagination';
import { ManagedFulfilment } from '../service-provider/service-provider.types';
import { DocumentAction } from '../agency/agency.types';

type ManagedJobsFilters = {
	search?: string;
	page?: number;
	limit?: number;
	category?: string;
	coordinates?: string;
	sort?: string;
	sortBy?: string;
	filter?: Record<string, string | number>;
};
@Injectable({
	providedIn: 'root'
})
export class ServiceProviderJobsService {
	private isFetching$ = new BehaviorSubject<boolean>(false);
	private fulfilments$ =
		new BehaviorSubject<PaginatedResponse<ManagedFulfilment> | null>(null);

	private paramSignal = signal<ManagedJobsFilters>({});

	constructor(private apiService: ApiService) {
		this.fetchJobs();
	}

	fetchJobs(_params?: ManagedJobsFilters) {
		this.isFetching$.next(true);
		this.setParamSignal(_params);
		const { filter, ...queryParams } = this.paramSignal();
		const query: Record<string, string | number | undefined> = {
			...queryParams
		};
		if (filter?.['status'] !== 'all') {
			query['filter'] = JSON.stringify(filter);
		}
		const filteredQuery = Object.fromEntries(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			Object.entries(query).filter(([_, value]) => value !== undefined)
		) as Record<string, string | number>;
		const params = this.apiService.buildFilterQueryParams(filteredQuery);
		this.fulfilments$.next(null);
		this.apiService.getData(`service-providers/jobs${params}`).subscribe({
			next: data => {
				const paginatedData =
					data.body as PaginatedResponse<ManagedFulfilment>;
				this.fulfilments$.next(paginatedData);
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isFetching$.next(false);
			}
		});
	}

	fetchJob(fulfilmentId: string, refresh = false) {
		if (
			!refresh &&
			this.fulfilments$.value?.result.some(
				fulfilment => fulfilment.id === fulfilmentId
			)
		) {
			return new Observable(subscriber => {
				subscriber.next({
					body: this.fulfilments$.value?.result.find(
						fulfilment => fulfilment.id === fulfilmentId
					)
				});
				subscriber.complete();
			});
		}
		return this.apiService.getData(
			`service-providers/jobs/${fulfilmentId}`
		);
	}

	acceptJob(fulfilmentId: string) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/accept`,
			{}
		);
	}

	rejectJob(fulfilmentId: string) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/reject`,
			{}
		);
	}

	cancelJob(fulfilmentId: string) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/cancel`,
			{}
		);
	}

	uploadQuote(
		fulfilmentId: string,
		quotes: Array<{ url: string; date: number; name: string }>
	) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/upload-quote`,
			{ quotes }
		);
	}

	uploadInvoice(
		fulfilmentId: string,
		invoices: Array<{ url: string; date: number; name: string }>
	) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/upload-invoice`,
			{ invoices }
		);
	}

	uploadMedia(fulfilmentId: string, supportingMediaUrls: Array<string>) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/upload-media`,
			{ supportingMediaUrls }
		);
	}

	setSiteVisitDate(fulfilmentId: string, quoteDate: number) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/schedule-date`,
			{ quoteDate }
		);
	}

	setJobDate(fulfilmentId: string, jobDate: number) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/schedule-date`,
			{ jobDate }
		);
	}

	manageQuote(
		fulfilmentId: string,
		quote: {
			date: number;
			name: string;
			reason: string | null;
			status: DocumentAction;
		}
	) {
		return this.apiService.postData(
			`service-providers/jobs/${fulfilmentId}/manage-quote`,
			{ ...quote }
		);
	}

	fulfilments(): Observable<PaginatedResponse<ManagedFulfilment> | null> {
		return this.fulfilments$.asObservable();
	}

	getParams() {
		return this.paramSignal();
	}

	setParamSignal(params?: ManagedJobsFilters) {
		if (!params) {
			return this.clearParams();
		}
		this.paramSignal.set(params);
	}

	clearParams() {
		this.paramSignal.set({});
	}
}
