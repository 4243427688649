import { AsyncPipe, NgClass } from '@angular/common';
import {
	Component,
	inject,
	input,
	model,
	OnDestroy,
	OnInit,
	output,
	signal
} from '@angular/core';
import { DividerComponent } from '../../../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule
} from '@angular/forms';
import {
	catchError,
	filter,
	first,
	firstValueFrom,
	forkJoin,
	Observable,
	of,
	Subject,
	takeUntil
} from 'rxjs';
import {
	CategoryForm,
	ITaskCheckbox
} from '../edit-category/edit-category.component';
import { ServiceProviderService } from '../../../../../shared/data-access/service-provider/service-provider.service';
import {
	getDownloadURL,
	ref,
	uploadBytesResumable,
	Storage,
	deleteObject
} from '@angular/fire/storage';
import {
	Category,
	ServiceProviderCategory,
	UploadedDocuments
} from '../../../../../shared/data-access/service-provider/service-provider.types';
import { ToastService } from '../../../../../shared/data-access/toast.service';

@Component({
	selector: 'fixify-category-form',
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		DividerComponent,
		TextInputComponent,
		ReactiveFormsModule
	],
	templateUrl: './category-form.component.html',
	styleUrl: './category-form.component.css'
})
export class CategoryFormComponent implements OnInit, OnDestroy {
	storage = inject(Storage);
	toastService = inject(ToastService);
	serviceProvider = inject(ServiceProviderService);
	categoryForm = model.required<FormGroup<CategoryForm>>();
	categories = input.required<Array<Category>>();
	unavailableCategoryIds = input.required<Array<string>>();
	spCategory = input<ServiceProviderCategory>();
	canEditCategoryType = input<boolean>(true);
	stopEdit = output<void>();
	selectedCategory = '';
	destroy$ = new Subject<void>();
	serviceProviderId: string | null = null;
	uploadedFiles = signal<Array<UploadedDocuments>>([]);
	uploadObservables = signal(Array<Observable<UploadedDocuments | null>>());

	ngOnInit() {
		this.selectedCategory = this.spCategory()?.name ?? '';

		if (this.canEditCategoryType()) {
			const categoryDetails = this.categories()?.find(
				c => c.id === this.spCategory()?.id
			);

			categoryDetails?.tasks.forEach(task => {
				const taskGroup = new FormGroup({
					name: new FormControl(task),
					selected: new FormControl(
						this.spCategory()?.tasks.includes(task) ?? false
					)
				});
				(this.categoryForm().get('tasks') as FormArray).push(taskGroup);
			});
		}

		this.setObservable(this.spCategory()?.supportingDocuments);

		this.serviceProvider
			.serviceProviderId()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: serviceProviderId => {
					this.serviceProviderId = serviceProviderId;
				}
			});
	}

	setObservable(observables: Array<UploadedDocuments> = []) {
		const uploadedObservables = observables.map(doc => {
			return new Observable<UploadedDocuments>(observer => {
				observer.next({
					url: doc.url,
					percentage: 100,
					fileName: doc.fileName
				});
				observer.complete();
			});
		});

		this.uploadObservables.set([
			...this.uploadObservables(),
			...uploadedObservables
		]);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	allTasksForFormGroup() {
		return this.categoryForm().controls.tasks as FormArray<
			FormGroup<ITaskCheckbox>
		>;
	}

	categoryInUse(categoryId: string) {
		return (
			this.unavailableCategoryIds().includes(categoryId) &&
			this.spCategory()?.id !== categoryId
		);
	}

	isCategoryChosen(categoryName: string) {
		return categoryName === this.selectedCategory;
	}

	selectFileFromDevice() {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = '.pdf, image/*';
		input.multiple = true;
		input.click();

		input.onchange = () => {
			const files = Array.from(input.files ?? []);

			const uploadingObservables = files.map(file =>
				this.uploadMedia(file).pipe(
					takeUntil(this.destroy$),
					catchError(error => {
						console.error('Upload failed:', error);
						return of(null); // Return null for failed uploads
					})
				)
			);

			this.uploadObservables.set([
				...this.uploadObservables(),
				...uploadingObservables
			]);

			forkJoin(uploadingObservables).subscribe({
				next: (results: (UploadedDocuments | null)[]) => {
					const supportingDocs = results.filter(
						result => result !== null
					) as UploadedDocuments[];

					const currentFiles =
						this.categoryForm().controls.supportingDocuments
							.value ?? [];
					this.categoryForm().controls.supportingDocuments.setValue(
						currentFiles.concat(supportingDocs)
					);

					const previouslyUploaded = this.uploadedFiles();
					this.uploadedFiles.set([
						...previouslyUploaded,
						...supportingDocs
					]);
				},
				error: error => {
					console.error('Error in upload process:', error);
				}
			});
		};
	}

	uploadMedia(file: File): Observable<UploadedDocuments> {
		if (!this.serviceProviderId) {
			throw new Error('Service Provider ID not set');
		}
		const categoryName = this.categoryForm().controls.name.value;
		const categoryId = this.categories()?.find(
			category => category.name === categoryName
		)?.id;
		if (!this.serviceProvider.toString()) {
			this.toastService.add(
				`Couldn't identify user, please reload.`,
				5000,
				'error'
			);
			throw new Error("Couldn't identify user, please reload");
		}
		const filePath = `serviceProviders/${this.serviceProviderId.toString()}/categories/${categoryId}/${file.name}`;
		const storageRef = ref(this.storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		return new Observable<UploadedDocuments>(observer => {
			uploadTask.on(
				'state_changed',
				snapshot => {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					observer.next({
						url: '',
						percentage: Math.round(progress),
						fileName: file.name
					});
				},
				error => {
					console.error('Upload failed:', error);
					observer.error(error);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then(
						downloadURL => {
							observer.next({
								url: downloadURL,
								percentage: 100,
								fileName: file.name
							});
							observer.complete();
						}
					);
				}
			);
		});
	}

	async deleteMedia(media: Observable<UploadedDocuments | null>) {
		const mediaToRemove = await firstValueFrom(
			media.pipe(
				filter((doc): doc is UploadedDocuments => doc !== null),
				first()
			)
		);

		const storageRef = ref(this.storage, mediaToRemove.url);
		await deleteObject(storageRef);
		this.uploadObservables.update(observables =>
			observables.filter(observable => observable !== media)
		);
		this.categoryForm().controls.supportingDocuments.setValue(
			(
				this.categoryForm().controls.supportingDocuments.value ?? []
			).filter((doc: UploadedDocuments) => doc.url !== mediaToRemove.url)
		);
	}

	retrieveTasks(cagtegoryName: string) {
		return (
			this.categories()?.find(category => category.name === cagtegoryName)
				?.tasks ?? []
		);
	}
}
