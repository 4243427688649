<div class="relative container mx-auto h-full">
	<div
		[ngClass]="{ '2xl:absolute': !disableFullTable() }"
		class="block inset-0 flex flex-col"
	>
		@if (!disableFullTable()) {
			<!-- <div class="mb-6">
				<fixify-tabs
					[tabItems]="barFilters"
					(onTabItemChange)="onTabItemChange($event)"
				></fixify-tabs>
			</div> -->
		}
		<div class="flex justify-between mb-4">
			<div
				[formGroup]="filterForm"
				class="w-full flex justify-between items-center"
			>
				<fixify-text-input
					class="min-w-80"
					[control]="filterForm.get('search')!"
					label=""
					id="search"
					inputType="search"
					placeholder="Search ..."
				></fixify-text-input>
				<!-- <div class="shrink flex gap-2">
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="filter-lines"
								></fixify-icon
							></span>
							Filters
						</div>
					</button>
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="arrows-down"
								></fixify-icon
							></span>
							Sort
						</div>
					</button>
				</div> -->
			</div>
		</div>
		<div class="flex-grow">
			<fixify-table
				[data]="properties"
				[itemsPerPage]="itemsPerPage"
				[selectedFieldName]="properties ? 'selected' : null"
				[currentPage]="currentPage"
				(pageChange)="onPageChange($event)"
				(rowClicked)="onRowClicked($event)"
			>
				<ng-template
					fixifyTableColumn
					header=""
					field="id"
					let-value
					let-row="row"
				>
					<input
						(click)="selectItem($event, row)"
						[disabled]="onlySingleSelectAllowed(row)"
						[ngClass]="{
							'opacity-50': onlySingleSelectAllowed(row)
						}"
						type="checkbox"
						class="checkbox-primary"
						[checked]="row.selected"
						[value]="value"
					/>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="ADDRESS"
					field="address.formatted"
					let-value
					let-row="row"
				>
					<div class="flex items-center">
						<fixify-property-avatar
							[url]="row.imageUrl"
						></fixify-property-avatar>
						<div class="line-clamp-1 ml-4">
							{{ value }}
						</div>
						@if (row.estateId) {
							<div>
								<div
									[id]="value + '-property'"
									[attr.data-dropdown-toggle]="value"
									data-dropdown-offset-distance="10"
									data-dropdown-offset-skidding="0"
									class="flex px-2 py-0.5 rounded-sm bg-primary-100 ml-1 cursor-pointer"
								>
									<fixify-icon
										class="text-primary-500 w-3 h-3"
										name="building-06"
									></fixify-icon>
								</div>
								<div
									[id]="value"
									[attr.aria-labelledby]="value + '-property'"
									class="absolute hidden z-20 w-44 bg-white rounded divide-y divide-gray-100 shadow left-0 top-0"
								>
									<ul
										class="text-sm text-gray-700 font-normal cursor-default"
									>
										<li class="py-3 px-4">
											<p
												class="block text-gray-700 underline cursor-pointer"
											>
												{{ row.estateName }}
											</p>
										</li>
									</ul>
								</div>
							</div>
						}
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="SUBURB"
					field="address.suburb"
					let-value
				>
					{{ value }}
				</ng-template>
				<!-- <ng-template
					fixifyTableColumn
					header="TYPE"
					field="type"
					let-value
				>
					{{ value }}
				</ng-template> -->
				<ng-template
					fixifyTableColumn
					header="STATUS"
					field="vacant"
					let-value
				>
					{{ value ? 'Vacant' : 'Occupied' }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="AGENT"
					field="managingAgent"
					let-value
					let-row="row"
				>
					<div
						(click)="openAgentModal($event, row.managingAgent)"
						[ngClass]="{
							'hover:underline cursor-pointer':
								!disableFullTable()
						}"
					>
						{{ value?.name ?? '--'}}
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="TOTAL JOBS"
					field="totalJobs"
					let-value
				>
					{{ value }}
				</ng-template>
				@if (!disableFullTable()) {
					<ng-template
						fixifyTableColumn
						header="Actions"
						field="id"
						let-value
						let-row="row"
					>
						<div (click)="$event.stopPropagation()">
							<div
								[id]="value + '-button'"
								[attr.data-dropdown-toggle]="value"
								data-dropdown-offset-distance="0"
								data-dropdown-offset-skidding="-70"
								class="w-10 h-10 rounded-full hover:border-2 hover:border-gray-200 flex items-center justify-center cursor-pointer"
							>
								<fixify-icon
									class="text-gray-700 w-6 h-6"
									name="dots-vertical"
								></fixify-icon>
							</div>
							<div
								[id]="value"
								[attr.aria-labelledby]="value + '-button'"
								class="absolute hidden z-20 w-44 bg-white rounded divide-y divide-gray-100 shadow left-0 top-0"
							>
								<ul
									class="text-sm text-gray-700 font-normal cursor-pointer"
								>
									<li>
										<p
											class="block py-3 px-4 text-gray-700 hover:bg-gray-100"
										>
											Create job
										</p>
									</li>
									<li (click)="assignToAgent([row])">
										<p
											class="block py-3 px-4 text-gray-700 hover:bg-gray-100"
										>
											Assign different agent
										</p>
									</li>

									<li (click)="goToEditProperty(row)">
										<p
											class="block py-3 px-4 text-gray-700 hover:bg-gray-100"
										>
											Edit property details
										</p>
									</li>
									<li (click)="archive(row)">
										<p
											class="block py-3 px-4 text-red-700 hover:bg-gray-100"
										>
											Archive property
										</p>
									</li>
								</ul>
							</div>
						</div>
					</ng-template>
				}
			</fixify-table>
		</div>
		<div class="mt-8">
			<fixify-pagination
				[currentPage]="currentPage"
				[totalPages]="totalPages"
				[maxItems]="totalCount"
				[pageSize]="itemsPerPage"
				(pageChange)="onPageChange($event)"
			></fixify-pagination>
		</div>
	</div>
	@if (selectedProperties().length > 0 && !disableFullTable()) {
		<div
			class="fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-white shadow-lg px-4 py-3 rounded bg-white mb-8 border-2 border-gray-100"
		>
			<div class="flex gap-2 items-center">
				<p class="text-gray-700 text-sm mr-8">
					{{ selectedProperties().length }}
					{{
						selectedProperties().length === 1
							? 'property'
							: 'properties'
					}}
					selected
				</p>
				<button
					(click)="assignToAgent(selectedProperties())"
					class="btn btn-secondary small"
				>
					Assign to another agent
				</button>
				<!-- TODO -->
				<!-- <button class="btn btn-secondary small">Create bulk job</button> -->
				<button
					(click)="deleteSelectedProperties()"
					class="btn btn-primary danger small"
				>
					Delete
				</button>
			</div>
		</div>
	}
</div>

