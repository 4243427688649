import { Injectable, signal } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { EstateMetrics, ManagedEstate } from './agency.types';
import { ToastService } from '../toast.service';
import { PaginatedResponse } from '../../utils/pagination';
import { AgencyPropertiesService } from './agency.properties.service';

type ManagedEstateFilters = {
	search?: string;
	page?: number;
	limit?: number;
	coordinates?: string;
	sort?: string;
	sortBy?: string;
};
@Injectable({
	providedIn: 'root'
})
export class AgencyEstatesService {
	private isSubmitting$ = new BehaviorSubject<boolean>(false);
	private isFetching$ = new BehaviorSubject<boolean>(false);
	private estates$ =
		new BehaviorSubject<PaginatedResponse<ManagedEstate> | null>(null);
	private estateMetrics$ = new BehaviorSubject<EstateMetrics | null>(null);

	private paramSignal = signal<ManagedEstateFilters>({});

	constructor(
		private apiService: ApiService,
		private toastService: ToastService,
		private agentPropertiesService: AgencyPropertiesService
	) {
		this.fetchEstates();
		this.fetchEstatesMetrics();
	}

	fetchEstates(_params?: ManagedEstateFilters) {
		this.isFetching$.next(true);
		this.setParamSignal(_params);
		const params = this.apiService.buildFilterQueryParams({
			...this.paramSignal()
		});
		this.apiService.getData(`agency/estates${params}`).subscribe({
			next: data => {
				const paginatedData =
					data.body as PaginatedResponse<ManagedEstate>;
				paginatedData.result = paginatedData.result.map(property => {
					if (property.managingAgent) {
						if (
							property.managingAgent.firstName &&
							property.managingAgent.lastName
						)
							property.managingAgent.name = `${property.managingAgent.firstName.charAt(0)}. ${property.managingAgent.lastName}`;
					}

					return property;
				});
				this.estates$.next(paginatedData);
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isFetching$.next(false);
			}
		});
	}

	getParams() {
		return this.paramSignal();
	}

	setParamSignal(params?: ManagedEstateFilters) {
		if (!params) {
			return this.clearParams();
		}
		this.paramSignal.set(params);
	}

	clearParams() {
		this.paramSignal.set({});
	}

	fetchEstate(estateId: string) {
		if (
			this.estates$.value?.result.some(estate => estate.id === estateId)
		) {
			return new Observable(subscriber => {
				subscriber.next({
					body: this.estates$.value?.result.find(
						estate => estate.id === estateId
					)
				});
				subscriber.complete();
			});
		}
		return this.apiService.getData(`agency/estate/${estateId}`);
	}

	fetchEstatesMetrics() {
		return this.apiService.getData('agency/estates/metrics').subscribe({
			next: data => {
				const metrics = data.body as EstateMetrics;
				this.estateMetrics$.next(metrics);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	deleteEstate(estateId: string) {
		this.isSubmitting$.next(true);
		this.apiService.deleteData(`agency/estate/${estateId}`).subscribe({
			next: () => {
				this.fetchEstates();
			},
			error: error => {
				console.error(error);
				this.toastService.add(
					error.error.body.errorMessage,
					5000,
					'error'
				);
			},
			complete: () => {
				this.isSubmitting$.next(false);
				this.toastService.add(
					'Estate has been successfully deleted',
					5000,
					'success'
				);
			}
		});
	}

	deleteEstates(estateIds: string[]) {
		this.isSubmitting$.next(true);
		this.apiService.deleteData('agency/estates', { estateIds }).subscribe({
			next: () => {
				this.fetchEstates();
			},
			error: error => {
				console.error(error);
				this.toastService.add(
					error.error.body.errorMessage,
					5000,
					'error'
				);
			},
			complete: () => {
				this.isSubmitting$.next(false);
				this.toastService.add(
					'Estates have been successfully deleted',
					5000,
					'success'
				);
			}
		});
	}

	assignAgentToEstates(managingAgentId: string | null, estateIds: string[]) {
		this.isSubmitting$.next(true);
		this.apiService
			.postData('agency/estates/assign-agent', {
				managingAgentId,
				estateIds
			})
			.subscribe({
				next: () => {
					this.fetchEstates();
					this.toastService.add(
						'Estates have been successfully assigned',
						5000,
						'success'
					);
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	updateEstate(estateId: string, estate: Partial<ManagedEstate>) {
		this.isSubmitting$.next(true);
		this.apiService
			.putData(`agency/estate/${estateId}`, { ...estate })
			.subscribe({
				next: () => {
					this.fetchEstates();
					this.toastService.add(
						'Estate has been successfully updated',
						5000,
						'success'
					);
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	createEstate(estate: Partial<ManagedEstate>) {
		return this.apiService.postData(`agency/create-estate`, { ...estate });
	}

	linkPropertiesToEstate(estateId: string | null, propertyIds: string[]) {
		this.isSubmitting$.next(true);
		this.apiService
			.postData('agency/estate/link-properties', {
				estateId,
				propertyIds
			})
			.subscribe({
				next: () => {
					this.agentPropertiesService.fetchProperties();
					// this.fetchEstates(); //TODO: Uncomment when moved to it's own properties service
					this.toastService.add(
						'Property links successfully managed',
						5000,
						'success'
					);
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	estateMetrics(): Observable<EstateMetrics | null> {
		return this.estateMetrics$.asObservable();
	}

	estates(): Observable<PaginatedResponse<ManagedEstate> | null> {
		return this.estates$.asObservable();
	}
}
