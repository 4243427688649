import { Component, input, model, output } from '@angular/core';
import { PropertyAvatarComponent } from '../property-avatar/property-avatar.component';
import { NgClass } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { FavouritePillComponent } from '../favourite-pill/favourite-pill.component';

@Component({
	selector: 'fixify-sp-info-card',
	standalone: true,
	imports: [
		PropertyAvatarComponent,
		NgClass,
		IconComponent,
		FavouritePillComponent
	],
	templateUrl: './sp-info-card.component.html',
	styleUrl: './sp-info-card.component.css'
})
export class SpInfoCardComponent {
	companyName = input.required<string>();
	secondaryText = input<string>('');
	tertiaryText = input<string>('');
	isFavourite = input<boolean>(false);
	imageUrl = input<string>('');
	actionText = input<string>('');
	cardVariant = input<boolean>(false);
	selectable = input<boolean>(false);
	selected = model<boolean>();
	actionPress = output();
	tertiaryPress = output();
	restrictSize = input<boolean>(true);

	actionClick($event: MouseEvent) {
		$event.stopPropagation();
		this.actionPress.emit();
	}

	itemClick() {
		if (!this.selectable()) return;

		this.selected.set(!this.selected());
	}

	teritaryClick($event: MouseEvent) {
		$event.stopPropagation();
		this.tertiaryPress.emit();
	}
}
