import { Injectable, signal } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { JobMetrics, ManagedJobs, UploadedDocument } from './agency.types';
import { PaginatedResponse } from '../../utils/pagination';
import { Category } from '../service-provider/service-provider.types';

type ManagedJobsFilters = {
	search?: string;
	page?: number;
	limit?: number;
	category?: string;
	coordinates?: string;
	sort?: string;
	sortBy?: string;
	filter?: Record<string, string | number>;
};
@Injectable({
	providedIn: 'root'
})
export class AgencyJobsService {
	private isFetching$ = new BehaviorSubject<boolean>(false);
	private jobs$ = new BehaviorSubject<PaginatedResponse<ManagedJobs> | null>(
		null
	);
	private categories$ = new BehaviorSubject<Array<Category> | null>(null);
	private jobMetrics$ = new BehaviorSubject<JobMetrics | null>(null);

	private paramSignal = signal<ManagedJobsFilters>({});

	constructor(private apiService: ApiService) {
		this.fetchCategories();
		this.fetchJobs();
		this.fetchJobsMetrics();
	}

	fetchCategories() {
		this.apiService.getData(`agency/jobs/categories`).subscribe({
			next: data => {
				const categories = data.body.categories as Array<Category>;
				this.categories$.next(categories);
			},
			error: error => {
				console.error(error);
				this.categories$.next([]);
			}
		});
	}

	submitJob(job: Partial<ManagedJobs>) {
		return this.apiService.postData(`agency/job`, job);
	}

	fetchJobs(_params?: ManagedJobsFilters) {
		this.isFetching$.next(true);
		this.setParamSignal(_params);
		const { filter, ...queryParams } = this.paramSignal();
		const query: Record<string, string | number | undefined> = {
			...queryParams
		};
		if (filter?.['status'] !== 'all') {
			query['filter'] = JSON.stringify(filter);
		}
		const filteredQuery = Object.fromEntries(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			Object.entries(query).filter(([_, value]) => value !== undefined)
		) as Record<string, string | number>;
		const params = this.apiService.buildFilterQueryParams(filteredQuery);
		this.jobs$.next(null);
		this.apiService.getData(`agency/jobs${params}`).subscribe({
			next: data => {
				const paginatedData =
					data.body as PaginatedResponse<ManagedJobs>;
				paginatedData.result = paginatedData.result.map(job => {
					if (job.managingAgent) {
						if (
							job.managingAgent.firstName &&
							job.managingAgent.lastName
						)
							job.managingAgent.name = `${job.managingAgent.firstName.charAt(0)}. ${job.managingAgent.lastName}`;
					}

					return job;
				});
				this.jobs$.next(paginatedData);
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isFetching$.next(false);
			}
		});
	}

	fetchJobsMetrics() {
		return this.apiService.getData('agency/jobs/metrics').subscribe({
			next: data => {
				const metrics = data.body as JobMetrics;
				this.jobMetrics$.next(metrics);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	updateServiceProviders(jobId: string, job: Partial<ManagedJobs>) {
		return this.apiService.putData(
			`agency/job/${jobId}/service-providers`,
			job
		);
	}

	updatePaymentResponsibility(jobId: string, responsibleForPayment: string) {
		return this.apiService.putData(
			`agency/job/${jobId}/payment-responsibility`,
			{ responsibleForPayment }
		);
	}

	cancelJob(jobId: string, reason: string) {
		return this.apiService.deleteData(`agency/job/${jobId}/cancel`, {
			reason
		});
	}

	manageQuote(jobId: string, doc: UploadedDocument) {
		return this.apiService.postData(`agency/job/${jobId}/quote`, doc);
	}

	manageInvoice(jobId: string, doc: UploadedDocument) {
		return this.apiService.postData(`agency/job/${jobId}/invoice`, doc);
	}

	getParams() {
		return this.paramSignal();
	}

	setParamSignal(params?: ManagedJobsFilters) {
		if (!params) {
			return this.clearParams();
		}
		this.paramSignal.set(params);
	}

	clearParams() {
		this.paramSignal.set({});
	}

	fetchJob(jobId: string, refresh = false) {
		if (
			!refresh &&
			this.jobs$.value?.result.some(job => job.id === jobId)
		) {
			return new Observable(subscriber => {
				subscriber.next({
					body: this.jobs$.value?.result.find(job => job.id === jobId)
				});
				subscriber.complete();
			});
		}
		return this.apiService.getData(`agency/job/${jobId}`);
	}

	fetchJobDetails(jobId: string) {
		return this.apiService.getData(`agency/job/${jobId}/details`);
	}

	jobs(): Observable<PaginatedResponse<ManagedJobs> | null> {
		return this.jobs$.asObservable();
	}

	categories(): Observable<Array<Category> | null> {
		return this.categories$.asObservable();
	}

	jobMetrics(): Observable<JobMetrics | null> {
		return this.jobMetrics$.asObservable();
	}
}
