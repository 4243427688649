<fixify-modal size="lg">
	<div class="self-start h-8 w-8 p-2 rounded-md bg-primary-50 mb-5">
		<fixify-icon
			class="text-primary-500 stroke-2"
			name="calendar-date"
		></fixify-icon>
	</div>
	<p class="text-xl font-semibold text-gray-900 mb-1">
		{{ data?.title ?? 'Date and time' }}
	</p>
	<p class="text-sm font-normal text-gray-500 mb-6">
		Add the agreed upon date and time here.
	</p>
	@if (error()) {
		<fixify-validation-message
			text="Date cannot be in the past"
		></fixify-validation-message>
	}

	<div [formGroup]="dateForm" class="flex flex-col gap-3">
		<div class="relative">
			<div
				class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
			>
				<fixify-icon
					class="text-gray-500 stroke-1 w-4 h-4"
					name="calendar-plus-01"
				></fixify-icon>
			</div>
			<input
				datepicker
				datepicker-format="dd MM yyyy"
				datepicker-autohide
				type="text"
				(changeDate)="onDatePicked($event)"
				class="block w-full ps-10 form-input focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent"
				placeholder="Select date"
			/>
		</div>
		<div class="relative">
			<div
				class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
			>
				<fixify-icon
					class="text-gray-500 stroke-1 w-4 h-4"
					name="clock"
				></fixify-icon>
			</div>
			<input
				#timeInput
				(click)="openPicker(timeInput)"
				onfocus="(this.type='time')"
				min="{{ now.getHours() }}:{{ now.getMinutes() }}"
				formControlName="hourMinutes"
				onblur="(this.value == '' ? this.type='text' : this.type='time')"
				class="block w-full ps-10 form-input focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent"
				placeholder="Select time"
			/>
		</div>
	</div>

	<div class="flex justify-end gap-2 mt-6">
		<button (click)="close()" class="btn btn-outline border-gray-200">
			Cancel
		</button>
		<button
			[disabled]="!selectedDate()"
			(click)="saveDateTime()"
			class="btn btn-primary"
		>
			Save
		</button>
	</div>
</fixify-modal>

