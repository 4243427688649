import { Component, input } from '@angular/core';
import { AvatarComponent } from '../../../../shared/ui/avatar/avatar.component';
import { IconItemComponent } from '../../../../shared/ui/icon-item/icon-item.component';
import { IconComponent } from '../../../../shared/ui/icon/icon.component';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited
} from '../../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-service-provider-agency-details',
	standalone: true,
	imports: [AvatarComponent, IconItemComponent, IconComponent],
	templateUrl: './service-provider-agency-details.component.html',
	styleUrl: './service-provider-agency-details.component.css'
})
export class ServiceProviderAgencyDetailsComponent {
	managedFulfilment = input.required<
		ManagedFulfilmentDetails | ManagedFulfilmentLimited
	>();

	agentEmail() {
		const agent = this.managedFulfilment().agent;
		return 'email' in agent ? agent.email : '';
	}

	agentMobileNumber() {
		const agent = this.managedFulfilment().agent;
		return 'mobileNumber' in agent ? agent.mobileNumber : '';
	}
}
