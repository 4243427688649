<div class="flex gap-4">
	<div class="w-5 h-5 text-gray-500 m-0.5">
		<fixify-icon [name]="iconName()"></fixify-icon>
	</div>
	<div class="flex flex-col gap-1 text-base font-normal">
		<p class="text-gray-500">{{ title() }}</p>
		@if (subtitle()) {
			<p class="text-gray-900 ml-1">{{ subtitle() }}</p>
		}
		@if (subsubtitle()) {
			<p class="text-gray-900 ml-1">{{ subsubtitle() }}</p>
		}
		@if (images().length > 0) {
			<div class="flex flex-wrap gap-2">
				@for (image of images(); track $index) {
					<div class="relative w-20 h-20 2xl:w-28 2xl:h-28">
						<img
							(click)="view(image)"
							class="w-full h-full object-cover rounded-lg cursor-pointer"
							src="{{ image }}"
						/>
					</div>
				}
			</div>
		}
	</div>
</div>

