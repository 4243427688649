import { Injectable } from '@angular/core';
import { ApiService } from '../api-service.service';
import {
	Agency,
	createAJobRequest,
	createAPropertyRequest,
	residentialSearchUserRequest
} from './residential.types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ResidentialService {
	private agency$ = new BehaviorSubject<Agency | null>(null);
	constructor(private apiService: ApiService) {}

	fetchAgencyDetails(agencyId: string) {
		return this.apiService.getData(`residential/${agencyId}`);
	}

	fetchAllResidentialProperties(
		agencyId: string | null,
		search: residentialSearchUserRequest
	) {
		if (agencyId === null) {
			throw new Error('Agency ID is not set');
		}
		return this.apiService.postData(
			`residential/${agencyId}/properties`,
			search
		);
	}

	fetchPropertyDetails(agencyId: string | null, propertyId: string | null) {
		if (agencyId === null || propertyId === null) {
			throw new Error('Agency ID and Property ID are required');
		}
		return this.apiService.getData(
			`residential/${agencyId}/properties/${propertyId}`
		);
	}

	createAJob(
		agencyId: string | null,
		propertyId: string | null,
		data: createAJobRequest
	) {
		if (agencyId === null) {
			throw new Error('Agency ID is not set');
		}
		if (propertyId === null) {
			throw new Error('Property ID is not set');
		}
		return this.apiService.postData(
			`residential/${agencyId}/properties/${propertyId}/create-job`,
			data
		);
	}

	createAProperty(agencyId: string | null, data: createAPropertyRequest) {
		if (agencyId === null) {
			throw new Error('Agency ID is not set');
		}
		return this.apiService.postData(
			`residential/${agencyId}/create-property`,
			data
		);
	}

	fetchEstates(agencyId: string | null) {
		if (agencyId === null) {
			throw new Error('Agency ID is not set');
		}
		return this.apiService.getData(`residential/${agencyId}/estates`);
	}

	setAgency(agency: Agency) {
		this.agency$.next(agency);
	}

	agency() {
		return this.agency$.asObservable();
	}
}
