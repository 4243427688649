<!-- MODALS -->

<fixify-view-linked-agency-modal
	[(isModalOpen)]="isViewLinkedAgencyModalOpen"
	[linkedAgency]="selectedAgency()!"
	(onDelink)="openDelinkAgencyModal()"
></fixify-view-linked-agency-modal>

<!-- END MODALS -->

<div class="container mx-auto">
	<div class="flex justify-between mb-4">
		<p class="text-2xl text-gray-900 font-medium mb-2 font-outfit">
			My locations
		</p>
		@if (locations().length > 0) {
			<div class="flex gap-2">
				<button (click)="openAddLocationModal()" class="btn-primary">
					Add location
				</button>
			</div>
		}
	</div>

	@if (locations().length === 0) {
		<fixify-no-locations
			(addLocation)="openAddLocationModal()"
		></fixify-no-locations>
		<div class="mt-6">
			<fixify-divider></fixify-divider>
		</div>
	} @else {
		<div class="pb-4"></div>
		<fixify-service-provider-locations
			[locations]="locations()"
		></fixify-service-provider-locations>
	}

	<p class="text-2xl text-gray-900 font-medium mb-8 mt-6 font-outfit">
		Linked agencies
	</p>
	@if (linkedAgencies.length === 0) {
		<div class="flex flex-col w-full items-center mt-8 pb-4">
			<img src="assets/svg/no-agencies.svg" />
			<p class="text-base font-semibold text-gray-700 mb-1">
				No linked agencies
			</p>
			<p class="text-sm font-normal text-gray-500 mb-1">
				Not directly linked to any agencies.
			</p>
		</div>
	} @else {
		<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
			@for (agency of linkedAgencies; track $index) {
				<fixify-agency-tile
					(click)="openViewLinkedAgencyModal(agency)"
					[linkedAgency]="agency"
				></fixify-agency-tile>
			}
		</div>
	}
</div>
