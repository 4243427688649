import { Component, input, output, signal } from '@angular/core';
import { DividerComponent } from '../divider/divider.component';
import { GoogleMap, MapAdvancedMarker, MapCircle } from '@angular/google-maps';
import { DEFAULT_LAT_LNG } from '../../models/maps';
import { Location } from '../../data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-service-provider-locations',
	standalone: true,
	imports: [DividerComponent, GoogleMap, MapCircle, MapAdvancedMarker],
	templateUrl: './service-provider-locations.component.html',
	styleUrl: './service-provider-locations.component.css'
})
export class ServiceProviderLocationsComponent {
	allowActions = input<boolean>(true);
	selectedLocation = signal<Location | null>(null);
	delete = output<Location>();
	edit = output<Location>();
	radius = signal<number>(30000);
	circleOptions: google.maps.CircleOptions = {
		fillColor: '#FFA757',
		fillOpacity: 0.4,
		strokeWeight: 0,
		clickable: false
	};
	center = signal(DEFAULT_LAT_LNG);
	zoom = signal(5);
	options: google.maps.MapOptions = {
		streetViewControl: false,
		zoomControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		mapId: '4ae9361332aa6c90',
		minZoom: 5,
		restriction: {
			latLngBounds: {
				north: -20.1258,
				south: -36.8192,
				west: 14.5126,
				east: 35.8913
			},
			strictBounds: false
		}
	};

	locations = input.required<Location[]>();

	deleteLocation(location: Location) {
		this.delete.emit(location);
	}

	editLocation(location: Location) {
		this.edit.emit(location);
	}

	generateContent(id: string) {
		const content = document.createElement('div');
		content.id = id;
		content.innerHTML = `<img src="assets/svg/map-pin.svg" class="translate-y-3" />`;
		return content;
	}

	async goToLocation(location: [number, number]) {
		// change to force zoom, otherwise the zoom will not reset if manual zoom was used by the user
		if (this.zoom() === 10) {
			this.zoom.set(10.2);
		} else {
			this.zoom.set(10);
		}

		// wait for zoom to finish, otherwise the map will not center correctly due to it's set restrictions
		await new Promise(resolve => setTimeout(resolve, 1));
		this.center.set({
			lat: location[0],
			lng: location[1]
		});
	}
}
