<div class="relative container mx-auto flex flex-col h-full">
	<div class="absolute inset-0 flex flex-col">
		<div class="flex justify-between items-center mb-8">
			<p class="text-2xl text-gray-900 font-medium mb-2 font-outfit">
				My Maintenance Jobs
			</p>
		</div>

		<div
			class="flex mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
		>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">Total jobs</p>
				<p class="text-3xl text-primary-500">1201</p>
				<div class="flex items-center">
					<fixify-icon
						class="text-green-500 w-4 h-4"
						name="arrow-up"
					></fixify-icon>
					<p class="text-xs font-normal text-gray-500">
						<span class="text-green-500">35%</span> vs last month
					</p>
				</div>
			</fixify-dashboard-card>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					Average job duration
				</p>
				<p class="text-3xl text-primary-500">5 days</p>
				<div class="flex items-center">
					<fixify-icon
						class="text-green-500 w-4 h-4"
						name="arrow-up"
					></fixify-icon>
					<p class="text-xs font-normal text-gray-500">
						<span class="text-green-500">12%</span> vs last month
					</p>
				</div>
			</fixify-dashboard-card>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					Total properties serviced
				</p>
				<p class="text-3xl text-primary-500">12</p>
				<div class="flex items-center">
					<fixify-icon
						class="text-green-500 w-4 h-4"
						name="arrow-up"
					></fixify-icon>
					<p class="text-xs font-normal text-gray-500">
						<span class="text-green-500">20%</span> vs last month
					</p>
				</div>
			</fixify-dashboard-card>
		</div>

		<div class="flex-grow">
			<fixify-service-provider-jobs-table></fixify-service-provider-jobs-table>
		</div>
	</div>
</div>
