/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, input, model, output, signal } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ReactiveFormsModule
} from '@angular/forms';
import {
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	takeUntil
} from 'rxjs';
import { NgClass } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'fixify-generic-search-input',
	standalone: true,
	imports: [NgClass, ReactiveFormsModule, IconComponent],
	templateUrl: './generic-search-input.component.html',
	styleUrl: './generic-search-input.component.css'
})
export class GenericSearchInputComponent<T> {
	private searchSubject = new Subject<{
		input: string;
	}>();
	control = input.required<AbstractControl>();
	ngControl!: FormControl;
	searchResults = model<Array<T>>([]);
	showRequiredStar = input<boolean>(false);
	label = input<string>('');
	disabled = input<boolean>(false);
	placeholder = input<string>('Search...');
	ignoreNextSearchChange = signal<boolean>(false);
	selectedResult = output<T | null>();
	multiSelect = input<boolean>(false);
	allSelections = model<Array<T>>([]);
	searching = output<boolean>();
	destroy$ = new Subject<void>();
	genericSearchFunction =
		input.required<(input: string) => Observable<Array<T>>>();
	transformer = input<(item: T) => string>(item => {
		if (typeof item === 'string') {
			return item;
		} else {
			return JSON.stringify(item);
		}
	});
	uniquenessFunction = input<(item: T) => string>();
	isFocused = model(false);

	handleInputClick(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();
	}

	ngOnInit() {
		this.ngControl = this.control() as FormControl;

		this.initSearchStream()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: results => {
					let uniqueResults = results;
					if (this.uniquenessFunction()) {
						uniqueResults = results.filter(
							r =>
								!this.allSelections().some(
									s =>
										this.uniquenessFunction()!(s) ===
										this.uniquenessFunction()!(r)
								)
						);
					}

					this.searchResults.set(uniqueResults ?? []);
					this.searching.emit(false);
				},
				error: error => {
					console.error(error);
				}
			});

		this.ngControl.valueChanges
			.pipe(
				debounceTime(500),
				takeUntil(this.destroy$),
				distinctUntilChanged()
			)
			.subscribe(val => {
				//TODO: @Wian I removed the !val check so that it would trigger on an empty string, please check if this is correct
				if (this.ignoreNextSearchChange()) {
					this.ignoreNextSearchChange.set(false);
					return;
				}
				this.searching.emit(true);
				this.selectedResult.emit(null);

				this.search(val);
			});
	}

	initSearchStream(): Observable<Array<T>> {
		return this.searchSubject
			.asObservable()
			.pipe(
				exhaustMap(({ input }) =>
					this.genericSearchFunction().bind(this)(input)
				)
			);
	}

	search(input: string): void {
		this.searchSubject.next({ input });
	}

	selected(suggestion: T) {
		if (!this.multiSelect()) {
			this.ignoreNextSearchChange.set(true);
			this.ngControl.setValue(this.transformer()(suggestion));
			this.searchResults.set([]);
		} else {
			this.searchResults.set(
				this.searchResults().filter(s => s !== suggestion)
			);
		}

		this.selectedResult.emit(suggestion);

		if (this.multiSelect()) {
			// add to selections if not duplicate
			if (!this.allSelections().includes(suggestion)) {
				this.allSelections.set([...this.allSelections(), suggestion]);
			}
		}
	}

	setFocus(value: boolean) {
		this.isFocused.set(value);
	}

	removeSuggestion(suggestion: T) {
		this.allSelections.set(
			this.allSelections().filter(s => s !== suggestion)
		);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
