import { Component, signal } from '@angular/core';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { ManagedAgent } from '../../../../../shared/data-access/agency/agency.types';

@Component({
	selector: 'fixify-view-agent-modal',
	standalone: true,
	imports: [
		AvatarComponent,
		IconComponent,
		DatePipe,
		ModalCardComponent,
		TitleCasePipe
	],
	templateUrl: './view-agent-modal.component.html',
	styleUrl: './view-agent-modal.component.css'
})
export class ViewAgentModalComponent extends ModalComponent<
	ManagedAgent,
	'edit' | 'delete'
> {
	agent = signal<ManagedAgent | undefined>(undefined);

	protected override onDataSet(): void {
		this.agent.set(this.data);
	}

	deleteClicked() {
		this.close('delete');
	}

	editClicked() {
		this.close('edit');
	}
}
