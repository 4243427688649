<div class="max-w px-12 md:px-22 xl:px-44">
	<div
		(click)="goBack()"
		class="flex gap-2 items-center cursor-pointer mb-1.5"
	>
		<fixify-icon
			class="w-4 h-4 text-gray-800"
			name="arrow-left"
		></fixify-icon>
		<p class="text-sm font-medium text-gray-800">Back</p>
	</div>
	<div class="flex justify-start items-center">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl leading-tight pr-2 py-2.5">
				Agent details
			</h1>
		</div>
		<div class="flex gap-3">
			<button
				(click)="showDeleteAgentModal()"
				class="grow-0 btn btn-outline danger"
			>
				Delete
			</button>
			<button
				(click)="onSubmit()"
				[disabled]="!formValid() || !formTouched()"
				class="grow-0 btn btn-primary"
			>
				Save
			</button>
		</div>
	</div>

	<div class="h-8"></div>

	<form
		#formElement="ngForm"
		[formGroup]="agentInfoForm"
		class="grid gap-x-6 lg:gap-x-12 gap-y-6 grid-cols-1 md:grid-cols-2 gap-6 pt-6"
	>
		<div class="w-20 h-20 self-start">
			<fixify-avatar
				source="{{ agent()?.profilePicture }}"
				[placeholder]="initials()"
			></fixify-avatar>
		</div>
		<div class="">
			<fixify-text-input
				[disabled]="true"
				[control]="agentInfoForm.get('firstName')!"
				label="First name"
				id="firstName"
				inputType="text"
				placeholder="Agent first name"
				errorText="Enter agent first name"
				[showRequiredStar]="true"
			></fixify-text-input>
		</div>
		<div class="row-start-1 col-start-1 md:col-start-2 row-span-5">
			<div class="flex flex-col gap-4">
				<div
					class="flex-1 rounded-lg border-gray-100 border-2 p-4 flex flex-col gap-0.5 justify-start"
				>
					<fixify-icon
						class="w-6 h-6 mb-2"
						name="home-05-plus"
					></fixify-icon>
					<div class="flex gap-2 justify-between items-center">
						<div class="flex flex-col gap-0.5">
							<p class="text-sm text-gray-500">
								Property portfolio
							</p>
							<p class="text-sm text-gray-900">
								-- linked properties
							</p>
						</div>
						<button
							class="btn-outline small border-0 text-primary-500"
						>
							View
						</button>
					</div>
				</div>
				<div
					class="flex-1 rounded-lg border-gray-100 border-2 p-4 flex flex-col gap-0.5 justify-start"
				>
					<fixify-icon
						class="w-6 h-6 mb-2"
						name="luggage-plus"
					></fixify-icon>
					<div class="flex gap-2 justify-between items-center">
						<div class="flex flex-col gap-0.5">
							<p class="text-sm text-gray-500">Managed jobs</p>
							<p class="text-sm text-gray-900">-- linked jobs</p>
						</div>
						<button
							class="btn-outline small border-0 text-primary-500"
						>
							View
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="">
			<fixify-text-input
				[disabled]="true"
				[control]="agentInfoForm.get('lastName')!"
				label="Last name"
				id="lastName"
				inputType="text"
				placeholder="Agent last name"
				errorText="Enter agent last name"
			></fixify-text-input>
		</div>
		<div class="">
			<fixify-dropdown
				class="mt-8"
				[control]="agentInfoForm.get('role')!"
				[currentlySelectedItem]="selectedRole()"
				[items]="roles"
				[nameFieldOfItem]="'name'"
				[defaultSelectable]="false"
				[valueFieldOfItem]="'value'"
				defaultText="-- Agent role --"
				label="Role"
				[showRequiredStar]="true"
			></fixify-dropdown>
		</div>
		<div class="">
			<fixify-text-input
				[disabled]="true"
				[control]="agentInfoForm.get('email')!"
				label="Email address"
				id="email"
				inputType="email"
				placeholder="Agent email address"
			></fixify-text-input>
		</div>
		<div class="">
			<label for="mobileNumber" class="input-label">Phone number</label>
			<div class="flex cursor-not-allowed">
				<select
					id="country"
					class="text-gray-400 form-input max-w-28 rounded-r-none pointer-events-none"
				>
					<option selected>
						{{ agentCountry }}
					</option>
				</select>
				<div
					class="bg-gray-100 w-[1px] flex justify-center items-center"
				>
					<div class="h-4 w-full bg-gray-300"></div>
				</div>
				<input
					[value]="agent()?.mobileNumber ?? ''"
					readonly
					type="tel"
					id="mobileNumber"
					class="text-gray-400 form-input rounded-l-none focus:ring-0 focus:outline-none focus:border-0 cursor-not-allowed"
					placeholder="Agent number"
				/>
			</div>
		</div>
	</form>
</div>

