import { Injectable } from '@angular/core';
import { ApiService } from '../api-service.service';
import { AgencyInvites } from './agency.types';
import { ModalService } from '../../ui/modals/modal.service';
import { InviteToAgencyComponent } from '../../ui/modals/invite-to-agency/invite-to-agency.component';

@Injectable({
	providedIn: 'root'
})
export class AgencyInvitesService {
	constructor(
		private apiService: ApiService,
		private modalService: ModalService
	) {}

	// Invites
	fetchAgencyInvites() {
		this.apiService.getData('agency/fetch-agency-invites').subscribe({
			next: data => {
				const agencyInvites = (data.body as AgencyInvites)
					.agencyInvites;

				agencyInvites.forEach(async invite => {
					const accepted = await this.modalService.showModal(
						InviteToAgencyComponent,
						invite
					);
					if (accepted) {
						this.acceptAgencyInvite(invite.id);
					} else {
						this.declineAgencyInvite(invite.id);
					}
				});
			},
			error: error => {
				console.error(error);
			}
		});
	}

	acceptAgencyInvite(inviteId: string) {
		this.apiService
			.postData('agency/accept-agency-invite', { inviteId })
			.subscribe({
				next: () => {
					window.location.reload();
				},
				error: error => {
					console.error(error);
				}
			});
	}

	declineAgencyInvite(inviteId: string) {
		this.apiService
			.postData('agency/decline-agency-invite', { inviteId })
			.subscribe({
				error: error => {
					console.error(error);
				}
			});
	}
}
