<div>
	<p class="text-lg font-medium text-gray-900 mb-8">Job overview</p>
	<div class="flex flex-col gap-6">
		<fixify-icon-item
			iconName="tool-02"
			title="Job type"
			[subtitle]="managedFulfilment().category"
		></fixify-icon-item>
		<div class="flex gap-3">
			@if (managedFulfilment().job.requiresOnsiteVisit) {
				<div class="flex-1 flex gap-6">
					<fixify-icon-item
						iconName="calendar-plus-01"
						title="Site visit date & time"
						[subtitle]="
							managedFulfilment().scheduledQuoteDate !== null
								? (managedFulfilment().scheduledQuoteDate | date: 'dd MMM yyyy · HH:mm')
								: 'To be confirmed'
						"
					></fixify-icon-item>
					@if (managedFulfilment().jobStatus !== 'new') {
						<div
							(click)="siteVisitDateTime()"
							class="cursor-pointer rounded-full aspect-square h-8 bg-primary-50 p-1.5"
						>
							<fixify-icon
								class="w-6 h-6 text-primary-500"
								[name]="managedFulfilment().scheduledQuoteDate ? 'pencil-line' : 'plus'"
							></fixify-icon>
						</div>
					}
				</div>
			}
			@if (managedFulfilment().jobStatus !== 'new') {
				@if (managedFulfilment().job.requiresOnsiteVisit) {
					<div class="flex flex-col">
						<div
							class="h-full border-[0.5px] border-gray-200"
						></div>
					</div>
				}
				<div class="flex-1 flex gap-6">
					<fixify-icon-item
						iconName="calendar-plus-01"
						title="Job date & time"
						[subtitle]="
							managedFulfilment().scheduledJobDate !== null
								? (managedFulfilment().scheduledJobDate | date: 'dd MMM yyyy · HH:mm')
								: 'To be confirmed'
						"
					></fixify-icon-item>
					<div
						(click)="jobDateTime()"
						class="cursor-pointer rounded-full aspect-square h-8 bg-primary-50 p-1.5"
					>
						<fixify-icon
							class="w-6 h-6 text-primary-500"
							[name]="managedFulfilment().scheduledJobDate ? 'pencil-line' : 'plus'"
						></fixify-icon>
					</div>
				</div>
			}
		</div>
		<fixify-icon-item
			iconName="pencil-line"
			title="Job description"
			[subtitle]="managedFulfilment().job.description"
		></fixify-icon-item>
		<fixify-icon-item
			iconName="sticker-square"
			title="Extra notes"
			[subtitle]="managedFulfilment().job.notes"
		></fixify-icon-item>
		<fixify-icon-item
			iconName="camera-01"
			title="Photos"
			[images]="managedFulfilment().job.images"
		></fixify-icon-item>
	</div>
</div>

