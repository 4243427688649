<fixify-modal (onBackdropClick)="close()" size="md">
	<div class="flex gap-2 justify-between items-start mb-5">
		<div class="w-20 h-20">
			<fixify-avatar></fixify-avatar>
		</div>
		<fixify-icon
			(click)="close()"
			class="w-8 h-8 text-gray-400 cursor-pointer"
			name="x-close"
		></fixify-icon>
	</div>
	<div class="flex flex-col gap-1 mb-6">
		<p class="text-xl font-semibold">{{ data?.stakeholder?.fullName }}</p>
		<p class="text-sm text-gray-500">
			{{ data?.type ?? 'Other' | titlecase }}
		</p>
	</div>
	<div class="mb-8">
		<ul>
			<li class="py-3">
				<fixify-icon-item
					iconName="mail-01"
					title="Email address"
					[subtitle]="
						data?.stakeholder?.email ?? 'No email address provided'
					"
				></fixify-icon-item>
			</li>
			<li class="py-3">
				<fixify-icon-item
					iconName="phone"
					title="Contact number"
					[subtitle]="
						data?.stakeholder?.mobileNumber ??
						'No contact number provided'
					"
				></fixify-icon-item>
			</li>
		</ul>
	</div>
	@if (data?.editVisible) {
		<div
			class="flex items-center justify-end space-x-2 border-gray-200 rounded-b mt-2"
		>
			<button (click)="editClicked()" type="button" class="btn-outline">
				Edit Details
			</button>
		</div>
	}
</fixify-modal>

