<fixify-category-form
	[(categoryForm)]="categoryForm"
	[categories]="categories()"
	[spCategory]="spCategory()"
	[canEditCategoryType]="false"
	[unavailableCategoryIds]="unavailableCategoryIds()"
></fixify-category-form>

<div class="flex flex-row items-center justify-end gap-2 pb-10">
	<svg
		(click)="stopEditing()"
		class="w-6 h-6 p-1 text-gray-600 cursor-pointer"
		aria-hidden="true"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
	>
		<path
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
			d="M3 9h13a5 5 0 0 1 0 10H7M3 9l4-4M3 9l4 4"
		/>
	</svg>

	<button
		(click)="openDeleteModal()"
		class="btn-outline danger small border-gray-200"
	>
		Delete category
	</button>
	<button (click)="saveAndStopEdit()" class="btn-primary small">
		Save changes
	</button>
</div>

