import { Component, computed, signal } from '@angular/core';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { NgClass } from '@angular/common';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited,
	ServiceProviderManageDocument
} from '../../../../../shared/data-access/service-provider/service-provider.types';
import { ref, Storage, deleteObject } from '@angular/fire/storage';
import { ServiceProviderJobsService } from '../../../../../shared/data-access/service-provider/service-provider.jobs.service';
import { ToastService } from '../../../../../shared/data-access/toast.service';
import { LoaderComponent } from '../../../../../shared/ui/loader/loader.component';
import { uploadMedia } from '../../../../../shared/utils/media/upload';
import { catchError, forkJoin, of, tap } from 'rxjs';

@Component({
	selector: 'app-job-upload-document',
	standalone: true,
	imports: [ModalCardComponent, IconComponent, NgClass, LoaderComponent],
	templateUrl: './job-upload-document.component.html',
	styleUrl: './job-upload-document.component.css'
})
export class JobUploadDocumentComponent extends ModalComponent<
	{
		type: 'quote' | 'photo' | 'invoice';
		title: string;
		subtitle: string;
		fulfilment: ManagedFulfilmentDetails | ManagedFulfilmentLimited;
	},
	Array<ServiceProviderManageDocument> | Array<string>
> {
	dragOver = signal<boolean>(false);
	cannotSubmit = computed(
		() =>
			this.loading() ||
			this.uploads().length === 0 ||
			this.uploads().some(
				u =>
					(u.progress < 100 && u.error === null) ||
					(u.progress === 100 && u.url === null)
			)
	);
	cannotCancel = computed(
		() => this.loading() || this.uploads().some(u => u.url !== null)
	);
	loading = signal<boolean>(false);
	uploads = signal<
		Array<{
			file: File;
			progress: number;
			url: string | null;
			error: string | null;
		}>
	>([]);

	constructor(
		private storage: Storage,
		private serviceProviderJobsService: ServiceProviderJobsService,
		private toastService: ToastService
	) {
		super();
	}

	onDragOver(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dragOver.set(true);
	}

	onDragLeave(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dragOver.set(false);
	}

	onDrop(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.dragOver.set(false);

		const files = event.dataTransfer?.files;
		if (files?.length) {
			this.handleFiles(Array.from(files));
		}
	}

	onFileSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files?.length) {
			this.handleFiles(Array.from(input.files));
		}
	}

	private handleFiles(files: Array<File>) {
		const validFiles = Array.from(files).filter(file => {
			const fileSizeMB = file.size / 1024 / 1024;
			return fileSizeMB <= 50;
		});

		if (validFiles.length === 0) {
			return;
		}

		const fulfilment = this.data?.fulfilment;
		if (!fulfilment) return;

		// Initialize uploads state
		this.uploads.update(current => [
			...current,
			...validFiles.map(file => ({
				file,
				progress: 0,
				url: null,
				error: null
			}))
		]);

		// Create upload tasks
		const uploadTasks = validFiles.map(file => {
			const filePath = `agency/job/${fulfilment.job.id}/fulfilment/${fulfilment.id}/${this.data?.type}s/${file.name}`;
			const storageRef = ref(this.storage, filePath);

			return uploadMedia(file, storageRef).pipe(
				tap(progress => {
					this.uploads.update(uploads =>
						uploads.map(upload =>
							upload.file.name === file.name
								? {
										...upload,
										progress: progress.percentage,
										url: progress.url || null
									}
								: upload
						)
					);
				}),
				catchError(error => {
					this.uploads.update(uploads =>
						uploads.map(upload =>
							upload.file.name === file.name
								? {
										...upload,
										error: error.message
									}
								: upload
						)
					);
					return of(null);
				})
			);
		});

		// Execute uploads
		forkJoin(uploadTasks).subscribe();
		this.resetInput();
	}

	async remove(fileUrl: string) {
		const storageRef = ref(this.storage, fileUrl);
		await deleteObject(storageRef);

		// Remove file from uploads array
		this.uploads.update(uploads => uploads.filter(u => u.url !== fileUrl));
		this.resetInput();
	}

	retry(fileToRetry: string) {
		const upload = this.uploads().find(u => u.file.name === fileToRetry);
		if (!upload || !this.data?.fulfilment) return;

		const filePath = `agency/job/${this.data.fulfilment.job.id}/fulfilment/${this.data.fulfilment.id}/${this.data.type}s/${upload.file.name}`;
		const storageRef = ref(this.storage, filePath);

		uploadMedia(upload.file, storageRef).subscribe({
			next: result => {
				this.uploads.update(uploads =>
					uploads.map(u =>
						u.file.name === fileToRetry
							? {
									...u,
									progress: 100,
									url: result.url,
									error: null
								}
							: u
					)
				);
			},
			error: error => {
				console.error('Retry failed:', error);
				this.uploads.update(uploads =>
					uploads.map(u =>
						u.file.name === fileToRetry
							? { ...u, error: 'Upload failed' }
							: u
					)
				);
			}
		});
	}

	resetInput() {
		const input = document.getElementById('file-input') as HTMLInputElement;
		if (input) {
			input.value = '';
		}
	}

	submit() {
		const uploadedFiles = this.uploads().filter(u => u.url !== null);
		if (uploadedFiles.length === 0) {
			this.toastService.add('No files uploaded', 5000, 'error');
			return;
		}

		const fulfilment = this.data?.fulfilment;
		if (!fulfilment) return;

		this.loading.set(true);

		switch (this.data?.type) {
			case 'quote':
				this.serviceProviderJobsService
					.uploadQuote(
						fulfilment.id,
						uploadedFiles.map(u => ({
							date: Date.now(),
							url: u.url as string,
							name: u.file.name
						}))
					)
					.subscribe({
						next: data => {
							const { quotes } = data.body;
							this.loading.set(false);
							this.close(quotes);
						}
					});
				break;

			case 'invoice':
				this.serviceProviderJobsService
					.uploadInvoice(
						fulfilment.id,
						uploadedFiles.map(u => ({
							date: Date.now(),
							url: u.url as string,
							name: u.file.name
						}))
					)
					.subscribe({
						next: data => {
							const { invoices } = data.body;
							this.loading.set(false);
							this.close(invoices);
						}
					});
				break;

			case 'photo':
				this.serviceProviderJobsService
					.uploadMedia(
						fulfilment.id,
						uploadedFiles.map(u => u.url as string)
					)
					.subscribe({
						next: data => {
							const { supportingMediaUrls } = data.body;
							this.loading.set(false);
							this.close(supportingMediaUrls);
						}
					});
				break;
		}
	}

	triggerFileSelect() {
		const input = document.getElementById('file-input') as HTMLInputElement;
		if (input) {
			input.value = '';
			input.click();
		}
	}
}
