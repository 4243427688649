import { Component, input } from '@angular/core';
import { staticMapImageUrl } from '../../../../shared/utils/maps';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited
} from '../../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-service-provider-job-location',
	standalone: true,
	imports: [],
	templateUrl: './service-provider-job-location.component.html',
	styleUrl: './service-provider-job-location.component.css'
})
export class ServiceProviderJobLocationComponent {
	managedFulfilment = input.required<
		ManagedFulfilmentDetails | ManagedFulfilmentLimited
	>();

	mapUrl() {
		if (!this.managedFulfilment().job.address.coordinates) {
			return '';
		}

		const lat = parseFloat(
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.managedFulfilment().job.address.coordinates![0].toString()
		);

		const lng = parseFloat(
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.managedFulfilment().job.address.coordinates![1].toString()
		);

		return staticMapImageUrl(
			{
				lat: lat,
				lng: lng
			},
			this.managedFulfilment().jobStatus === 'new' ? 14 : 18,
			400,
			300
		);
	}

	vagueAddress() {
		return `${this.managedFulfilment().job.address.province}, ${this.managedFulfilment().job.address.city}, ${this.managedFulfilment().job.address.suburb}, ${this.managedFulfilment().job.address.postalCode}`;
	}

	namedAddress() {
		const address = this.managedFulfilment().job.address;
		const formattedAddress =
			'formatted' in address ? address.formatted : this.vagueAddress();
		return this.managedFulfilment().jobStatus === 'new'
			? this.vagueAddress()
			: formattedAddress;
	}
}
