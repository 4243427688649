import { Component, computed, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { nameToInitials } from '../../utils/string-manipulation';

@Component({
	selector: 'fixify-avatar',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './avatar.component.html',
	styleUrl: './avatar.component.css'
})
export class AvatarComponent {
	source = input<string>('');
	placeholder = input<string>('');
	shortenPlaceholder = input<boolean>(false);

	shortenedPlaceHolder = computed(() => {
		if (this.shortenPlaceholder()) {
			return nameToInitials(this.placeholder());
		}
		return this.placeholder();
	});
}
