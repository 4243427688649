import { Component } from '@angular/core';
import { ModalComponent } from '../modal.service';
import { TextInputComponent } from '../../text-input/text-input.component';
import { ValidationMessageComponent } from '../../../../creator-portal/ui/validation-message/validation-message.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { IconComponent } from '../../icon/icon.component';
import { ModalCardComponent } from '../../modal-card/modal-card.component';

export interface IGenericRejectModalData {
	title?: string;
	subtitle?: string;
	formTitle?: string;
	rejectButtonText?: string;
	hideTopBar?: boolean;
}

@Component({
	selector: 'fixify-generic-reject-modal',
	standalone: true,
	imports: [
		TextInputComponent,
		ValidationMessageComponent,
		ReactiveFormsModule,
		IconComponent,
		ModalCardComponent
	],
	templateUrl: './generic-reject-modal.component.html',
	styleUrl: './generic-reject-modal.component.css'
})
export class GenericRejectModalComponent extends ModalComponent<
	IGenericRejectModalData,
	string
> {
	reasonFormGroup: FormGroup;

	constructor() {
		super();
		this.reasonFormGroup = new FormGroup({
			reason: new FormControl('', [Validators.required])
		});
	}

	reject() {
		const reason = this.reasonFormGroup.controls['reason'].value;

		if (!reason) {
			return;
		}

		this.close(reason);
	}
}
