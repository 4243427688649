import { Component } from '@angular/core';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
	ITabItem,
	TabsComponent
} from '../../../../../shared/ui/tabs/tabs.component';
import {
	JobStatus,
	ManagedJobs
} from '../../../../../shared/data-access/agency/agency.types';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { PropertyAvatarComponent } from '../../../../../shared/ui/property-avatar/property-avatar.component';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { AgencyJobsService } from '../../../../../shared/data-access/agency/agency.jobs.service';
import { Router } from '@angular/router';
import { initTooltips } from 'flowbite';

interface StatusCount {
	all: number;
	new: number;
	ongoing: number;
	completed: number;
	cancelled: number;
}
@Component({
	selector: 'fixify-agency-jobs-table',
	standalone: true,
	imports: [
		PaginationComponent,
		IconComponent,
		TableComponent,
		DatePipe,
		TextInputComponent,
		ReactiveFormsModule,
		TabsComponent,
		TableColumnDirective,
		PropertyAvatarComponent,
		StatusPillComponent,
		TitleCasePipe
	],
	templateUrl: './agency-jobs-table.component.html',
	styleUrl: './agency-jobs-table.component.css'
})
export class AgencyJobsTableComponent {
	itemsPerPage = 10;
	currentPage = 1;
	totalCount = 0;
	totalPages = 0;
	filterForm: FormGroup;

	private destroy$ = new Subject<void>();

	barFilters: Array<ITabItem> = [
		{
			label: 'all',
			value: '-',
			active: true
		},
		{
			label: 'new',
			value: '-',
			active: false
		},
		{
			label: 'ongoing',
			value: '-',
			active: false
		},
		{
			label: 'completed',
			value: '-',
			active: false
		},
		{
			label: 'cancelled',
			value: '-',
			active: false
		}
	];
	jobs: Array<ManagedJobs> | null = null;

	constructor(
		private agencyJobsService: AgencyJobsService,
		private router: Router
	) {
		this.filterForm = new FormGroup({
			search: new FormControl(''),
			status: new FormControl<JobStatus | 'all'>('all')
		});

		this.filterForm.valueChanges
			.pipe(debounceTime(250), takeUntil(this.destroy$))
			.subscribe({
				next: value => {
					this.agencyJobsService.fetchJobs({
						search: value.search,
						filter: value.status ? { status: value.status } : {}
					});
				}
			});

		this.agencyJobsService
			.jobs()
			.pipe(takeUntil(this.destroy$))
			.subscribe(jobs => {
				this.jobs =
					jobs?.result.map(job => {
						return {
							...job,
							selected: false
						};
					}) ?? null;
				this.totalPages = jobs?.totalPages ?? 0;
				this.currentPage = jobs?.page ?? 0;
				this.totalCount = jobs?.totalCount ?? 0;
				const filterMetaData = jobs?.filterMetaData?.[
					'statusCount'
				] as StatusCount;
				if (filterMetaData) {
					this.barFilters = Object.entries(filterMetaData).map(
						([key, value]) => ({
							label: key,
							value: value.toString(),
							active:
								key ===
								(this.filterForm.get('status')?.value ?? 'all')
						})
					);
				}
			});
	}

	ngAfterViewInit() {
		initTooltips();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	getColor(status: string) {
		switch (status) {
			case 'new':
				return 'green';
			case 'ongoing':
				return 'primary';
			case 'completed':
				return 'bright-orange';
			case 'cancelled':
				return 'red';
			case 'archived':
				return 'yellow';
			default:
				return '';
		}
	}

	onPageChange(page: number) {
		this.currentPage = page;
		this.currentPage = page;
		this.jobs = null;
		const search = this.filterForm.get('search')?.value;
		const status = this.filterForm.get('status')?.value;
		let filter = {};
		if (status != 'all') filter = { status };
		this.agencyJobsService.fetchJobs({ page, search, filter });

		// this.currentPage = page;
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		// this.properties = [...this.properties];
	}

	onRowClicked(job: ManagedJobs) {
		if (job.status === 'new') {
			this.router.navigate(['/agency/jobs/create', job.id]);
		} else {
			this.router.navigate(['/agency/jobs/track', job.id]);
		}
	}

	onTabItemChange(item: ITabItem) {
		this.filterForm
			.get('status')
			?.setValue(item.label === 'all' ? 'all' : item.label);
	}
}
