<fixify-modal size="md">
	<div class="flex justify-between gap-3 mb-5">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="user-01"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<p class="text-xl font-semibold text-gray-900 mb-0.5">
		Assign payment responsibility
	</p>
	<p class="text-base font-normal text-gray-700">
		Choose who will pay for this maintenance, including possible call out
		fees and labour costs.
	</p>
	<div class="mt-8 mb-6 flex flex-col gap-2">
		@for (item of items; track $index) {
			<div
				(click)="selectItem(item)"
				class="border-gray-100 border-[2px] rounded-md p-4 cursor-pointer"
				[ngClass]="{ 'border-primary-500': item.selected }"
			>
				<div class="flex items-center gap-3 line-clamp-1">
					<fixify-icon
						class="w-5 h-5 text-gray-900 stroke-2"
						[name]="item.iconName"
					></fixify-icon>
					<p class="text-sm font-medium text-gray-900">
						{{ item.name | titlecase }}
					</p>
				</div>
			</div>
		}
	</div>
	<div class="flex justify-end gap-2">
		<button
			(click)="assign()"
			[disabled]="noneSelected()"
			class="btn btn-outline border-gray-200"
		>
			Assign
		</button>
	</div>
</fixify-modal>

