<fixify-modal size="4xl">
	<div class="flex justify-between gap-3 mb-8">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="search-md"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>

	@if (addEstate()) {
		<div class="flex justify-end gap-2 mt-4">
			<button
				[disabled]="!addEstate()"
				(click)="add()"
				class="btn btn-primary flex justify-center items-center"
			>
				Add estate
				<fixify-icon
					class="text-white w-5 h-5 stroke-2 ml-2"
					name="plus"
				></fixify-icon>
			</button>
		</div>
	}

	<div class="flex flex-col justify-center">
		<p class="text-xl font-semibold text-gray-900 mb-1">
			Add favourite complex/estate
		</p>
		<p class="text-base font-normal text-gray-500 mb-6">
			Search to select existing complex/estates to add as a favourites for
			this serice provider.
		</p>

		<fixify-complex-estates-table
			[modalView]="true"
			[multiSelect]="true"
			[(selectedEstates)]="selectedEstates"
		></fixify-complex-estates-table>
		@if (selectedEstates().length > 0) {
			<p class="text-sm font-medium mb-2">Selected estates:</p>
			<div class="flex flex-wrap gap-2 flex-end">
				@for (selectedEstate of selectedEstates(); track $index) {
					<div
						class="rounded-md p-1 bg-primary-50 text-primary-700 gap-1 flex items-center"
					>
						<p class="text-sm font-normal">
							{{ selectedEstate.name }}
						</p>
						<div
							class="w-4 h-4 cursor-pointer"
							(click)="removeEstate(selectedEstate)"
						>
							<fixify-icon name="x-circle"></fixify-icon>
						</div>
					</div>
				}
			</div>
		}

		<div class="flex justify-end gap-2 mt-4">
			<button (click)="close()" class="btn btn-outline border-gray-200">
				Cancel
			</button>
			<button
				[disabled]="!canLink()"
				(click)="link()"
				class="btn btn-primary"
			>
				Link
			</button>
		</div>
	</div>
</fixify-modal>

