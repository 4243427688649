import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AgentLoggedInGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService
	) {}

	canActivate(): Observable<boolean> {
		return this.authService.checkClaim('agent.agencyId').pipe(
			tap(isAgent => {
				//TODO: Remove the env check later
				if (!isAgent && environment.env !== 'local') {
					this.router.navigate(['/login/agency']);
				}
			})
		);
	}
}
