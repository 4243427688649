@if (topBarText()) {
	<div
		class="bg-green-100 text-green-700 flex p-2 gap-2 justify-center items-center rounded-t"
		[ngClass]="{ 'text-red-700 bg-red-100': topBarError() }"
	>
		<p class="text-base font-semibold">{{ topBarText() }}</p>
		<fixify-icon
			name="arrow-left"
			class="w-5 h-5 stroke-2 rotate-180"
		></fixify-icon>
	</div>
}
<div
	class="w-full rounded-b bg-gray-100 p-8"
	[ngClass]="{ 'rounded-t': !topBarText() }"
>
	@if (statusText()) {
		<fixify-status-pill class="inline-block mb-4" [color]="statusColor()">
			{{ statusText() }}
		</fixify-status-pill>
	}
	<div class="flex gap-2 items-center mb-1">
		<p class="text-xl text-gray-900 font-medium">{{ title() }}</p>
		@if (titleIcon()) {
			<fixify-icon
				class="w-5 h-5 text-green-500 stroke-2"
				[name]="titleIcon()"
			></fixify-icon>
		}
	</div>
	<p class="text-base text-gray-700 font-normal">
		{{ subtitle() }}
	</p>
	@if (anyActionText()) {
		<div class="flex flex-col gap-3 mt-4">
			@if (primaryActionText()) {
				<button
					(click)="primaryPress.emit()"
					[disabled]="loading()"
					class="btn btn-primary"
				>
					@if (loading()) {
						<div class="flex justify-center items-center">
							<app-loader [loadingState]="loading()"></app-loader>
						</div>
					} @else {
						{{ primaryActionText() }}
					}
				</button>
			}
			@if (secondaryActionText()) {
				<button
					[disabled]="loading()"
					(click)="secondaryPress.emit()"
					class="btn btn-secondary"
				>
					{{ secondaryActionText() }}
				</button>
			}
			@if (tertiaryActionText()) {
				<button
					[disabled]="loading()"
					(click)="tertiaryPress.emit()"
					class="btn btn-secondary text-red-600 bg-transparent border-none"
				>
					{{ tertiaryActionText() }}
				</button>
			}
		</div>
	}
</div>

