import { Component, inject, OnDestroy } from '@angular/core';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AgentsTableComponent } from './ui/tables/agents-table/agents-table.component';
import { ToastService } from '../../shared/data-access/toast.service';
import { ModalService } from '../../shared/ui/modals/modal.service';
import { InviteAgentsModalComponent } from './ui/modals/invite-agents-modal/invite-agents-modal.component';
import { Subject, takeUntil } from 'rxjs';
import { AgentMetrics } from '../../shared/data-access/agency/agency.types';
import { LoaderComponent } from '../../shared/ui/loader/loader.component';
import { AgencyAgentsService } from '../../shared/data-access/agency/agency.agents.service';

@Component({
	selector: 'app-agents',
	standalone: true,
	imports: [
		DashboardCardComponent,
		ReactiveFormsModule,
		AgentsTableComponent,
		LoaderComponent,
		LoaderComponent
	],
	templateUrl: './agents.component.html',
	styleUrl: './agents.component.css'
})
export class AgentsComponent implements OnDestroy {
	toastService = inject(ToastService);
	modalService = inject(ModalService);
	agencyAgentsService = inject(AgencyAgentsService);

	destroy$ = new Subject<void>();

	metrics: AgentMetrics | null = null;

	constructor() {
		this.agencyAgentsService
			.agentMetrics()
			.pipe(takeUntil(this.destroy$))
			.subscribe(agentMetrics => {
				this.metrics = agentMetrics;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	openInviteAgentsModal() {
		this.modalService.showModal<null, void>(InviteAgentsModalComponent);
	}
}
