import {
	Component,
	OnDestroy,
	computed,
	effect,
	input,
	model
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ITabItem } from '../../../../../shared/ui/tabs/tabs.component';
import { PropertyAvatarComponent } from '../../../../../shared/ui/property-avatar/property-avatar.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { PropertyModalComponent } from '../../../../../shared/ui/modals/property-modal/property-modal.component';
import { ViewAgentModalComponent } from '../../../../agents/ui/modals/view-agent-modal/view-agent-modal.component';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { AssignToAgentComponent } from '../../modals/assign-to-agent/assign-to-agent.component';
import {
	ManagedAgent,
	ManagedProperty
} from '../../../../../shared/data-access/agency/agency.types';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { NgClass } from '@angular/common';
import { AgencyPropertiesService } from '../../../../../shared/data-access/agency/agency.properties.service';

interface TableManagedProperties extends ManagedProperty {
	selected?: boolean;
}

@Component({
	selector: 'fixify-agency-property-table',
	standalone: true,
	imports: [
		NgClass,
		PaginationComponent,
		TableColumnDirective,
		TableComponent,
		TextInputComponent,
		ReactiveFormsModule,
		IconComponent,
		PropertyAvatarComponent
	],
	templateUrl: './property-table.component.html',
	styleUrl: './property-table.component.css'
})
export class PropertyTableComponent implements OnDestroy {
	itemsPerPage = 10;
	currentPage = 1;
	totalCount = 0;
	totalPages = 0;
	modalView = input<boolean>(false);
	singleSelect = input<boolean>(false);
	disableFullTable = computed(() => {
		return this.modalView() || this.singleSelect();
	});

	private destroy$ = new Subject<void>();

	// barFilters: Array<ITabItem> = [
	// 	{
	// 		label: 'All',
	// 		value: '432',
	// 		active: true
	// 	},
	// 	{
	// 		label: 'Houses',
	// 		value: '255',
	// 		active: false
	// 	},
	// 	{
	// 		label: 'Apartments',
	// 		value: '157',
	// 		active: false
	// 	},
	// 	{
	// 		label: 'Common Property',
	// 		value: '20',
	// 		active: false
	// 	},
	// 	{
	// 		label: 'Archived',
	// 		value: '10',
	// 		active: false
	// 	}
	// ];

	properties: Array<TableManagedProperties> | null = null;
	filterForm: FormGroup;
	selectedProperties = model<Array<ManagedProperty>>([]);

	constructor(
		private router: Router,
		private modalService: ModalService,
		private agencyPropertiesService: AgencyPropertiesService
	) {
		effect(() => {
			const currentlySelected = this.selectedProperties();
			this.properties?.forEach(p => {
				p.selected = currentlySelected.some(sp => sp.id === p.id);
			});
		});

		this.filterForm = new FormGroup({
			search: new FormControl('')
		});

		this.agencyPropertiesService
			.properties()
			.pipe(takeUntil(this.destroy$))
			.subscribe(properties => {
				this.properties =
					properties?.result.map(properties => {
						return {
							...properties,
							selected: this.selectedProperties().some(
								sp => sp.id === properties.id
							)
						};
					}) ?? null;
				this.totalPages = properties?.totalPages ?? 0;
				this.currentPage = properties?.page ?? 0;
				this.totalCount = properties?.totalCount ?? 0;
			});

		this.filterForm.valueChanges
			.pipe(debounceTime(250), takeUntil(this.destroy$))
			.subscribe({
				next: value => {
					this.agencyPropertiesService.fetchProperties({
						search: value.search
					});
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	// This will be returned by the BE, but for testing this is calculated
	// get totalPages(): number {
	// 	return Math.ceil(this.properties.length / this.itemsPerPage);
	// }

	archive(property: ManagedProperty) {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Archive Property',
				body: 'Are you sure you want to archive this property?',
				deleteButtonText: 'Archive',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					//TODO archive property on BE
				}
			});
	}

	assignToAgent(properties: Array<TableManagedProperties>) {
		this.modalService.showModal(AssignToAgentComponent).then(result => {
			if (result) {
				this.agencyPropertiesService.assignAgentToProperties(
					result.id,
					properties.map(p => p.id)
				);
				this.clearSelectedProperties();
			}
		});
	}

	clearSelectedProperties() {
		this.selectedProperties.set([]);
		this.properties?.forEach(p => (p.selected = false));
	}

	deleteSelectedProperties() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: `Delete ${this.selectedProperties().length} ${this.selectedProperties().length === 1 ? 'property' : 'properties'}?`,
				body: `Are you sure you want to delete the selected ${this.selectedProperties().length === 1 ? 'property' : 'properties'}?`,
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(deleteResult => {
				if (deleteResult) {
					this.agencyPropertiesService.deleteProperties(
						this.selectedProperties().map(p => p.id)
					);
					this.clearSelectedProperties();
				}
			});
	}

	goToEditProperty(property: ManagedProperty) {
		this.router.navigate(['agency/properties/edit', property.id]);
	}

	onPageChange(page: number) {
		this.currentPage = page;
		this.properties = null;
		const search = this.filterForm.get('search')?.value;
		this.agencyPropertiesService.fetchProperties({ page, search });
		// this.currentPage = page;
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		// this.properties = [...this.properties];
	}

	onRowClicked(property: TableManagedProperties) {
		// Do not allow sophisticated actions when in modal select view
		if (this.disableFullTable()) {
			if (!this.onlySingleSelectAllowed(property))
				this.selectItem(null, property);
		} else {
			this.openPropertyModal(property);
		}
	}

	onTabItemChange(item: ITabItem) {
		console.log(item);
		// Apply BE filter
	}

	openAgentModal(event: MouseEvent, agent: ManagedAgent) {
		event.stopPropagation();
		if (this.disableFullTable()) return;
		this.modalService
			.showModal(ViewAgentModalComponent, agent)
			.then(result => {
				if (result) {
					switch (result) {
						case 'delete':
							this.modalService
								.showModal(DeleteModalComponent, {
									title: 'Delete agent?',
									body: `Are you sure you want to delete ${agent.name}?`,
									deleteButtonText: 'Delete',
									enableCancel: true
								})
								.then(deleteResult => {
									if (deleteResult) {
										//TODO delete agent
									}
								});

							break;
						case 'edit':
							this.router.navigate([
								'/agency/agents/edit',
								agent.id
							]);
							break;
					}
				}
			});
	}

	onlySingleSelectAllowed(property: ManagedProperty) {
		return (
			this.singleSelect() &&
			this.selectedProperties()?.length > 0 &&
			!this.selectedProperties()?.includes(property)
		);
	}

	openPropertyModal(property: ManagedProperty) {
		this.modalService.showModal(PropertyModalComponent, property);
	}

	selectItem(event: MouseEvent | null, property: TableManagedProperties) {
		//TODO: Change after BE implementation to make sure to keep a record of property IDs to work with pagination and search results
		event?.stopPropagation();
		property.selected = !property.selected;

		if (property.selected) {
			this.selectedProperties.set([
				...this.selectedProperties(),
				property
			]);
		} else {
			this.selectedProperties.set([
				...this.selectedProperties().filter(p => p.id !== property.id)
			]);
		}
	}
}
