import { Component, computed, model, signal } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { NgClass, TitleCasePipe, ViewportScroller } from '@angular/common';
import {
	ManagedJobs,
	ManagedProperty,
	ManagedServiceProvider
} from '../../../../../shared/data-access/agency/agency.types';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { DividerComponent } from '../../../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { RequestCardComponent } from '../../../../../creator-portal/ui/request-card/request-card.component';
import { ValidationMessageComponent } from '../../../../../creator-portal/ui/validation-message/validation-message.component';
import {
	PropertyType,
	RequestorType
} from '../../../../../shared/data-access/residential/residential.types';
import {
	Category,
	UploadState,
	UploadedDocuments
} from '../../../../../shared/data-access/service-provider/service-provider.types';
import { titleCase } from '../../../../../shared/utils/string-manipulation';
import { ToastService } from '../../../../../shared/data-access/toast.service';
import {
	catchError,
	firstValueFrom,
	forkJoin,
	Observable,
	of,
	Subject,
	takeUntil,
	tap
} from 'rxjs';
import { GenericSearchInputComponent } from '../../../../../shared/ui/generic-search-input/generic-search-input.component';
import { SpInfoCardComponent } from '../../../../../shared/ui/sp-info-card/sp-info-card.component';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { JobSubmittedModalComponent } from '../../ui/modals/job-submitted-modal/job-submitted-modal.component';
import { Router } from '@angular/router';
import { AgencyJobsService } from '../../../../../shared/data-access/agency/agency.jobs.service';
import { AuthService } from '../../../../../shared/auth/auth.service';
import { deleteObject, ref, Storage } from '@angular/fire/storage';
import { AgencyServiceProvidersService } from '../../../../../shared/data-access/agency/agency.service.providers.service';
import { LoaderComponent } from '../../../../../shared/ui/loader/loader.component';
import { uploadMedia } from '../../../../../shared/utils/media/upload';

type Comms = {
	name: RequestorType;
	icon: string;
	common: boolean;
};

export enum QuoteType {
	ACCURATE = 'accurate',
	FAST = 'fast'
}

@Component({
	selector: 'fixify-agency-new-job',
	standalone: true,
	imports: [
		IconComponent,
		ReactiveFormsModule,
		DividerComponent,
		TextInputComponent,
		RequestCardComponent,
		ValidationMessageComponent,
		NgClass,
		TitleCasePipe,
		GenericSearchInputComponent,
		SpInfoCardComponent,
		StatusPillComponent,
		LoaderComponent
	],
	templateUrl: './new-job.component.html',
	styleUrl: './new-job.component.css'
})
export class NewJobComponent {
	allComms: Array<Comms> = [
		{
			name: RequestorType.OCCUPANT,
			icon: 'user-square',
			common: false
		},
		{
			name: RequestorType.LANDLORD,
			icon: 'user-circle',
			common: false
		},
		{
			name: RequestorType.TRUSTEE,
			icon: 'users-dark',
			common: false
		},
		{
			name: RequestorType.ESTATE_MANAGER,
			icon: 'users-dark',
			common: true
		}
	];
	comms = this.allComms.filter(c => !c.common);
	availableCategories: Array<Category> | null = null;
	jobDetailsForm: FormGroup;
	selectedProperty = model<Pick<
		ManagedProperty,
		'id' | 'estateName' | 'estateId' | 'address'
	> | null>(null);
	preformedJob = model<ManagedJobs | null>(null);

	PropertyType = PropertyType;
	QuoteType = QuoteType;
	submitAttempted = signal(false);
	$destroy = new Subject<void>();

	uploadObservables = signal(Array<Observable<UploadedDocuments | null>>());
	uploadState = signal<Record<string, UploadState>>({});
	uploadStateArray = computed(() => Object.values(this.uploadState()));
	agencyId = firstValueFrom(this.authService.getClaim('agent.agencyId'));
	isSearchingServiceProviders = false;

	availableServiceProviders: ManagedServiceProvider[] = [];

	selectedServiceProviders: ManagedServiceProvider[] = [];

	constructor(
		private toastService: ToastService,
		private modalService: ModalService,
		private router: Router,
		private viewPort: ViewportScroller,
		private agencyJobsService: AgencyJobsService,
		private agencyServiceProvidersService: AgencyServiceProvidersService,
		private authService: AuthService,
		private storage: Storage
	) {
		this.agencyJobsService
			.categories()
			.pipe(takeUntil(this.$destroy))
			.subscribe(categories => {
				this.availableCategories = categories;
			});

		this.agencyServiceProvidersService
			.searching()
			.pipe(takeUntil(this.$destroy))
			.subscribe(fetching => {
				this.isSearchingServiceProviders = fetching;
			});

		this.agencyServiceProvidersService
			.serviceProviders()
			.pipe(takeUntil(this.$destroy))
			.subscribe(serviceProviders => {
				this.availableServiceProviders = serviceProviders?.result ?? [];
			});

		this.agencyServiceProvidersService.clearServiceProviders();

		this.jobDetailsForm = new FormGroup({
			propertyType: new FormControl<PropertyType | null>(
				null,
				Validators.required
			),
			landmark: new FormControl<string | null>(null),
			category: new FormControl<Category | null>(null, [
				Validators.required
			]),
			details: new FormControl<string>('', Validators.required),
			extra: new FormControl<string | null>(null),
			images: new FormControl<Array<string>>([]),
			commsUpdateTo: new FormControl<Array<Comms>>(
				[],
				[Validators.required]
			),
			quoteType: new FormControl<QuoteType | null>(null, [
				Validators.required
			]),
			quoteAmount: new FormControl<number>(1, [
				Validators.required,
				Validators.min(1),
				Validators.max(5)
			]),
			assignTo: new FormControl<'all' | 'specific' | null>(null, [
				Validators.required
			]),
			paymentResponsibility: new FormControl<Comms | null>(null),
			spSearch: new FormControl<string>('')
		});
	}

	ngOnInit() {
		this.jobDetailsForm.controls['propertyType'].valueChanges
			.pipe(takeUntil(this.$destroy))
			.subscribe((propertyType: PropertyType) => {
				this.jobDetailsForm.controls['commsUpdateTo'].setValue([]);
				this.jobDetailsForm.controls['paymentResponsibility'].setValue(
					null
				);
				if (propertyType === PropertyType.PRIVATE) {
					this.jobDetailsForm.controls['landmark'].setValue(null);
					this.comms = this.allComms.filter(c => !c.common);
				} else {
					this.jobDetailsForm.controls['extra'].setValue(null);
					this.comms = this.allComms.filter(c => c.common);
				}
			});

		this.jobDetailsForm.controls['assignTo'].valueChanges
			.pipe(takeUntil(this.$destroy))
			.subscribe((assignTo: 'all' | 'specific') => {
				if (assignTo === 'all') {
					// TODO: remove specific assignees
				}
			});

		if (!this.selectedProperty()?.estateId)
			this.changePropertyType(PropertyType.PRIVATE);

		if (this.preformedJob()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const job = this.preformedJob()!;
			this.jobDetailsForm.patchValue({
				propertyType: job.common ? 'common' : 'private',
				landmark: job.landmark ?? null,
				category: this.availableCategories?.find(
					category => category.id == job.categoryId
				),
				details: job.description,
				extra: job.notes ?? null,
				images: job.images
			});
			const states = this.preformedJob()?.images.reduce(
				(acc, imageUrl, index) => ({
					...acc,
					[`tenant_uploaded_${index}`]: {
						fileName: `tenant_uploaded_${index}`,
						progress: 100,
						status: 'complete',
						fileSrc: imageUrl
					}
				}),
				{}
			);
			this.uploadState.update(state => ({
				...state,
				...states
			}));
		}

		// scroll to get top of component when switching components
		this.viewPort.scrollToPosition([0, 0]);
	}

	changeAddress() {
		this.selectedProperty.set(null);
	}

	changePropertyType(propertyType: PropertyType) {
		this.jobDetailsForm.controls['propertyType'].setValue(propertyType);
	}

	changeQuoteType(quoteType: QuoteType) {
		this.jobDetailsForm.controls['quoteType'].setValue(quoteType);
	}

	goBack() {
		this.selectedProperty.set(null);
	}

	invalidControl(controlName: string) {
		return (
			this.jobDetailsForm.controls[controlName].invalid &&
			this.submitAttempted()
		);
	}

	onSelectedServiceProvider(sp: ManagedServiceProvider | null) {
		if (!sp) {
			return;
		}
		this.selectedServiceProviders.push(sp);
	}

	onSelectChangeServiceProvider(
		selected: boolean | undefined,
		sp: ManagedServiceProvider | null
	) {
		if (!sp) {
			return;
		}

		if (selected) {
			if (!this.selectedServiceProviders.find(s => s.id === sp.id))
				this.selectedServiceProviders.push(sp);
		} else {
			this.selectedServiceProviders =
				this.selectedServiceProviders.filter(s => s.id !== sp.id);
		}
	}

	removeSelectedServiceProvider(sp: ManagedServiceProvider) {
		this.selectedServiceProviders = this.selectedServiceProviders.filter(
			s => s.id !== sp.id
		);
	}

	searchServiceProviders = (
		input: string
	): Observable<Array<ManagedServiceProvider>> => {
		this.agencyServiceProvidersService.fetchServiceProviders({
			search: input,
			limit: 5,
			category: this.jobDetailsForm.controls['category'].value?.id
		});
		return new Observable<Array<ManagedServiceProvider>>(observer => {
			observer.next([]);
			observer.complete();
		});
	};

	genericSpTransformer(item: ManagedServiceProvider) {
		return [item.firstName, item.lastName].join(' ');
	}

	selectCategory(category: Category) {
		this.jobDetailsForm.controls['category'].setValue(category);
		if (this.jobDetailsForm.controls['assignTo'].value === 'specific') {
			this.selectedServiceProviders = [];
			this.agencyServiceProvidersService.fetchServiceProviders({
				limit: 5,
				category: category.id
			});
		}
	}

	selectAssignTo(assignTo: 'all' | 'specific') {
		this.jobDetailsForm.controls['assignTo'].setValue(assignTo);
		if (assignTo === 'specific') {
			if (this.jobDetailsForm.controls['category'].invalid) {
				this.toastService.add(
					'Please select a category before selecting specific service providers',
					5000,
					'error'
				);
				this.jobDetailsForm.controls['assignTo'].setValue(null);
				return;
			}
			this.agencyServiceProvidersService.fetchServiceProviders({
				limit: 5,
				category: this.jobDetailsForm.controls['category'].value.id
			});
		}
	}

	selectComms(comms: Comms) {
		const commsUpdateTo = this.jobDetailsForm.controls['commsUpdateTo'];
		const currentValue = commsUpdateTo.value as Array<Comms>;
		const index = currentValue.findIndex(c => c.name === comms.name);

		if (index === -1) {
			currentValue.push(comms);
		} else {
			currentValue.splice(index, 1);
		}

		this.jobDetailsForm.controls['commsUpdateTo'].setValue(currentValue);
	}

	selectResponsibility(responsibility: Comms) {
		this.jobDetailsForm.controls['paymentResponsibility'].setValue(
			responsibility
		);
	}

	submit() {
		this.submitAttempted.set(true);

		if (this.jobDetailsForm.invalid) {
			this.toastService.add(
				'Please fill in all required fields',
				5000,
				'error'
			);
			return;
		}

		const value = this.jobDetailsForm.value;

		this.agencyJobsService
			.submitJob({
				id: this.preformedJob()?.id ?? undefined,
				propertyId: this.selectedProperty()?.id,
				categoryId: value.category.id,
				description: value.details,
				notes: value.extra == '' ? null : value.extra,
				images: value.images,
				common: value.propertyType == 'common',
				landmark: value.landmark == '' ? null : value.landmark,
				requiresOnsiteVisit: value.quoteType == 'fast',
				requiredNumberOfQuotes: +value.quoteAmount,
				sendToAllProviders: value.assignTo == 'all',
				serviceProviderIds: this.selectedServiceProviders.map(
					sp => sp.id
				),
				responsibleForPayment: value.paymentResponsibility?.name ?? null
			})
			.subscribe({
				next: data => {
					const job = data.body as ManagedJobs;
					this.agencyJobsService.fetchJobs();

					this.modalService
						.showModal(JobSubmittedModalComponent)
						.then(result => {
							switch (result) {
								case 'view-all':
									this.router.navigate(['/agency/jobs']);
									break;
								case 'view-job':
									this.router.navigate([
										'/agency/jobs/track',
										job.id
									]);
									break;
								default:
									this.router.navigate(['/agency/jobs']);
									break;
							}
						});
				},
				error: error => {
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				}
			});
	}

	quoteAmount() {
		return this.jobDetailsForm.controls['quoteAmount'].value;
	}

	whoText() {
		return (
			(
				this.jobDetailsForm.controls['commsUpdateTo']
					.value as Array<Comms>
			)
				.map(c => titleCase(c.name + 's'))
				.join(', ') || 'Who'
		);
	}

	ngOnDestroy() {
		this.$destroy.next();
		this.$destroy.complete();
	}

	selectFilesFromDevice() {
		if (!this.selectedProperty()) {
			throw new Error('Property ID not set');
		}

		if (!this.agencyId) {
			this.toastService.add(
				`Couldn't identify agency, please reload.`,
				5000,
				'error'
			);
			throw new Error('Agency ID not set');
		}

		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.multiple = true;
		input.click();

		input.onchange = () => {
			const files = Array.from(input.files ?? []);

			const uploads = files.map(file => {
				const fileSrcUrl = URL.createObjectURL(file);
				// Initialize state
				this.uploadState.update(state => ({
					...state,
					[file.name]: {
						fileName: file.name,
						progress: 0,
						status: 'pending',
						fileSrc: fileSrcUrl
					}
				}));

				const filePath = `agency/${this.agencyId}/properties/${this.selectedProperty()?.id}/jobs/${file.name}`;
				const storageRef = ref(this.storage, filePath);
				return uploadMedia(file, storageRef).pipe(
					tap(progress => {
						this.uploadState.update(state => ({
							...state,
							[file.name]: {
								...state[file.name],
								progress: progress.percentage,
								status: progress.url ? 'complete' : 'uploading',
								url: progress.url,
								fileSrc: fileSrcUrl
							}
						}));

						if (progress.url) {
							const images =
								this.jobDetailsForm.controls['images'];
							images.setValue([...images.value, progress.url]);
						}
					}),
					catchError(error => {
						this.uploadState.update(state => ({
							...state,
							[file.name]: {
								...state[file.name],
								status: 'error',
								error: error.message
							}
						}));
						return of(null);
					})
				);
			});

			// Execute uploads
			forkJoin(uploads).pipe(takeUntil(this.$destroy)).subscribe();
		};
	}

	async deleteMedia(media: UploadState | null) {
		if (!media) {
			return;
		}

		const storageRef = ref(this.storage, media.url);
		await deleteObject(storageRef);

		const images = this.jobDetailsForm.controls['images'];
		images.setValue(
			images.value.filter((doc: string) => doc !== media.url)
		);

		this.uploadState.update(state => {
			const newState = { ...state };
			delete newState[media.fileName];
			return newState;
		});
	}
}
