<fixify-modal size="md">
	<div class="flex justify-between gap-3">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50 mb-5">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="search-md"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<div [formGroup]="agentSearchForm" class="flex flex-col justify-center">
		<p class="text-xl font-semibold text-gray-900 mb-1">
			Assign properties to another agent
		</p>
		<p class="text-base font-normal text-gray-500">
			Select another agent to manage these properties. They will be moved
			from the current agent's portfolio to the new agent's portfolio.
		</p>
		<div (click)="$event.stopPropagation()" class="my-6">
			<fixify-generic-search-input
				[control]="agentSearchForm.get('search')!"
				placeholder="Search for agent ..."
				[genericSearchFunction]="searchAgents"
				label="Assigned Agent"
				[transformer]="genericAgentTransformer"
				(selectedResult)="onSelectedResult($event)"
			></fixify-generic-search-input>
		</div>
		<div class="flex justify-end gap-2">
			<button (click)="close()" class="btn btn-outline border-gray-200">
				Cancel
			</button>
			<button
				[disabled]="!this.selectedAgent()"
				(click)="onSave()"
				class="btn btn-primary"
			>
				Assign to agent
			</button>
		</div>
	</div>
</fixify-modal>

