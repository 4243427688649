import { Injectable } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AgentMetrics, ManagedAgent } from './agency.types';
import { ToastService } from '../toast.service';
import { PaginatedResponse } from '../../utils/pagination';
import { Role } from '../../models/agent';

@Injectable({
	providedIn: 'root'
})
export class AgencyAgentsService {
	private isSubmitting$ = new BehaviorSubject<boolean>(false);
	private isFetching$ = new BehaviorSubject<boolean>(false);
	private agents$ =
		new BehaviorSubject<PaginatedResponse<ManagedAgent> | null>(null);
	private agentMetrics$ = new BehaviorSubject<AgentMetrics | null>(null);

	constructor(
		private apiService: ApiService,
		private toastService: ToastService
	) {
		this.fetchAgents();
		this.fetchAgentsMetrics();
	}

	// Managing Agents
	fetchAgents(page?: number, limit?: number, sort?: string, sortBy?: string) {
		this.isFetching$.next(true);
		const params = `?page=${page ?? 1}&limit=${limit ?? 10}&sort=${sort ?? 'desc'}&sortBy=${sortBy ?? 'status'}`;
		this.apiService.getData(`agency/agents${params}`).subscribe({
			next: data => {
				const paginatedData =
					data.body as PaginatedResponse<ManagedAgent>;
				paginatedData.result = paginatedData.result.map(agent => {
					if (agent.firstName && agent.lastName)
						//get initial of firstName and then full lastname
						agent.name = `${agent.firstName.charAt(0)}. ${agent.lastName}`;
					return agent;
				});
				this.agents$.next(paginatedData);
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isFetching$.next(false);
			}
		});
	}

	searchAgents(
		search: string,
		page?: number,
		limit?: number,
		sort?: string,
		sortBy?: string
	) {
		const params = `?search=${search}&page=${page ?? 1}&limit=${limit ?? 10}&sort=${sort ?? 'desc'}&sortBy=${sortBy ?? 'status'}`;
		return this.apiService.getData(`agency/agents${params}`);
	}

	fetchAgent(agentId: string) {
		if (this.agents$.value?.result.some(agent => agent.id === agentId)) {
			return new Observable(subscriber => {
				subscriber.next({
					body: this.agents$.value?.result.find(
						property => property.id === agentId
					)
				});
				subscriber.complete();
			});
		}
		return this.apiService.getData(`agency/agent/${agentId}`);
	}

	fetchAgentsMetrics() {
		return this.apiService.getData('agency/agents/metrics').subscribe({
			next: data => {
				const metrics = data.body as AgentMetrics;
				this.agentMetrics$.next(metrics);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	deleteAgent(agentId: string) {
		this.isSubmitting$.next(true);
		this.apiService.deleteData(`agency/agent/${agentId}`).subscribe({
			next: () => {
				this.fetchAgents();
			},
			error: error => {
				console.error(error);
				this.toastService.add(
					error.error.body.errorMessage,
					5000,
					'error'
				);
			},
			complete: () => {
				this.isSubmitting$.next(false);
				this.toastService.add(
					'Agent has been successfully deleted',
					5000,
					'success'
				);
			}
		});
	}

	updateAgent(agentId: string, role: Role) {
		this.isSubmitting$.next(true);
		this.apiService.putData(`agency/agent/${agentId}`, { role }).subscribe({
			next: () => {
				this.fetchAgents();
				this.toastService.add(
					'Agent has been successfully updated',
					5000,
					'success'
				);
			},
			error: error => {
				console.error(error);
				this.toastService.add(
					error.error.body.errorMessage,
					5000,
					'error'
				);
			},
			complete: () => {
				this.isSubmitting$.next(false);
			}
		});
	}

	inviteAgents(invites: Array<{ email: string; role: Role }>) {
		this.isSubmitting$.next(true);
		this.apiService
			.postData('agency/invite-agents', { agents: invites })
			.subscribe({
				next: () => {
					this.fetchAgents();
				},
				error: error => {
					console.dir(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	agentMetrics(): Observable<AgentMetrics | null> {
		return this.agentMetrics$.asObservable();
	}

	agents(): Observable<PaginatedResponse<ManagedAgent> | null> {
		return this.agents$.asObservable();
	}
}
