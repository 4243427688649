import { NgClass } from '@angular/common';
import { Component, input, model, output } from '@angular/core';

@Component({
	selector: 'fixify-request-card',
	standalone: true,
	imports: [NgClass],
	templateUrl: './request-card.component.html',
	styleUrl: './request-card.component.css'
})
export class RequestCardComponent {
	changeCardSelect = output<string>();
	value = input.required<string>();
	subText = input<string>('');
	imgUrl = input.required<string>();
	text = input.required<string>();
	selected = model<boolean>();
	titleSizeOverride = input<string>('');
	borderError = input<boolean>(false);

	select() {
		this.changeCardSelect.emit(this.value());
	}
}
