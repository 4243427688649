<div class="flex h-screen">
	<app-sidenav class="hidden min-[600px]:block"></app-sidenav>
	<main class="flex flex-col grow">
		<app-navbar></app-navbar>
		<div class="grow px-8 py-10 overflow-y-scroll overflow-auto">
			<router-outlet />
		</div>
		<fixify-bottom-nav class="block min-[600px]:hidden"></fixify-bottom-nav>
	</main>
</div>
