<div
	(click)="onTileClick()"
	class="flex justify-between gap-2 items-center rounded-md border-gray-100 border-2 p-4 cursor-pointer"
>
	<div class="flex gap-2 items-center">
		<div class="w-8 h-8">
			<fixify-avatar [placeholder]="shortened()"></fixify-avatar>
		</div>
		<p class="text-sm font-medium text-gray-900">
			{{ stakeholder().fullName }}
		</p>
	</div>
	<div class="flex gap-2 items-center">
		<p class="text-sm font-medium text-gray-900">View details</p>
		<fixify-icon
			name="chevron-right"
			class="w-4 h-4 text-gray-900"
		></fixify-icon>
	</div>
</div>

