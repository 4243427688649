<fixify-modal
	(onBackdropClick)="removeFocus()"
	(onModalClick)="removeFocus()"
	size="md"
>
	<div class="flex justify-between gap-3">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50 mb-5">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="search-md"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<div [formGroup]="spSearchForm" class="flex flex-col justify-center">
		<p class="text-xl font-semibold text-gray-900 mb-1">
			Add favourite service providers
		</p>
		<p class="text-base font-normal text-gray-500">
			Search to add favourited service providers to this property.
		</p>
		<div (click)="$event.stopPropagation()" class="my-6">
			<fixify-generic-search-input
				[control]="spSearchForm.get('search')!"
				[multiSelect]="true"
				placeholder="Search service provider ..."
				[genericSearchFunction]="genericSpTest"
				[uniquenessFunction]="uniquenessFunction"
				[transformer]="genericSpTransformer"
				[(isFocused)]="focus"
				[(allSelections)]="allSelections"
			></fixify-generic-search-input>
		</div>
		<div class="flex justify-end gap-2">
			<button (click)="close()" class="btn btn-outline border-gray-200">
				Cancel
			</button>
			<button (click)="onSave()" class="btn btn-primary">Save</button>
		</div>
	</div>
</fixify-modal>

