<div>
	<div class="flex justify-between gap-3 items-center mb-4">
		<div>
			<div class="flex gap-1.5 items-center">
				<p class="text-lg font-medium text-gray-900">
					Assign service providers
				</p>
				<fixify-status-pill color="primary"
					>{{
						managedJob().assignedServiceProviders.length ||
							managedJob().assignedServiceProvidersCount
					}}
					assigned</fixify-status-pill
				>
			</div>
			<div class="flex gap-1.5 items-center mt-1">
				@if (managedJob().assignedServiceProviders.length > 0) {
					<div class="flex gap-2 items-center text-gray-400">
						<p class="text-base font-normal">
							{{
								managedJob().requiresOnsiteVisit
									? 'Accurate quote'
									: 'Fast quote'
							}}
						</p>
						<fixify-icon
							data-tooltip-target="tooltip-default"
							class="w-4 h-4 cursor-pointer stroke-2"
							name="info-circle"
						></fixify-icon>
						<div
							id="tooltip-default"
							role="tooltip"
							class="absolute max-w-[300px] z-10 invisible inline-block px-3 py-2 text-sm font-normal text-white transition-opacity duration-300 bg-gray-800 rounded-sm shadow-sm opacity-0 tooltip"
						>
							{{
								managedJob().requiresOnsiteVisit
									? 'A site visit will be scheduled for service providers to provide their quote.'
									: 'No site visit necessary. You’ll receive quotes at no cost.'
							}}
							<div class="tooltip-arrow" data-popper-arrow></div>
						</div>
					</div>
				}
				<fixify-status-pill>{{
					serviceProviderAcceptedText()
				}}</fixify-status-pill>
			</div>
		</div>
		@if (
			managedJob().assignedServiceProviders.length > 0 ||
			managedJob().assignedServiceProvidersCount
		) {
			<div class="flex gap-1">
				<button
					(click)="assign()"
					[disabled]="
						managedJob().status === 'cancelled' ||
						managedJob().status === 'completed'
					"
					class="btn btn-secondary small"
				>
					Edit
				</button>
			</div>
		}
	</div>
	@if (
		managedJob().assignedServiceProviders.length === 0 &&
		!managedJob().assignedServiceProvidersCount
	) {
		<div class="flex flex-col justify-center items-center py-4 gap-2">
			<fixify-icon
				class="w-5 h-5 text-gray-900"
				name="users-plus"
			></fixify-icon>
			<p class="text-sm font-medium text-gray-900">
				Assign service providers for this job
			</p>
			<button
				(click)="assign()"
				[disabled]="
					managedJob().status === 'cancelled' ||
					managedJob().status === 'completed'
				"
				class="btn btn-outline small"
			>
				Assign service providers
			</button>
		</div>
	} @else if (false) {
		<!-- TODO: None accepted -->
		<div class="flex flex-col justify-center items-center py-4 gap-2">
			<img class="w-20 h-20" src="assets/svg/no-users.svg" />
			<p class="text-sm font-medium text-gray-900">
				No service providers have accepted this request yet.
			</p>
			<button
				(click)="assign()"
				[disabled]="
					managedJob().status === 'cancelled' ||
					managedJob().status === 'completed'
				"
				class="btn btn-outline small"
			>
				Edit assigned service providers
			</button>
		</div>
	} @else {
		<!-- TODO: change this to accepted service providers -->
		<div class="grid grid-cols-2 gap-4 lg:grid-cols-3">
			@for (
				acceptedServiceProvider of managedJob()
					.acceptedServiceProviders;
				track $index
			) {
				<fixify-sp-info-card
					companyName="{{
						acceptedServiceProvider.companyDetails?.companyName ??
							'Unknown'
					}}"
					imageUrl="{{
						acceptedServiceProvider.companyDetails?.companyLogo ??
							''
					}}"
					[restrictSize]="false"
					secondaryText="{{
						acceptedServiceProvider.totalCompletedJobs ?? '0'
					}} completed jobs"
					[isFavourite]="acceptedServiceProvider.favourited"
				></fixify-sp-info-card>
			}
		</div>
	}
</div>

