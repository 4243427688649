import {
	Component,
	ContentChildren,
	QueryList,
	effect,
	input,
	output
} from '@angular/core';
import { TableColumnDirective } from './directives/table-column.directive';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { initDropdowns, initTooltips } from 'flowbite';
import { LoaderComponent } from '../loader/loader.component';

@Component({
	selector: 'fixify-table',
	standalone: true,
	imports: [NgTemplateOutlet, NgClass, LoaderComponent],
	templateUrl: './table.component.html',
	styleUrl: './table.component.css'
})
export class TableComponent<T> {
	data = input.required<Array<T> | null>();
	disabledFieldName = input<keyof T | null>(null);
	selectedFieldName = input<keyof T | null>(null);
	itemsPerPage = input<number>(10);
	currentPage = input<number>(1);
	pageChange = output<number>();
	rowClicked = output<T>();
	@ContentChildren(TableColumnDirective<T>)
	columns: QueryList<TableColumnDirective<T>>;

	constructor() {
		/**
		 * The preferred way to use the interactive UI components from Flowbite is via the data attributes interface which allows us to add functionality via the HTML element attributes and most of the examples on our documentation applies this strategy.

			For example, to set up a modal component all you need to do is use data-modal-target and data-modal-{toggle|show|hide} to toggle, show, or hide the component by clicking on any trigger element.

			Flowbite interactive elements such as dropdowns/modals need to be initialized if they are re-rendered using ng-templates
			A workaround is to manually handle showing/hiding dropdowns/modals


			https://flowbite.com/docs/getting-started/quickstart/#data-attributes
		 */
		effect(() => {
			if (this.data()) {
				initDropdowns();
				initTooltips();
			}
		});
		this.columns = new QueryList<TableColumnDirective<T>>();
	}

	get paginatedData(): T[] | null {
		const startIndex = 0; //(this.currentPage() - 1) * this.itemsPerPage(); //TODO: Look into possibly adding more records instead of fetching every time?
		if (this.data() == null) return null;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.data()!.slice(startIndex, startIndex + this.itemsPerPage());
	}

	getFieldValue(row: unknown, field: string) {
		return field
			.split('.')
			.reduce(
				(acc, part) => acc && (acc as Record<string, unknown>)[part],
				row
			);
	}

	isDisabled(data: T): boolean {
		if (this.disabledFieldName() === null) {
			return false;
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return !data[this.disabledFieldName()!];
	}

	isSelected(data: T): boolean {
		if (this.selectedFieldName() === null) {
			return false;
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return !!data[this.selectedFieldName()!];
	}

	onPageChange(page: number) {
		this.pageChange.emit(page);
	}

	onRowClicked(data: T) {
		this.rowClicked.emit(data);
	}
}
