import { Component, input, OnInit } from '@angular/core';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { ManagedJobsDetails } from '../../../../../shared/data-access/agency/agency.types';
import { PropertyAvatarComponent } from '../../../../../shared/ui/property-avatar/property-avatar.component';

@Component({
	selector: 'fixify-job-property-card',
	standalone: true,
	imports: [StatusPillComponent, PropertyAvatarComponent],
	templateUrl: './job-property-card.component.html',
	styleUrl: './job-property-card.component.css'
})
export class JobPropertyCardComponent implements OnInit {
	job = input.required<ManagedJobsDetails>();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	property: any = null;

	ngOnInit() {
		this.property = this.job().estate || this.job().property;
	}

	title() {
		return (
			this.property?.name ||
			this.property?.estateName ||
			this.property?.address.buildingName ||
			this.property?.address.suburb
		);
	}
}
