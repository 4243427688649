import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NavService {
	expandedState = new BehaviorSubject<boolean>(true);

	toggleExpanded() {
		this.expandedState.next(!this.expandedState.value);
	}

	get expanded() {
		return this.expandedState.asObservable();
	}
}
