import { Component, input } from '@angular/core';
import { IconItemComponent } from '../../../../../shared/ui/icon-item/icon-item.component';
import { ManagedJobsDetails } from '../../../../../shared/data-access/agency/agency.types';

@Component({
	selector: 'fixify-job-overview',
	standalone: true,
	imports: [IconItemComponent],
	templateUrl: './job-overview.component.html',
	styleUrl: './job-overview.component.css'
})
export class JobOverviewComponent {
	managedJob = input.required<ManagedJobsDetails>();
}
