import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';

export const noDuplicateEmails = (
	control: AbstractControl
): ValidationErrors => {
	const invalid = (control as FormArray).controls.some(
		(control, index, controls) =>
			controls
				.filter((c, i) => i !== index)
				.some(c => c.value.email === control.value.email)
	);

	return (invalid ? { duplicateEmails: true } : null) as ValidationErrors;
};

export const latLngCoordinateString = (
	control: AbstractControl
): ValidationErrors => {
	const split = control.value.split(',').map((val: string) => {
		return val.trim();
	});

	const invalid = split.length !== 2 || split.some(isNaN);

	return (
		invalid ? { invalidLatLngCoordinateString: true } : null
	) as ValidationErrors;
};

export const emailValidator = (control: AbstractControl): ValidationErrors => {
	const email = control.value;
	const emailPattern =
		/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])*$/;
	const valid = emailPattern.test(email);
	return (valid ? null : { invalidEmail: true }) as ValidationErrors;
};
