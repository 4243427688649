<div>
	<p class="text-lg font-medium text-gray-900 mb-8">Job overview</p>
	<div class="flex flex-col gap-6">
		<fixify-icon-item
			iconName="tool-02"
			title="Job type"
			[subtitle]="managedJob().category.name"
		></fixify-icon-item>
		<!-- <fixify-icon-item
			iconName="calendar-plus-01"
			title="Site visit date & time"
			subtitle="To be confirmed"
		></fixify-icon-item> -->
		<fixify-icon-item
			iconName="pencil-line"
			title="Job description"
			[subtitle]="managedJob().description"
		></fixify-icon-item>
		@if(managedJob().notes) {
			<fixify-icon-item
				iconName="sticker-square"
				title="Extra notes"
				[subtitle]="managedJob().notes"
			></fixify-icon-item>
		}
		<fixify-icon-item
			iconName="camera-01"
			title="Photos"
			[images]="managedJob().images"
		></fixify-icon-item>
	</div>
</div>

