import { Component } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'fixify-favourite-pill',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './favourite-pill.component.html',
	styleUrl: './favourite-pill.component.css'
})
export class FavouritePillComponent {}
