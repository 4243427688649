import { Component, ElementRef, viewChild } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { initTooltips } from 'flowbite';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { emailValidator } from '../../../../../shared/validators';

@Component({
	selector: 'app-invite-service-providers-modal',
	standalone: true,
	imports: [ModalCardComponent, IconComponent, ReactiveFormsModule],
	templateUrl: './invite-service-providers-modal.component.html',
	styleUrl: './invite-service-providers-modal.component.css'
})
export class InviteServiceProvidersModalComponent extends ModalComponent<
	void,
	Array<string>
> {
	emailItems: Array<string> = [];
	emailForm = new FormGroup({
		email: new FormControl('', [Validators.required, emailValidator])
	});
	emailInput = viewChild.required<ElementRef<HTMLInputElement>>('emailInput');

	constructor() {
		super();
	}

	ngAfterViewInit() {
		initTooltips();
	}

	giveFocusToEmail() {
		this.emailInput().nativeElement.focus();
	}

	onKeyPress(event: KeyboardEvent) {
		if (event.key === 'Enter' && this.emailForm.valid) {
			this.emailItems.push(this.emailForm.value.email ?? '');
			this.emailForm.reset();
			this.giveFocusToEmail();
		}
	}

	removeItem(index: number) {
		this.emailItems.splice(index, 1);

		if (this.emailItems.length === 0) {
			this.giveFocusToEmail();
		}
	}

	submit() {
		this.close(this.emailItems);
	}
}
