import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import { StatusPillComponent } from '../../../shared/ui/status-pill/status-pill.component';
import { DatePipe, TitleCasePipe, Location } from '@angular/common';
import { statusColorMapper } from '../../../agency/agency-jobs/track-job/track-job.component';
import { ServiceProviderJobOverviewComponent } from '../ui/service-provider-job-overview/service-provider-job-overview.component';
import { ServiceProviderToDoComponent } from '../ui/service-provider-to-do/service-provider-to-do.component';
import { ServiceProviderJobLocationComponent } from '../ui/service-provider-job-location/service-provider-job-location.component';
import { ServiceProviderCustomersComponent } from '../ui/service-provider-customers/service-provider-customers.component';
import { ServiceProviderAgencyDetailsComponent } from '../ui/service-provider-agency-details/service-provider-agency-details.component';
import { ServiceProviderDocumentsComponent } from '../ui/service-provider-documents/service-provider-documents.component';
import { JobDateTimeComponent } from '../ui/modals/job-date-time/job-date-time.component';
import { ModalService } from '../../../shared/ui/modals/modal.service';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited
} from '../../../shared/data-access/service-provider/service-provider.types';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceProviderJobsService } from '../../../shared/data-access/service-provider/service-provider.jobs.service';
import { ToastService } from '../../../shared/data-access/toast.service';

@Component({
	selector: 'app-service-provider-track-job',
	standalone: true,
	imports: [
		DividerComponent,
		IconComponent,
		StatusPillComponent,
		TitleCasePipe,
		DatePipe,
		ServiceProviderJobOverviewComponent,
		ServiceProviderToDoComponent,
		ServiceProviderJobLocationComponent,
		ServiceProviderCustomersComponent,
		ServiceProviderAgencyDetailsComponent,
		ServiceProviderDocumentsComponent,
		LoaderComponent
	],
	templateUrl: './service-provider-track-job.component.html',
	styleUrl: './service-provider-track-job.component.css'
})
export class ServiceProviderTrackJobComponent implements OnInit {
	managedFulfilment:
		| ManagedFulfilmentDetails
		| ManagedFulfilmentLimited
		| null = null;

	@ViewChild('documentsComponent', { static: false }) documents!: ElementRef;

	constructor(
		private modalService: ModalService,
		private route: ActivatedRoute,
		private router: Router,
		private serviceProviderJobsService: ServiceProviderJobsService,
		private toastService: ToastService,
		private location: Location
	) {}

	ngOnInit() {
		const { jobId } = this.route.snapshot.params;

		if (jobId) {
			this.serviceProviderJobsService.fetchJob(jobId, true).subscribe({
				next: data => {
					if (
						['ongoing', 'completed'].includes(data.body.jobStatus)
					) {
						this.managedFulfilment =
							data.body as ManagedFulfilmentDetails;
					} else {
						this.managedFulfilment =
							data.body as ManagedFulfilmentLimited;
					}
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
					this.router.navigate(['/service-provider/jobs']);
				}
			});
		}
	}

	goBack() {
		this.location.back();
	}

	mapColor() {
		return this.managedFulfilment
			? statusColorMapper[this.managedFulfilment.jobStatus]
			: 'red';
	}

	openDateModal() {
		this.modalService
			.showModal(JobDateTimeComponent, {
				title: this.showSiteVisit()
					? 'Site visit date and time'
					: 'Job date and time'
			})
			.then(date => {
				if (date) {
					if (this.showSiteVisit() && this.managedFulfilment) {
						this.serviceProviderJobsService
							.setSiteVisitDate(
								this.managedFulfilment.id,
								date.getTime()
							)
							.subscribe({
								next: data => {
									const { scheduledQuoteDate } = data.body;
									if (this.managedFulfilment) {
										this.managedFulfilment.scheduledQuoteDate =
											scheduledQuoteDate;

										this.toastService.add(
											'Site visit date set 🎉',
											5000,
											'success'
										);
									}
								}
							});
					}
					if (!this.showSiteVisit() && this.managedFulfilment) {
						this.serviceProviderJobsService
							.setJobDate(
								this.managedFulfilment.id,
								date.getTime()
							)
							.subscribe({
								next: data => {
									const { scheduledJobDate } = data.body;
									if (this.managedFulfilment) {
										this.managedFulfilment.scheduledJobDate =
											scheduledJobDate;

										this.toastService.add(
											'Job date set 🎉',
											5000,
											'success'
										);
									}
								}
							});
					}
				}
			});
	}

	anyDatesSet() {
		return this.showSiteVisit() || this.showJobDate();
	}

	showSiteVisit() {
		const quoteSent = this.managedFulfilment
			? this.managedFulfilment.quotes.length > 0
			: false;
		const siteVisitSet = this.managedFulfilment
			? (this.managedFulfilment.scheduledQuoteDate ?? 0) > 0
			: false;

		return !quoteSent && siteVisitSet;
	}

	showJobDate() {
		const siteJobSet = this.managedFulfilment
			? (this.managedFulfilment.scheduledJobDate ?? 0) > 0
			: false;
		const invoiceSent = this.managedFulfilment
			? this.managedFulfilment.invoices.length > 0
			: false;

		return siteJobSet && !invoiceSent;
	}

	trigerUpload(action: 'quote' | 'invoice' | 'media') {
		const docChild = this
			.documents as unknown as ServiceProviderDocumentsComponent;
		let selectedTab: 'Invoices' | 'Quotes' | 'Photos';
		switch (action) {
			case 'invoice':
				selectedTab = 'Invoices';
				break;
			case 'media':
				selectedTab = 'Photos';
				break;
			case 'quote':
				selectedTab = 'Quotes';
				break;
			default:
				console.log("Couldn't identify upload type");
				return;
		}
		docChild.documentFilters = docChild.documentFilters.map(filter => {
			filter.active = filter.label == selectedTab;
			return filter;
		});
		docChild.uploadDocument();
	}

	triggerSetDate() {
		this.openDateModal();
	}
}
