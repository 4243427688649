<form [formGroup]="categoryForm()">
	<div>
		<label for="name" class="input-label">Category</label>
		<select
			formControlName="name"
			id="name"
			class="form-input text-gray-900"
		>
			<option selected disabled value="">Select category</option>
			@for (cat of categories(); track $index) {
				<option
					[disabled]="
						categoryInUse(cat.id) || !this.canEditCategoryType()
					"
					[selected]="isCategoryChosen(cat.name)"
					[value]="cat.name"
				>
					{{ cat.name }}
				</option>
				;
			}
		</select>
	</div>
	@if (categoryForm().get('name')?.value) {
		<div class="">
			<p class="text-sm font-medium text-gray-700 mt-6">
				Tasks <span class="text-red-500">*</span>
			</p>
			<p class="text-sm font-normal text-gray-500 mt-1">
				Select at least one task.
			</p>
			<div
				formArrayName="tasks"
				class="py-2 max-h-52 overflow-scroll hover:overflow-scroll"
			>
				@for (
					task of allTasksForFormGroup().controls;
					track task.value
				) {
					<div
						[formGroupName]="$index"
						class="flex items-center my-4"
					>
						<input
							[id]="'checkbox-' + $index"
							formControlName="selected"
							type="checkbox"
							class="checkbox-secondary"
						/>
						<label
							[for]="'checkbox-' + $index"
							class="ms-2 text-sm font-normal text-gray-900"
							>{{ task.get('name')?.value }}</label
						>
					</div>
				}
			</div>

			<p class="text-sm font-medium text-gray-700 mt-4 mb-2">
				Supporting Documents
			</p>

			@for (doc of uploadObservables(); track $index) {
				@if (doc !== null) {
					<div class="pb-1 pt-1 flex space-between">
						<div class="flex-1 truncate">
							<p
								class="text-gray-500 underline text-sm font-normal mb-2 text-ellipsis overflow-hidden"
							>
								{{ (doc | async)?.fileName }}
							</p>
						</div>

						<div class="flex-none">
							<p
								class="text-red-500 underline text-sm font-normal mb-2 ml-2 cursor-pointer"
								(click)="deleteMedia(doc)"
							>
								Delete
							</p>
						</div>
					</div>
				}
			}

			<button
				(click)="selectFileFromDevice()"
				class="btn-outline small border-gray-200"
			>
				<img
					src="assets/svg/plus-grey.svg
					"
					alt="img"
					class="h-4 w-4 mr-2"
				/>
				Upload Document
			</button>
		</div>
	}
	<div class="py-6">
		<fixify-divider></fixify-divider>
	</div>
</form>

