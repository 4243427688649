import { Directive, TemplateRef, Input } from '@angular/core';

export type DeepKeyOf<T> = {
	[K in keyof T & string]: T[K] extends object
		? K | `${K}.${DeepKeyOf<T[K]>}`
		: K;
}[keyof T & string];

@Directive({
	selector: '[fixifyTableColumn]',
	standalone: true
})
export class TableColumnDirective<T> {
	@Input() header!: string;
	@Input() field!: DeepKeyOf<T>;
	@Input() rtl = false;

	constructor(
		public template: TemplateRef<{
			$implicit: unknown;
			index: number;
			row: T;
		}>
	) {}
}
