<div class="relative container mx-auto h-full">
	<div class="block 2xl:absolute inset-0 flex flex-col">
		<div class="flex justify-between mb-4 items-center">
			<p class="text-base font-normal text-gray-900 mr-3">
				All agents
			</p>
			<div [formGroup]="filterForm" class="flex gap-3 items-center">
				<!-- <div class="shrink">
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="filter-lines"
								></fixify-icon
							></span>
							Filters
						</div>
					</button>
				</div> -->

				<fixify-text-input
					class="min-w-80"
					[control]="filterForm.get('search')!"
					label=""
					id="search"
					inputType="search"
					placeholder="Search ..."
				></fixify-text-input>
			</div>
		</div>
		<div class="overflow-y-auto w-full flex-grow">
			<fixify-table
				[data]="agents"
				[itemsPerPage]="itemsPerPage"
				(pageChange)="onPageChange($event)"
				[disabledFieldName]="'joinedOn'"
			>
				<ng-template
					fixifyTableColumn
					header="Name"
					field="name"
					let-value
					let-row="row"
				>
					<div class="flex gap-3 items-center">
						<div class="relative w-8 h-8">
							@if (isProfilePictureAvailable(row)) {
								<fixify-avatar
									[source]="row.profilePicture"
								></fixify-avatar>
							} @else {
								<fixify-avatar></fixify-avatar>
							}
						</div>
						@if (!isPending(row)) {
							<p>{{ value }}</p>
						} @else {
							<fixify-status-pill color="primary"
								>Pending</fixify-status-pill
							>
						}
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="Role"
					field="role"
					let-value
				>
					{{ value }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="Email"
					field="email"
					let-value
				>
					<a [href]="'mailto:' + value">{{ value }}</a>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="Phone Number"
					field="mobileNumber"
					let-value
				>
					{{ value ? value : '--' }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="Date Joined"
					field="joinedOn"
					let-value
				>
					{{ value ? (value | date: 'mediumDate') : '--' }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="Actions"
					field="id"
					let-value
					let-row="row"
				>
					<div>
						<div
							[id]="value + '-button'"
							[attr.data-dropdown-toggle]="value"
							data-dropdown-offset-distance="0"
							data-dropdown-offset-skidding="-70"
							class="w-10 h-10 rounded-full hover:border-2 hover:border-gray-200 flex items-center justify-center cursor-pointer"
						>
							<fixify-icon
								class="text-gray-700 w-6 h-6"
								name="dots-vertical"
							></fixify-icon>
						</div>
						<div
							[id]="value"
							[attr.aria-labelledby]="value + '-button'"
							class="absolute hidden z-20 w-44 bg-white rounded divide-y divide-gray-100 shadow left-0 top-0"
						>
							<ul
								class="text-sm text-gray-700 font-normal cursor-pointer"
							>
								@if (!isPending(row)) {
									<li (click)="viewAgent(row)">
										<p
											class="block py-3 px-4 hover:bg-gray-100"
										>
											View details
										</p>
									</li>
									<li (click)="editAgent(row.id)">
										<p
											class="block py-3 px-4 hover:bg-gray-100"
										>
											Edit details
										</p>
									</li>
								}

								<li (click)="showDeleteAgentModal(row)">
									<p
										class="block py-3 px-4 text-red-700 hover:bg-gray-100"
									>
										Delete agent
									</p>
								</li>
							</ul>
						</div>
					</div>
				</ng-template>
			</fixify-table>
		</div>
		<div class="mt-8">
			<fixify-pagination
				[currentPage]="currentPage"
				[totalPages]="totalPages"
				[maxItems]="totalCount"
				[pageSize]="itemsPerPage"
				(pageChange)="onPageChange($event)"
			></fixify-pagination>
		</div>
	</div>
</div>

