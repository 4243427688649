<div class="relative container mx-auto h-full">
	<div class="block inset-0 flex flex-col">
		<div class="mb-6">
			<fixify-tabs
				[tabItems]="barFilters"
				(onTabItemChange)="onTabItemChange($event)"
			></fixify-tabs>
		</div>
		<div class="flex justify-between mb-4">
			<div
				[formGroup]="filterForm"
				class="w-full flex justify-between items-center"
			>
				<fixify-text-input
					class="min-w-80"
					[control]="filterForm.get('search')!"
					label=""
					id="search"
					inputType="search"
					placeholder="Search ..."
				></fixify-text-input>
				<div class="shrink flex gap-2">
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="filter-lines"
								></fixify-icon
							></span>
							Filters
						</div>
					</button>
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="arrows-down"
								></fixify-icon
							></span>
							Sort
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="flex-grow">
			<fixify-table
				[data]="serviceProviders"
				[itemsPerPage]="itemsPerPage"
				[currentPage]="currentPage"
				(pageChange)="onPageChange($event)"
				(rowClicked)="onRowClicked($event)"
				[disabledFieldName]="'joinedOn'"
			>
				<ng-template
					fixifyTableColumn
					header="NAME"
					field="companyDetails"
					let-value
					let-row="row"
				>
					<div class="flex items-center justify-between">
						<div class="flex items-center">
							<div class="w-10 h-10">
								<fixify-avatar
									[placeholder]="value?.companyName"
									[shortenPlaceholder]="true"
									[source]="value?.companyUrl"
								></fixify-avatar>
							</div>

							<div class="line-clamp-1 ml-4">
								{{ value?.companyName }}
							</div>
						</div>
						@if (!row?.joinedOn) {
							<fixify-status-pill color="yellow"
								>Pending</fixify-status-pill
							>
						} @else if (row?.favourited) {
							<div
								class="rounded-sm h-6 w-8 p-1 px-2 bg-primary-100 flex items-center"
							>
								<fixify-icon
									name="star-01"
									symbol="filled"
									class="text-primary-900 w-full h-full"
								></fixify-icon>
							</div>
						} @else if (newJoiner(row?.joinedOn)) {
							<fixify-status-pill color="green"
								>New</fixify-status-pill
							>
						}
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="CATEGORY"
					field="id"
					let-row="row"
				>
					<div class="line-clamp-1 flex gap-2 items-center">
						{{ getListOfCategoryItems(row) }}
						<span class="text-xs text-gray-500 font-normal">{{
							categoriesExceeding(row) > 0
								? '+' + categoriesExceeding(row) + ' more'
								: ''
						}}</span>
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="LOCATIONS"
					field=""
					let-value
					let-row="row"
				>
					<div class="flex justify-between gap-1 items-center">
						<p class="line-clamp-1 min-w-0">I, need, locations</p>

						<p
							class="text-xs text-gray-500 font-normal flex-shrink-0"
						>
							+4 more
						</p>
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="COMPLETED JOBS"
					field="totalCompletedJobs"
					[rtl]="true"
					let-value
				>
					<div class="flex justify-end">
						{{ value ?? '--' }}
					</div>
				</ng-template>

				<ng-template
					fixifyTableColumn
					header="Actions"
					field="id"
					let-value
					let-row="row"
				>
					<div (click)="$event.stopPropagation()">
						<div
							[id]="value + '-button'"
							[attr.data-dropdown-toggle]="value"
							data-dropdown-offset-distance="0"
							data-dropdown-offset-skidding="-70"
							class="w-10 h-10 rounded-full hover:border-2 hover:border-gray-200 flex items-center justify-center cursor-pointer"
						>
							<fixify-icon
								class="text-gray-700 w-6 h-6"
								name="dots-vertical"
							></fixify-icon>
						</div>
						<div
							[id]="value"
							[attr.aria-labelledby]="value + '-button'"
							class="z-20 absolute hidden w-44 bg-white rounded divide-y divide-gray-100 shadow left-0 top-0"
						>
							<ul
								class="text-sm text-gray-700 font-normal cursor-pointer"
							>
								<li (click)="viewServiceProvider(row)">
									<p
										class="block py-3 px-4 text-gray-700 hover:bg-gray-100"
									>
										View details
									</p>
								</li>
								@if (row?.joinedOn) {
									<li (click)="favouriteServiceProvider(row)">
										<p
											class="block py-3 px-4 text-gray-700 hover:bg-gray-100"
										>
											{{
												row?.favourited
													? 'Unfavourite'
													: 'Favourite'
											}}
										</p>
									</li>
								}
								<li (click)="removeServiceProvider(row)">
									<p
										class="block py-3 px-4 text-red-700 hover:bg-gray-100"
									>
										Remove
									</p>
								</li>
							</ul>
						</div>
					</div>
				</ng-template>
			</fixify-table>
		</div>
		<div class="mt-8">
			<fixify-pagination
				[currentPage]="currentPage"
				[totalPages]="totalPages"
				[maxItems]="totalCount"
				[pageSize]="itemsPerPage"
				(pageChange)="onPageChange($event)"
			></fixify-pagination>
		</div>
	</div>
</div>

