<div
	class="p-4 rounded-md border-2 border-gray-100"
	[ngClass]="{
		'max-w-80': restrictSize(),
		'cursor-pointer': selectable(),
		'flex items-center gap-4': !cardVariant(),
		'items-start': cardVariant()
	}"
	(click)="itemClick()"
>
	@if (!cardVariant()) {
		<fixify-property-avatar [url]="imageUrl()"></fixify-property-avatar>
		<div class="flex flex-col gap-2 flex-grow">
			<div class="flex gap-2 items-center">
				<p
					class="inline-block leading-tight text-base font-normal text-gray-900 line-clamp-1"
				>
					{{ companyName() }}
				</p>
				@if (isFavourite()) {
					<fixify-favourite-pill></fixify-favourite-pill>
				}
			</div>

			@if (secondaryText()) {
				<p class="text-sm font-normal text-gray-500">
					{{ secondaryText() }}
				</p>
			}
			@if (tertiaryText()) {
				<div>
					<p
						class="inline px-0.5 underline text-xs font-normal text-gray-800 cursor-pointer"
						(click)="teritaryClick($event)"
					>
						{{ tertiaryText() }}
					</p>
				</div>
			}
		</div>
		@if (selectable()) {
			<div
				[ngClass]="{ 'text-primary-500': selected() }"
				class="text-gray-500 w-8 h-8 self-start"
			>
				<fixify-icon class="stroke-1" name="check-circle"></fixify-icon>
			</div>
		}
		@if (actionText() && !selectable()) {
			<div>
				<button
					(click)="actionClick($event)"
					class="btn btn-outline danger small border-0"
				>
					{{ actionText() }}
				</button>
			</div>
		}
	} @else {
		<fixify-property-avatar
			class="inline-block"
			[url]="imageUrl()"
		></fixify-property-avatar>
		<p
			class="leading-tight text-base font-normal text-gray-900 line-clamp-1 mt-4"
		>
			{{ companyName() }}
		</p>
		@if (isFavourite()) {
			<div class="inline-block mt-2">
				<fixify-favourite-pill></fixify-favourite-pill>
			</div>
		}
		@if (secondaryText()) {
			<p class="text-sm font-normal text-gray-500 mt-2">
				{{ secondaryText() }}
			</p>
		}
		@if (tertiaryText()) {
			<div class="mt-2">
				<p
					class="inline px-0.5 underline text-xs font-normal text-gray-800 cursor-pointer"
					(click)="teritaryClick($event)"
				>
					{{ tertiaryText() }}
				</p>
			</div>
		}
	}
</div>

