import { Component, inject, signal } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GenericSearchInputComponent } from '../../../../../shared/ui/generic-search-input/generic-search-input.component';
import { Observable } from 'rxjs';
import { Agent } from '../../../../../shared/data-access/agency/agency.types';
import { AgencyAgentsService } from '../../../../../shared/data-access/agency/agency.agents.service';

@Component({
	selector: 'fixify-assign-to-agent',
	standalone: true,
	imports: [
		ModalCardComponent,
		IconComponent,
		ReactiveFormsModule,
		GenericSearchInputComponent
	],
	templateUrl: './assign-to-agent.component.html',
	styleUrl: './assign-to-agent.component.css'
})
export class AssignToAgentComponent extends ModalComponent<void, Agent | null> {
	agencyAgentsService = inject(AgencyAgentsService);
	agentSearchForm = new FormGroup({
		search: new FormControl('')
	});

	selectedAgent = signal<Agent | null>(null);

	searchAgents = (input: string): Observable<Array<Agent>> => {
		const agentSearch = this.agencyAgentsService.searchAgents(input);
		return new Observable<Array<Agent>>(observer => {
			agentSearch.subscribe({
				next: data => {
					observer.next(data.body.result);
					observer.complete();
				},
				error: error => {
					console.error(error);
					observer.error(error);
				}
			});
		});
	};

	genericAgentTransformer(item: Agent) {
		return [item.firstName, item.lastName].join(' ');
	}

	onSave() {
		this.close(this.selectedAgent());
	}

	onSelectedResult(agent: Agent | null) {
		this.selectedAgent.set(agent);
	}
}
