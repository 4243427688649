<fixify-modal size="4xl">
	<div class="flex justify-between gap-3 mb-5 items-center">
		<p class="text-xl font-medium text-gray-900">
			Assign service provider(s)
		</p>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-6 h-6 cursor-pointer"
		></fixify-icon>
	</div>
	<fixify-divider></fixify-divider>
	<div class="flex flex-col overflow-auto p-1 mb-4">
		<p class="text-sm font-normal text-gray-700 mt-8 mb-4">
			First, choose how you want to receive quotes from service providers:
		</p>
		@if (invalidControl('quoteType')) {
			<div class="mb-4">
				<fixify-validation-message
					text="Select an option to continue"
				></fixify-validation-message>
			</div>
		}
		<div class="gap-4 flex w-full">
			<fixify-request-card
				class="flex-1"
				titleSizeOverride="!text-xl"
				(changeCardSelect)="changeQuoteType(QuoteType.ACCURATE)"
				imgUrl="assets/svg/file-plus-02.svg"
				[selected]="spForm.controls['quoteType'].value === 'accurate'"
				value="accurate"
				text="Accurate quote"
				subText="A site visit will be scheduled for service providers."
				[borderError]="invalidControl('quoteType')"
			></fixify-request-card>
			<fixify-request-card
				class="flex-1"
				titleSizeOverride="!text-xl"
				(changeCardSelect)="changeQuoteType(QuoteType.FAST)"
				imgUrl="assets/svg/clock-plus.svg"
				[selected]="spForm.controls['quoteType'].value === 'fast'"
				value="fast"
				text="Fastest quote"
				subText="No site visit necessary. Get quotes at no cost."
				[borderError]="invalidControl('quoteType')"
			></fixify-request-card>
		</div>
		<div class="mt-6">
			<p class="font-sm font-medium font-gray-900 mb-2">
				How many quotes would you like to receive?
			</p>
			<fixify-text-input
				[control]="spForm.controls['quoteAmount']"
				inputType="number"
				placeholder="Maximum of 5"
				[errorText]="
					'Please enter a number between ' +
					minQuotes +
					' and 5. Note - you cannot select a fewer amount of quotes originally selected.'
				"
			></fixify-text-input>
		</div>
		<p class="my-8 text-base font-normal text-gray-700">
			Next, assign service providers:
		</p>
		@if (invalidControl('assignTo')) {
			<fixify-validation-message
				text="Select an option to continue"
			></fixify-validation-message>
		}
		<div class="grid grid-cols-1 md:grid-cols-2 gap-2">
			<div
				[ngClass]="{
					'border-primary-500':
						spForm.controls['assignTo'].value == 'all',
					'!border-red-700': invalidControl('assignTo')
				}"
				(click)="selectAssignTo('all')"
				class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
			>
				<fixify-icon
					class="w-5 h-5 stroke-2 mr-3"
					name="users-plus"
				></fixify-icon>
				<p class="text-sm font-medium text-gray-900">
					Send to all available providers
				</p>
			</div>
			<div
				[ngClass]="{
					'border-primary-500':
						spForm.controls['assignTo'].value == 'specific',
					'!border-red-700': invalidControl('assignTo')
				}"
				(click)="selectAssignTo('specific')"
				class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
			>
				<fixify-icon
					class="w-5 h-5 stroke-2 mr-3"
					name="user-plus-01"
				></fixify-icon>
				<p class="text-sm font-medium text-gray-900">
					Select specific providers
				</p>
			</div>
		</div>
		@if (spForm.controls['assignTo'].value === 'specific') {
			<div class="mt-3 px-5 py-4 bg-stone relative">
				<div
					class="absolute -top-2 left-3/4 -translate-x-1/2 w-0 h-0 border-x-8 border-x-transparent border-b-8 border-b-stone"
				></div>
				<p class="text-sm font-normal text-gray-700">
					This allows you to target providers you prefer or have
					worked with before. The first
					<strong>{{ quoteAmount() }}</strong> providers to accept
					your request will provide a quote.
				</p>
			</div>
			<div class="mt-6">
				<fixify-generic-search-input
					[control]="spForm.controls['spSearch']"
					placeholder="Search ..."
					[genericSearchFunction]="searchServiceProviders"
					[transformer]="genericSpTransformer"
					(selectedResult)="onSelectedServiceProvider($event)"
				></fixify-generic-search-input>
			</div>
			<div class="mt-4 pr-4 max-h-96 overflow-auto flex flex-col gap-4">
				@if (isSearchingServiceProviders) {
					<div class="flex justify-center items-center">
						<app-loader [loadingState]="true"></app-loader>
					</div>
				} @else {
					@for (sp of availableServiceProviders; track $index) {
						<fixify-sp-info-card
							companyName="{{
								sp.companyDetails?.companyName ?? 'Unknown'
							}}"
							imageUrl="{{
								sp.companyDetails?.companyLogo ?? ''
							}}"
							[restrictSize]="false"
							secondaryText="{{
								sp.totalCompletedJobs ?? '0'
							}} completed jobs"
							[selectable]="true"
							[isFavourite]="sp.favourited ?? false"
							[selected]="selectedServiceProviders.includes(sp)"
							(selectedChange)="
								onSelectChangeServiceProvider($event, sp)
							"
						></fixify-sp-info-card>
					}
				}
			</div>
			@if (selectedServiceProviders.length > 0) {
				<div
					class="mt-4 pr-4 max-h-96 overflow-auto flex flex-col gap-4"
				>
					<p class="text-sm font-medium text-gray-900">
						Selected service providers - SP to Quote amount ratio:
						<span
							[ngClass]="{
								'text-red-500':
									selectedServiceProviders.length <
									quoteAmount(),
								'text-green-500':
									selectedServiceProviders.length >
									quoteAmount(),
								'text-primary-500':
									selectedServiceProviders.length ==
									quoteAmount()
							}"
							>{{ selectedServiceProviders.length }} /
							{{ quoteAmount() }}</span
						>
					</p>
					<div class="flex flex-wrap gap-2">
						@for (sp of selectedServiceProviders; track $index) {
							<fixify-status-pill [color]="'primary'">
								<div class="flex gap-1 items-center">
									<p class="p-1 text-xs font-medium">
										{{
											sp.companyDetails?.companyName ??
												'Unknown'
										}}
									</p>
									<div
										(click)="
											removeSelectedServiceProvider(sp)
										"
										class="w-4 h-4 text-primary-800 cursor-pointer"
									>
										<fixify-icon
											name="x-circle"
										></fixify-icon>
									</div>
								</div>
							</fixify-status-pill>
						}
					</div>
				</div>
			}
		}
	</div>
	<fixify-divider></fixify-divider>
	<div class="flex justify-between gap-2 mt-6">
		<button (click)="close()" class="btn btn-outline border-gray-200">
			Cancel
		</button>
		<button (click)="assign()" class="btn btn-primary">Assign</button>
	</div>
</fixify-modal>

