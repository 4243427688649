import { Component, model } from '@angular/core';
import { TrackInformationCardComponent } from '../../../../../shared/ui/track-information-card/track-information-card.component';
import { JobRejectDetailItemComponent } from '../../../../../shared/ui/job-reject-detail-item/job-reject-detail-item.component';
import {
	ManagedJobs,
	ManagedJobsDetails,
	ManagedServiceProvider
} from '../../../../../shared/data-access/agency/agency.types';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { GenericRejectModalComponent } from '../../../../../shared/ui/modals/generic-reject-modal/generic-reject-modal.component';
import { ToastService } from '../../../../../shared/data-access/toast.service';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { nameToInitials } from '../../../../../shared/utils/string-manipulation';
import { ServiceProvider } from '../../../../../shared/data-access/service-provider/service-provider.types';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { NgClass } from '@angular/common';
import { RatingItemComponent } from '../rating-item/rating-item.component';
import { DividerComponent } from '../../../../../shared/ui/divider/divider.component';
import { RateServiceProviderModalComponent } from '../modals/rate-service-provider-modal/rate-service-provider-modal.component';
import { AgencyJobsService } from '../../../../../shared/data-access/agency/agency.jobs.service';

export interface ITestRating {
	qualityOfWork: number | null;
	qualityOfQuote: number | null;
	communication: number | null;
	reliability: number | null;
	review?: string;
	expand?: boolean;
}

interface ExtendedServiceProvider
	extends Pick<
		ServiceProvider,
		| 'companyDetails'
		| 'totalCompletedJobs'
		| 'id'
		| 'firstName'
		| 'lastName'
	> {
	rating?: ITestRating;
}

@Component({
	selector: 'fixify-to-do',
	standalone: true,
	imports: [
		TrackInformationCardComponent,
		JobRejectDetailItemComponent,
		StatusPillComponent,
		AvatarComponent,
		IconComponent,
		NgClass,
		RatingItemComponent,
		DividerComponent
	],
	templateUrl: './to-do.component.html',
	styleUrl: './to-do.component.css'
})
export class ToDoComponent {
	constructor(
		private modalService: ModalService,
		private toastService: ToastService,
		private agencyJobsService: AgencyJobsService
	) {}
	managedJob = model.required<ManagedJobsDetails>();

	// TODO: replace with managedJob accepted service providers
	acceptedServiceProviders: Array<ExtendedServiceProvider> = [
		{
			companyDetails: {
				companyName: 'Bob the Builder',
				companyLogo: '',
				country: 'South Africa',
				province: 'Gauteng',
				registeredName: 'Bob the Builder Pty Ltd',
				tradingName: 'Bob the Builder',
				registrationNumber: 'REG123',
				vatNumber: 'VAT456',
				entityType: 'PTY LTD'
			},
			totalCompletedJobs: 150,
			id: 'sp1',
			firstName: 'Bob',
			lastName: 'Builder',
			rating: {
				qualityOfWork: null,
				communication: 4,
				qualityOfQuote: 3,
				reliability: 2,
				review: 'Bob is a great service provider',
				expand: false
			}
		},
		{
			companyDetails: {
				companyName: 'Bob the Builder',
				companyLogo: '',
				country: 'South Africa',
				province: 'Gauteng',
				registeredName: 'Bob the Builder Pty Ltd',
				tradingName: 'Bob the Builder',
				registrationNumber: 'REG123',
				vatNumber: 'VAT456',
				entityType: 'PTY LTD'
			},
			totalCompletedJobs: 150,
			id: 'sp1',
			firstName: 'Bob',
			lastName: 'Builder'
		}
	];

	assignServiceProviders() {
		console.log('Assign service provider');
	}

	cancelJob() {
		this.modalService
			.showModal(GenericRejectModalComponent, {
				formTitle: 'Reason for cancellation',
				subtitle: 'Provide a reason for cancelling this job.',
				title: 'Cancel job',
				rejectButtonText: 'Cancel job'
			})
			.then(reason => {
				if (!reason) {
					this.toastService.add('Reason required', 3000, 'error');
					return;
				}

				this.agencyJobsService
					.cancelJob(this.managedJob().id, reason)
					.subscribe({
						next: data => {
							const updatedJob = data.body as ManagedJobs;
							const { statusReason, status } = updatedJob;
							this.managedJob.update(job => {
								job.status = status;
								job.statusReason = statusReason;
								return job;
							});
							this.toastService.add(
								'Job cancelled',
								3000,
								'success'
							);
						},
						error: error => {
							console.error(error);
						}
					});
			});
	}

	overallRating(rating: ITestRating) {
		return (
			((rating.communication ?? 5) +
				(rating.qualityOfQuote ?? 5) +
				(rating.qualityOfWork ?? 5) +
				(rating.reliability ?? 5)) /
			4
		).toFixed(2);
	}

	rateServiceProvider(sp: ExtendedServiceProvider) {
		this.modalService.showModal(
			RateServiceProviderModalComponent,
			sp as ManagedServiceProvider
		);
	}

	rejectJob() {
		this.modalService
			.showModal(GenericRejectModalComponent, {
				formTitle: 'Reason for rejection',
				subtitle: 'Provide a reason for rejecting this new job.',
				title: 'Reject job',
				rejectButtonText: 'Reject job',
				hideTopBar: true
			})
			.then(reason => {
				if (!reason) {
					return;
				}

				this.managedJob.update(job => {
					job.status = 'cancelled';
					job.statusReason = reason;
					return job;
				});

				this.toastService.add('Job rejected', 3000, 'success');
			});
	}

	shortened(serviceProvider: ExtendedServiceProvider) {
		return nameToInitials(
			serviceProvider.companyDetails?.companyName ?? ''
		);
	}

	tappedServiceProvider(sp: ExtendedServiceProvider) {
		if (!sp.rating) {
			return;
		}

		sp.rating.expand = !sp.rating.expand;
	}

	waitingForAcceptance() {
		return (
			this.managedJob()?.status == 'ongoing' &&
			this.managedJob().acceptedServiceProviders.length == 0
		);
	}

	waitingForQuotes() {
		return (
			this.managedJob()?.status == 'ongoing' &&
			this.managedJob().acceptedServiceProviders.length &&
			this.managedJob().quotes.length == 0
		);
	}

	waitingForPaymentResponsibilty() {
		return (
			['ongoing', 'completed'].includes(this.managedJob()?.status) &&
			this.managedJob().responsibleForPayment == null
		);
	}

	reviewQuote() {
		return (
			this.managedJob()?.status == 'ongoing' &&
			this.managedJob().quotes.some(quote => quote.status == 'pending')
		);
	}

	waitingForInvoices() {
		return (
			this.managedJob()?.status == 'ongoing' &&
			this.managedJob().quotes.some(
				quote => quote.status == 'approved'
			) &&
			this.managedJob().invoices.length == 0
		);
	}

	canMarkForCompletion() {
		return (
			this.managedJob()?.status == 'ongoing' &&
			(this.managedJob()?.invoices ?? []).length > 0
			//TODO: Check for all completed fulfilments, requires backend intervention
		);
	}

	markAsComplete() {
		this.managedJob.update(job => {
			//TODO: Write the backend function to mark job as complete
			if (job) {
				job.status = 'completed';
			}
			return job;
		});
	}
}
