<fixify-modal size="md">
	<div class="flex justify-between gap-3 mb-5">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="x-square"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<p class="text-xl font-semibold text-gray-900 mb-0.5">
		Rate service provider
	</p>
	<p class="text-base font-normal text-gray-700 mb-8">
		Rate and review the service provider:
	</p>
	<div class="flex flex-col items-center gap-1 mb-8">
		<fixify-avatar
			class="w-12 h-12"
			[placeholder]="data?.companyDetails?.companyName ?? '--'"
			[shortenPlaceholder]="true"
			[source]="data?.companyDetails?.companyLogo ?? ''"
		></fixify-avatar>
		<p class="text-lg font-medium tet-gray-900">
			{{ data?.companyDetails?.companyName ?? 'Unknown company name' }}
		</p>
	</div>
	<div class="flex flex-col gap-3">
		<div class="flex gap-2 justify-between">
			<p class="text-sm text-gray-900 font-medium">Quality of work</p>
			<fixify-interactive-rating-stars
				[(stars)]="reviewForm.controls['qualityOfWork'].value"
				[totalStars]="5"
			></fixify-interactive-rating-stars>
		</div>
		<div class="flex gap-2 justify-between">
			<p class="text-sm text-gray-900 font-medium">Quality of quote</p>
			<fixify-interactive-rating-stars
				[(stars)]="reviewForm.controls['qualityOfQuote'].value"
				[totalStars]="5"
			></fixify-interactive-rating-stars>
		</div>
		<div class="flex gap-2 justify-between">
			<p class="text-sm text-gray-900 font-medium">Communication</p>
			<fixify-interactive-rating-stars
				[(stars)]="reviewForm.controls['communication'].value"
				[totalStars]="5"
			></fixify-interactive-rating-stars>
		</div>
		<div class="flex gap-2 justify-between">
			<p class="text-sm text-gray-900 font-medium">Reliability</p>
			<fixify-interactive-rating-stars
				[(stars)]="reviewForm.controls['reliability'].value"
				[totalStars]="5"
			></fixify-interactive-rating-stars>
		</div>
		<p class="text-xs font-light text-gray-700">
			Note: Not rating a specific item will be considered as not
			applicable.
		</p>
	</div>
	<div [formGroup]="reviewForm" class="mb-6 mt-8">
		<p class="text-sm text-gray-900 font-medium mb-2">Review</p>
		<fixify-text-input
			[control]="reviewForm.controls['review']"
			inputType="textarea"
			placeholder="Enter review"
			[showErrorMessage]="true"
			errorText="Enter a review to continue"
		></fixify-text-input>
	</div>
	<div class="flex justify-end gap-2">
		<button class="btn btn-outline border-gray-200" (click)="close()">
			Cancel
		</button>
		<button
			(click)="submit()"
			[disabled]="reviewForm.invalid"
			class="btn btn-primary"
		>
			Submit
		</button>
	</div>
</fixify-modal>

