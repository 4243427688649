<fixify-modal size="md">
	<div class="flex flex-col items-center mx-8 text-center mb-16">
		<img class="m-4" src="assets/svg/ghost.svg" />
		<p class="text-base font-semibold text-gray-700 mb-2">
			Job no longer available
		</p>
		<p class="text-base font-normal text-gray-500">
			That job you were eyeing? Poof! It’s no longer up for grabs. Next
			time, review and action faster for more jobs.
		</p>
		<button (click)="close()" class="btn btn-primary mt-4">Go back</button>
	</div>
</fixify-modal>

