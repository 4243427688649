import { Component, computed, input, model } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
	selector: 'fixify-interactive-rating-stars',
	standalone: true,
	imports: [IconComponent, NgClass],
	templateUrl: './interactive-rating-stars.component.html',
	styleUrl: './interactive-rating-stars.component.css'
})
export class InteractiveRatingStarsComponent {
	stars = model.required<number | null>();
	totalStars = input.required<number>();

	starItemArray = computed(() =>
		Array.from(
			{ length: this.totalStars() },
			(_, i) => i < (this.stars() ?? 0)
		)
	);

	onStarClick(index: number) {
		if (this.stars() === index + 1) {
			this.stars.set(null);
		} else {
			this.stars.set(index + 1);
		}
	}
}
