import { Component, Inject } from '@angular/core';
import { ServiceProviderService } from '../../data-access/service-provider/service-provider.service';
import { from, map, Observable, switchMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import {
	CURRENT_AUTH_USER_SERVICE,
	currentAuthServiceProvider
} from '../../factories/user.type.factory';
import { AgencyAccountService } from '../../data-access/agency/agency.account.service';

@Component({
	selector: 'app-navbar',
	standalone: true,
	imports: [AsyncPipe],
	providers: [currentAuthServiceProvider],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.css'
})
export class NavbarComponent {
	shortenedName$: Observable<string>;

	constructor(
		@Inject(CURRENT_AUTH_USER_SERVICE)
		private userAuthServicePromise: Promise<
			ServiceProviderService | AgencyAccountService
		>
	) {
		this.shortenedName$ = from(this.userAuthServicePromise).pipe(
			switchMap(userAuthService => userAuthService.getShortenedName()),
			map(name => {
				const words = name?.split(' ') ?? [];
				const capitalizedWords = words.map((word, index) => {
					if (index < 2) {
						return word.charAt(0).toUpperCase();
					}
					return '';
				});
				return capitalizedWords.join('') || '--';
			})
		);
	}
}
