<div
	class="flex max-h-[372px] overflow-hidden rounded border-2 border-gray-100"
>
	<div class="w-7/12">
		<google-map
			[center]="center()"
			[zoom]="zoom()"
			[options]="options"
			width="100%"
			height="372px"
		>
			@for (location of locations(); track $index) {
				<map-circle
					[center]="location.latlng"
					[radius]="radius()"
					[options]="circleOptions"
					[id]="location.id"
				/>
				<map-advanced-marker
					[position]="location.latlng"
					[content]="generateContent(location.id)"
				></map-advanced-marker>
			}
		</google-map>
	</div>
	<div class="w-5/12 overflow-scroll">
		@for (location of locations(); track $index) {
			<div
				(click)="goToLocation(location.coordinates)"
				class="py-5 px-6 flex items-center cursor-pointer"
			>
				<img class="mr-3" src="assets/svg/marker-pin-gray.svg" />
				<p class="mr-3 w-full text-sm font-normal text-gray-900">
					{{ location.name }}
				</p>
				@if (allowActions()) {
					<div class="flex gap-2">
						<img
							(click)="deleteLocation(location)"
							class="h-6 w-6 cursor-pointer"
							src="assets/svg/delete.svg"
						/>
						<img
							(click)="editLocation(location)"
							class="h-6 w-6 cursor-pointer"
							src="assets/svg/edit.svg"
						/>
					</div>
				}
			</div>
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		}
	</div>
</div>

