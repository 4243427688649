import { Component, computed, signal } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { PropertyTableComponent } from '../../../../properties/ui/tables/property-table/property-table.component';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ManagedProperty } from '../../../../../shared/data-access/agency/agency.types';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';

@Component({
	selector: 'app-favourite-properties-modal',
	standalone: true,
	imports: [IconComponent, PropertyTableComponent, ModalCardComponent],
	templateUrl: './favourite-properties-modal.component.html',
	styleUrl: './favourite-properties-modal.component.css'
})
export class FavouritePropertiesModalComponent extends ModalComponent<
	void,
	Array<ManagedProperty>
> {
	selectedProperties = signal<Array<ManagedProperty>>([]);

	canLink = computed(() => this.selectedProperties().length > 0);

	link() {
		this.close(this.selectedProperties());
	}

	removeProperty(property: ManagedProperty) {
		this.selectedProperties.set([
			...this.selectedProperties().filter(p => p.id !== property.id)
		]);
	}
}
