<div>
	<div class="flex gap-2 items-center">
		<p class="text-sm font-medium text-gray-900 mb-1">{{ title() }}</p>
		@if (stars() === null) {
			<p class="text-xs">(Not rated)</p>
		}
	</div>
	<div class="flex gap-1">
		@for (star of starItemArray(); track $index) {
			<fixify-icon
				class="w-10 h-10"
				[ngClass]="{
					'text-yellow-300': star,
					'text-gray-200': !star
				}"
				name="star-01"
				symbol="filled"
			></fixify-icon>
		}
	</div>
</div>

