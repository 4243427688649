import { Component } from '@angular/core';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { ServiceProviderJobsTableComponent } from './ui/tables/service-provider-jobs-table/service-provider-jobs-table.component';
@Component({
	selector: 'app-jobs',
	standalone: true,
	imports: [
		DashboardCardComponent,
		IconComponent,
		ServiceProviderJobsTableComponent
	],
	templateUrl: './jobs.component.html',
	styleUrl: './jobs.component.css'
})
export class JobsComponent {}
