<div class="max-w px-4 xl:px-16">
	<div class="flex gap-3 mb-1.5">
		<div class="flex cursor-pointer">
			<fixify-icon
				(click)="goBack()"
				class="w-6 h-6 text-gray-800 mt-1"
				name="arrow-left"
			></fixify-icon>
		</div>
		<div class="w-full">
			@if (managedJob) {
				<div class="flex gap-3 items-center justify-between">
					<div class="flex items-center gap-3">
						<p
							class="text-2xl text-gray-900 font-medium font-outfit"
						>
							Job #{{ managedJob.jobNumber }}
						</p>
						<fixify-status-pill [color]="mapColor()">
							{{ managedJob.status | titlecase }}
						</fixify-status-pill>
					</div>
					<div class="">
						<button
							class="small flex gap-2 items-center btn btn-outline border-gray-300"
						>
							Actions
							<fixify-icon
								class="w-4 h-4 rotate-90"
								name="chevron-right"
							></fixify-icon>
						</button>
					</div>
				</div>
				<p class="text-sm font-normal text-gray-500 mt-0.5">
					Created on
					{{ managedJob.createdOn | date: 'dd MMM, yyyy' }} at
					{{ managedJob.createdOn | date: 'HH:mm' }}
				</p>
			}
		</div>
	</div>
	@if (!managedJob) {
		<div class="flex justify-center items-center">
			<app-loader [loadingState]="true"></app-loader>
		</div>
	} @else {
		<div
			class="flex flex-col flex-col-reverse lg:flex-row lg:gap-6 mt-9 xl:gap-12"
		>
			<div class="w-full lg:w-3/5">
				<div>
					<p class="text-lg font-medium text-gray-900 my-4">
						Property
					</p>
				</div>
				<div>
					<fixify-job-property-card
						[job]="managedJob"
					></fixify-job-property-card>
					@if (managedJob.common) {
						<div class="py-8">
							<fixify-icon-item
								iconName="swimming-pool"
								title="Common Property  ·  Landmark"
								[subtitle]="
									managedJob.landmark ??
									'No specific location specified'
								"
							></fixify-icon-item>
						</div>
					}
				</div>
				<fixify-divider class="mt-4 mb-8"></fixify-divider>
				<!-- Job overview -->
				<fixify-job-overview
					[managedJob]="managedJob"
				></fixify-job-overview>
				<fixify-divider class="my-8"></fixify-divider>
				<!-- Job user details -->
				@if (managedJob.occupants?.length) {
					<div>
						<p class="text-lg font-medium text-gray-900 mb-4">
							Tenant details
						</p>
						<div class="flex flex-col gap-4">
							@for (
								occupant of managedJob.occupants;
								track $index
							) {
								<fixify-job-user-detail-tile
									[stakeholder]="occupant"
									type="tenant"
								></fixify-job-user-detail-tile>
							}
						</div>
					</div>
					<fixify-divider class="my-8"></fixify-divider>
				}
				@if (managedJob.landlords?.length) {
					<div class="mb-8">
						<p class="text-lg font-medium text-gray-900 mb-4">
							Landlord details
						</p>
						<div class="flex flex-col gap-4">
							@for (
								landlord of managedJob.landlords;
								track $index
							) {
								<fixify-job-user-detail-tile
									[stakeholder]="landlord"
									type="landlord"
								></fixify-job-user-detail-tile>
							}
						</div>
					</div>
					<fixify-divider class="my-8"></fixify-divider>
				}
				@if (managedJob.estateManagers?.length) {
					<div class="mb-8">
						<p class="text-lg font-medium text-gray-900 mb-4">
							Estate manager details
						</p>
						<div class="flex flex-col gap-4">
							@for (
								estateManager of managedJob.estateManagers;
								track $index
							) {
								<fixify-job-user-detail-tile
									[stakeholder]="estateManager"
									type="estate manager"
								></fixify-job-user-detail-tile>
							}
						</div>
					</div>
					<fixify-divider class="my-8"></fixify-divider>
				}
				<!-- Job documents -->
				<fixify-job-documents
					[(managedJob)]="managedJob"
				></fixify-job-documents>
			</div>
			<!-- To-do cards -->
			<div
				class="w-full mb-6 lg:w-2/5 lg:mb-0 lg:overflow-y-auto lg:sticky lg:top-0 lg:h-min"
			>
				<fixify-to-do [(managedJob)]="managedJob"></fixify-to-do>
			</div>
		</div>
		<fixify-divider class="my-8"></fixify-divider>
		<fixify-job-assign-service-provider
			[(managedJob)]="managedJob"
		></fixify-job-assign-service-provider>
		<fixify-divider class="my-8"></fixify-divider>
		<fixify-job-payment-responsibility
			[(userType)]="managedJob.responsibleForPayment"
			(userTypeChange)="userTypeChanged($event)"
			[managedJob]="managedJob"
		></fixify-job-payment-responsibility>
	}
</div>

