/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, model, output } from '@angular/core';
import { TrackInformationCardComponent } from '../../../../shared/ui/track-information-card/track-information-card.component';
import { ModalService } from '../../../../shared/ui/modals/modal.service';
import { RatingItemComponent } from '../../../../agency/agency-jobs/track-job/ui/rating-item/rating-item.component';
import { JobRejectDetailItemComponent } from '../../../../shared/ui/job-reject-detail-item/job-reject-detail-item.component';
import {
	ManagedFulfilment,
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited
} from '../../../../shared/data-access/service-provider/service-provider.types';
import { Router } from '@angular/router';
import { ServiceProviderJobsService } from '../../../../shared/data-access/service-provider/service-provider.jobs.service';
import { ToastService } from '../../../../shared/data-access/toast.service';
import { JobAlreadyTakenModalComponent } from '../modals/job-already-taken-modal/job-already-taken-modal.component';

@Component({
	selector: 'fixify-service-provider-to-do',
	standalone: true,
	imports: [
		TrackInformationCardComponent,
		RatingItemComponent,
		JobRejectDetailItemComponent
	],
	templateUrl: './service-provider-to-do.component.html',
	styleUrl: './service-provider-to-do.component.css'
})
export class ServiceProviderToDoComponent {
	managedFulfilment = model.required<
		ManagedFulfilmentDetails | ManagedFulfilmentLimited | null
	>();
	loading = false;
	upload = output<'quote' | 'invoice' | 'media'>();
	setDate = output();

	constructor(
		private modalService: ModalService,
		private router: Router,
		private serviceProviderJobsService: ServiceProviderJobsService,
		private toastService: ToastService
	) {}

	accept() {
		if (this.managedFulfilment()) this.loading = true;
		this.serviceProviderJobsService
			.acceptJob(this.managedFulfilment()!.id)
			.subscribe({
				next: data => {
					const updated = data.body as ManagedFulfilment;
					if (updated.jobStatus == 'claimed') {
						this.modalService
							.showModal(JobAlreadyTakenModalComponent)
							.then(() => {
								this.toastService.add(
									`You'll get it next time! 😢`,
									5000,
									'info'
								);
							});
					} else {
						this.toastService.add(
							'Congratulations, you got the job 🎉',
							5000,
							'success'
						);
					}
					const jobId = this.managedFulfilment()!.id;
					this.serviceProviderJobsService
						.fetchJob(jobId, true)
						.subscribe({
							next: data => {
								const updated =
									data.body as ManagedFulfilmentDetails;
								this.managedFulfilment.set(updated);
							},
							error: error => {
								console.error(error);
								this.toastService.add(
									error.error.body.errorMessage,
									5000,
									'error'
								);
								this.router.navigate([
									'/service-provider/jobs'
								]);
							},
							complete: () => {
								this.loading = false;
							}
						});
				}
			});
	}

	decline() {
		if (this.managedFulfilment()) this.loading = true;
		this.serviceProviderJobsService
			.rejectJob(this.managedFulfilment()!.id)
			.subscribe({
				next: () => {
					const jobId = this.managedFulfilment()!.id;
					this.serviceProviderJobsService
						.fetchJob(jobId, true)
						.subscribe({
							next: data => {
								const updated =
									data.body as ManagedFulfilmentDetails;
								this.managedFulfilment.set(updated);
								this.toastService.add(
									'Job has been declined',
									5000,
									'info'
								);
							},
							error: error => {
								console.error(error);
								this.toastService.add(
									error.error.body.errorMessage,
									5000,
									'error'
								);
								this.router.navigate([
									'/service-provider/jobs'
								]);
							},
							complete: () => {
								this.loading = false;
							}
						});
				}
			});
	}

	markAsComplete() {
		this.managedFulfilment.update(fulfilment => {
			//TODO: Write the backend function to mark fulfilemt as complete
			if (fulfilment) {
				fulfilment.jobStatus = 'completed';
			}
			return fulfilment;
		});
	}

	waitingforSignOff() {
		return (
			this.managedFulfilment()?.jobStatus == 'completed' &&
			this.managedFulfilment()?.job.status != 'completed'
		);
	}

	canMarkForCompletion() {
		return (
			this.managedFulfilment()?.jobStatus == 'ongoing' &&
			(this.managedFulfilment()?.invoices ?? []).length > 0
		);
	}

	shouldUploadQuote() {
		return (
			this.managedFulfilment()?.jobStatus == 'ongoing' &&
			(this.managedFulfilment()?.quotes ?? []).length == 0
		);
	}

	shouldUploadQuotePreviouslyRejected() {
		const quotes = this.managedFulfilment()?.quotes ?? [];
		return (
			this.managedFulfilment()?.jobStatus == 'ongoing' &&
			quotes.some(quote => quote.status == 'rejected') &&
			!quotes.some(quote =>
				['pending', 'approved'].includes(quote.status)
			)
		);
	}

	shouldUploadInvoice() {
		const quotes = this.managedFulfilment()?.quotes ?? [];
		const invoices = this.managedFulfilment()?.invoices ?? [];
		return (
			this.managedFulfilment()?.jobStatus == 'ongoing' &&
			quotes.some(quote => quote.status == 'approved') &&
			invoices.length == 0
		);
	}

	quoteInForReview() {
		const quotes = this.managedFulfilment()?.quotes ?? [];
		return (
			this.managedFulfilment()?.jobStatus == 'ongoing' &&
			quotes.some(quote => quote.status == 'pending')
		);
	}

	optionallyScheduleSiteVisit() {
		const fulfilment = this.managedFulfilment();
		const quotes = fulfilment?.quotes ?? [];
		return (
			fulfilment?.jobStatus == 'ongoing' &&
			quotes.length == 0 &&
			!fulfilment.scheduledQuoteDate &&
			fulfilment.job.requiresOnsiteVisit
		);
	}

	optionallyScheduleJobVisit() {
		const fulfilment = this.managedFulfilment();
		const quotes = fulfilment?.quotes ?? [];
		const invoices = fulfilment?.invoices ?? [];
		return (
			fulfilment?.jobStatus == 'ongoing' &&
			quotes.some(quote => quote.status == 'approved') &&
			invoices.length == 0 &&
			!fulfilment.scheduledJobDate
		);
	}

	uploadSupportingDocuments() {
		const fulfilment = this.managedFulfilment();
		const media = fulfilment?.supportingMediaUrls ?? [];
		return media.length == 0;
	}

	cancelFulfilment() {
		this.serviceProviderJobsService
			.cancelJob(this.managedFulfilment()!.id)
			.subscribe({
				next: () => {
					const jobId = this.managedFulfilment()!.id;
					this.serviceProviderJobsService
						.fetchJob(jobId, true)
						.subscribe({
							next: data => {
								const updatedJob =
									data.body as ManagedFulfilment;
								const { jobStatus, status } = updatedJob;

								this.managedFulfilment.update(job => {
									job!.status = status;
									job!.jobStatus = jobStatus;
									return job;
								});
								this.toastService.add(
									'Job has been cancelled',
									5000,
									'info'
								);
							},
							error: error => {
								console.error(error);
								this.toastService.add(
									error.error.body.errorMessage,
									5000,
									'error'
								);
								this.router.navigate([
									'/service-provider/jobs'
								]);
							},
							complete: () => {
								this.loading = false;
							}
						});
				}
			});
	}
}
