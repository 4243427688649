<div>
	<p class="text-lg font-medium text-gray-900 mb-4">Documents</p>
	<fixify-tabs
		[tabItems]="documentFilters"
		(onTabItemChange)="onDocumentItemChange($event)"
	></fixify-tabs>
	@if (documentsToView().length === 0) {
		<div class="flex justify-center my-8">
			<div class="flex flex-col items-center">
				<fixify-icon
					name="file-06"
					class="w-5 h-5 text-gray-900 mb-2"
				></fixify-icon>
				<p class="text-gray-900 text-sm font-medium">
					No {{ documentText() }} uploaded
				</p>
				<button
					(click)="uploadDocument()"
					class="btn btn-outline small mt-1"
				>
					Upload
				</button>
			</div>
		</div>
	} @else {
		<div
			class="my-4 flex flex-col gap-2"
			[ngClass]="{
				'grid grid-cols-6': activeTabItem()?.label === 'Photos'
			}"
		>
			@for (document of documentsToView(); track $index) {
				@if (activeTabItem()?.label === 'Photos') {
					<div
						(click)="openImage(document.url)"
						class="flex rounded aspect-square w-full bg-gray-100 cursor-pointer"
					>
						<img
							class="w-full h-full object-cover rounded-lg"
							src="{{ document.url }}"
						/>
					</div>
				} @else {
					<div
						[ngClass]="{
							'border-b-[1px]':
								documentsToView().length !== $index + 1
						}"
					>
						<div class="py-4 flex gap-2 border-gray-100">
							<div class="w-5 h-5 text-gray-500">
								<fixify-icon name="file-06"></fixify-icon>
							</div>
							<div class="flex flex-col gap-1">
								<p class="text-sm font-normal text-gray-900">
									{{ document.name }}
								</p>
								<p class="text-xs font-normal text-gray-500">
									{{
										document.createdAt
											| date: 'dd MMM yyyy · HH:mm'
									}}
								</p>
							</div>
							<div class="flex gap-2 items-start ml-2">
								<div class="flex items-center gap-3">
									<div
										(click)="openImage(document.url)"
										class="w-8 h-8 rounded-full aspect-square border-gray-200 border-[1px] p-1.5"
									>
										<fixify-icon
											name="download-02"
											class="text-gray-900 cursor-pointer stroke-2"
										></fixify-icon>
									</div>
									@if (
										document.type === 'quote' &&
										document.status !== 'outdated'
									) {
										<button
											(click)="markOutdated(document)"
											class="btn btn-outline border-gray-200 small"
										>
											@if (loading) {
												<div
													class="flex justify-center items-center"
												>
													<app-loader
														[loadingState]="loading"
													></app-loader>
												</div>
											} @else {
												Mark as outdated
											}
										</button>
									}
								</div>
							</div>
							@if (document.type !== 'photo' && document.status) {
								<div class="flex-grow">
									<div
										class="flex h-full items-center justify-end"
									>
										<fixify-status-pill
											[color]="getColor(document.status)"
											>{{
												document.status | titlecase
											}}</fixify-status-pill
										>
									</div>
								</div>
							}
						</div>
						@if (document.status === 'rejected') {
							<div
								[ngClass]="{
									'mb-4':
										documentsToView().length !== $index + 1
								}"
								class="flex flex-col gap-1 ml-7"
							>
								<p class="text-sm font-normal text-gray-500">
									Reason
								</p>
								<p class="text-xs font-normal text-gray-900">
									{{
										document.rejectedReason ??
											'No specific reason provided'
									}}
								</p>
							</div>
						}
					</div>
				}
			}
			@if (activeTabItem()?.label === 'Photos') {
				<div
					(click)="uploadDocument()"
					class="flex rounded aspect-square w-full bg-gray-100 cursor-pointer items-center justify-center"
				>
					<fixify-icon
						class="w-1/2 text-gray-400"
						name="plus"
					></fixify-icon>
				</div>
			} @else if (documentsToView().length !== 0) {
				<div (click)="uploadDocument()">
					<button class="btn btn-outline small border-gray-200">
						Upload another
					</button>
				</div>
			}
		</div>
	}
</div>

