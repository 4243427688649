import { Component, signal } from '@angular/core';
import { ModalComponent } from '../../../../../../shared/ui/modals/modal.service';
import { IconComponent } from '../../../../../../shared/ui/icon/icon.component';
import { ModalCardComponent } from '../../../../../../shared/ui/modal-card/modal-card.component';
import { DividerComponent } from '../../../../../../shared/ui/divider/divider.component';
import { NgClass } from '@angular/common';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { SpInfoCardComponent } from '../../../../../../shared/ui/sp-info-card/sp-info-card.component';
import { LoaderComponent } from '../../../../../../shared/ui/loader/loader.component';
import { GenericSearchInputComponent } from '../../../../../../shared/ui/generic-search-input/generic-search-input.component';
import { TextInputComponent } from '../../../../../../shared/ui/text-input/text-input.component';
import { RequestCardComponent } from '../../../../../../creator-portal/ui/request-card/request-card.component';
import { ValidationMessageComponent } from '../../../../../../creator-portal/ui/validation-message/validation-message.component';
import { QuoteType } from '../../../../create-job/ui/new-job/new-job.component';
import {
	ManagedJobsDetails,
	ManagedServiceProvider
} from '../../../../../../shared/data-access/agency/agency.types';
import { Observable, Subject, takeUntil } from 'rxjs';
import { StatusPillComponent } from '../../../../../../shared/ui/status-pill/status-pill.component';
import { AgencyServiceProvidersService } from '../../../../../../shared/data-access/agency/agency.service.providers.service';
import { AgencyJobsService } from '../../../../../../shared/data-access/agency/agency.jobs.service';

type EditServiceProviders = Pick<
	ManagedJobsDetails,
	| 'requiresOnsiteVisit'
	| 'requiredNumberOfQuotes'
	| 'sendToAllProviders'
	| 'serviceProviderIds'
	| 'acceptedServiceProviders'
	| 'assignedServiceProviders'
>;

@Component({
	selector: 'fixify-edit-service-provider-modal',
	standalone: true,
	imports: [
		IconComponent,
		ModalCardComponent,
		DividerComponent,
		NgClass,
		ReactiveFormsModule,
		SpInfoCardComponent,
		LoaderComponent,
		GenericSearchInputComponent,
		TextInputComponent,
		RequestCardComponent,
		ValidationMessageComponent,
		StatusPillComponent
	],
	templateUrl: './edit-service-provider-modal.component.html',
	styleUrl: './edit-service-provider-modal.component.css'
})
export class EditServiceProviderModalComponent extends ModalComponent<
	ManagedJobsDetails,
	EditServiceProviders
> {
	spForm: FormGroup;
	submitAttempted = signal(false);
	QuoteType = QuoteType;
	isSearchingServiceProviders = false;
	availableServiceProviders: ManagedServiceProvider[] = [];
	selectedServiceProviders: ManagedServiceProvider[] = [];
	minQuotes = 1;
	$destroy = new Subject<void>();

	constructor(
		private agencyServiceProvidersService: AgencyServiceProvidersService,
		private agencyJobsService: AgencyJobsService
	) {
		super();

		this.spForm = new FormGroup({
			quoteType: new FormControl<QuoteType | null>(null, [
				Validators.required
			]),
			quoteAmount: new FormControl<number>(1, [
				Validators.required,
				Validators.min(1),
				Validators.max(5)
			]),
			assignTo: new FormControl<'all' | 'specific' | null>(null, [
				Validators.required
			]),
			spSearch: new FormControl<string>('')
		});
	}

	override onDataSet() {
		this.spForm.controls['assignTo'].setValue(
			this.data?.sendToAllProviders ? 'all' : 'specific'
		);

		this.spForm.controls['quoteType'].setValue(
			this.data?.requiresOnsiteVisit ? QuoteType.ACCURATE : QuoteType.FAST
		);

		this.minQuotes = this.data?.requiredNumberOfQuotes ?? 1;

		this.spForm.controls['quoteAmount'].setValue(this.minQuotes);
		this.spForm.controls['quoteAmount'].setValidators([
			Validators.required,
			Validators.min(this.minQuotes),
			Validators.max(5)
		]);

		if (!this.data?.sendToAllProviders) {
			this.selectedServiceProviders = (
				this.data?.assignedServiceProviders ?? []
			).map(sp => {
				return {
					id: sp.id,
					firstName: sp.firstName,
					lastName: sp.lastName,
					companyDetails: sp.companyDetails,
					totalCompletedJobs: sp.totalCompletedJobs,
					verified: true, //TODO - Default for now
					categories: null //TODO - Default for now
				};
			});
		}

		this.agencyServiceProvidersService
			.searching()
			.pipe(takeUntil(this.$destroy))
			.subscribe(fetching => {
				this.isSearchingServiceProviders = fetching;
			});

		this.agencyServiceProvidersService
			.serviceProviders()
			.pipe(takeUntil(this.$destroy))
			.subscribe(serviceProviders => {
				this.availableServiceProviders = serviceProviders?.result ?? [];
			});

		this.agencyServiceProvidersService.clearServiceProviders();

		this.spForm.updateValueAndValidity();
	}

	override ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	assign() {
		this.submitAttempted.set(true);
		if (this.spForm.valid) {
			{
				const updatedServiceProvider = {
					requiresOnsiteVisit:
						this.spForm.controls['quoteType'].value == 'accurate',
					requiredNumberOfQuotes:
						this.spForm.controls['quoteAmount'].value,
					sendToAllProviders:
						this.spForm.controls['assignTo'].value === 'all',
					serviceProviderIds: this.selectedServiceProviders.map(
						sp => sp.id
					)
				};
				const jobId = this.data?.id as string;
				this.agencyJobsService
					.updateServiceProviders(jobId, updatedServiceProvider)
					.subscribe({
						next: data => {
							const updatedJob = data.body as ManagedJobsDetails;
							const {
								requiresOnsiteVisit,
								requiredNumberOfQuotes,
								sendToAllProviders,
								serviceProviderIds,
								acceptedServiceProviders,
								assignedServiceProviders
							} = updatedJob;
							this.close({
								requiresOnsiteVisit,
								requiredNumberOfQuotes,
								sendToAllProviders,
								serviceProviderIds,
								acceptedServiceProviders,
								assignedServiceProviders
							});
						},
						error: error => {
							console.error(error);
						}
					});
			}
		}
	}

	changeQuoteType(quoteType: QuoteType) {
		this.spForm.controls['quoteType'].setValue(quoteType);
	}

	invalidControl(controlName: string) {
		return (
			this.spForm.controls[controlName].invalid && this.submitAttempted()
		);
	}

	onSelectedServiceProvider(sp: ManagedServiceProvider | null) {
		if (!sp) {
			return;
		}
		this.selectedServiceProviders.push(sp);
	}

	onSelectChangeServiceProvider(
		selected: boolean | undefined,
		sp: ManagedServiceProvider | null
	) {
		if (!sp) {
			return;
		}

		if (selected) {
			if (!this.selectedServiceProviders.find(s => s.id === sp.id))
				this.selectedServiceProviders.push(sp);
		} else {
			this.selectedServiceProviders =
				this.selectedServiceProviders.filter(s => s.id !== sp.id);
		}
	}

	removeSelectedServiceProvider(sp: ManagedServiceProvider) {
		this.selectedServiceProviders = this.selectedServiceProviders.filter(
			s => s.id !== sp.id
		);
	}

	searchServiceProviders = (
		input: string
	): Observable<Array<ManagedServiceProvider>> => {
		this.agencyServiceProvidersService.fetchServiceProviders({
			search: input,
			limit: 5,
			category: this.data?.categoryId
		});
		return new Observable<Array<ManagedServiceProvider>>(observer => {
			observer.next([]);
			observer.complete();
		});
	};

	genericSpTransformer(item: ManagedServiceProvider) {
		return [item.firstName, item.lastName].join(' ');
	}

	selectAssignTo(assignTo: 'all' | 'specific') {
		this.spForm.controls['assignTo'].setValue(assignTo);
		if (assignTo === 'specific') {
			this.agencyServiceProvidersService.fetchServiceProviders({
				limit: 5,
				category: this.data?.categoryId
			});
		}
	}

	quoteAmount() {
		return this.spForm.controls['quoteAmount'].value;
	}
}
