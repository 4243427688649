<div class="container mx-auto flex flex-col h-full">
	<p class="text-2xl text-gray-900 font-medium font-outfit mb-2">
		My account
	</p>
	<p class="text-gray-600 mb-6">{{ agentEmail | async }}</p>

	@if (!(accountCompletion | async)?.personalInfo) {
		<div class="bg-gray-100 p-4 mb-6 rounded-lg">
			<div class="flex items-start">
				<div class="flex-shrink-0">
					<img src="assets/svg/check-verified.svg" />
				</div>

				<div class="ml-3">
					<h3 class="text-lg font-medium text-gray-900">
						Complete your account
					</h3>
					<p class="mt-1 text-sm text-gray-600">
						Once you have completed all of the required details, you
						will have access to adding properties, service
						providers, agents and more.
					</p>
				</div>
			</div>
		</div>
	}

	<div class="grid grid-cols-2 lg:grid-cols-3 gap-6">
		<div
			[routerLink]="'/agency/account/personal-info'"
			class="p-6 bg-white rounded-lg border border-gray-200 grid gap-y-1 cursor-pointer"
		>
			<div class="flex-shrink-0">
				<img src="assets/svg/user-edit.svg" />
			</div>
			<div
				class="flex flex-col lg:flex-row lg:flex-start lg:items-center items-start mb-2"
			>
				<h2 class="text-gray-900 mr-2">Personal info</h2>
				@if (!(accountCompletion | async)?.personalInfo) {
					<span
						class="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded"
						>Incomplete</span
					>
				}
			</div>
			<p class="text-gray-500">
				Provide your personal details and how we can reach you
			</p>
		</div>

		<div
			[routerLink]="'/agency/account/agency-details'"
			class="p-6 bg-white rounded-lg border border-gray-200 grid gap-y-1 cursor-pointer"
		>
			<div class="flex-shrink-0">
				<img src="assets/svg/file-02.svg" />
			</div>
			<div
				class="flex flex-col lg:flex-row lg:flex-start lg:items-center items-start mb-2"
			>
				<h2 class="text-gray-900 mr-2">Agency Details</h2>
				@if (!(accountCompletion | async)?.agencyDetails) {
					<span
						class="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded"
						>Incomplete</span
					>
				}
			</div>
			<p class="text-gray-500">Manage agency information and documents</p>
		</div>
	</div>
	<div class="flex flex-col flex-grow"></div>
	<div class="mt-6 text-center">
		<a href="#" class="text-sm text-gray-600 hover:underline"
			>Need to deactivate your account?</a
		>
		<button class="block mx-auto mt-2 text-sm text-red-600 hover:underline">
			Deactivate account
		</button>
	</div>
</div>

