import { Component, computed, signal } from '@angular/core';
import { ModalComponent, ModalService } from '../modal.service';
import { PropertyAvatarComponent } from '../../property-avatar/property-avatar.component';
import { IconComponent } from '../../icon/icon.component';
import { PropertyInfoCellComponent } from '../../../../agency/properties/ui/property-info-cell/property-info-cell.component';
import { AvatarComponent } from '../../avatar/avatar.component';
import { NgClass } from '@angular/common';
import { SpInfoCardComponent } from '../../sp-info-card/sp-info-card.component';
import { ModalCardComponent } from '../../modal-card/modal-card.component';
import { Router } from '@angular/router';
import { DeleteModalComponent } from '../delete/delete.component';
import { AddFavouriteSpComponent } from '../../../../agency/properties/ui/modals/add-favourite-sp/add-favourite-sp.component';
import { LinkToComplexEstateComponent } from '../../../../agency/properties/ui/modals/link-to-complex-estate/link-to-complex-estate.component';
import { BaseProperty } from '../../../data-access/agency/agency.types';
import { staticMapImageUrl } from '../../../utils/maps';
import { ViewAgentModalComponent } from '../../../../agency/agents/ui/modals/view-agent-modal/view-agent-modal.component';
import { LinkPropertiesComponent } from '../../../../agency/complex-estates/ui/modals/link-properties/link-properties.component';
import { AgencyEstatesService } from '../../../data-access/agency/agency.estates.service';

@Component({
	selector: 'app-property-modal',
	standalone: true,
	imports: [
		PropertyAvatarComponent,
		IconComponent,
		PropertyInfoCellComponent,
		AvatarComponent,
		NgClass,
		SpInfoCardComponent,
		ModalCardComponent
	],
	templateUrl: './property-modal.component.html',
	styleUrl: './property-modal.component.css'
})
export class PropertyModalComponent extends ModalComponent<BaseProperty, void> {
	property = signal<BaseProperty | null>(null);

	landlordsTrusteesManagers = computed(() => {
		const landlords = this.property()?.landlords;
		const managers = this.property()?.estateManagers;
		const trustees = this.property()?.trustees;

		return [...(landlords ?? []), ...(trustees ?? []), ...(managers ?? [])];
	});

	isEstate = computed(() => {
		return this.property()?.estateManagers !== undefined;
	});

	mapUrl = computed(() => {
		if (!this.property()?.address) {
			return null;
		}

		const coordinates = this.property()?.address.coordinates;

		if (!coordinates) {
			return null;
		}

		return staticMapImageUrl(
			{
				lat: coordinates[0],
				lng: coordinates[1]
			},
			16,
			400,
			300
		);
	});

	constructor(
		private router: Router,
		private modalService: ModalService,
		private agencyEstatesService: AgencyEstatesService
	) {
		super();
	}

	protected override onDataSet(): void {
		this.property.set(this.data ?? null);
	}

	addOrEditFavouriteSps() {
		this.modalService
			.showModal(
				AddFavouriteSpComponent,
				this.property()?.favouritedServiceProviders ?? []
			)
			.then(result => {
				if (result) {
					//TODO add or edit favourite service providers on the BE
					this.property.update(property => {
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						property!.favouritedServiceProviders = result;
						return property;
					});
				}
			});
	}

	ekWeetNieBaas() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: `Archive ${this.isEstate() ? 'Complex/Estate' : 'Property'}`,
				body: `Are you sure you want to archive this ${this.isEstate() ? 'complex/estate' : 'property'}?`,
				deleteButtonText: 'Archive',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					//TODO archive property on BE
				}
			});
	}

	onClose(): void {
		this.close();
	}

	goToEditProperty() {
		this.close();
		this.router.navigate([
			`agency/${this.isEstate() ? 'complexes-estates' : 'properties'}/edit`,
			this.property()?.id
		]);
	}

	hasFavouriteSps() {
		return this.property()?.favouritedServiceProviders?.length !== 0;
	}

	linkOrUnlinkEstate() {
		if (this.property()?.estateName) {
			this.modalService
				.showModal(DeleteModalComponent, {
					title: 'Unlink Property from Estate',
					body: 'Are you sure you want to unlink this property from the estate?',
					deleteButtonText: 'Unlink',
					enableCancel: true
				})
				.then(result => {
					if (result) {
						this.agencyEstatesService.linkPropertiesToEstate(null, [
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							this.property()!.id
						]);
						this.property.update(property => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							property!.estateName = null;
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							property!.estateId = null;
							return property;
						});
					}
				});
		} else {
			this.modalService
				.showModal(LinkToComplexEstateComponent)
				.then(result => {
					if (result) {
						const estate = result[0];
						this.agencyEstatesService.linkPropertiesToEstate(
							estate.id,
							[
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								this.property()!.id
							]
						);
						this.property.update(property => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							property!.estateId = estate.id;
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							property!.estateName = estate.name;
							return property;
						});
					}
				});
		}
	}

	async manageLinkedProperties() {
		this.modalService.showModal(LinkPropertiesComponent).then(result => {
			if (result && this.property()) {
				this.agencyEstatesService.linkPropertiesToEstate(
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					this.property()!.id,
					result.map(p => p.id)
				);
			}
		});
	}

	openAgentModal() {
		if (!this.property()?.managingAgent) {
			return;
		}

		this.modalService
			.showModal(ViewAgentModalComponent, this.property()?.managingAgent)
			.then(result => {
				if (result) {
					switch (result) {
						case 'delete':
							this.modalService
								.showModal(DeleteModalComponent, {
									title: 'Delete agent?',
									body: `Are you sure you want to delete ${this.property()?.managingAgent?.name}?`,
									deleteButtonText: 'Delete',
									enableCancel: true
								})
								.then(deleteResult => {
									if (deleteResult) {
										//TODO delete agent
									}
								});

							break;
						case 'edit':
							this.close();
							this.router.navigate([
								'/agency/agents/edit',
								this.property()?.managingAgent?.id
							]);
							break;
					}
				}
			});
	}
}
