import { Component, signal } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ValidationMessageComponent } from '../../../../../creator-portal/ui/validation-message/validation-message.component';
import { initDatepickers } from 'flowbite';

@Component({
	selector: 'fixify-job-date-time',
	standalone: true,
	imports: [
		ModalCardComponent,
		IconComponent,
		ReactiveFormsModule,
		ValidationMessageComponent
	],
	templateUrl: './job-date-time.component.html',
	styleUrl: './job-date-time.component.css'
})
export class JobDateTimeComponent extends ModalComponent<
	{ title?: string },
	Date | null
> {
	dateForm: FormGroup;
	now = new Date();
	selectedDate = signal<Date | null>(null);
	error = signal<string | null>(null);
	$destroy = new Subject<void>();

	constructor() {
		super();

		this.dateForm = new FormGroup({
			yearMonthDay: new FormControl<Date | null>(null, [
				Validators.required
			]),
			hourMinutes: new FormControl<string | null>(null, [
				Validators.required
			])
		});

		this.dateForm.valueChanges
			.pipe(takeUntil(this.$destroy))
			.subscribe(() => {
				this.checkDateValidity();
			});
	}

	ngOnInit() {
		initDatepickers();
	}

	checkDateValidity() {
		const date = this.dateForm.get('yearMonthDay')?.value;
		const time = this.dateForm.get('hourMinutes')?.value;
		if (date && time) {
			const dateTime = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				parseInt(time.split(':')[0]),
				parseInt(time.split(':')[1])
			);

			if (dateTime.getTime() < new Date().getTime()) {
				this.selectedDate.set(null);
				this.error.set('Date and time must be in the future');
				return;
			}
			this.error.set(null);
			this.selectedDate.set(dateTime);
		} else {
			this.selectedDate.set(null);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDatePicked(event: any): void {
		this.dateForm.controls['yearMonthDay'].setValue(event.detail.date);
	}

	openPicker(inputElement: HTMLInputElement): void {
		inputElement.showPicker();
	}

	saveDateTime() {
		this.close(this.selectedDate());
	}

	override ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}
}
