import { Component, OnInit, inject } from '@angular/core';
import { Auth, connectAuthEmulator } from '@angular/fire/auth';
import { RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { environment } from '../environments/environment';
import { connectStorageEmulator, Storage } from '@angular/fire/storage';
import { Functions, connectFunctionsEmulator } from '@angular/fire/functions';
import { ModalHostDirective } from './shared/directives/modal.host.directive';
import { ToastService } from './shared/data-access/toast.service';
import { ToastComponent } from './shared/ui/toast/toast.component';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, ModalHostDirective, ToastComponent, AsyncPipe],
	templateUrl: './app.component.html',
	styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
	title = 'fixify-platform';
	toastr = inject(ToastService);

	toasts = this.toastr.toasts();

	constructor(
		private auth: Auth,
		private storage: Storage,
		private functions: Functions
	) {
		if (environment.useEmulators?.auth) {
			connectAuthEmulator(this.auth, 'http://localhost:9099');
		}
		if (environment.useEmulators?.storage) {
			connectStorageEmulator(this.storage, 'localhost', 9199);
		}
		if (environment.useEmulators?.functions) {
			connectFunctionsEmulator(this.functions, 'localhost', 5001);
		}
	}

	ngOnInit(): void {
		initFlowbite();
	}
}
