<div class="mb-8">
	<div class="flex justify-between gap-3 items-center mb-4">
		<div>
			<div>
				<p class="text-lg font-medium text-gray-900">
					Payment responsibility
				</p>
			</div>
			<div class="flex gap-1.5 items-center mt-1">
				<div class="flex gap-2 items-center text-gray-400">
					<p class="text-base font-normal">Optional</p>
					<fixify-icon
						data-tooltip-target="tooltip-payment"
						class="w-4 h-4 cursor-pointer stroke-2"
						name="info-circle"
					></fixify-icon>
					<div
						id="tooltip-payment"
						role="tooltip"
						class="absolute max-w-[300px] z-10 invisible inline-block px-3 py-2 text-sm font-normal text-white transition-opacity duration-300 bg-gray-800 rounded-sm shadow-sm opacity-0 tooltip"
					>
						Choose who will pay for this maintenance, including
						possible call out fees and labour costs.
						<div class="tooltip-arrow" data-popper-arrow></div>
					</div>
				</div>
			</div>
			@if (userType()) {
				<div class="border-gray-100 border-[2px] rounded-md p-4 mt-4">
					<div class="flex items-center gap-3 line-clamp-1">
						<fixify-icon
							class="w-5 h-5 text-gray-900 stroke-2"
							name="user-circle"
						></fixify-icon>
						<p class="text-sm font-medium text-gray-900">
							{{ userType() | titlecase }}
						</p>
					</div>
				</div>
			}
		</div>

		<div class="flex gap-1">
			<button
				[disabled]="
					managedJob().status === 'cancelled' ||
					managedJob().status === 'completed'
				"
				(click)="assign()"
				class="btn btn-outline small"
				[ngClass]="{ 'btn-secondary border-none': userType() }"
			>
				{{ userType() ? 'Edit' : 'Assign' }}
			</button>
		</div>
	</div>
</div>

