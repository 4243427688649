<fixify-modal (onBackdropClick)="close()" size="4xl">
	<div class="flex justify-between gap-3 mb-8">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="search-md"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<div class="flex flex-col justify-center">
		<p class="text-xl font-semibold text-gray-900 mb-1">
			Link properties to this estate
		</p>
		<p class="text-base font-normal text-gray-500 mb-6">
			Search to select existing properties to link to this estate
		</p>
		<fixify-agency-property-table
			[modalView]="true"
			(selectedPropertiesChange)="selectedPropertiesChange($event)"
		></fixify-agency-property-table>
		<div class="flex justify-end gap-2 mt-4">
			<button (click)="close()" class="btn btn-outline border-gray-200">
				Cancel
			</button>
			<button [disabled]="!canLink()" (click)="link()" class="btn btn-primary">
				Link
			</button>
		</div>
	</div>
</fixify-modal>

