import { Component } from '@angular/core';
import { ModalComponent } from '../../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../../shared/ui/icon/icon.component';
import { NgClass, TitleCasePipe } from '@angular/common';
import {
	ManagedJobs,
	ResponsibleForPaymentType
} from '../../../../../../shared/data-access/agency/agency.types';
import { AgencyJobsService } from '../../../../../../shared/data-access/agency/agency.jobs.service';
import { ToastService } from '../../../../../../shared/data-access/toast.service';

interface IPaymentResponsibilityModalItem {
	name: ResponsibleForPaymentType;
	iconName: string;
	selected?: boolean;
}

@Component({
	selector: 'fixify-payment-responsibility-modal',
	standalone: true,
	imports: [ModalCardComponent, IconComponent, NgClass, TitleCasePipe],
	templateUrl: './payment-responsibility-modal.component.html',
	styleUrl: './payment-responsibility-modal.component.css'
})
export class PaymentResponsibilityModalComponent extends ModalComponent<
	{
		selected: ResponsibleForPaymentType;
		common: boolean;
		jobId: string;
	} | null,
	ResponsibleForPaymentType
> {
	items: IPaymentResponsibilityModalItem[] = [];

	constructor(
		private agencyJobsService: AgencyJobsService,
		private toastService: ToastService
	) {
		super();
	}

	protected override onDataSet(): void {
		const name = this.data?.selected;
		this.items = !this.data?.common
			? [
					{
						name: 'landlord',
						iconName: 'user-circle'
					},
					{
						name: 'tenant',
						iconName: 'users-01'
					},
					{
						name: 'trustee',
						iconName: 'user-square'
					}
				]
			: [
					{
						name: 'estate manager',
						iconName: 'user-circle'
					}
				];
		this.items.forEach(i => (i.selected = i.name === name));
	}

	assign() {
		const selectedItem = this.items.find(i => i.selected);
		const jobId = this.data?.jobId as string;
		if (selectedItem) {
			this.agencyJobsService
				.updatePaymentResponsibility(jobId, selectedItem.name)
				.subscribe({
					next: data => {
						const updatedJob = data.body as ManagedJobs;
						const { responsibleForPayment } = updatedJob;
						this.close(responsibleForPayment);
					},
					error: error => {
						console.error(error);
					}
				});
		} else {
			this.toastService.add('Selection must be made', 5000, 'error');
		}
	}

	noneSelected() {
		return !this.items.some(i => i.selected);
	}

	selectItem(item: IPaymentResponsibilityModalItem) {
		this.items.forEach(i => (i.selected = i === item));
	}
}
