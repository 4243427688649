@if (fetchingJobDetails) {
	<div class="flex justify-center">
		<app-loader
			class="mt-8"
			[loadingState]="true"
		></app-loader>
	</div>
} @else {
	@if (!selectedProperty()) {
		<fixify-select-job-address
			[(singleProperty)]="selectedProperty"
		></fixify-select-job-address>
	} @else {
		<fixify-agency-new-job
			[(selectedProperty)]="selectedProperty"
			[(preformedJob)]="job"
		></fixify-agency-new-job>
	}
}