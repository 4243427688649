import { Component } from '@angular/core';
import { ModalComponent } from '../modal.service';
import { ModalCardComponent } from '../../modal-card/modal-card.component';
import { AvatarComponent } from '../../avatar/avatar.component';
import { IconComponent } from '../../icon/icon.component';
import { Stakeholder } from '../../../data-access/agency/agency.types';
import { TitleCasePipe } from '@angular/common';
import { IconItemComponent } from '../../icon-item/icon-item.component';
import { RefinedStakeholder } from '../../../data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-view-user-modal',
	standalone: true,
	imports: [
		ModalCardComponent,
		AvatarComponent,
		IconComponent,
		TitleCasePipe,
		IconItemComponent
	],
	templateUrl: './view-user-modal.component.html',
	styleUrl: './view-user-modal.component.css'
})
export class ViewUserModalComponent extends ModalComponent<
	{
		stakeholder: Stakeholder | RefinedStakeholder;
		type: 'landlord' | 'tenant' | 'trustee' | 'estate manager';
		editVisible?: boolean;
	},
	'edit'
> {
	constructor() {
		super();
	}

	editClicked() {
		this.close('edit');
	}
}
