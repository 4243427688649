<fixify-modal size="4xl">
	<div class="flex items-center justify-between gap-3 mb-8">
		<p class="text-xl font-medium text-gray-900">
			Edit favourited properties
		</p>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>

	<div class="flex flex-col justify-center">
		<div class="flex flex-wrap gap-2">
			@for (property of properties; track $index) {
				<div
					(click)="onSelectProperty(property)"
					class="rounded-md border-2 border-gray-200 text-gray-900 px-4 py-3 flex gap-2 cursor-pointer"
					[class.border-red-400]="isSelected(property)"
				>
					<p class="text-sm font-normal">
						{{ shortenAddress(property) }}
					</p>
					<fixify-icon
						name="trash-01"
						class="w-5 h-5 text-red-500"
						[class.stroke-2]="isSelected(property)"
					></fixify-icon>
				</div>
			}
		</div>

		<div class="flex justify-end gap-2 mt-4">
			<button (click)="close()" class="btn btn-outline border-gray-200">
				Cancel
			</button>
			<button
				[disabled]="selectedProperties.length === 0"
				(click)="remove()"
				class="btn btn-primary"
			>
				Remove
				{{ selectedProperties.length ? selectedProperties.length : '' }}
				properties
			</button>
		</div>
	</div>
</fixify-modal>

