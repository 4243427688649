<!-- table.component.html -->
<div class="relative w-full h-full">
	<div class="overflow-x-auto w-full">
		<div class="overflow-y-auto 2xl:max-h-full">
			<table
				class="w-full text-sm text-left rtl:text-right text-gray-900"
			>
				<thead
					class="text-xs text-gray-400 uppercase bg-gray-50 sticky top-0 z-10"
				>
					<tr>
						@for (column of columns; track $index) {
							<th
								scope="col"
								class="px-4 py-5 uppercase text-xs font-semibold"
								[class.text-end]="column.rtl"
							>
								{{ column.header }}
							</th>
						}
					</tr>
				</thead>
				<tbody>
					@if (paginatedData == null) {
						<tr>
							<td
								[attr.colspan]="columns.length"
								class="px-4 py-5 text-gray-900"
							>
								<div class="flex justify-center">
									<app-loader
										[loadingState]="true"
									></app-loader>
								</div>
							</td>
						</tr>
					} @else {
						@for (
							row of paginatedData;
							track row;
							let idx = $index
						) {
							<tr
								(click)="onRowClicked(row)"
								[ngClass]="{
									'!bg-gray-50':
										isDisabled(row) || isSelected(row)
								}"
								class="bg-white border-b hover:bg-gray-100"
							>
								@for (column of columns; track $index) {
									<td class="px-4 py-5 text-gray-900">
										<ng-container
											[ngTemplateOutlet]="column.template"
											[ngTemplateOutletContext]="{
												$implicit: getFieldValue(
													row,
													column.field
												),
												index: idx,
												row: row
											}"
										>
										</ng-container>
									</td>
								}
							</tr>
						}
					}
				</tbody>
			</table>
		</div>
	</div>
</div>

