<fixify-modal size="md">
	<div class="flex items-start justify-between rounded-t">
		<p class="text-xl font-semibold text-gray-900">
			{{ data?.title ?? 'Delete' }}
		</p>
	</div>
	<div class="pt-1 pb-6">
		<p class="text-base leading-relaxed text-gray-500">
			{{ data?.body ?? 'Are you sure you want to delete this item?' }}
		</p>
	</div>
	<div
		class="flex items-center justify-end space-x-2 border-gray-200 rounded-b"
	>
		@if (data?.enableCancel) {
			<button
				(click)="close(false)"
				type="button"
				class="btn-outline border-gray-200"
			>
				Cancel
			</button>
		}
		<button
			(click)="deleteClicked()"
			type="button"
			class="btn-primary danger"
		>
			{{ data?.deleteButtonText ?? 'Delete' }}
		</button>
	</div>
</fixify-modal>

