import { Component } from '@angular/core';
import { ModalComponent } from '../../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../../shared/ui/icon/icon.component';
import { Router } from '@angular/router';

@Component({
	selector: 'fixify-job-submitted-modal',
	standalone: true,
	imports: [ModalCardComponent, IconComponent],
	templateUrl: './job-submitted-modal.component.html',
	styleUrl: './job-submitted-modal.component.css'
})
export class JobSubmittedModalComponent extends ModalComponent<
	void,
	'view-all' | 'view-job'
> {
	constructor(private router: Router) {
		super();
	}
}
