<fixify-modal (onBackdropClick)="onClose()" size="4xl">
	@if (property()?.imageUrl) {
		<div class="flex mb-6">
			<fixify-property-avatar
				class="w-32 h-20"
				[url]="property()?.imageUrl!"
			></fixify-property-avatar>
		</div>
	}

	<div class="flex flex-col mb-10">
		<p class="text-3xl font-medium text-gray-900">
			{{ property()?.address?.street?.number }}
			{{ property()?.address?.street?.name }}
			@if (isEstate()) {
				{{
					property()?.address?.buildingName
						? '· ' + property()?.address?.buildingName
						: ''
				}}
			} @else {
				{{
					property()?.address?.unitNumber
						? '· Unit ' + property()?.address?.unitNumber
						: ''
				}}
			}
		</p>
		<p class="text-xl font-normal text-gray-900">
			{{ property()?.address?.city }},
			{{ property()?.address?.postalCode }}
		</p>
	</div>

	<div class="mb-6">
		<div class="flex grid grid-cols-3 gap-2">
			@if (isEstate()) {
				<fixify-property-info-cell
					title="Type"
					subtitle="Estate"
					iconName="building-06"
				></fixify-property-info-cell>
			} @else {
				<fixify-property-info-cell
					title="Status"
					subtitle="{{ property()?.vacant ? 'Vacant' : 'Occupied' }}"
					iconName="user-circle"
				></fixify-property-info-cell>
			}
			<fixify-property-info-cell
				title="Total jobs"
				subtitle="{{ property()?.totalJobs }}"
				iconName="tool-02"
			></fixify-property-info-cell>
		</div>
	</div>

	<div class="flex flex-col mb-6">
		@if (!isEstate()) {
			<div
				class="py-4 gap-4 flex justify-between items-center bg-white min-h-20 border-b-2 border-gray-100"
			>
				<p class="leading-tight text-base font-semibold text-gray-900">
					Occupants
				</p>
				<div
					class="flex text-right flex-col gap-1 leading-tight text-base font-normal text-gray-900"
				>
					@if (property()?.vacant) {
						<p>No occupants</p>
					} @else {
						@for (
							occupant of property()?.occupants ?? [];
							track $index
						) {
							<p>{{ occupant.fullName }}</p>
						}
					}
				</div>
			</div>
		}
		<div
			class="py-4 gap-4 flex justify-between items-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<p class="leading-tight text-base font-semibold text-gray-900">
				{{ isEstate() ? 'Estate Managers' : 'Landlords/Trustees' }}
			</p>
			<div
				class="flex flex-col gap-1 leading-tight text-base font-normal text-gray-900"
			>
				@for (item of landlordsTrusteesManagers(); track $index) {
					<p>{{ item.fullName }}</p>
				}
			</div>
		</div>
		<div
			class="py-4 gap-4 flex justify-between items-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<p class="leading-tight text-base font-semibold text-gray-900">
				Managing Agent
			</p>
			<div
				class="flex flex-col gap-1 leading-tight text-base font-normal text-gray-900"
			>
				<div class="flex gap-3 items-center">
					@if (property()?.managingAgent?.profilePicture) {
						<fixify-avatar
							[source]="
								property()?.managingAgent?.profilePicture!
							"
							class="h-8 w-8"
						></fixify-avatar>
					} @else {
						<fixify-avatar class="h-8 w-8"></fixify-avatar>
					}
					<p
						(click)="openAgentModal()"
						class="hover:underline hover:cursor-pointer"
					>
						{{ property()?.managingAgent?.firstName ?? 'None' }}
						{{ property()?.managingAgent?.lastName ?? '' }}
					</p>
				</div>
			</div>
		</div>
		<div
			class="py-4 flex flex-col justify-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<div class="w-full flex items-center gap-2 justify-between">
				@if (!isEstate()) {
					<div class="flex">
						<p
							class="leading-tight text-base font-semibold text-gray-900"
						>
							Linked Complex/Estate
						</p>
						@if (property()?.estateName) {
							<div
								class="ml-2 w-5 h-5 p-0.5 bg-primary-100 rounded"
							>
								<fixify-icon
									class="text-primary-900 w-4 h-4"
									name="home-05"
									symbol="filled"
								></fixify-icon>
							</div>
						}
					</div>
					<button
						(click)="linkOrUnlinkEstate()"
						[ngClass]="{
							'btn-secondary': !property()?.estateName,
							'btn-outline border-gray-200':
								property()?.estateName
						}"
						class="small"
					>
						{{
							!property()?.estateName
								? 'Link to complex/estate'
								: 'Unlink'
						}}
					</button>
				} @else {
					<p
						class="leading-tight text-base font-semibold text-gray-900"
					>
						{{
							property()?.linkedProperties + ' Linked Properties'
						}}
					</p>
					<button
						(click)="manageLinkedProperties()"
						class="small btn-outline border-gray-200"
					>
						{{ 'Manage' }}
					</button>
				}
			</div>
			@if (property()?.estateName) {
				<p class="text-sm text-gray-900 font-normal">
					{{ property()?.estateName }}
				</p>
			}
		</div>
		<div
			class="py-4 flex flex-col justify-center bg-white min-h-20 border-b-2 border-gray-100"
		>
			<div class="w-full flex items-center gap-2 justify-between">
				<p class="leading-tight text-base font-semibold text-gray-900">
					Favourite service providers
				</p>
				<button
					(click)="addOrEditFavouriteSps()"
					[ngClass]="{
						'btn-secondary': !hasFavouriteSps(),
						'btn-outline border-gray-200': hasFavouriteSps()
					}"
					class="small"
				>
					{{ !hasFavouriteSps() ? 'Add service providers' : 'Edit' }}
				</button>
			</div>
			@if (hasFavouriteSps()) {
				<div class="flex flex-wrap gap-2 mt-4">
					@for (
						item of property()?.favouritedServiceProviders ?? [];
						track $index
					) {
						<fixify-sp-info-card
							companyName="{{ item.companyDetails?.companyName }}"
							imageUrl="{{ item.companyDetails?.companyLogo }}"
						></fixify-sp-info-card>
					}
				</div>
			}
		</div>
		@if (mapUrl()) {
			<div class="bg-white py-4 min-h-20 border-b-2 border-gray-100">
				<p
					class="pb-2 leading-tight text-base font-semibold text-gray-900"
				>
					Map
				</p>
				<div class="flex-col">
					<div class="max-h-80">
						<img
							[src]="mapUrl()"
							class="rounded-md object-contain"
						/>
					</div>
				</div>
			</div>
		}
	</div>

	<div class="flex justify-between gap-2">
		<button
			(click)="ekWeetNieBaas()"
			class="btn btn-outline danger border-0"
		>
			Archive {{ isEstate() ? 'estate/complex' : 'property' }}
		</button>
		<div class="flex gap-2">
			<button class="btn-outline">Create job</button>
			<button (click)="goToEditProperty()" class="btn-primary">
				Edit details
			</button>
		</div>
	</div>
</fixify-modal>

