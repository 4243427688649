import {
	Component,
	OnDestroy,
	OnInit,
	computed,
	inject,
	signal
} from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from '../../../shared/data-access/breadcrumb.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { AlertComponent } from '../../../shared/ui/alert/alert.component';
import { NewCategoriesComponent } from './ui/new-categories/new-categories.component';
import { ServiceProviderService } from '../../../shared/data-access/service-provider/service-provider.service';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { CurrentCategoriesComponent } from './ui/current-categories/current-categories.component';
import {
	ReceivedCategories,
	ServiceProviderCategory
} from '../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'app-categories',
	standalone: true,
	imports: [
		BreadcrumbComponent,
		NgClass,
		AsyncPipe,
		AlertComponent,
		NewCategoriesComponent,
		CurrentCategoriesComponent
	],
	templateUrl: './categories.component.html',
	styleUrl: './categories.component.css'
})
export class CategoriesComponent implements OnInit, OnDestroy {
	serviceProviderService = inject(ServiceProviderService);
	breadcrumbService = inject(BreadcrumbService);
	destroy$ = new Subject<void>();
	serviceProviderId = signal<string | null>(null);
	save = signal<boolean>(false);

	categoryStatusses$ = this.serviceProviderService.serviceProvider().pipe(
		map(sp => {
			const categories = sp?.categories ?? {};
			const entries = Object.entries(categories);

			const anyVerifying = entries.some(
				([, value]) => value.status === 'pending'
			);
			const hasCategories = !!sp?.categories;
			const fullyVerified = entries.every(
				([, value]) => value.status === 'active'
			);
			const allDeactivated = entries.every(
				([, value]) => value.status === 'deactivated'
			);
			const someVerifyingWithoutActive =
				entries.some(([, value]) => value.status === 'pending') &&
				entries.every(([, value]) => value.status !== 'active');
			const allVerifying = entries.every(
				([, value]) => value.status === 'pending'
			);

			return {
				anyVerifying,
				hasCategories,
				fullyVerified,
				allDeactivated,
				someVerifyingWithoutActive,
				allVerifying
			};
		})
	);

	categories$ = this.serviceProviderService.categories();
	spCategories$ = this.serviceProviderService.serviceProvider().pipe(
		map(sp => {
			const categories = sp?.categories ?? ({} as ReceivedCategories);
			const category = Object.entries(categories).map(([key, value]) => {
				return {
					id: key,
					name: value.name,
					tasks: value.tasks,
					supportingDocuments: value.supportingDocuments.map(
						(document: string) => {
							return {
								fileName:
									this.serviceProviderService.extractFilenameFromFirebaseUrl(
										document
									),
								url: document,
								percentage: 100
							};
						}
					),
					status: value.status
				} as unknown as ServiceProviderCategory;
			});
			return category;
		})
	);

	unavailableCategoryIds$ = combineLatest([
		this.categories$,
		this.spCategories$
	]).pipe(
		map(([categories, spCategories]) => {
			const spCategoryIds = spCategories.map(spCategory => spCategory.id);
			return categories
				.filter(category => spCategoryIds.includes(category.id))
				.map(category => category.id);
		})
	);

	formValid = signal(false);
	addCategory = signal(false);
	editCategory = signal(false);

	buttonText = computed(() => {
		if (this.addCategory()) {
			return 'Save';
		}

		return 'Add Category';
	});

	disableSave = computed(() => {
		if (this.addCategory()) {
			return !this.formValid();
		}

		if (this.editCategory()) {
			return true;
		}

		return false;
	});

	ngOnInit() {
		this.breadcrumbService.setBreadcrumbs([
			{ label: 'Account', url: '/service-provider/account' },
			{ label: 'Categories' }
		]);

		this.serviceProviderService
			.serviceProvider()
			.pipe(takeUntil(this.destroy$))
			.subscribe(sp => {
				this.serviceProviderId.set(sp?.id ?? null);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	addOrSaveChanges() {
		if (this.editCategory()) {
			// TODO: Save changes
			this.editCategory.set(false);
		}

		if (this.addCategory()) {
			// TODO: Add category
			this.save.set(true);
			this.addCategory.set(false);
		}

		this.addCategory.set(true);
	}

	breadcrumbs() {
		return this.breadcrumbService.breadcrumbs$;
	}

	editingIdChange(event: string | null) {
		this.editCategory.set(!!event);
	}

	formValidChange(event: boolean) {
		this.formValid.set(event);
	}

	noCategoriesToAdd() {
		this.addCategory.set(false);
		this.formValid.set(false);
	}

	toggleAddCategory() {
		this.addCategory.set(true);
	}
}
