import { FormArray, FormControl, FormGroup } from '@angular/forms';

export const markAllFieldsAsDirtyAndTouched = (
	formGroup: FormGroup | FormArray
) => {
	Object.keys(formGroup.controls).forEach(field => {
		const control = formGroup.get(field);
		if (control instanceof FormControl) {
			control.markAsDirty();
			control.markAsTouched();
		} else if (
			control instanceof FormGroup ||
			control instanceof FormArray
		) {
			markAllFieldsAsDirtyAndTouched(control);
		}
	});
	formGroup.updateValueAndValidity({ onlySelf: true });
};
