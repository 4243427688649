@if (managedFulfilment()!.jobStatus === 'new') {
	<fixify-track-information-card
		statusText="To do"
		statusColor="bright-orange"
		title="Review & Action job"
		subtitle="Review the job details and choose to accept or reject the job."
		primaryActionText="Accept job"
		tertiaryActionText="Decline job"
		[loading]="loading"
		(primaryPress)="accept()"
		(tertiaryPress)="decline()"
	></fixify-track-information-card>
} @else if (managedFulfilment()!.jobStatus === 'cancelled') {
	<fixify-job-reject-detail-item
		[reason]="managedFulfilment()!.job.statusReason ?? 'No reason provided'"
		title="Job cancelled"
	></fixify-job-reject-detail-item>
} @else if (managedFulfilment()!.jobStatus === 'ongoing') {
	<div class="flex flex-col gap-6 items-center">
		@if (waitingforSignOff()) {
			<fixify-track-information-card
				class="w-full"
				title="Waiting for final sign off"
				subtitle="Your invoice(s) are being reviewed by the managing agent."
			></fixify-track-information-card>
		}
		@if (canMarkForCompletion()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				title="Mark fulfilment as complete"
				subtitle="Once you are satisfied with your uploaded invoice, you should mark your fulfilment as complete. Note - Once you mark this fulfilment as complete you will no longer be able to upload invoices."
				primaryActionText="Mark as complete"
				secondaryActionText="Upload more invoices"
				(primaryPress)="markAsComplete()"
				(secondaryPress)="upload.emit('invoice')"
			></fixify-track-information-card>
		}
		@if (shouldUploadQuote()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				title="Upload your quote"
				subtitle="Upload your quote for review and approval."
				primaryActionText="Upload"
				(primaryPress)="upload.emit('quote')"
			></fixify-track-information-card>
		}
		@if (shouldUploadQuotePreviouslyRejected()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				topBarText="Quote rejected"
				[topBarError]="true"
				title="Upload new quote"
				subtitle="Your quote was rejected. Please submit a new quote or contact the agency to cancel this job.."
				primaryActionText="Upload"
				(primaryPress)="upload.emit('quote')"
			></fixify-track-information-card>
		}
		@if (shouldUploadInvoice()) {
			<fixify-track-information-card
				class="w-full"
				statusText="To do"
				statusColor="bright-orange"
				title="Upload your invoice"
				subtitle="Once you are finished with the job, upload your invoice(s)."
				primaryActionText="Upload"
				(primaryPress)="upload.emit('invoice')"
			></fixify-track-information-card>
		}
		@if (quoteInForReview()) {
			<fixify-track-information-card
				class="w-full"
				title="Quote sent for review"
				subtitle="You will be notified if your quote has been approved. Once approved, contact the customer and schedule a date and time that works for you both."
				primaryActionText="Upload more"
				(primaryPress)="upload.emit('quote')"
			></fixify-track-information-card>
		}
		@if (optionallyScheduleSiteVisit()) {
			<fixify-track-information-card
				class="w-full"
				statusText="Optional"
				statusColor="dark-gray"
				title="Schedule site visit date"
				subtitle="Contact the customer to schedule a date and time that works for you both. Add the details here once you have them."
				primaryActionText="Add date & time"
				(primaryPress)="setDate.emit()"
			></fixify-track-information-card>
		}
		@if (optionallyScheduleJobVisit()) {
			<fixify-track-information-card
				class="w-full"
				statusText="Optional"
				topBarText="Quote accepted"
				statusColor="dark-gray"
				title="Schedule job date"
				subtitle="Your quote was accepted! Contact the customer to schedule a date and time that works for you both. Add the details here once you have them.."
				primaryActionText="Add date & time"
				(primaryPress)="setDate.emit()"
			></fixify-track-information-card>
		}
		@if (uploadSupportingDocuments()) {
			<fixify-track-information-card
				class="w-full"
				statusText="Optional"
				statusColor="dark-gray"
				title="Upload supporting photos"
				subtitle="Add any photos that support your quote."
				primaryActionText="Upload"
				(primaryPress)="upload.emit('media')"
			></fixify-track-information-card>
		}

		<p (click)="cancelFulfilment()" class="text-action py-2 text-sm font-medium tetx-gray-600">
			Cancel job
		</p>
	</div>
} @else if (managedFulfilment()!.jobStatus === 'completed') {
	<div class="flex flex-col gap-6 items-center">
		@if (waitingforSignOff()) {
			<fixify-track-information-card
				class="w-full"
				title="Waiting for final sign off"
				subtitle="Your invoice(s) are being reviewed by the managing agent."
			></fixify-track-information-card>
		}

		<p (click)="cancelFulfilment()" class="text-action py-2 text-sm font-medium tetx-gray-600">
			Cancel job
		</p>
	</div>
} @else if (managedFulfilment()!.job.status === 'completed') {
	<fixify-track-information-card
		class="w-full"
		titleIcon="check-circle"
		title="Job complete!"
		subtitle="Completed on ???"
	></fixify-track-information-card>
	<!-- TODO: if there is a rating -->
	@if (true) {
		<div class="w-full rounded bg-gray-100 p-8">
			<p class="mb-4 text-base text-gray-500 font-medium">Your rating</p>
			<div class="flex flex-col gap-3">
				<div class="flex flex-col gap-3">
					<fixify-rating-item
						title="Quality of work"
						[stars]="3"
						[totalStars]="5"
					></fixify-rating-item>
					<fixify-rating-item
						title="Quality of quote"
						[stars]="5"
						[totalStars]="5"
					></fixify-rating-item>
					<fixify-rating-item
						title="Communication"
						[stars]="4"
						[totalStars]="5"
					></fixify-rating-item>
					<fixify-rating-item
						title="Reliability"
						[stars]="2"
						[totalStars]="5"
					></fixify-rating-item>
					<div>
						<p class="text-sm font-medium text-gray-900 mb-1">
							Review
						</p>
						<p class="text-base text-gray-900 font-normal">
							{{ 'No review comments' }}
						</p>
					</div>
				</div>
				<p class="text-sm font-semibold text-gray-900 mt-5">
					- Jonathan, Harmony Real Estate
				</p>
			</div>
		</div>
	}
}

