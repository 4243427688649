import { Component, computed, signal } from '@angular/core';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ComplexEstatesTableComponent } from '../../../../complex-estates/ui/tables/complex-estates-table/complex-estates-table.component';
import { ManagedEstate } from '../../../../../shared/data-access/agency/agency.types';

@Component({
	selector: 'fixify-link-to-complex-estate-modal',
	standalone: true,
	imports: [ModalCardComponent, IconComponent, ComplexEstatesTableComponent],
	templateUrl: './link-to-complex-estate.component.html',
	styleUrl: './link-to-complex-estate.component.css'
})
export class LinkToComplexEstateComponent extends ModalComponent<
	{ canAddEstate?: boolean },
	Array<ManagedEstate>
> {
	selectedEstates = signal<Array<ManagedEstate>>([]);

	canLink = computed(() => this.selectedEstates().length > 0);
	addEstate = signal<boolean>(false);

	protected override onDataSet(): void {
		this.addEstate.set(this.data?.canAddEstate ?? false);
	}

	selectedEstatesChange(estates: Array<ManagedEstate>) {
		this.selectedEstates.set(estates);
	}

	link() {
		this.close(this.selectedEstates());
	}

	add() {
		this.close([]);
	}
}
