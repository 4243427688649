import { Component, OnDestroy, signal } from '@angular/core';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { DatePipe } from '@angular/common';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { ViewAgentModalComponent } from '../../modals/view-agent-modal/view-agent-modal.component';
import { Router } from '@angular/router';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { ManagedAgent } from '../../../../../shared/data-access/agency/agency.types';
import { Subject, takeUntil } from 'rxjs';
import { AgencyAgentsService } from '../../../../../shared/data-access/agency/agency.agents.service';

// export interface ITestAgentInterface {
// 	id: number;
// 	name: string | null;
// 	role: string;
// 	email: string;
// 	phoneNumber: string | null;
// 	dateJoined: Date | null;
// }

@Component({
	selector: 'fixify-agents-table',
	standalone: true,
	imports: [
		TableComponent,
		TableColumnDirective,
		PaginationComponent,
		ReactiveFormsModule,
		TextInputComponent,
		IconComponent,
		DatePipe,
		AvatarComponent,
		StatusPillComponent
	],
	templateUrl: './agents-table.component.html',
	styleUrl: './agents-table.component.css'
})
export class AgentsTableComponent implements OnDestroy {
	itemsPerPage = 10;
	currentPage = 1;
	totalPages = 0;
	totalCount = 0;
	selectedAgent = signal<ManagedAgent | null>(null);

	private destroy$ = new Subject<void>();

	agents: Array<ManagedAgent> | null = null;

	filterForm: FormGroup;

	constructor(
		private router: Router,
		private modalService: ModalService,
		private agencyAgentsService: AgencyAgentsService
	) {
		this.filterForm = new FormGroup({
			search: new FormControl('')
		});

		this.agencyAgentsService
			.agents()
			.pipe(takeUntil(this.destroy$))
			.subscribe(agents => {
				this.agents = agents?.result ?? null;
				this.totalPages = agents?.totalPages ?? 0;
				this.currentPage = agents?.page ?? 0;
				this.totalCount = agents?.totalCount ?? 0;
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	// This will be returned by the BE, but for testing this is calculated
	// get totalPages(): number {
	// 	return Math.ceil(this.agents?.length ?? 0 / this.itemsPerPage);
	// }

	isPending(agent: ManagedAgent) {
		return agent?.status === 'pending';
	}

	isProfilePictureAvailable(agent: ManagedAgent) {
		return agent?.profilePicture != null;
	}

	onPageChange(page: number) {
		this.currentPage = page;
		this.agents = null;
		this.agencyAgentsService.fetchAgents(page);
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		// this.agents = [...(this.agents ?? [])];
	}

	deleteSelectedAgent() {
		if (!this.selectedAgent()) {
			return;
		}

		this.agencyAgentsService.deleteAgent(this.selectedAgent()?.id ?? '');

		// call BE to delete agent, show a loader
		// Rememer to call the BE to fetch the data again after deletion, for the current page
	}

	editAgent(agentId: string | number) {
		this.router.navigate(['/agency/agents/edit', agentId]);

		// NEW WINDOW EXAMPLE: open a new window to edit the agent at path /agency/agents/edit/:id

		// const url = this.router.serializeUrl(
		// 	this.router.createUrlTree(['/agency/agents/edit', agentId])
		// );

		// window.open(url, '_blank');
	}

	editSelectedAgent() {
		if (!this.selectedAgent()) {
			return;
		}

		this.editAgent(this.selectedAgent()?.id ?? '');
	}

	showDeleteAgentModal(agent?: ManagedAgent) {
		if (!agent) {
			if (!this.selectedAgent()) {
				return;
			}

			this.selectedAgent.set(this.selectedAgent());
		} else {
			this.selectedAgent.set(agent);
		}

		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Delete ' + this.selectedAgent()?.name + '?',
				body: 'This action cannot be undone. Are you sure you want to delete this agent?',
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.deleteSelectedAgent();
				}
			});
	}

	viewAgent(agent: ManagedAgent) {
		this.selectedAgent.set(agent);
		this.modalService
			.showModal(ViewAgentModalComponent, agent)
			.then(result => {
				if (result) {
					switch (result) {
						case 'edit':
							this.editSelectedAgent();
							break;
						case 'delete':
							this.showDeleteAgentModal(agent);
							break;
					}
				}
			});
	}
}
