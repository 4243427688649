<div class="max-w px-4 xl:px-16">
	<div class="flex gap-3 mb-1.5">
		<div class="flex cursor-pointer">
			<fixify-icon
				(click)="goBack()"
				class="w-6 h-6 text-gray-800 mt-1"
				name="arrow-left"
			></fixify-icon>
		</div>
		<div class="w-full">
			@if (managedFulfilment) {
				<div class="flex gap-3 items-center justify-between">
					<div class="flex items-center gap-3">
						<p
							class="text-2xl text-gray-900 font-medium font-outfit"
						>
							Job #{{ managedFulfilment.job.jobNumber }}
						</p>
						<fixify-status-pill [color]="mapColor()">
							{{ managedFulfilment.jobStatus | titlecase }}
						</fixify-status-pill>
					</div>
				</div>
				<p class="text-sm font-normal text-gray-500 mt-0.5">
					Created on
					{{ managedFulfilment.createdOn | date: 'dd MMM, yyyy' }} at
					{{ managedFulfilment.createdOn | date: 'HH:mm' }}
				</p>
			}
		</div>
	</div>
	@if (!managedFulfilment) {
		<div class="flex justify-center items-center">
			<app-loader [loadingState]="true"></app-loader>
		</div>
	} @else {
		@if (managedFulfilment.jobStatus === 'ongoing' && anyDatesSet()) {
			<div
				class="z-30 sticky -top-10 rounded-lg bg-stone items-center justify-center p-4 flex gap-2 mt-6"
			>
				<fixify-icon
					class="w-6 h-6 text-primary-500"
					name="calendar-check-01"
				></fixify-icon>
				<p class="text-base font-medium text-gray-900">
					{{
						showSiteVisit()
							? 'Site visit scheduled:'
							: 'Job date scheduled:'
					}}
					{{
						(showSiteVisit()
							? managedFulfilment.scheduledQuoteDate
							: managedFulfilment.scheduledJobDate
						) | date: 'dd MMM yyyy · HH:mm'
					}}
				</p>
				<div
					(click)="openDateModal()"
					class="mx-3 text-action text-sm text-gray-700"
				>
					Edit
				</div>
			</div>
		}
		<div
			class="flex flex-col flex-col-reverse lg:flex-row lg:gap-6 mt-9 xl:gap-12"
		>
			<div class="w-full lg:w-3/5">
				<fixify-service-provider-job-overview
					[(managedFulfilment)]="managedFulfilment"
				></fixify-service-provider-job-overview>
				<fixify-divider class="my-8"></fixify-divider>
				<fixify-service-provider-customers
					[managedFulfilment]="managedFulfilment"
				></fixify-service-provider-customers>
				@if (managedFulfilment.jobStatus !== 'new') {
					<fixify-service-provider-documents #documentsComponent
						[(managedFulfilment)]="managedFulfilment"
					></fixify-service-provider-documents>
					<fixify-divider class="my-8"></fixify-divider>
				}
				<fixify-service-provider-job-location
					[managedFulfilment]="managedFulfilment"
				></fixify-service-provider-job-location>
			</div>
			<div
				class="w-full mb-6 lg:w-2/5 lg:mb-0 lg:overflow-y-auto lg:sticky lg:top-8 lg:h-min"
			>
				<fixify-service-provider-to-do
					[(managedFulfilment)]="managedFulfilment"
					(upload)="trigerUpload($event)"
					(setDate)="triggerSetDate()"
				></fixify-service-provider-to-do>
			</div>
		</div>
		<fixify-divider class="my-8"></fixify-divider>
		<fixify-service-provider-agency-details
			[managedFulfilment]="managedFulfilment"
		></fixify-service-provider-agency-details>
	}
</div>

