import { Component, computed, effect, input, model } from '@angular/core';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { ITabItem } from '../../../../../shared/ui/tabs/tabs.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { PropertyAvatarComponent } from '../../../../../shared/ui/property-avatar/property-avatar.component';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { ViewAgentModalComponent } from '../../../../agents/ui/modals/view-agent-modal/view-agent-modal.component';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import {
	ManagedAgent,
	ManagedEstate
} from '../../../../../shared/data-access/agency/agency.types';
import { PropertyModalComponent } from '../../../../../shared/ui/modals/property-modal/property-modal.component';
import { AssignToAgentComponent } from '../../../../properties/ui/modals/assign-to-agent/assign-to-agent.component';
import { NgClass } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { AgencyEstatesService } from '../../../../../shared/data-access/agency/agency.estates.service';

interface TableManagedEstate extends ManagedEstate {
	selected?: boolean;
}

@Component({
	selector: 'fixify-complex-estates-table',
	standalone: true,
	imports: [
		NgClass,
		PaginationComponent,
		TableColumnDirective,
		TextInputComponent,
		ReactiveFormsModule,
		IconComponent,
		TableComponent,
		PropertyAvatarComponent
	],
	templateUrl: './complex-estates-table.component.html',
	styleUrl: './complex-estates-table.component.css'
})
export class ComplexEstatesTableComponent {
	itemsPerPage = 10;
	currentPage = 1;
	totalCount = 0;
	totalPages = 0;
	filterForm: FormGroup;
	modalView = input<boolean>(false);
	multiSelect = input<boolean>(false);
	selectedEstates = model<Array<TableManagedEstate>>([]);
	disableSelectCheckbox = computed(
		() =>
			this.selectedEstates().length == 1 &&
			this.modalView() &&
			!this.multiSelect()
	);
	estates: Array<TableManagedEstate> | null = null;

	private destroy$ = new Subject<void>();

	constructor(
		private router: Router,
		private modalService: ModalService,
		private agencyEstatesService: AgencyEstatesService
	) {
		effect(() => {
			const currentlySelected = this.selectedEstates();
			this.estates?.forEach(e => {
				e.selected = currentlySelected.some(se => se.id === e.id);
			});
		});

		this.filterForm = new FormGroup({
			search: new FormControl('')
		});

		//TODO: Uncomment when backend is capable of doing search
		// this.filterForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
		// 	next: value => {
		// 		this.agencyEstatesService.fetchEstates({
		// 			search: value.search
		// 		});
		// 	}
		// });

		this.agencyEstatesService
			.estates()
			.pipe(takeUntil(this.destroy$))
			.subscribe(estates => {
				this.estates =
					estates?.result.map(estate => {
						return {
							...estate,
							selected: this.selectedEstates().some(
								p => p.id === estate.id
							)
						};
					}) ?? null;
				this.totalPages = estates?.totalPages ?? 0;
				this.currentPage = estates?.page ?? 0;
				this.totalCount = estates?.totalCount ?? 0;
			});
	}

	// This will be returned by the BE, but for testing this is calculated
	// get totalPages(): number {
	// 	return Math.ceil(this.estates.length / this.itemsPerPage);
	// }

	assignToAgent(estates: Array<TableManagedEstate>) {
		this.modalService.showModal(AssignToAgentComponent).then(result => {
			if (result) {
				this.agencyEstatesService.assignAgentToEstates(
					result.id,
					estates.map(p => p.id)
				);
				this.clearSelectedProperties();
			}
		});
	}

	clearSelectedProperties() {
		this.selectedEstates.set([]);
		this.estates?.forEach(p => (p.selected = false));
	}

	archive(property: ManagedEstate) {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Archive Complex/Estate',
				body: 'Are you sure you want to archive this complex/estate?',
				deleteButtonText: 'Archive',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					//TODO archive property on BE
				}
			});
	}

	deleteSelectedComplexesEstates() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: `Delete ${this.selectedEstates().length} ${this.selectedEstates().length === 1 ? 'complex/estate' : 'complex/estates'}?`,
				body: `Are you sure you want to delete the selected ${this.selectedEstates().length === 1 ? 'complex/estate' : 'complex/estates'}?`,
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(deleteResult => {
				if (deleteResult) {
					this.agencyEstatesService.deleteEstates(
						this.selectedEstates().map(p => p.id)
					);
					this.clearSelectedProperties();
				}
			});
	}

	estateManagerNames(estate: ManagedEstate) {
		return [...estate.estateManagers.map(m => m.fullName)].join(',');
	}

	goToEditComplexEstate(estate: ManagedAgent) {
		this.router.navigate(['agency/complexes-estates/edit', estate.id]);
	}

	async linkProperties(estate: ManagedEstate) {
		// This HAS to be lazy loaded to avoid circular dependencies
		const { LinkPropertiesComponent } = await import(
			'../../modals/link-properties/link-properties.component'
		);
		this.modalService.showModal(LinkPropertiesComponent).then(result => {
			if (result) {
				this.agencyEstatesService.linkPropertiesToEstate(
					estate.id,
					result.map(p => p.id)
				);
			}
		});
	}

	onlySingleSelectAllowed(estate: ManagedEstate) {
		return (
			!this.multiSelect() &&
			this.selectedEstates()?.length > 0 &&
			!this.selectedEstates()?.includes(estate)
		);
	}

	onPageChange(page: number) {
		this.currentPage = page;
		this.estates = null;
		//TODO: i,plment serach when backend is setup for it
		// const search = this.filterForm.get('search')?.value;
		this.agencyEstatesService.fetchEstates({ page });
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		// this.estates = [...this.estates];
	}

	onRowClicked(estate: ManagedEstate) {
		// Do not allow complex flows when viewing this table from a select modal
		if (this.modalView()) {
			if (!this.onlySingleSelectAllowed(estate))
				this.selectItem(null, estate);
		} else {
			this.openPropertyModal(estate);
		}
	}

	onTabItemChange(item: ITabItem) {
		console.log(item);
		// Apply BE filter
	}

	openPropertyModal(estate: ManagedEstate) {
		this.modalService.showModal(PropertyModalComponent, estate);
	}

	openAgentModal(event: MouseEvent, agent: ManagedAgent) {
		event.stopPropagation();
		if (this.modalView()) return;
		this.modalService
			.showModal(ViewAgentModalComponent, agent)
			.then(result => {
				if (result) {
					switch (result) {
						case 'delete':
							this.modalService
								.showModal(DeleteModalComponent, {
									title: 'Delete agent?',
									body: `Are you sure you want to delete ${agent.name}?`,
									deleteButtonText: 'Delete',
									enableCancel: true
								})
								.then(deleteResult => {
									if (deleteResult) {
										//TODO delete agent
									}
								});

							break;
						case 'edit':
							this.router.navigate([
								'/agency/agents/edit',
								agent.id
							]);
							break;
					}
				}
			});
	}

	selectItem(event: MouseEvent | null, estate: TableManagedEstate) {
		//TODO: Change after BE implementation to make sure to keep a record of property IDs to work with pagination and search results
		event?.stopPropagation();

		if (!estate.selected && this.disableSelectCheckbox()) {
			return;
		}

		estate.selected = !estate.selected;

		if (estate.selected) {
			this.selectedEstates.set([...this.selectedEstates(), estate]);
		} else {
			this.selectedEstates.set(
				this.selectedEstates().filter(p => p.id !== estate.id)
			);
		}
	}
}
