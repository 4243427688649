<fixify-modal size="2xl">
	<img class="mb-5" src="assets/svg/location-pin-primary.svg" />
	<div class="flex items-start justify-between rounded-t">
		<p class="text-xl font-semibold text-gray-900">{{ data?.title }}</p>
	</div>
	<div class="pt-1 pb-6">
		<p class="text-base leading-relaxed text-gray-500">
			{{ data?.body }}
		</p>
	</div>
	<form [formGroup]="searchForm" class="mb-6">
		<fixify-search-places
			[control]="searchForm.get('search')!"
			(selectedLocation)="selectedLocation($event)"
			[(ignoreNextSearchChange)]="ignoreNextSearchChange"
			[(searchResults)]="searchResults"
		></fixify-search-places>
	</form>
	<div class="w-full mb-6 overflow-hidden rounded h-[300px]">
		<google-map
			[center]="center()"
			[zoom]="zoom()"
			height="100%"
			width="100%"
			[options]="options"
			(mapClick)="mapClick($event)"
		>
			@for (location of filteredLocations(); track $index) {
				<map-circle
					[center]="location.latlng"
					[radius]="radius()"
					[options]="circleOptions"
					[id]="location.id"
				/>
				<map-advanced-marker
					[position]="location.latlng"
					[content]="generateContent(location.id)"
				></map-advanced-marker>
			}
			@if (clickedLocation !== null) {
				<map-circle
					[center]="clickedLocation"
					[radius]="radius()"
					[options]="circleOptions"
				/>
				<map-advanced-marker
					[position]="clickedLocation"
					[content]="createContent()"
					[options]="{}"
				></map-advanced-marker>
			}
		</google-map>
	</div>
	<div
		class="flex items-center justify-end space-x-2 border-gray-200 rounded-b"
	>
		<button (click)="close()" class="btn-outline border-gray-200">
			Cancel
		</button>

		<button
			(click)="addLocation()"
			[disabled]="
				!clickedLocation || searchForm.invalid || loadingResult()
			"
			class="btn btn-primary"
		>
			{{ data?.editLocation ? 'Update' : 'Add' }}
		</button>
	</div>
</fixify-modal>

