import { Component, inject, signal } from '@angular/core';
import { DividerComponent } from '../../shared/ui/divider/divider.component';
import {
	AgencyTileComponent,
	LinkedAgency
} from './ui/agency-tile/agency-tile.component';
import { DeleteModalComponent } from '../../shared/ui/modals/delete/delete.component';
import { ViewLinkedAgencyModalComponent } from '../../shared/ui/modals/view-linked-agency-modal/view-linked-agency-modal.component';
import { ModalService } from '../../shared/ui/modals/modal.service';
import { ServiceProviderLocationsComponent } from '../../shared/ui/service-provider-locations/service-provider-locations.component';
import { Location } from '../../shared/data-access/service-provider/service-provider.types';
import { Subject, takeUntil } from 'rxjs';
import { ServiceProviderService } from '../../shared/data-access/service-provider/service-provider.service';
import { AddLocationModalComponent } from '../../shared/ui/modals/add-location-modal/add-location-modal.component';
import { NoLocationsComponent } from './ui/no-locations/no-locations.component';

@Component({
	selector: 'app-locations',
	standalone: true,
	imports: [
		DividerComponent,
		AgencyTileComponent,
		ViewLinkedAgencyModalComponent,
		ServiceProviderLocationsComponent,
		NoLocationsComponent
	],
	templateUrl: './locations.component.html',
	styleUrl: './locations.component.css'
})
export class LocationsComponent {
	locations = signal<Array<Location>>([]);
	isViewLinkedAgencyModalOpen = signal<boolean>(false);
	selectedAgency = signal<LinkedAgency | null>(null);
	linkedAgencies: Array<LinkedAgency> = [
		// {
		// 	id: '123',
		// 	name: 'Oasis Properties',
		// 	url: 'https://www.agency1.com',
		// 	formattedAddress:
		// 		'901 Scottsridge Estate, Street, Big Bay, Western Cape, 1009',
		// 	managingAgent: 'John Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// },
		// {
		// 	id: '2345',
		// 	name: 'PrimeNest Realty',
		// 	url: 'https://www.agency2.com',
		// 	formattedAddress: '383 Manitoba Drive, Faerie Glen, Pretoria, 0081',
		// 	managingAgent: 'Jane Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// },
		// {
		// 	id: '3456',
		// 	name: 'NextDoor Realty Group',
		// 	url: 'https://www.agency3.com',
		// 	formattedAddress: 'Durban',
		// 	managingAgent: 'John Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// },
		// {
		// 	id: '4567',
		// 	name: 'Summit property Solutions Brackenfell',
		// 	url: 'https://www.agency3.com',
		// 	formattedAddress: 'Pretoria',
		// 	managingAgent: 'Jane Doe',
		// 	email: 'john@doe.com',
		// 	contactNumber: '021 123 4567'
		// }
	];

	serviceProviderService = inject(ServiceProviderService);
	destroy$ = new Subject<void>();

	constructor(private modalService: ModalService) {}

	ngOnInit(): void {
		this.serviceProviderService
			.locations()
			.pipe(takeUntil(this.destroy$))
			.subscribe(locations => {
				this.locations.set([...locations]);
			});
	}

	openAddLocationModal() {
		this.modalService
			.showModal(AddLocationModalComponent, {
				title: 'Add location',
				body: 'Search to add an address and adjust the pin on the map.\nEach circle represents a 30km radius.',
				editLocation: null,
				otherLocations: []
			})
			.then(location => {
				if (location) {
					this.serviceProviderService.addLocation(
						location.coordinates,
						location.name
					);
				}
			});
	}

	openDeleteModal(location: Location, $event: MouseEvent) {
		$event.stopPropagation();

		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Delete location?',
				body: `Are you sure you want to delete ${location.name}?`,
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.serviceProviderService.removeLocation(
						location.id ?? ''
					);
				}
			});
	}

	openModalAsEdit(location: Location, $event: MouseEvent) {
		$event.stopPropagation();

		this.modalService
			.showModal(AddLocationModalComponent, {
				title: 'Edit location',
				body: 'Edit the address and adjust the pin on the map.\nEach circle represents a 30km radius.',
				editLocation: location,
				otherLocations: this.locations()
			})
			.then(location => {
				if (location) {
					this.serviceProviderService.editLocation(
						location.id,
						location.coordinates,
						location.name
					);
				}
			});
	}

	openViewLinkedAgencyModal(agency: LinkedAgency) {
		this.selectedAgency.set(agency);
		this.isViewLinkedAgencyModalOpen.set(true);
	}

	openDelinkAgencyModal() {
		if (!this.selectedAgency()) {
			return;
		}

		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Delink from ' + this.selectedAgency()?.name + '?',
				body: 'This action is irreversible. Are you sure you want to delink this agency?',
				deleteButtonText: 'Delink',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.delinkAgency();
				}
			});
	}

	delinkAgency() {
		if (!this.selectedAgency()) {
			return;
		}

		const index = this.linkedAgencies.findIndex(
			agency => agency.id === this.selectedAgency()?.id
		);

		if (index === -1) {
			return;
		}

		this.linkedAgencies.splice(index, 1);
	}

	setLocations(locations: Array<Location>) {
		console.log('locations', locations);
		this.locations.set(locations);
	}
}
