import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ServiceProviderService } from '../data-access/service-provider/service-provider.service';
import { AuthService } from '../auth/auth.service';
import { firstValueFrom } from 'rxjs';
import { AgencyAccountService } from '../data-access/agency/agency.account.service';

export const CURRENT_AUTH_USER_SERVICE = new InjectionToken<
	Promise<ServiceProviderService | AgencyAccountService>
>('CURRENT_AUTH_USER_SERVICE');

@Injectable({ providedIn: 'root' })
export class UserServiceFactory {
	constructor(
		private injector: Injector,
		private authService: AuthService
	) {}

	role: 'agent' | 'service-provider' | null = null;

	async createUserService(): Promise<
		ServiceProviderService | AgencyAccountService
	> {
		const isAgent = await firstValueFrom(
			this.authService.checkClaim('agent.agencyId')
		);
		const isServiceProvider = await firstValueFrom(
			this.authService.checkClaim('serviceProvider')
		);

		if (isServiceProvider) {
			this.role = 'service-provider';
			return this.injector.get(ServiceProviderService);
		} else if (isAgent) {
			this.role = 'agent';
			return this.injector.get(AgencyAccountService);
		} else {
			throw new Error('User role not recognized');
		}
	}

	currentlyInjectedService() {
		switch (this.role) {
			case 'agent':
				return this.injector.get(AgencyAccountService);
			case 'service-provider':
				return this.injector.get(ServiceProviderService);
			default:
				throw new Error('User role not recognized');
		}
	}
}

export const currentAuthServiceProvider = {
	provide: CURRENT_AUTH_USER_SERVICE,
	useFactory: (userServiceFactory: UserServiceFactory) =>
		userServiceFactory.createUserService(),
	deps: [UserServiceFactory]
};
