<div class="w-full h-16 bg-stone flex justify-evenly">
	@for (item of itemsToShow(); track $index) {
		<div
			[routerLink]="item.route"
			routerLinkActive="bg-white"
			ariaCurrentWhenActive="page"
			class="flex flex-col text-base justify-center items-center font-medium flex py-4 px-6 text-gray-500 hover:bg-white cursor-pointer"
		>
			<fixify-icon
				[name]="item.icon"
				class="h-6 w-6 text-gray-500 stroke-2"
				[class.text-primary-500]="item.active"
			></fixify-icon>
		</div>
	}
	@if (itemsToHide().length !== 0) {
		<div
			id="dropdownMenuIconButton"
			data-dropdown-toggle="dropdownDots"
			class="flex flex-col text-base justify-center items-center font-medium flex py-4 px-6 text-gray-500 hover:bg-white cursor-pointer"
		>
			<fixify-icon
				name="dots-vertical"
				class="h-6 w-6 text-gray-500 rotate-90 stroke-2"
			></fixify-icon>
			<div
				id="dropdownDots"
				class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
			>
				<ul
					class="py-2 text-sm text-gray-700"
					aria-labelledby="dropdownMenuIconButton"
				>
					@for (hiddenItem of itemsToHide(); track $index) {
						<li>
							<a
								[routerLink]="hiddenItem.route"
								class="block px-4 py-4 hover:bg-gray-100"
								>{{ hiddenItem.name }}</a
							>
						</li>
					}
				</ul>
			</div>
		</div>
	}
</div>

