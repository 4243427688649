/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const versions = ['v1'] as const;
type Version = (typeof versions)[number];

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private apiUrl = environment.apiUrl;

	constructor(private http: HttpClient) {}

	getData(endpoint: string, v: Version = 'v1'): Observable<any> {
		return this.http.get(`${this.apiUrl}/${v}/${endpoint}`);
	}

	postData(
		endpoint: string,
		data: unknown,
		v: Version = 'v1'
	): Observable<any> {
		return this.http.post(`${this.apiUrl}/${v}/${endpoint}`, data);
	}

	putData(
		endpoint: string,
		data: unknown,
		v: Version = 'v1'
	): Observable<any> {
		return this.http.put(`${this.apiUrl}/${v}/${endpoint}`, data);
	}

	deleteData(
		endpoint: string,
		data: unknown | undefined = undefined,
		v: Version = 'v1'
	): Observable<any> {
		return this.http.delete(`${this.apiUrl}/${v}/${endpoint}`, {
			body: data
		});
	}

	buildFilterQueryParams(filters: Record<string, string | number>): string {
		const queryParams = Object.keys(filters)
			.filter(key => filters[key as keyof typeof filters] !== undefined)
			.map(
				key =>
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					`${key}=${encodeURIComponent(filters[key as keyof typeof filters]!)}`
			)
			.join('&');

		return queryParams ? `?${queryParams}` : '';
	}
}
