<div class="relative container mx-auto h-full">
	<div class="block inset-0 2xl:absolute flex flex-col">
		<div class="mb-6">
			<fixify-tabs
				[tabItems]="barFilters"
				(onTabItemChange)="onTabItemChange($event)"
			></fixify-tabs>
		</div>
		<div class="flex justify-between mb-4">
			<div
				[formGroup]="filterForm"
				class="w-full flex justify-between items-center"
			>
				<fixify-text-input
					class="min-w-80"
					[control]="filterForm.get('search')!"
					label=""
					id="search"
					inputType="search"
					placeholder="Search ..."
				></fixify-text-input>
				<!-- <div class="shrink flex gap-2">
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="filter-lines"
								></fixify-icon
							></span>
							Filters
						</div>
					</button>
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="arrows-down"
								></fixify-icon
							></span>
							Sort
						</div>
					</button>
				</div> -->
			</div>
		</div>
		<div class="flex-grow">
			<fixify-table
				[data]="jobs"
				[itemsPerPage]="itemsPerPage"
				[currentPage]="currentPage"
				(pageChange)="onPageChange($event)"
				(rowClicked)="onRowClicked($event)"
			>
				<ng-template
					fixifyTableColumn
					header="JOB ID"
					field="jobNumber"
					let-value
				>
					#{{ value }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="STATUS"
					field="status"
					let-value
				>
					<fixify-status-pill
						class="inline-block"
						[color]="getColor(value)"
					>
						{{ value | titlecase }}
					</fixify-status-pill>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="TO DO"
					field="todo"
					let-value
				>
					{{ value ? value : '--' }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="CREATED ON"
					field="createdOn"
					let-value
				>
					{{ value | date: "dd MMM, yyyy 'at' HH:mm" }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="PROPERTY"
					field="property"
					let-value
					let-row
				>
					<div class="flex items-center">
						<fixify-property-avatar
							[url]="row.imageUrl"
						></fixify-property-avatar>
						<div class="line-clamp-1 ml-4">
							{{ row.address.formatted }}
						</div>
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="SERVICE PROVIDER"
					field="serviceProviders"
					let-value
					let-index="index"
				>
					<div
						(click)="$event.stopPropagation()"
						class="flex items-center gap-1"
					>
						@for (
							serviceProvider of value;
							track serviceProvider.id
						) {
							<fixify-property-avatar
								[attr.data-tooltip-target]="
									'tooltip-' +
									serviceProvider.id +
									'-' +
									index
								"
								[url]="
									serviceProvider.companyDetails.companyLogo
								"
							></fixify-property-avatar>
							<div
								[id]="
									'tooltip-' +
									serviceProvider.id +
									'-' +
									index
								"
								role="tooltip"
								class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
							>
								{{
									serviceProvider.companyDetails
										?.companyName ?? 'Unknown'
								}}
								<div
									class="tooltip-arrow"
									data-popper-arrow
								></div>
							</div>
						} @empty {
							--
						}
					</div>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="ACTIONS"
					field="id"
					let-value
					let-row="row"
				>
					<div (click)="$event.stopPropagation()">
						<div
							[id]="value + '-button'"
							[attr.data-dropdown-toggle]="value"
							data-dropdown-offset-distance="0"
							data-dropdown-offset-skidding="-70"
							class="w-10 h-10 rounded-full hover:border-2 hover:border-gray-200 flex items-center justify-center cursor-pointer"
						>
							<fixify-icon
								class="text-gray-700 w-6 h-6"
								name="dots-vertical"
							></fixify-icon>
						</div>
						<div
							[id]="value"
							[attr.aria-labelledby]="value + '-button'"
							class="z-20 absolute hidden w-44 bg-white rounded divide-y divide-gray-100 shadow left-0 top-0"
						>
							<ul
								class="text-sm text-gray-700 font-normal cursor-pointer"
							></ul>
						</div>
					</div>
				</ng-template>
			</fixify-table>
		</div>
		<div class="mt-8">
			<fixify-pagination
				[currentPage]="currentPage"
				[totalPages]="totalPages"
				[maxItems]="totalCount"
				[pageSize]="itemsPerPage"
				(pageChange)="onPageChange($event)"
			></fixify-pagination>
		</div>
	</div>
</div>

