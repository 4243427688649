import { Component, input } from '@angular/core';
import { ToastService, ToastType } from '../../data-access/toast.service';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';
import { set } from '@angular/fire/database';

@Component({
	selector: 'app-toast',
	standalone: true,
	templateUrl: './toast.component.html',
	styleUrl: './toast.component.css',
	imports: [IconComponent, NgClass]
})
export class ToastComponent {
	uniqueId = input.required<string>();
	message = input<string>('');
	type = input<ToastType>('info');
	isVisible = false;

	constructor(private toastService: ToastService) {}

	ngOnInit() {
		setTimeout(() => {
			this.isVisible = true;
		}, 0);
	}

	getIconName() {
		switch (this.type()) {
			case 'info':
				return 'annotation-info';
			case 'success':
				return 'check';
			case 'error':
				return 'alert-triangle';
			default:
				return 'info';
		}
	}

	onDismiss() {
		this.isVisible = false;
		setTimeout(() => {
			this.toastService.dismiss(this.uniqueId());
		}, 500);
	}
}
