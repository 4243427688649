import { Component } from '@angular/core';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ManagedProperty } from '../../../../../shared/data-access/agency/agency.types';

@Component({
	selector: 'app-edit-favourite-properties-modal',
	standalone: true,
	imports: [ModalCardComponent, IconComponent],
	templateUrl: './edit-favourite-properties-modal.component.html',
	styleUrl: './edit-favourite-properties-modal.component.css'
})
export class EditFavouritePropertiesModalComponent extends ModalComponent<
	Array<ManagedProperty>,
	Array<ManagedProperty>
> {
	selectedProperties: Array<ManagedProperty> = [];
	properties: Array<ManagedProperty> = [];

	constructor() {
		super();
	}

	override onDataSet(): void {
		this.properties = this.data ?? [];
	}

	isSelected(property: ManagedProperty): boolean {
		return this.selectedProperties.some(p => p.id === property.id);
	}

	onSelectProperty(property: ManagedProperty): void {
		if (this.isSelected(property)) {
			this.selectedProperties = this.selectedProperties.filter(
				p => p.id !== property.id
			);
		} else {
			this.selectedProperties.push(property);
		}
	}

	remove() {
		this.close(this.selectedProperties);
	}

	shortenAddress(property: ManagedProperty): string {
		return `${property.address.unitNumber} ${property.address.buildingName}, ${property.address.suburb}`;
	}
}
