<fixify-modal size="md">
	@if (!data?.hideTopBar) {
		<div class="flex justify-between gap-3 mb-5">
			<div class="w-8 h-8 p-1.5 rounded bg-primary-50">
				<fixify-icon
					class="w-5 h-5 text-primary-500 stroke-1"
					name="x-square"
				></fixify-icon>
			</div>
			<fixify-icon
				(click)="close()"
				name="x-close"
				class="text-gray-400 w-8 h-8 cursor-pointer"
			></fixify-icon>
		</div>
	}
	<p class="text-xl font-semibold text-gray-900 mb-0.5">
		{{ data?.title ?? 'Reason for rejection' }}
	</p>
	<p class="text-base font-normal text-gray-700 mb-8">
		{{ data?.subtitle ?? 'Provide a reason for rejection.' }}
	</p>
	<div [formGroup]="reasonFormGroup" class="mb-6">
		<fixify-validation-message
			[class.hidden]="
				reasonFormGroup.controls['reason'].valid ||
				reasonFormGroup.controls['reason'].pristine
			"
			text="Enter a reason to continue"
		>
		</fixify-validation-message>
		<fixify-text-input
			[control]="reasonFormGroup.controls['reason']"
			inputType="textarea"
			placeholder="Enter reason"
			[label]="data?.formTitle ?? ''"
			[showErrorMessage]="false"
		></fixify-text-input>
	</div>
	<div class="flex justify-end gap-2">
		<button class="btn btn-outline border-gray-200" (click)="close()">
			Cancel
		</button>
		<button
			[disabled]="reasonFormGroup.invalid"
			class="btn btn-primary danger"
			(click)="reject()"
		>
			{{ data?.rejectButtonText ?? 'Reject' }}
		</button>
	</div>
</fixify-modal>

