<div class="relative container mx-auto h-full">
	<div class="block inset-0 2xl:absolute flex flex-col">
		<div class="mb-6">
			<fixify-tabs
				[tabItems]="barFilters"
				(onTabItemChange)="onTabItemChange($event)"
			></fixify-tabs>
		</div>
		<div class="flex justify-between mb-4">
			<div
				[formGroup]="filterForm"
				class="w-full flex justify-between items-center"
			>
				<fixify-text-input
					class="min-w-80"
					[control]="filterForm.get('search')!"
					label=""
					id="search"
					inputType="search"
					placeholder="Search ..."
				></fixify-text-input>
				<div class="shrink flex gap-2">
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="filter-lines"
								></fixify-icon
							></span>
							Filters
						</div>
					</button>
					<button
						class="btn btn-outline border-gray-200 small flex items-center"
					>
						<div class="flex items-center gap-2 text-gray-800">
							<span class="w-4 h-4"
								><fixify-icon
									class="text-gray-700 text-center"
									name="arrows-down"
								></fixify-icon
							></span>
							Sort
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="flex-grow">
			<fixify-table
				[data]="jobs"
				[itemsPerPage]="itemsPerPage"
				[currentPage]="currentPage"
				(pageChange)="onPageChange($event)"
				(rowClicked)="onRowClicked($event)"
			>
				<ng-template
					fixifyTableColumn
					header="JOB ID"
					field="job.jobNumber"
					let-value
				>
					#{{ value }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="STATUS"
					field="jobStatus"
					let-value
				>
					<fixify-status-pill
						class="inline-block"
						[color]="getColor(value)"
					>
						{{ value | titlecase }}
					</fixify-status-pill>
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="TO DO"
					field="todo"
					let-value
				>
					{{ value ? value : '--' }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="PROPERTY"
					field="job.address"
					let-value
					let-row
				>
					<!-- <div class="flex items-center">
						<fixify-property-avatar
							[url]="value?.imageUrl"
						></fixify-property-avatar>
						<div class="line-clamp-1 ml-4">
							{{value.city}}, {{value.province}}, {{value.postalCode}}
						</div>
					</div> -->
					{{ value.city }}, {{ value.province }},
					{{ value.postalCode }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="DATE & TIME"
					field=""
					let-row="row"
				>
				{{
					dateAndTime(row) | date: "dd MMM, yyyy 'at' HH:mm"
				}}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="CATEGORY"
					field=""
					let-value
				>
					Plumbing
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="AGENCY"
					field="agencyName"
					let-value
				>
					{{ value }}
				</ng-template>
				<ng-template
					fixifyTableColumn
					header="ACTIONS"
					field="id"
					let-value
					let-row="row"
				>
					<div (click)="$event.stopPropagation()">
						<div
							[id]="value + '-button'"
							[attr.data-dropdown-toggle]="value"
							data-dropdown-offset-distance="0"
							data-dropdown-offset-skidding="-70"
							class="w-10 h-10 rounded-full hover:border-2 hover:border-gray-200 flex items-center justify-center cursor-pointer"
						>
							<fixify-icon
								class="text-gray-700 w-6 h-6"
								name="dots-vertical"
							></fixify-icon>
						</div>
						<div
							[id]="value"
							[attr.aria-labelledby]="value + '-button'"
							class="z-20 absolute hidden w-44 bg-white rounded divide-y divide-gray-100 shadow left-0 top-0"
						>
							<ul
								class="text-sm text-gray-700 font-normal cursor-pointer"
							>
								<li>
									<p
										class="block py-3 px-4 hover:bg-gray-100"
									>
										Edit site visit date
									</p>
								</li>
								<li>
									<p
										class="block py-3 px-4 hover:bg-gray-100"
									>
										Edit job date
									</p>
								</li>
								<li>
									<p
										class="block py-3 px-4 hover:bg-gray-100 text-red-600"
									>
										Cancel job
									</p>
								</li>
							</ul>
						</div>
					</div>
				</ng-template>
			</fixify-table>
		</div>
		<div class="mt-8">
			<fixify-pagination
				[currentPage]="currentPage"
				[totalPages]="totalPages"
				[maxItems]="totalCount"
				[pageSize]="itemsPerPage"
				(pageChange)="onPageChange($event)"
			></fixify-pagination>
		</div>
	</div>
</div>

