import { Component, signal } from '@angular/core';
import {
	ModalComponent,
	ModalService
} from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { DividerComponent } from '../../../../../shared/ui/divider/divider.component';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { FavouritePillComponent } from '../../../../../shared/ui/favourite-pill/favourite-pill.component';
import { IconItemComponent } from '../../../../../shared/ui/icon-item/icon-item.component';
import { initTooltips } from 'flowbite';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { NoLocationsComponent } from '../../../../../service-provider/locations/ui/no-locations/no-locations.component';
import { ServiceProviderLocationsComponent } from '../../../../../shared/ui/service-provider-locations/service-provider-locations.component';
import { Location } from '../../../../../shared/data-access/service-provider/service-provider.types';
import { FavouriteComplexEstatesModalComponent } from '../favourite-complex-estates-modal/favourite-complex-estates-modal.component';
import { FavouritePropertiesModalComponent } from '../favourite-properties-modal/favourite-properties-modal.component';
import { EditFavouritePropertiesModalComponent } from '../edit-favourite-properties-modal/edit-favourite-properties-modal.component';
import { EditFavouriteComplexEstatesModalComponent } from '../edit-favourite-complex-estates-modal/edit-favourite-complex-estates-modal.component';

@Component({
	selector: 'app-view-my-service-provider-modal',
	standalone: true,
	imports: [
		ModalCardComponent,
		IconComponent,
		DividerComponent,
		AvatarComponent,
		FavouritePillComponent,
		IconItemComponent,
		NoLocationsComponent,
		ServiceProviderLocationsComponent
	],
	templateUrl: './view-my-service-provider-modal.component.html',
	styleUrl: './view-my-service-provider-modal.component.css'
})
export class ViewMyServiceProviderModalComponent extends ModalComponent<
	void,
	void
> {
	mockPropertyNames = [
		'2 Fernanale Crescent',
		'3 Fernanale Crescent',
		'4 Fernanale Crescent',
		'5 Fernanale Crescent',
		'6 Fernanale Crescent',
		'7 Fernanale Crescent',
		'8 Fernanale Crescent',
		'9 Fernanale Crescent',
		'10 Fernanale Crescent'
	];

	locations = signal<Array<Location>>([]);

	mockEstateNames = [
		'Fernanale Estate',
		'Example Estate',
		'Yolo Estate',
		'Example Estate 2'
	];

	constructor(private modalService: ModalService) {
		super();
	}

	ngAfterViewInit() {
		initTooltips();
	}

	openComplexEstatesModal() {
		this.modalService
			.showModal(FavouriteComplexEstatesModalComponent)
			.then(newlyFavouritedEstates => {
				if (!newlyFavouritedEstates) {
					return;
				}

				console.log(newlyFavouritedEstates);
				// TODO: BE + Add newly added favourites to the list
			});
	}

	openEditFavouritePropertiesModal() {
		this.modalService
			.showModal(EditFavouritePropertiesModalComponent, []) // This empty array will eventually be the list of favourited properties
			.then(propertiesToRemoveFromFavourites => {
				if (!propertiesToRemoveFromFavourites) {
					return;
				}

				console.log(propertiesToRemoveFromFavourites);
				// TODO: BE + Remove properties from the favourites list
			});
	}

	openEditFavouriteComplexEstatesModal() {
		this.modalService
			.showModal(EditFavouriteComplexEstatesModalComponent, []) // This empty array will eventually be the list of favourited complex estates
			.then(complexEstatesToRemoveFromFavourites => {
				if (!complexEstatesToRemoveFromFavourites) {
					return;
				}

				console.log(complexEstatesToRemoveFromFavourites);
				// TODO: BE + Remove properties from the favourites list
			});
	}

	openPropertiesModal() {
		this.modalService
			.showModal(FavouritePropertiesModalComponent)
			.then(newlyFavouritedProperties => {
				if (!newlyFavouritedProperties) {
					return;
				}

				console.log(newlyFavouritedProperties);
				// TODO: BE + Add newly added favourites to the list
			});
	}

	removeServiceProvider() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Remove service provider?',
				body: 'This service provider will be removed from your list.',
				enableCancel: true,
				deleteButtonText: 'Yes, remove'
			})
			.then(result => {
				if (!result) {
					return;
				}
			});
	}

	viewProperty() {
		//TODO: Open in new tab
	}

	viewComplexEstate() {
		// TODO: Open in new tab
	}
}
