<fixify-modal (onBackdropClick)="close()" size="4xl">
	<div class="flex justify-between gap-3">
		<p class="text-xl font-medium text-gray-900">
			Service Provider details
		</p>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<div class="-mx-8 my-6 w-[calc(100%+4rem)]">
		<fixify-divider></fixify-divider>
	</div>

	<div class="mb-8 bg-gray-50 rounded p-6 w-full flex gap-12">
		<div class="w-48 flex-none flex flex-col gap-4 items-center">
			<div class="max-w-48 w-full aspect-square">
				<fixify-avatar
					placeholder="Collins Plumbing"
					[shortenPlaceholder]="true"
				></fixify-avatar>
			</div>
			<fixify-favourite-pill></fixify-favourite-pill>
			<p class="text-sm font-normal text-gray-500">11 completed jobs</p>
		</div>
		<div class="flex-1">
			<p class="text-2xl font-medium text-gray-900 mb-4">
				Collins Plumbing
			</p>
			<div class="flex flex-col gap-6">
				<fixify-icon-item
					iconName="user-01"
					title="Contact person"
					subtitle="Collin Mgube"
				></fixify-icon-item>
				<fixify-icon-item
					iconName="phone"
					title="Contact number"
					subtitle="011 DEEZ NUTS"
				></fixify-icon-item>
				<fixify-icon-item
					iconName="mail-01"
					title="Email address"
					subtitle="deez.nuts@collins.co.za"
				></fixify-icon-item>
			</div>
		</div>
	</div>
	<div class="flex gap-3 justify-between mb-4">
		<div class="flex gap-2 items-center">
			<p class="text-base font-medium text-gray-900">
				Favourited properties
			</p>
			<div class="rounded-sm aspect-square h-5 w-5 p-0.5 bg-primary-100">
				<fixify-icon
					name="star-01"
					symbol="filled"
					class="text-primary-900 w-full h-full"
				></fixify-icon>
			</div>
		</div>
		<div class="flex gap-2">
			@if (mockPropertyNames.length > 0) {
				<button
					(click)="openEditFavouritePropertiesModal()"
					class="btn btn-secondary small"
				>
					Edit
				</button>
			}

			<button
				(click)="openPropertiesModal()"
				class="btn btn-outline border-gray-200 small"
			>
				Add
			</button>
		</div>
	</div>
	<div class="overflow-x-auto">
		<div class="max-h-36 grid grid-cols-3 gap-1 min-w-fit">
			@for (propertyName of mockPropertyNames; track $index) {
				<div>
					<p
						(click)="viewProperty()"
						class="text-sm inline hover:underline hover:font-medium cursor-pointer"
					>
						{{ propertyName }}
					</p>
				</div>
			} @empty {
				<p class="text-sm text-gray-500">
					No properties favourited for this service provider.
				</p>
			}
		</div>
	</div>
	<fixify-divider class="my-8"></fixify-divider>
	<div class="flex gap-3 justify-between mb-4">
		<div class="flex gap-2 items-center">
			<p class="text-base font-medium text-gray-900">
				Favourited complexes and estates
			</p>
			<div class="rounded-sm aspect-square h-5 w-5 p-0.5 bg-primary-100">
				<fixify-icon
					name="star-01"
					symbol="filled"
					class="text-primary-900 w-full h-full"
				></fixify-icon>
			</div>
		</div>
		<div class="flex gap-2">
			@if (mockEstateNames.length > 0) {
				<button
					(click)="openEditFavouriteComplexEstatesModal()"
					class="btn btn-secondary small"
				>
					Edit
				</button>
			}
			<button
				(click)="openComplexEstatesModal()"
				class="btn btn-outline border-gray-200 small"
			>
				Add
			</button>
		</div>
	</div>
	<div class="overflow-x-auto">
		<div class="max-h-36 grid grid-cols-3 gap-1 min-w-fit">
			@for (complexEstateName of mockEstateNames; track $index) {
				<div>
					<p
						(click)="viewComplexEstate()"
						class="text-sm inline hover:underline hover:font-medium cursor-pointer"
					>
						{{ complexEstateName }}
					</p>
				</div>
			} @empty {
				<p class="text-sm text-gray-500">
					No complexes/estates favourited for this service provider.
				</p>
			}
		</div>
	</div>
	<fixify-divider class="my-8"></fixify-divider>
	<p class="text-base font-medium text-gray-900">Locations</p>
	@if (locations().length === 0) {
		<fixify-no-locations
			[showAddLocation]="false"
			subtext="This service provider does not have any locations added."
		></fixify-no-locations>
	} @else {
		<div class="pb-4"></div>
		<fixify-service-provider-locations
			[locations]="locations()"
			[allowActions]="false"
		></fixify-service-provider-locations>
	}
	<div class="-mx-8 mt-6 w-[calc(100%+4rem)]">
		<fixify-divider></fixify-divider>
	</div>
	<button
		(click)="removeServiceProvider()"
		class="btn btn-outline border-0 mt-6 ml-3 danger"
	>
		Remove service provider
	</button>
</fixify-modal>

