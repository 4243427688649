import { Component, OnInit } from '@angular/core';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import {
	StatusPillComponent,
	StatusPillColor
} from '../../../shared/ui/status-pill/status-pill.component';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { JobPropertyCardComponent } from './ui/job-property-card/job-property-card.component';
import { IconItemComponent } from '../../../shared/ui/icon-item/icon-item.component';
import {
	ManagedJobs,
	ManagedJobsDetails,
	ResponsibleForPaymentType
} from '../../../shared/data-access/agency/agency.types';
import { DatePipe, TitleCasePipe, Location } from '@angular/common';
import { JobDocumentsComponent } from './ui/job-documents/job-documents.component';
import { JobAssignServiceProviderComponent } from './ui/job-assign-service-provider/job-assign-service-provider.component';
import { JobPaymentResponsibilityComponent } from './ui/job-payment-responsibility/job-payment-responsibility.component';
import { JobOverviewComponent } from './ui/job-overview/job-overview.component';
import { JobUserDetailTileComponent } from './ui/job-user-detail-tile/job-user-detail-tile.component';
import { ToDoComponent } from './ui/to-do/to-do.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyJobsService } from '../../../shared/data-access/agency/agency.jobs.service';
import { ToastService } from '../../../shared/data-access/toast.service';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';

export const statusColorMapper: Record<ManagedJobs['status'], StatusPillColor> =
	{
		new: 'green',
		cancelled: 'red',
		completed: 'green',
		ongoing: 'purple',
		rejected: 'red',
		claimed: 'dark-gray'
	};

@Component({
	selector: 'app-track-job',
	standalone: true,
	imports: [
		IconComponent,
		StatusPillComponent,
		IconComponent,
		DividerComponent,
		JobPropertyCardComponent,
		IconItemComponent,
		TitleCasePipe,
		DatePipe,
		JobDocumentsComponent,
		JobAssignServiceProviderComponent,
		JobPaymentResponsibilityComponent,
		JobOverviewComponent,
		JobUserDetailTileComponent,
		ToDoComponent,
		LoaderComponent
	],
	templateUrl: './track-job.component.html',
	styleUrl: './track-job.component.css'
})
export class TrackJobComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private agencyJobsService: AgencyJobsService,
		private toastService: ToastService,
		private location: Location
	) {}

	managedJob: ManagedJobsDetails | null = null;

	ngOnInit() {
		const { jobId } = this.route.snapshot.params;

		if (jobId) {
			this.agencyJobsService.fetchJobDetails(jobId).subscribe({
				next: data => {
					this.managedJob = data.body as ManagedJobsDetails;
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
					this.router.navigate(['/agency/jobs']);
				}
			});
		}
	}

	goBack() {
		this.location.back();
	}

	mapColor() {
		return this.managedJob
			? statusColorMapper[this.managedJob.status]
			: 'red';
	}

	userTypeChanged(userType: ResponsibleForPaymentType | null) {
		if (userType) {
			// TODO: Backend stuff
		}
	}
}
