import { Component, model } from '@angular/core';
import { SpInfoCardComponent } from '../../../../../shared/ui/sp-info-card/sp-info-card.component';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ManagedJobsDetails } from '../../../../../shared/data-access/agency/agency.types';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { EditServiceProviderModalComponent } from '../modals/edit-service-provider-modal/edit-service-provider-modal.component';
import { ToastService } from '../../../../../shared/data-access/toast.service';
import { initTooltips } from 'flowbite';

@Component({
	selector: 'fixify-job-assign-service-provider',
	standalone: true,
	imports: [SpInfoCardComponent, StatusPillComponent, IconComponent],
	templateUrl: './job-assign-service-provider.component.html',
	styleUrl: './job-assign-service-provider.component.css'
})
export class JobAssignServiceProviderComponent {
	managedJob = model.required<ManagedJobsDetails>();

	constructor(
		private modalService: ModalService,
		private toastService: ToastService
	) {}

	ngAfterViewInit() {
		initTooltips();
	}

	assign() {
		this.modalService
			.showModal(EditServiceProviderModalComponent, this.managedJob())
			.then(newAssignments => {
				if (!newAssignments) {
					return;
				}

				this.managedJob.set({
					...this.managedJob(),
					...newAssignments
				});

				this.toastService.add(
					'Assigned Service Providers',
					5000,
					'success'
				);
			});
	}

	serviceProviderAcceptedText() {
		return this.managedJob().acceptedServiceProviders.length > 0
			? `${this.managedJob().acceptedServiceProviders.length} accepted`
			: '0 accepted';
	}
}
