@if (verifiedMobileNumber && verifingMobileNumber) {
	<fixify-verify-mobile-number-modal
		[(isModalOpen)]="verifingMobileNumber"
		(verified)="mobileNumberVerfied($event)"
		[phoneNumber]="verifiedMobileNumber"
	></fixify-verify-mobile-number-modal>
}
<div class="max-w px-12 md:px-22 xl:px-44">
	<app-breadcrumb
		[items]="this.breadcrumbs() | async"
		public
	></app-breadcrumb>

	<div class="flex justify-start items-center">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl font-outfit pr-2 py-2.5">
				Personal info
			</h1>
			@if (!accountCompletion()) {
				<span
					class="inline-block bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded"
					>Incomplete</span
				>
			}
		</div>
		<div>
			<button
				type="submit"
				(click)="formElement.ngSubmit.emit()"
				[disabled]="!formValid || !formTouched || isSubmitting"
				[ngClass]="{
					'opacity-50 cursor-not-allowed':
						!formValid || !formTouched || isSubmitting
				}"
				class="grow-0 btn btn-primary"
			>
				{{ isSubmitting ? 'Saving...' : 'Save' }}
			</button>
		</div>
	</div>

	<div class="h-8"></div>

	<form
		#formElement="ngForm"
		[formGroup]="personalInfoForm"
		(ngSubmit)="onSubmit()"
		class="grid gap-x-6 lg:gap-x-12 gap-y-6 grid-cols-1 md:grid-cols-2 gap-6 pt-6"
	>
		<div class="">
			<fixify-text-input
				[control]="personalInfoForm.get('firstName')!"
				label="First name"
				id="firstName"
				inputType="text"
				placeholder="Enter your first name"
				errorText="Enter your first name"
				[showRequiredStar]="true"
			></fixify-text-input>
		</div>
		<div class="row-start-1 col-start-1 md:col-start-2 row-span-5">
			<div
				class="p-6 bg-white rounded-lg border border-gray-200 grid gap-y-1 cursor-pointer"
			>
				<div class="flex-shrink-0 mb-2">
					<img src="assets/svg/user-edit-active.svg" />
				</div>
				<p class="text-gray-500">
					Your information isn’t shared with anyone.
				</p>
			</div>
		</div>
		<div class="">
			<fixify-text-input
				[control]="personalInfoForm.get('lastName')!"
				label="Last name"
				id="lastName"
				inputType="text"
				placeholder="Enter your last name"
				errorText="Enter your last name"
				[showRequiredStar]="true"
			></fixify-text-input>
		</div>

		<div class="">
			<fixify-text-input
				[control]="personalInfoForm.get('email')!"
				label="Email address"
				id="email"
				inputType="email"
				placeholder="Enter your email address"
				errorText="Enter your email address"
				[showRequiredStar]="true"
			></fixify-text-input>
		</div>
		<div class="">
			<fixify-dropdown
				[disabled]="true"
				class="mt-8"
				[control]="personalInfoForm.get('role')!"
				[currentlySelectedItem]="selectedRole()"
				[items]="roles"
				[nameFieldOfItem]="'name'"
				[defaultSelectable]="false"
				[valueFieldOfItem]="'value'"
				defaultText="-- Select a role --"
				label="Role"
			></fixify-dropdown>
			<p class="leading-tight text-sm font-normal text-gray-500 mt-2">
				The default role is owner when creating an agency.
			</p>
			<p
				class="leading-tight text-sm font-normal text-primary-500 hover:underline cursor-pointer"
			>
				More info
			</p>
		</div>
		<div class="">
			<label for="mobileNumber" class="input-label"
				>Phone number <span class="text-red-500">*</span></label
			>
			<div class="flex">
				<select
					formControlName="country"
					id="country"
					class="form-input max-w-28 rounded-r-none"
				>
					@for (country of countries; track $index) {
						<option
							[selected]="country.name === selectedCountry?.name"
							[value]="country.code"
						>
							{{ country.countryCode }}
						</option>
					}
				</select>
				<div
					class="bg-gray-100 w-[1px] flex justify-center items-center"
				>
					<div class="h-4 w-full bg-gray-300"></div>
				</div>
				<input
					formControlName="mobileNumber"
					type="tel"
					id="mobileNumber"
					class="form-input rounded-l-none"
					placeholder="Enter your number"
				/>
			</div>
			@if (hasError('mobileNumber')) {
				<p class="input-error-text">Invalid phone number</p>
			}
		</div>

		<fixify-upload
			[label]="'Profile image'"
			[url]="this.profileLogoUrl()"
			[(uploading)]="uploadState.uploading"
			[(percentage)]="uploadState.percentage"
			(uploadFile)="uploadMedia($event)"
			(removeFile)="deleteMedia()"
		></fixify-upload>
	</form>
</div>

