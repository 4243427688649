<fixify-modal (onBackdropClick)="close()" size="xl">
	<div class="flex justify-between gap-3 mb-6">
		<div class="w-8 h-8 p-1.5 rounded bg-primary-50">
			<fixify-icon
				class="w-5 h-5 text-primary-500 stroke-1"
				name="users-plus"
			></fixify-icon>
		</div>
		<fixify-icon
			(click)="close()"
			name="x-close"
			class="text-gray-400 w-8 h-8 cursor-pointer"
		></fixify-icon>
	</div>
	<div class="flex flex-col justify-center">
		<p class="text-xl font-semibold text-gray-900 mb-1">
			Invite service providers
		</p>
		<div class="flex gap-2 items-center text-gray-400 mb-6">
			<p class="text-base font-normal text-gray-500">
				Service providers will be invited to join your agency panel.
			</p>
			<fixify-icon
				data-tooltip-target="tooltip-payment"
				class="w-4 h-4 cursor-pointer stroke-2"
				name="info-circle"
			></fixify-icon>
			<div
				id="tooltip-payment"
				role="tooltip"
				class="absolute max-w-[300px] z-10 invisible inline-block px-3 py-2 text-sm font-normal text-white transition-opacity duration-300 bg-gray-800 rounded-sm shadow-sm opacity-0 tooltip"
			>
				You take full responsibility for any verification checks that
				might be bypassed when adding a provider directly to your
				agency.
				<div class="tooltip-arrow" data-popper-arrow></div>
			</div>
		</div>
		<label class="text-sm font-normal text-gray-500"
			>Press the enter key to add a valid address</label
		>
		<div
			[formGroup]="emailForm"
			(click)="giveFocusToEmail()"
			class="border-[1px] border-gray-900 rounded-lg px-4 py-3 w-full bg-gray-100"
		>
			<div
				class="flex flex-wrap gap-1"
				[class.mb-2]="emailItems.length > 0"
			>
				@for (mock of emailItems; track $index) {
					<div
						(click)="$event.stopPropagation()"
						class="flex items-center gap-1 text-base font-normal rounded-full text-gray-900 bg-gray-200 py-1.5 pl-3 pr-2"
					>
						{{ mock }}
						<div class="w-4 h-4">
							<fixify-icon
								(click)="removeItem($index)"
								class="cursor-pointer"
								name="x-circle"
							></fixify-icon>
						</div>
					</div>
				}
			</div>
			<input
				(keypress)="onKeyPress($event)"
				#emailInput
				formControlName="email"
				class="input-bare"
			/>
		</div>

		<div class="flex justify-end gap-2 mt-4">
			<button (click)="close()" class="btn btn-outline border-gray-200">
				Cancel
			</button>
			<button
				(click)="submit()"
				[disabled]="emailItems.length == 0"
				class="btn btn-primary"
			>
				{{
					emailItems.length == 0
						? 'Add emails to send'
						: 'Send ' +
							emailItems.length +
							' invite' +
							(emailItems.length > 1 ? 's' : '')
				}}
			</button>
		</div>
	</div>
</fixify-modal>

