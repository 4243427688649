import { Component } from '@angular/core';
import { ModalComponent } from '../modal.service';
import { ModalCardComponent } from '../../modal-card/modal-card.component';

@Component({
	selector: 'fixify-delete-modal',
	standalone: true,
	imports: [ModalCardComponent],
	templateUrl: './delete.component.html',
	styleUrl: './delete.component.css'
})
export class DeleteModalComponent extends ModalComponent<
	{
		title?: string;
		body?: string;
		deleteButtonText?: string;
		enableCancel?: boolean;
	},
	boolean
> {
	deleteClicked() {
		this.close(true);
	}
}
