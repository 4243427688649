import { Component, signal } from '@angular/core';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { AsyncPipe, Location } from '@angular/common';
import { SearchPlacesComponent } from '../../../shared/ui/search-places/search-places.component';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';
import { UploadComponent } from '../../../shared/ui/upload/upload.component';
import { PhoneInputComponent } from '../../../shared/ui/phone-input/phone-input.component';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { AlertComponent } from '../../../shared/ui/alert/alert.component';
import { GenericSearchInputComponent } from '../../../shared/ui/generic-search-input/generic-search-input.component';
import { SpInfoCardComponent } from '../../../shared/ui/sp-info-card/sp-info-card.component';
import { ValidationMessageComponent } from '../../../creator-portal/ui/validation-message/validation-message.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../shared/ui/modals/modal.service';
import { ToastService } from '../../../shared/data-access/toast.service';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import {
	Provinces,
	provinces,
	staticMapImageUrl
} from '../../../shared/utils/maps';
import {
	Agent,
	ManagedEstate
} from '../../../shared/data-access/agency/agency.types';
import { Suggestion } from '../../../shared/models/maps';
import { ServiceProvider } from '../../../shared/data-access/service-provider/service-provider.types';
import {
	emailValidator,
	latLngCoordinateString
} from '../../../shared/validators';
import { AddFavouriteSpComponent } from '../../properties/ui/modals/add-favourite-sp/add-favourite-sp.component';
import { PropertyImageGuidelineComponent } from '../../properties/ui/modals/property-image-guideline/property-image-guideline.component';
import { SpFavouritingComponent } from '../../properties/ui/modals/sp-favouriting/sp-favouriting.component';
import { DeleteModalComponent } from '../../../shared/ui/modals/delete/delete.component';
import { Address } from '../../../creator-portal/service/creator-portal.service';
import { markAllFieldsAsDirtyAndTouched } from '../../../shared/utils/forms';
import { AuthService } from '../../../shared/auth/auth.service';
import {
	ref,
	uploadBytesResumable,
	Storage,
	getDownloadURL,
	deleteObject
} from '@angular/fire/storage';
import { firestoreAutoId } from '../../../shared/utils/firestore';
import { AgencyAgentsService } from '../../../shared/data-access/agency/agency.agents.service';
import { AgencyEstatesService } from '../../../shared/data-access/agency/agency.estates.service';

interface FormStakeholder {
	name: string;
	email: string;
	phone: string;
	id: string;
}

@Component({
	selector: 'fixify-add-edit-complexes',
	standalone: true,
	imports: [
		IconComponent,
		ReactiveFormsModule,
		DividerComponent,
		TextInputComponent,
		SearchPlacesComponent,
		LoaderComponent,
		UploadComponent,
		PhoneInputComponent,
		DropdownComponent,
		AlertComponent,
		GenericSearchInputComponent,
		SpInfoCardComponent,
		ValidationMessageComponent,
		AsyncPipe
	],
	templateUrl: './add-edit-complexes.component.html',
	styleUrl: './add-edit-complexes.component.css'
})
export class AddEditComplexesComponent {
	complexEstateId = signal<string | null>(null);
	complexEstateForm: FormGroup;
	complexEstate = signal<ManagedEstate | null>(null);
	searchResults = signal<Array<Suggestion>>([]);
	agentSearchResults = signal<Array<Agent>>([]);
	ignoreNextSearchChange = signal(false);
	fetchingEstate = signal(false);
	selectedLocation = signal<google.maps.LatLngLiteral | null>(null);
	selectedAgent = signal<Agent | null>(null);
	searchingForAddresses = signal(false);
	linkedEstate = signal<{ name: string } | null>(null);
	addressSelected = signal(false);
	$destroy = new Subject<void>();
	mapUrl: string | null = null;
	provinces = [...provinces];
	showErrors = signal(false);
	serviceProviders = signal<Array<ServiceProvider>>([]);

	complexEstateImageUrl = signal<string | null>(null);
	uploadState: {
		percentage: number;
		downloadUrl?: string;
		uploading: boolean;
	} = {
		percentage: 0,
		uploading: false
	};

	jobId: string | null = null;
	propertyId: string | null = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private modalService: ModalService,
		private toastService: ToastService,
		private location: Location,
		private agencyEstatesService: AgencyEstatesService,
		private agencyAgentsService: AgencyAgentsService,
		private authService: AuthService,
		private storage: Storage
	) {
		const { complexEstateId } = this.route.snapshot.params;

		const { jobId, propertyId, estateName } =
			this.route.snapshot.queryParams;

		this.jobId = jobId ?? null;
		this.propertyId = propertyId ?? null;

		this.complexEstateId.set(complexEstateId ?? null);

		this.complexEstateForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			address: new FormGroup({
				streetAddress: new FormControl('', [Validators.required]),
				unitNumber: new FormControl(''),
				suburb: new FormControl('', [Validators.required]),
				city: new FormControl('', [Validators.required]),
				province: new FormControl<Provinces | ''>('', [
					Validators.required
				]),
				postalCode: new FormControl('', [Validators.required]),
				buildingName: new FormControl(''),
				coordinates: new FormControl('', [
					Validators.required,
					latLngCoordinateString
				]),
				street: new FormGroup({
					name: new FormControl(''),
					number: new FormControl('')
				})
			}),
			additionalInformation: new FormControl(''),
			managers: new FormArray([
				new FormGroup({
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						emailValidator
					]),
					phone: new FormControl('', [Validators.required]),
					id: new FormControl(firestoreAutoId())
				})
			]),
			managingAgent: new FormControl('', [Validators.required])
		});

		if (complexEstateId) {
			this.agencyEstatesService.fetchEstate(complexEstateId).subscribe({
				next: data => {
					this.ignoreNextSearchChange.set(true);
					const estate = data.body as ManagedEstate;
					this.complexEstateForm.patchValue({
						name: estate.name,
						address: {
							streetAddress: estate.address.formatted,
							unitNumber: estate.address.unitNumber,
							suburb: estate.address.suburb,
							city: estate.address.city,
							province: estate.address.province,
							postalCode: estate.address.postalCode,
							buildingName: estate.address.buildingName,
							coordinates: estate.address.coordinates
								?.map(c => c.toString())
								.join(','),
							street: {
								name: estate.address.street.name,
								number: estate.address.street.number
							}
						},
						additionalInformation: estate.description,
						vacant: estate.vacant,

						managingAgent:
							estate.managingAgent?.firstName +
							' ' +
							estate.managingAgent?.lastName
					});
					if (estate.address.coordinates) {
						this.mapUrl = staticMapImageUrl(
							{
								lat: parseFloat(
									estate.address.coordinates[0].toString()
								),
								lng: parseFloat(
									estate.address.coordinates[1].toString()
								)
							},
							16,
							400,
							300
						);
						this.selectedLocation.set({
							lat: estate.address.coordinates[0],
							lng: estate.address.coordinates[1]
						});
					}
					if (estate.estateManagers.length > 0) {
						this.removeManagerForm(0);
						const stakeholders = estate.estateManagers.map(o => ({
							name: o.fullName ?? '',
							email: o.email ?? '',
							phone: o.mobileNumber ?? '',
							id: o.id ?? ''
						}));
						this.addManagerForm(stakeholders);
					}

					if (estate?.imageUrl)
						this.complexEstateImageUrl.set(estate.imageUrl);

					this.addressSelected.set(true);

					this.serviceProviders.set(
						estate.favouritedServiceProviders
					);
					this.fetchingEstate.set(false);
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				}
			});
		}

		if (estateName) {
			this.complexEstateForm.patchValue({
				name: estateName
			});
		}
	}

	ngOnInit() {
		this.complexEstateForm
			.get('address.coordinates')
			?.statusChanges.pipe(takeUntil(this.$destroy))
			.subscribe(status => {
				if (status === 'VALID') {
					const split = this.complexEstateForm
						.get('address.coordinates')
						?.value.split(',');

					this.mapUrl = staticMapImageUrl(
						{
							lat: parseFloat(split[0]),
							lng: parseFloat(split[1])
						},
						16,
						400,
						300
					);
				}
			});
	}

	addManagerForm(stakeholders?: FormStakeholder[]) {
		if (stakeholders) {
			stakeholders.forEach(stakeholder => {
				(this.complexEstateForm.get('managers') as FormArray).push(
					new FormGroup({
						name: new FormControl(stakeholder.name, [
							Validators.required
						]),
						email: new FormControl(stakeholder.email, [
							Validators.required,
							emailValidator
						]),
						phone: new FormControl(stakeholder.phone, [
							Validators.required
						]),
						id: new FormControl(stakeholder.id, [
							Validators.required
						])
					})
				);
			});
		} else {
			(this.complexEstateForm.get('managers') as FormArray).push(
				new FormGroup({
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						emailValidator
					]),
					phone: new FormControl('', [Validators.required]),
					id: new FormControl(firestoreAutoId())
				})
			);
		}

		this.complexEstateForm.updateValueAndValidity();
	}

	allFormGroups(controlName: string): FormGroup[] {
		return (this.complexEstateForm.controls[controlName] as FormArray)
			.controls as FormGroup[];
	}

	goBack() {
		this.location.back();
	}

	onSelectedAgent(agent: Agent | null) {
		this.selectedAgent.set(agent);
	}

	openFavouriteServiceProvidersModal() {
		this.modalService
			.showModal(AddFavouriteSpComponent, this.serviceProviders())
			.then(result => {
				if (result) {
					this.serviceProviders.set(result);
				}
			});
	}

	openImageGuidelineModal() {
		this.modalService.showModal(PropertyImageGuidelineComponent);
	}

	openSpFavouritingModal() {
		this.modalService.showModal(SpFavouritingComponent);
	}

	removeManagerForm(index: number) {
		(this.complexEstateForm.get('managers') as FormArray).removeAt(index);
		this.complexEstateForm.updateValueAndValidity();
	}

	removeServiceProvider(sp: ServiceProvider) {
		this.serviceProviders.set(
			this.serviceProviders().filter(s => s.id !== sp.id)
		);
	}

	save() {
		if (this.complexEstateForm.invalid) {
			this.showErrors.set(true);
			markAllFieldsAsDirtyAndTouched(this.complexEstateForm);
			this.toastService.add(
				'Please fill in all required fields',
				5000,
				'error'
			);
			return;
		}
		const value = this.complexEstateForm.value;
		const coordinates = value.address.coordinates
			.split(',')
			.map((c: string | number) => +c);
		const managers = value.managers.map((manager: FormStakeholder) => {
			return {
				fullName: manager.name,
				email: manager.email,
				mobileNumber: manager.phone,
				id: manager.id
			};
		});

		if (this.complexEstateId()) {
			//updating estate
			const estate: Partial<ManagedEstate> = {
				name: value.name,
				favouritedServiceProviders: [], //TODO: Add service providers
				estateManagers: managers,
				address: {
					formatted: value.address.streetAddress,
					unitNumber: value.address.unitNumber,
					suburb: value.address.suburb,
					city: value.address.city,
					province: value.address.province,
					postalCode: value.address.postalCode,
					buildingName: value.address.buildingName ?? null,
					coordinates: coordinates,
					street: {
						name: value.address.street.name,
						number: value.address.street.number
					}
				},
				description: value.additionalInformation,
				imageUrl: this.complexEstateImageUrl() ?? null
			};
			if (this.selectedAgent()) {
				estate.managingAgentId = this.selectedAgent()?.id;
			}

			this.agencyEstatesService.updateEstate(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				this.complexEstateId()!,
				estate
			);
		} else {
			//creating estate
			const estate: Partial<ManagedEstate> = {
				name: value.name,
				favouritedServiceProviders: [], //TODO: Add service providers
				estateManagers: managers,
				address: {
					formatted: value.address.streetAddress,
					unitNumber: value.address.unitNumber,
					suburb: value.address.suburb,
					city: value.address.city,
					province: value.address.province,
					postalCode: value.address.postalCode,
					buildingName: value.address.buildingName ?? null,
					coordinates: coordinates,
					street: {
						name: value.address.street.name,
						number: value.address.street.number
					}
				},
				description: value.additionalInformation,
				imageUrl: this.complexEstateImageUrl() ?? null,
				managingAgentId: this.selectedAgent()?.id ?? null
			};
			this.agencyEstatesService.createEstate(estate).subscribe({
				next: data => {
					const { id } = data.body;
					this.agencyEstatesService.fetchEstates();
					this.toastService.add(
						'Estate has been successfully created',
						5000,
						'success'
					);
					if (this.propertyId) {
						this.toastService.add(
							'Redirected back to property',
							5000,
							'info'
						);
						this.router.navigate(
							['agency/properties/edit', this.propertyId],
							{
								queryParams: {
									jobId: this.jobId,
									estateId: id
								}
							}
						);
					}
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				}
			});
		}
	}

	searchAddresses(isSearching: boolean) {
		this.searchingForAddresses.set(isSearching);
	}

	onAddress(suggestion: Address | null) {
		if (!suggestion) {
			this.addressSelected.set(false);
			this.complexEstateForm.get('address')?.patchValue({
				unitNumber: '',
				suburb: '',
				city: '',
				province: '',
				postalCode: '',
				coordinates: '',
				street: {
					name: '',
					number: ''
				}
			});

			this.mapUrl = null;
			return;
		}

		this.ignoreNextSearchChange.set(true);

		this.complexEstateForm.get('address')?.patchValue({
			unitNumber: suggestion.unitNumber,
			suburb: suggestion.suburb,
			city: suggestion.city,
			province: suggestion.province,
			postalCode: suggestion.postalCode,
			coordinates: suggestion.coordinates
				?.map(c => c.toString())
				.join(','),
			street: {
				name: suggestion.street.name,
				number: suggestion.street.number
			}
		});

		if (suggestion.coordinates) {
			this.selectedLocation.set({
				lat: suggestion.coordinates[0],
				lng: suggestion.coordinates[1]
			});
		}

		this.addressSelected.set(true);
	}

	toggleDeleteEstateModal() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Delete Complex/Estate',
				body: 'This action cannot be undone, and all related property information will be permanently removed.',
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.agencyEstatesService.deleteEstate(
						this.complexEstateId() ?? ''
					);
					this.router.navigate(['/agency/complexes-estates']);
				}
			});
	}

	ngOnDestroy() {
		this.$destroy.next();
		this.$destroy.complete();
	}

	searchAgents = (input: string): Observable<Array<Agent>> => {
		const agentSearch = this.agencyAgentsService.searchAgents(input);
		return new Observable<Array<Agent>>(observer => {
			agentSearch.subscribe({
				next: data => {
					observer.next(data.body.result);
					observer.complete();
				},
				error: error => {
					console.error(error);
					observer.error(error);
				}
			});
		});
	};

	genericAgentTransformer(item: Agent) {
		return [item.firstName, item.lastName].join(' ');
	}

	async uploadMedia(file: File): Promise<void> {
		if (!this.complexEstateId()) {
			throw new Error('Estate ID not set');
		}
		const agencyId = await firstValueFrom(
			this.authService.getClaim('agent.agencyId')
		);
		if (!agencyId) {
			this.toastService.add(
				`Couldn't identify agency, please reload.`,
				5000,
				'error'
			);
			return;
		}
		const filePath = `agency/${agencyId}/estates/${this.complexEstateId()}/${file.name}`;
		const storageRef = ref(this.storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		if (this.complexEstateImageUrl()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			await this.deleteMedia().catch(_ => _);
			this.complexEstateImageUrl.set(null);
		}

		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.uploadState = {
					percentage: Math.round(progress),
					uploading: Math.round(progress) < 100
				};
			},
			error => console.error('Upload failed:', error),
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					this.complexEstateImageUrl.set(downloadURL);
					this.toastService.add(
						'Image uploaded successfully, please save',
						5000
					);
				});
			}
		);
	}

	async deleteMedia() {
		if (!this.complexEstateImageUrl()) return;
		const storageRef = ref(
			this.storage,
			this.complexEstateImageUrl() as string
		);
		await deleteObject(storageRef);
		// this.agencyService.updateProfilePicture(null); //TODO: Make sure it deletes the correct image
		this.complexEstateImageUrl.set(null);
	}
}
