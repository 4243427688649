<div class="relative container mx-auto flex flex-col h-full">
	<div class="absolute inset-0 flex flex-col">
		<div class="flex justify-between items-center mb-8">
			<p class="text-2xl text-gray-900 font-medium mb-2 font-outfit">
				Agents
			</p>
			<button (click)="openInviteAgentsModal()" class="btn btn-primary">
				Invite agents
			</button>
		</div>

		<div
			class="flex mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
		>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					Total active agents
				</p>
				<p class="text-3xl text-primary-500">
					@if (metrics === null) {
						<app-loader [loadingState]="true"></app-loader>
					} @else {
						{{ metrics.totalAgents }} agents
					}
				</p>
			</fixify-dashboard-card>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					Average properties per agent
				</p>
				<p class="text-3xl text-primary-500">
					@if (metrics === null) {
						<app-loader [loadingState]="true"></app-loader>
					} @else {
						{{ metrics.averagePropsPerAgent }} properties
					}
				</p>
				<!-- <p class="text-xs font-normal text-gray-500">On average</p> -->
			</fixify-dashboard-card>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					Completed jobs per agent this month
				</p>
				<p class="text-3xl text-primary-500">
					@if (metrics === null) {
						<app-loader [loadingState]="true"></app-loader>
					} @else {
						{{ metrics.completedJobs }} jobs
					}
				</p>
				<!-- <div class="flex items-center">
					<fixify-icon
						class="text-green-500 w-4 h-4"
						name="arrow-up"
					></fixify-icon>
					<p class="text-xs font-normal text-gray-500">
						<span class="text-green-500">10%</span> vs last month
					</p>
				</div> -->
			</fixify-dashboard-card>
		</div>

		<div class="flex-grow">
			<fixify-agents-table></fixify-agents-table>
		</div>
	</div>
</div>

