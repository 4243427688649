<div class="relative container mx-auto flex flex-col h-full">
	<div class="absolute inset-0 flex flex-col">
		<div class="flex justify-between items-center mb-8">
			<p class="text-2xl text-gray-900 font-medium mb-2 font-outfit">
				My Maintenance Jobs
			</p>
			<button
				(click)="createJob()"
				class="btn btn-primary flex justify-center items-center"
			>
				Create job
			</button>
		</div>

		<!-- TODO: Post MVP -->
		<!-- <div
			class="flex mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4"
		>
			<div class="p-6 flex bg-stone gap-3 rounded">
				<fixify-icon
					name="attachment-01"
					class="text-primary-500 w-8 h-8 xl:block hidden"
				></fixify-icon>
				<div class="flex flex-col gap-0.5">
					<p class="text-base font-medium text-gray-900">
						Sample spreadsheet template
					</p>
					<p class="text-sm font-normal text-gray-500">
						Download a sample spreadsheet template to format your
						property data correctly.
					</p>
				</div>
				<div class="flex">
					<button
						class="h-min text-primary-500 btn-outline small border-0 bg-transparent"
					>
						Download
					</button>
					<fixify-icon
						class="w-7 h-7 text-gray-400 cursor-pointer"
						name="x-close"
					></fixify-icon>
				</div>
			</div>
		</div> -->

		<div
			class="flex mb-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4"
		>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">Total jobs</p>
				<p class="text-3xl text-primary-500">
					@if (metrics === null) {
						<app-loader [loadingState]="true"></app-loader>
					} @else {
						{{ metrics.totalJobs }} jobs
					}
				</p>
				<p class="text-xs font-normal text-gray-500">
					Across all statuses
				</p>
			</fixify-dashboard-card>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					New jobs this month
				</p>
				<p class="text-3xl text-primary-500">
					@if (metrics === null) {
						<app-loader [loadingState]="true"></app-loader>
					} @else {
						{{ metrics.totalJobsThisMonth }} jobs
					}
				</p>
				<!-- <div class="flex items-center">
					<fixify-icon
						class="text-red-500 w-4 h-4"
						name="arrow-down"
					></fixify-icon>
					<p class="text-xs font-normal text-gray-500">
						<span class="text-red-500">4%</span> vs last month
					</p>
				</div> -->
			</fixify-dashboard-card>
			<fixify-dashboard-card>
				<p class="text-sm font-normal text-gray-900">
					Completed jobs this month
				</p>
				<p class="text-3xl text-primary-500">
					@if (metrics === null) {
						<app-loader [loadingState]="true"></app-loader>
					} @else {
						{{ metrics.totalCompletedJobsThisMonth }} jobs
					}
				</p>
				<!-- <div class="flex items-center">
					<fixify-icon
						class="text-green-500 w-4 h-4"
						name="arrow-up"
					></fixify-icon>
					<p class="text-xs font-normal text-gray-500">
						<span class="text-green-500">7%</span> vs last month
					</p>
				</div> -->
			</fixify-dashboard-card>
		</div>

		<div class="flex-grow">
			<fixify-agency-jobs-table></fixify-agency-jobs-table>
		</div>
	</div>
</div>

@if (false) {
	<div class="flex flex-col justify-center items-center">
		<div class="mt-8 mt-4">
			<img src="assets/svg/empty-box.svg" />
		</div>
		<p class="text-base font-semibold text-gray-700 mt-4 mb-1">
			You have no jobs
		</p>
		<p class="text-sm font-normal text-gray-500 mb-4">
			Finish setting up your account to start creating jobs.
		</p>
		<button class="btn btn-primary small">Complete account</button>
	</div>
}

