import { Component, OnDestroy, signal } from '@angular/core';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { AsyncPipe, Location, NgClass } from '@angular/common';
import { SelectableGridItemComponent } from '../../../shared/ui/selectable-grid-item/selectable-grid-item.component';
import { SearchPlacesComponent } from '../../../shared/ui/search-places/search-places.component';
import { Suggestion } from '../../../shared/models/maps';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';
import { UploadComponent } from '../../../shared/ui/upload/upload.component';
import { PhoneInputComponent } from '../../../shared/ui/phone-input/phone-input.component';
import { ModalService } from '../../../shared/ui/modals/modal.service';
import { SpFavouritingComponent } from '../ui/modals/sp-favouriting/sp-favouriting.component';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import {
	Provinces,
	provinces,
	staticMapImageUrl
} from '../../../shared/utils/maps';
import {
	emailValidator,
	latLngCoordinateString
} from '../../../shared/validators';
import { Address } from '../../../creator-portal/service/creator-portal.service';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { AlertComponent } from '../../../shared/ui/alert/alert.component';
import { PropertyImageGuidelineComponent } from '../ui/modals/property-image-guideline/property-image-guideline.component';
import { GenericSearchInputComponent } from '../../../shared/ui/generic-search-input/generic-search-input.component';
import {
	Agent,
	ManagedJobs,
	ManagedProperty
} from '../../../shared/data-access/agency/agency.types';
import { DeleteModalComponent } from '../../../shared/ui/modals/delete/delete.component';
import { AddFavouriteSpComponent } from '../ui/modals/add-favourite-sp/add-favourite-sp.component';
import { ServiceProvider } from '../../../shared/data-access/service-provider/service-provider.types';
import { SpInfoCardComponent } from '../../../shared/ui/sp-info-card/sp-info-card.component';
import { LinkToComplexEstateComponent } from '../ui/modals/link-to-complex-estate/link-to-complex-estate.component';
import { ValidationMessageComponent } from '../../../creator-portal/ui/validation-message/validation-message.component';
import { markAllFieldsAsDirtyAndTouched } from '../../../shared/utils/forms';
import { ToastService } from '../../../shared/data-access/toast.service';
import { firestoreAutoId } from '../../../shared/utils/firestore';
import {
	ref,
	uploadBytesResumable,
	Storage,
	getDownloadURL,
	deleteObject
} from '@angular/fire/storage';
import { AuthService } from '../../../shared/auth/auth.service';
import { AgencyAgentsService } from '../../../shared/data-access/agency/agency.agents.service';
import { AgencyPropertiesService } from '../../../shared/data-access/agency/agency.properties.service';
import { AgencyJobsService } from '../../../shared/data-access/agency/agency.jobs.service';

type AddressType = 'House' | 'Apartment' | null;
type LandlordTrustee = 'Landlord' | 'Trustee' | null;

interface FormStakeholder {
	landlordTrustee?: LandlordTrustee;
	name: string;
	email: string;
	phone: string;
	id: string;
}

@Component({
	selector: 'fixify-add-edit-property',
	standalone: true,
	imports: [
		IconComponent,
		ReactiveFormsModule,
		DividerComponent,
		TextInputComponent,
		NgClass,
		SelectableGridItemComponent,
		SearchPlacesComponent,
		LoaderComponent,
		UploadComponent,
		PhoneInputComponent,
		DropdownComponent,
		AlertComponent,
		GenericSearchInputComponent,
		SpInfoCardComponent,
		ValidationMessageComponent,
		AsyncPipe
	],
	templateUrl: './add-edit-property.component.html',
	styleUrl: './add-edit-property.component.css'
})
export class AddEditPropertyComponent implements OnDestroy {
	propertyId = signal<string | null>(null);
	fetchingProperty = signal(false);
	propertyForm: FormGroup;
	searchResults = signal<Array<Suggestion>>([]);
	agentSearchResults = signal<Array<Agent>>([]);
	ignoreNextSearchChange = signal(false);
	selectedLocation = signal<google.maps.LatLngLiteral | null>(null);
	selectedAgent = signal<Agent | null>(null);
	searchingForAddresses = signal(false);
	linkedEstate = signal<{ name: string } | null>(null);
	addressSelected = signal(false);
	$destroy = new Subject<void>();
	mapUrl: string | null = null;
	provinces = [...provinces];
	showErrors = signal(false);
	serviceProviders = signal<Array<ServiceProvider>>([]);
	estateName: string | null = null;

	propertyImageUrl = signal<string | null>(null);
	uploadState: {
		percentage: number;
		downloadUrl?: string;
		uploading: boolean;
	} = {
		percentage: 0,
		uploading: false
	};
	jobId: string | null = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private modalService: ModalService,
		private toastService: ToastService,
		private location: Location,
		private agencyPropertiesService: AgencyPropertiesService,
		private agencyAgentsService: AgencyAgentsService,
		private agencyJobsService: AgencyJobsService,
		private storage: Storage,
		private authService: AuthService
	) {
		const { propertyId } = this.route.snapshot.params;
		const { jobId, estateId } = this.route.snapshot.queryParams;
		this.jobId = jobId ?? null;

		this.propertyId.set(propertyId ?? null);
		if (propertyId) {
			this.fetchingProperty.set(true);
		}

		this.propertyForm = new FormGroup({
			address: new FormGroup({
				streetAddress: new FormControl('', [Validators.required]),
				unitNumber: new FormControl(''),
				suburb: new FormControl('', [Validators.required]),
				city: new FormControl('', [Validators.required]),
				province: new FormControl<Provinces | ''>('', [
					Validators.required
				]),
				postalCode: new FormControl('', [Validators.required]),
				buildingName: new FormControl(''),
				coordinates: new FormControl('', [
					Validators.required,
					latLngCoordinateString
				]),
				street: new FormGroup({
					name: new FormControl('', []),
					number: new FormControl('', [])
				})
			}),
			estateId: new FormControl<string | null>(null),
			additionalInformation: new FormControl(''),
			landlordTrustees: new FormArray([
				new FormGroup({
					landlordTrustee: new FormControl<LandlordTrustee>(null, [
						Validators.required
					]),
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						emailValidator
					]),
					phone: new FormControl('', [Validators.required]),
					id: new FormControl(firestoreAutoId())
				})
			]),
			vacant: new FormControl(false),
			occupants: new FormArray([
				new FormGroup({
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						emailValidator
					]),
					phone: new FormControl('', [Validators.required]),
					id: new FormControl(firestoreAutoId())
				})
			]),
			managingAgent: new FormControl('', [Validators.required])
		});

		if (propertyId) {
			this.agencyPropertiesService.fetchProperty(propertyId).subscribe({
				next: data => {
					this.ignoreNextSearchChange.set(true);
					const property = data.body as ManagedProperty;
					if (property.estateName)
						this.linkedEstate.set({
							name: property.estateName
						});
					this.estateName = property.estateName;
					this.propertyForm.patchValue({
						address: {
							streetAddress: property.address.formatted,
							unitNumber: property.address.unitNumber,
							suburb: property.address.suburb,
							city: property.address.city,
							province: property.address.province,
							postalCode: property.address.postalCode,
							buildingName: property.address.buildingName,
							coordinates: property.address.coordinates
								?.map(c => c.toString())
								.join(','),
							street: {
								name: property.address.street.name,
								number: property.address.street.number
							}
						},
						estateId: property.estateId ?? estateId ?? null,
						additionalInformation: property.description,
						vacant: property.vacant,

						managingAgent: property.managingAgent
							? property.managingAgent.firstName +
								' ' +
								property.managingAgent.lastName
							: ''
					});
					if (property.address.coordinates) {
						this.mapUrl = staticMapImageUrl(
							{
								lat: parseFloat(
									property.address.coordinates[0].toString()
								),
								lng: parseFloat(
									property.address.coordinates[1].toString()
								)
							},
							16,
							400,
							300
						);
						this.selectedLocation.set({
							lat: property.address.coordinates[0],
							lng: property.address.coordinates[1]
						});
					}
					if (property.vacant || property.occupants.length > 0) {
						this.removeAllOccupants();
					}
					if (property.occupants.length > 0) {
						const stakeholders = property.occupants.map(o => ({
							name: o.fullName ?? '',
							email: o.email ?? '',
							phone: o.mobileNumber ?? '',
							id: o.id ?? ''
						}));
						this.addOccupantForm(stakeholders);
					}

					if (
						property.landlords.length > 0 ||
						property.trustees.length > 0
					) {
						this.removeLandlordTrusteeForm(0);
						const stakeholders = property.landlords
							.map(l => ({
								landlordTrustee: 'Landlord' as LandlordTrustee,
								name: l.fullName ?? '',
								email: l.email ?? '',
								phone: l.mobileNumber ?? '',
								id: l.id ?? ''
							}))
							.concat(
								property.trustees.map(t => ({
									landlordTrustee:
										'Trustee' as LandlordTrustee,
									name: t.fullName ?? '',
									email: t.email ?? '',
									phone: t.mobileNumber ?? '',
									id: t.id ?? ''
								}))
							);
						this.addLandlordTrusteeForm(stakeholders);
					}

					if (property?.imageUrl)
						this.propertyImageUrl.set(property.imageUrl);

					this.addressSelected.set(true);

					this.serviceProviders.set(
						property.favouritedServiceProviders
					);
					this.fetchingProperty.set(false);
					if (jobId) {
						this.agencyJobsService.fetchJob(jobId).subscribe({
							next: data => {
								const job = data.body as ManagedJobs;
								if (job.requestorType) {
									switch (job.requestorType) {
										case 'landlord':
											if (
												!this.propertyForm.get(
													'landlordTrustees'
												)?.valid
											) {
												this.removeOccupantForm(0);
											}
											this.addLandlordTrusteeForm([
												{
													landlordTrustee: 'Landlord',
													name:
														job.requestorInfo
															.fullName ?? '',
													email:
														job.requestorInfo
															.email ?? '',
													phone:
														job.requestorInfo
															.mobileNumber ?? '',
													id: firestoreAutoId()
												}
											]);
											break;
										case 'trustee':
											if (
												!this.propertyForm.get(
													'landlordTrustees'
												)?.valid
											) {
												this.removeOccupantForm(0);
											}
											this.addLandlordTrusteeForm([
												{
													landlordTrustee: 'Landlord',
													name:
														job.requestorInfo
															.fullName ?? '',
													email:
														job.requestorInfo
															.email ?? '',
													phone:
														job.requestorInfo
															.mobileNumber ?? '',
													id: firestoreAutoId()
												}
											]);
											break;
									}
									if (
										!this.propertyForm.get('occupants')
											?.valid
									) {
										this.removeAllOccupants();
									}
									this.addOccupantForm([
										{
											name:
												job.occupantInfo.fullName ?? '',
											email: job.occupantInfo.email ?? '',
											phone:
												job.occupantInfo.mobileNumber ??
												'',
											id: firestoreAutoId()
										}
									]);
								}
								if (
									this.estateName &&
									!(property.estateId || estateId)
								) {
									this.toastService.add(
										`Estate needs to be created for '${this.estateName}'`,
										10000,
										'info'
									);
								}
							},
							error: error => {
								console.error(error);
								this.toastService.add(
									error.error.body.errorMessage,
									5000,
									'error'
								);
							}
						});
					}
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				}
			});
		}
	}

	ngOnInit() {
		this.propertyForm
			.get('vacant')
			?.valueChanges.pipe(takeUntil(this.$destroy))
			.subscribe(vacant => {
				if (vacant) {
					this.removeAllOccupants();
				} else {
					this.addOccupantForm();
				}
			});

		this.propertyForm
			.get('address.coordinates')
			?.statusChanges.pipe(takeUntil(this.$destroy))
			.subscribe(status => {
				if (status === 'VALID') {
					const split = this.propertyForm
						.get('address.coordinates')
						?.value.split(',');

					this.mapUrl = staticMapImageUrl(
						{
							lat: parseFloat(split[0]),
							lng: parseFloat(split[1])
						},
						16,
						400,
						300
					);
				}
			});
	}

	addLandlordTrusteeForm(stakeholders?: FormStakeholder[]) {
		if (stakeholders) {
			stakeholders.forEach(stakeholder => {
				(this.propertyForm.get('landlordTrustees') as FormArray).push(
					new FormGroup({
						landlordTrustee: new FormControl<LandlordTrustee>(
							stakeholder.landlordTrustee ?? null,
							[Validators.required]
						),
						name: new FormControl(stakeholder.name, [
							Validators.required
						]),
						email: new FormControl(stakeholder.email, [
							Validators.required,
							emailValidator
						]),
						phone: new FormControl(stakeholder.phone, [
							Validators.required
						]),
						id: new FormControl(stakeholder.id)
					})
				);
			});
		} else {
			(this.propertyForm.get('landlordTrustees') as FormArray).push(
				new FormGroup({
					landlordTrustee: new FormControl<LandlordTrustee>(null, [
						Validators.required
					]),
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						emailValidator
					]),
					phone: new FormControl('', [Validators.required]),
					id: new FormControl(firestoreAutoId())
				})
			);
		}

		this.propertyForm.updateValueAndValidity();
	}

	addOccupantForm(stakeholders?: FormStakeholder[]) {
		if (stakeholders) {
			stakeholders.forEach(stakeholder => {
				(this.propertyForm.get('occupants') as FormArray).push(
					new FormGroup({
						name: new FormControl(stakeholder.name, [
							Validators.required
						]),
						email: new FormControl(stakeholder.email, [
							Validators.required,
							emailValidator
						]),
						phone: new FormControl(stakeholder.phone, [
							Validators.required
						]),
						id: new FormControl(stakeholder.id)
					})
				);
			});
		} else {
			(this.propertyForm.get('occupants') as FormArray).push(
				new FormGroup({
					name: new FormControl('', [Validators.required]),
					email: new FormControl('', [
						Validators.required,
						emailValidator
					]),
					phone: new FormControl('', [Validators.required]),
					id: new FormControl(firestoreAutoId())
				})
			);
		}

		this.propertyForm.updateValueAndValidity();
	}

	allFormGroups(controlName: string): FormGroup[] {
		return (this.propertyForm.controls[controlName] as FormArray)
			.controls as FormGroup[];
	}

	goBack() {
		this.location.back();
	}

	linkOrUnlinkEstate() {
		if (this.linkedEstate()) {
			this.linkedEstate.set(null);
		} else {
			this.modalService
				.showModal(LinkToComplexEstateComponent, { canAddEstate: true })
				.then(result => {
					if (result) {
						this.propertyForm.patchValue({
							estateId: result[0]?.id ?? null
						});
						this.linkedEstate.set({
							name: result[0]?.estateName ?? ''
						});
					}
					if (result?.length == 0)
						this.router.navigate(['agency/complexes-estates/add'], {
							queryParams: {
								estateName: this.estateName,
								propertyId: this.propertyId(),
								jobId: this.jobId
							}
						});
				});
		}
	}

	onSelectedAgent(agent: Agent | null) {
		this.selectedAgent.set(agent);
	}

	openFavouriteServiceProvidersModal() {
		this.modalService
			.showModal(AddFavouriteSpComponent, this.serviceProviders())
			.then(result => {
				if (result) {
					this.serviceProviders.set(result);
				}
			});
	}

	openImageGuidelineModal() {
		this.modalService.showModal(PropertyImageGuidelineComponent);
	}

	openSpFavouritingModal() {
		this.modalService.showModal(SpFavouritingComponent);
	}

	removeAllOccupants() {
		(this.propertyForm.get('occupants') as FormArray).clear();
		this.propertyForm.updateValueAndValidity();
	}

	removeLandlordTrusteeForm(index: number) {
		(this.propertyForm.get('landlordTrustees') as FormArray).removeAt(
			index
		);
		this.propertyForm.updateValueAndValidity();
	}

	removeOccupantForm(index: number) {
		(this.propertyForm.get('occupants') as FormArray).removeAt(index);
		this.propertyForm.updateValueAndValidity();
	}

	removeServiceProvider(sp: ServiceProvider) {
		this.serviceProviders.set(
			this.serviceProviders().filter(s => s.id !== sp.id)
		);
	}

	save() {
		if (this.propertyForm.invalid) {
			this.showErrors.set(true);
			markAllFieldsAsDirtyAndTouched(this.propertyForm);
			this.toastService.add(
				'Please fill in all required fields',
				5000,
				'error'
			);
			return;
		}
		const value = this.propertyForm.value;
		const coordinates = value.address.coordinates
			.split(',')
			.map((c: string | number) => +c);
		const landlords = value.landlordTrustees
			.filter((landlord: FormStakeholder) => {
				return landlord.landlordTrustee === 'Landlord';
			})
			.map((landlord: FormStakeholder) => {
				return {
					fullName: landlord.name,
					email: landlord.email,
					mobileNumber: landlord.phone,
					id: landlord.id
				};
			});
		const trustees = value.landlordTrustees
			.filter((trustee: FormStakeholder) => {
				return trustee.landlordTrustee === 'Trustee';
			})
			.map((trustee: FormStakeholder) => {
				return {
					fullName: trustee.name,
					email: trustee.email,
					mobileNumber: trustee.phone,
					id: trustee.id
				};
			});

		const occupants = value.occupants.map((occupant: FormStakeholder) => {
			return {
				fullName: occupant.name,
				email: occupant.email,
				mobileNumber: occupant.phone,
				id: occupant.id
			};
		});

		if (this.propertyId()) {
			//updating property
			const property: Partial<ManagedProperty> = {
				estateId: value.estateId,
				vacant: value.vacant,
				favouritedServiceProviders: [], //TODO: Add service providers
				landlords: landlords,
				trustees: trustees,
				occupants: occupants,
				address: {
					formatted: value.address.streetAddress,
					unitNumber: value.address.unitNumber,
					suburb: value.address.suburb,
					city: value.address.city,
					province: value.address.province,
					postalCode: value.address.postalCode,
					buildingName: value.address.buildingName,
					coordinates: coordinates,
					street: {
						name: value.address.street.name,
						number: value.address.street.number
					}
				},
				description: value.additionalInformation,
				imageUrl: this.propertyImageUrl() ?? null
			};
			if (this.selectedAgent()) {
				property.managingAgentId = this.selectedAgent()?.id;
			}

			this.agencyPropertiesService
				.updateProperty(
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					this.propertyId()!,
					property
				)
				.subscribe({
					next: () => {
						this.agencyPropertiesService.fetchProperties();
						this.agencyJobsService.fetchJobs();
						this.toastService.add(
							'Property has been successfully planted',
							5000,
							'success'
						);
						if (this.jobId) {
							this.toastService.add(
								'Viewing created job',
								5000,
								'info'
							);
							this.router.navigate([
								'/agency/jobs/create',
								this.jobId
							]);
						}
					},
					error: error => {
						console.error(error);
						this.toastService.add(
							error.error.body.errorMessage,
							5000,
							'error'
						);
					}
				});
		} else {
			//creating property
			const property: Partial<ManagedProperty> = {
				estateId: value.estateId,
				vacant: value.vacant,
				favouritedServiceProviders: [], //TODO: Add service providers
				landlords: landlords,
				trustees: trustees,
				occupants: occupants,
				address: {
					formatted: value.address.streetAddress,
					unitNumber: value.address.unitNumber,
					suburb: value.address.suburb,
					city: value.address.city,
					province: value.address.province,
					postalCode: value.address.postalCode,
					buildingName: value.address.buildingName,
					coordinates: coordinates,
					street: {
						name: value.address.street.name,
						number: value.address.street.number
					}
				},
				description: value.additionalInformation,
				imageUrl: this.propertyImageUrl() ?? null,
				managingAgentId: this.selectedAgent()?.id ?? null
			};
			this.agencyPropertiesService.createProperty(property);
		}
	}

	searchAddresses(isSearching: boolean) {
		this.searchingForAddresses.set(isSearching);
	}

	onAddress(suggestion: Address | null) {
		if (!suggestion) {
			this.addressSelected.set(false);
			this.propertyForm.get('address')?.patchValue({
				unitNumber: '',
				suburb: '',
				city: '',
				province: '',
				postalCode: '',
				coordinates: ''
			});

			this.mapUrl = null;
			return;
		}

		this.ignoreNextSearchChange.set(true);

		this.propertyForm.get('address')?.patchValue({
			unitNumber: suggestion.unitNumber,
			suburb: suggestion.suburb,
			city: suggestion.city,
			province: suggestion.province,
			postalCode: suggestion.postalCode,
			coordinates: suggestion.coordinates
				?.map(c => c.toString())
				.join(','),
			street: {
				name: suggestion.street.name,
				number: suggestion.street.number
			}
		});

		if (suggestion.coordinates) {
			this.selectedLocation.set({
				lat: suggestion.coordinates[0],
				lng: suggestion.coordinates[1]
			});
		}

		this.addressSelected.set(true);
	}

	setAddressType(type: AddressType) {
		this.propertyForm.get('addressType')?.setValue(type);
	}

	setLandlordTrustee(formGroup: FormGroup, option: LandlordTrustee) {
		formGroup.get('landlordTrustee')?.setValue(option);
	}

	toggleDeletePropertyModal() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Delete Property',
				body: 'This action cannot be undone, and all related property information will be permanently removed.',
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.agencyPropertiesService.deleteProperty(
						this.propertyId() ?? ''
					);
					this.router.navigate(['/agency/properties']);
				}
			});
	}

	vacant() {
		return this.propertyForm.get('vacant')?.value;
	}

	ngOnDestroy() {
		this.$destroy.next();
		this.$destroy.complete();
	}

	searchAgents = (input: string): Observable<Array<Agent>> => {
		const agentSearch = this.agencyAgentsService.searchAgents(input);
		return new Observable<Array<Agent>>(observer => {
			agentSearch.subscribe({
				next: data => {
					observer.next(data.body.result);
					observer.complete();
				},
				error: error => {
					console.error(error);
					observer.error(error);
				}
			});
		});
	};

	genericAgentTransformer(item: Agent) {
		return [item.firstName, item.lastName].join(' ');
	}

	noLandlordTrusteeSelected(form: FormGroup) {
		return form.get('landlordTrustee')?.value === null && this.showErrors();
	}

	async uploadMedia(file: File): Promise<void> {
		if (!this.propertyId()) {
			throw new Error('Property ID not set');
		}
		const agencyId = await firstValueFrom(
			this.authService.getClaim('agent.agencyId')
		);
		if (!agencyId) {
			this.toastService.add(
				`Couldn't identify agency, please reload.`,
				5000,
				'error'
			);
			return;
		}
		const filePath = `agency/${agencyId}/properties/${this.propertyId()}/${file.name}`;
		const storageRef = ref(this.storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, file);

		if (this.propertyImageUrl()) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			await this.deleteMedia().catch(_ => _);
			this.propertyImageUrl.set(null);
		}

		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.uploadState = {
					percentage: Math.round(progress),
					uploading: Math.round(progress) < 100
				};
			},
			error => console.error('Upload failed:', error),
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					this.propertyImageUrl.set(downloadURL);
					this.toastService.add(
						'Image uploaded successfully, please save',
						5000
					);
				});
			}
		);
	}

	async deleteMedia() {
		if (!this.propertyImageUrl()) return;
		const storageRef = ref(this.storage, this.propertyImageUrl() as string);
		await deleteObject(storageRef);
		// this.agencyService.updateProfilePicture(null); //TODO: make sure theis is removed on the property profile
		this.propertyImageUrl.set(null);
	}
}
