import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { ManagedServiceProvider } from '../../../../../shared/data-access/agency/agency.types';
import { Subject } from 'rxjs';
import {
	ITabItem,
	TabsComponent
} from '../../../../../shared/ui/tabs/tabs.component';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { AvatarComponent } from '../../../../../shared/ui/avatar/avatar.component';
import { nameToInitials } from '../../../../../shared/utils/string-manipulation';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { ViewMyServiceProviderModalComponent } from '../../modals/view-my-service-provider-modal/view-my-service-provider-modal.component';

interface ITestServiceProvider extends ManagedServiceProvider {
	joinedOn: Date | null;
}

@Component({
	selector: 'fixify-agency-service-providers-table',
	standalone: true,
	imports: [
		PaginationComponent,
		ReactiveFormsModule,
		TextInputComponent,
		IconComponent,
		TableComponent,
		TableColumnDirective,
		AvatarComponent,
		TabsComponent,
		StatusPillComponent
	],
	templateUrl: './agency-service-providers-table.component.html',
	styleUrl: './agency-service-providers-table.component.css'
})
export class AgencyServiceProvidersTableComponent {
	itemsPerPage = 10;
	currentPage = 1;
	totalCount = 0;
	totalPages = 0;
	filterForm: FormGroup;

	serviceProviders: Array<ITestServiceProvider> | null = [
		{
			joinedOn: null,
			id: 'sp2',
			firstName: 'Sarah',
			lastName: 'Johnson',
			companyDetails: {
				companyLogo: 'https://example.com/logo2.jpg',
				companyName: 'Johnson Electric',
				country: 'South Africa',
				entityType: 'Pty Ltd',
				province: 'Gauteng',
				registeredName: 'Johnson Electrical Services',
				registrationNumber: 'REG789012',
				tradingName: 'Johnson Electric',
				vatNumber: 'VAT987654321'
			},
			categories: {
				cat1: {
					name: 'Plumbing',
					tasks: ['Leak Repairs', 'Installation'],
					supportingDocuments: ['https://example.com/doc1.pdf'],
					status: 'active'
				},
				cat2: {
					name: 'Electrical',
					tasks: ['Wiring', 'Maintenance'],
					supportingDocuments: ['https://example.com/doc2.pdf'],
					status: 'active'
				}
			},
			totalCompletedJobs: 89,
			verified: true,
			favourited: false,
			withinRange: true
		},
		{
			joinedOn: new Date(),
			id: 'sp1',
			firstName: 'John',
			lastName: 'Smith',
			companyDetails: {
				companyLogo: 'https://example.com/logo1.jpg',
				companyName: 'Smith Plumbing',
				country: 'South Africa',
				entityType: 'Pty Ltd',
				province: 'Western Cape',
				registeredName: 'Smith Plumbing Solutions',
				registrationNumber: 'REG123456',
				tradingName: 'Smith Plumbing',
				vatNumber: 'VAT123456789'
			},
			categories: {
				cat1: {
					name: 'Plumbing',
					tasks: ['Leak Repairs', 'Installation'],
					supportingDocuments: ['https://example.com/doc1.pdf'],
					status: 'active'
				}
			},
			totalCompletedJobs: 150,
			verified: true,
			favourited: true,
			withinRange: true
		},
		{
			id: 'sp3',
			joinedOn: new Date(),
			firstName: 'Mike',
			lastName: 'Wilson',
			companyDetails: {
				companyLogo: 'https://example.com/logo3.jpg',
				companyName: 'Wilson Construction',
				country: 'South Africa',
				entityType: 'Sole Proprietor',
				province: 'KwaZulu-Natal',
				registeredName: 'Wilson Building Services',
				registrationNumber: 'REG345678',
				tradingName: 'Wilson Construction',
				vatNumber: 'VAT345678912'
			},
			categories: {
				cat1: {
					name: 'Plumbing',
					tasks: ['Leak Repairs', 'Installation'],
					supportingDocuments: ['https://example.com/doc1.pdf'],
					status: 'active'
				},
				cat2: {
					name: 'Electrical',
					tasks: ['Wiring', 'Maintenance'],
					supportingDocuments: ['https://example.com/doc2.pdf'],
					status: 'active'
				},
				cat3: {
					name: 'Building',
					tasks: ['Renovations', 'Repairs'],
					supportingDocuments: ['https://example.com/doc3.pdf'],
					status: 'active'
				}
			},
			totalCompletedJobs: 45,
			verified: false,
			favourited: false,
			withinRange: false
		}
	];

	barFilters: Array<ITabItem> = [
		{
			label: 'All',
			value: '432',
			active: true
		},
		{
			label: 'Active',
			value: '255',
			active: false
		},
		{
			label: 'Favourited',
			value: '157',
			active: false
		},
		{
			label: 'Pending',
			value: '2',
			active: false
		}
	];

	categoryExceedingLimit = 2;
	private destroy$ = new Subject<void>();

	constructor(
		private router: Router,
		private modalService: ModalService
	) {
		this.filterForm = new FormGroup({
			search: new FormControl('')
		});
	}

	favouriteServiceProvider(sp: ITestServiceProvider) {
		sp.favourited = !sp.favourited;
		// TODO: Implement favourite service provider
	}

	getListOfCategoryItems(sp: ITestServiceProvider): string {
		if (!sp.categories) {
			return '--';
		}

		const categories = Object.values(sp.categories).slice(
			0,
			this.categoryExceedingLimit
		);
		return categories.map(cat => cat.name).join(', ');
	}

	categoriesExceeding(sp: ITestServiceProvider): number {
		if (!sp.categories) {
			return 0;
		}

		return Object.keys(sp.categories).length - this.categoryExceedingLimit;
	}

	newJoiner(date: Date | null) {
		if (!date) {
			return false;
		}

		const today = new Date();
		const diff = Math.abs(today.getTime() - date.getTime());
		const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

		return diffDays <= 7;
	}

	onPageChange(page: number) {
		this.currentPage = page;
		this.serviceProviders = null;
		//TODO: i,plment serach when backend is setup for it
		// const search = this.filterForm.get('search')?.value;
		// this.agencyEstatesService.fetchEstates({ page });
		// Trigger a BE call to fetch the data

		// BE to handle the pending ones, as they should be returned first and apply with pagination offset too

		// Always make a new array instance of the data. This is a workaround to trigger change detection for flowbite inits for
		// interactive UI elements like dropdowns, modals, etc.
		// this.estates = [...this.estates];
	}

	onRowClicked(sp: ITestServiceProvider) {
		// Do not allow complex flows when viewing this table from a select modal
	}

	onTabItemChange(item: ITabItem) {
		console.log(item);
		// Apply BE filter
	}

	random() {
		return Math.random() > 0.5;
	}

	removeServiceProvider(sp: ITestServiceProvider) {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Remove this service provider?',
				body: 'This service provider will be removed from your list of managed service providers.',
				deleteButtonText: 'Yes, remove',
				enableCancel: true
			})
			.then(result => {
				if (!result) {
					return;
				}

				// TODO: Remove service provider from agency
			});
	}

	shortenedCompanyName(name: string): string {
		return nameToInitials(name);
	}

	viewServiceProvider(sp: ITestServiceProvider) {
		this.modalService.showModal(ViewMyServiceProviderModalComponent);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
