import { Component } from '@angular/core';
import { ManagedEstate } from '../../../../../shared/data-access/agency/agency.types';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';

@Component({
	selector: 'app-edit-favourite-complex-estates-modal',
	standalone: true,
	imports: [IconComponent, ModalCardComponent],
	templateUrl: './edit-favourite-complex-estates-modal.component.html',
	styleUrl: './edit-favourite-complex-estates-modal.component.css'
})
export class EditFavouriteComplexEstatesModalComponent extends ModalComponent<
	Array<ManagedEstate>,
	Array<ManagedEstate>
> {
	selectedEstates: Array<ManagedEstate> = [];
	estates: Array<ManagedEstate> = [];

	constructor() {
		super();
	}

	override onDataSet(): void {
		this.estates = this.data ?? [];
	}

	isSelected(estate: ManagedEstate): boolean {
		return this.selectedEstates.some(p => p.id === estate.id);
	}

	onSelectEstate(estate: ManagedEstate): void {
		if (this.isSelected(estate)) {
			this.selectedEstates = this.selectedEstates.filter(
				p => p.id !== estate.id
			);
		} else {
			this.selectedEstates.push(estate);
		}
	}

	remove() {
		this.close(this.selectedEstates);
	}

	shortenAddress(estate: ManagedEstate): string {
		return `${estate.name}, ${estate.address.suburb}`;
	}
}
