import { Component } from '@angular/core';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { AgencyJobsTableComponent } from './ui/tables/agency-jobs-table/agency-jobs-table.component';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AgencyJobsService } from '../../shared/data-access/agency/agency.jobs.service';
import { JobMetrics } from '../../shared/data-access/agency/agency.types';
import { LoaderComponent } from '../../shared/ui/loader/loader.component';

@Component({
	selector: 'app-agency-jobs',
	standalone: true,
	imports: [
		IconComponent,
		DashboardCardComponent,
		AgencyJobsTableComponent,
		LoaderComponent
	],
	templateUrl: './agency-jobs.component.html',
	styleUrl: './agency-jobs.component.css'
})
export class AgencyJobsComponent {
	destroy$ = new Subject<void>();
	metrics: JobMetrics | null = null;

	constructor(
		private router: Router,
		private agencyJobsService: AgencyJobsService
	) {
		this.agencyJobsService
			.jobMetrics()
			.pipe(takeUntil(this.destroy$))
			.subscribe(jobMetrics => {
				this.metrics = jobMetrics;
			});
	}

	createJob() {
		this.router.navigate(['/agency/jobs/create']);
	}
}
