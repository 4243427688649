import { Component, input, model } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';
import { PaymentResponsibilityModalComponent } from '../modals/payment-responsibility-modal/payment-responsibility-modal.component';
import { ToastService } from '../../../../../shared/data-access/toast.service';
import {
	ManagedJobsDetails,
	ResponsibleForPaymentType
} from '../../../../../shared/data-access/agency/agency.types';
import { NgClass, TitleCasePipe } from '@angular/common';
import { initTooltips } from 'flowbite';

@Component({
	selector: 'fixify-job-payment-responsibility',
	standalone: true,
	imports: [IconComponent, TitleCasePipe, NgClass],
	templateUrl: './job-payment-responsibility.component.html',
	styleUrl: './job-payment-responsibility.component.css'
})
export class JobPaymentResponsibilityComponent {
	userType = model<ResponsibleForPaymentType | null>(null);
	managedJob = input.required<ManagedJobsDetails>();

	constructor(
		private modalService: ModalService,
		private toastService: ToastService
	) {}

	ngAfterViewInit() {
		initTooltips();
	}

	assign() {
		this.modalService
			.showModal(PaymentResponsibilityModalComponent, {
				selected: this.userType(),
				common: this.managedJob().common,
				jobId: this.managedJob().id
			})
			.then(result => {
				if (!result) {
					return;
				}

				if (result) {
					this.toastService.add(
						'Payment responsibility assigned',
						3000,
						'success'
					);

					this.userType.set(result);
				}
			});
	}
}
