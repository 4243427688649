import { Component } from '@angular/core';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { PropertyTableComponent } from './ui/tables/property-table/property-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyPropertiesService } from '../../shared/data-access/agency/agency.properties.service';
import { Subject, takeUntil } from 'rxjs';
import { PropertyMetrics } from '../../shared/data-access/agency/agency.types';
import { LoaderComponent } from '../../shared/ui/loader/loader.component';

@Component({
	selector: 'app-properties',
	standalone: true,
	imports: [
		IconComponent,
		DashboardCardComponent,
		PropertyTableComponent,
		LoaderComponent
	],
	templateUrl: './properties.component.html',
	styleUrl: './properties.component.css'
})
export class PropertiesComponent {
	destroy$ = new Subject<void>();
	metrics: PropertyMetrics | null = null;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private agencyPropertiesService: AgencyPropertiesService
	) {
		this.agencyPropertiesService
			.propertyMetrics()
			.pipe(takeUntil(this.destroy$))
			.subscribe(propertyMetrics => {
				this.metrics = propertyMetrics;
			});
	}

	addProperty() {
		this.router.navigate(['add'], { relativeTo: this.route });
	}
}
