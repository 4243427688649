import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { BreadcrumbComponent } from '../../../shared/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from '../../../shared/data-access/breadcrumb.service';
import { AsyncPipe, NgClass } from '@angular/common';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Subject, takeUntil } from 'rxjs';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { ServiceProviderService } from '../../../shared/data-access/service-provider/service-provider.service';
import { PersonalInfo } from '../../../shared/data-access/service-provider/service-provider.types';
import { VerifyMobileNumberComponent } from '../../../shared/ui/modals/verify-mobile-number/verify-mobile-number.component';
import { emailValidator } from '../../../shared/validators';
const PHONE_LIB = PhoneNumberUtil.getInstance();
const DEFAULT_COUNTRY = 'ZA';

@Component({
	selector: 'app-personal-info',
	standalone: true,
	imports: [
		BreadcrumbComponent,
		AsyncPipe,
		ReactiveFormsModule,
		NgClass,
		TextInputComponent,
		VerifyMobileNumberComponent
	],
	templateUrl: './personal-info.component.html',
	styleUrl: './personal-info.component.css'
})
export class PersonalInfoComponent implements OnInit, OnDestroy {
	personalInfoForm: FormGroup;
	countries: Array<Country> = [];
	selectedCountry = CountryList.findOneByCountryCode(DEFAULT_COUNTRY);
	private destroy$ = new Subject<void>();
	isSubmitting = false;
	isMobileNumberVerified = false;
	verifingMobileNumber = false;
	verifiedMobileNumber: string | null = null;
	accountCompletion = signal<boolean>(false);

	serviceProviderService = inject(ServiceProviderService);

	constructor(private breadcrumbService: BreadcrumbService) {
		this.personalInfoForm = new FormGroup({
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, emailValidator]),
			country: new FormControl(this.selectedCountry?.code ?? 'ZA', [
				Validators.required
			]),
			mobileNumber: new FormControl('', [Validators.required])
		});
	}
	ngOnDestroy(): void {
		this.destroy$.next();
	}

	breadcrumbs() {
		return this.breadcrumbService.breadcrumbs$;
	}

	hasError(control: string) {
		return (
			this.personalInfoForm.get(control)?.errors &&
			this.personalInfoForm.get(control)?.dirty
		);
	}

	get formTouched(): boolean {
		return this.personalInfoForm.dirty;
	}

	get formValid(): boolean {
		return this.personalInfoForm.valid;
	}

	ngOnInit() {
		this.breadcrumbService.setBreadcrumbs([
			{ label: 'Account', url: '/service-provider/account' },
			{ label: 'Personal Info' }
		]);

		this.countries = CountryList.getAll();

		this.personalInfoForm.controls['country'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((countryCode: string) => {
				this.selectedCountry =
					CountryList.findOneByCountryCode(countryCode);

				const mobileNumber =
					this.personalInfoForm.controls['mobileNumber'].value ?? '';
				this.validateMobileNumber(mobileNumber);
			});

		this.personalInfoForm.controls['mobileNumber'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((mobileNumber: string) => {
				this.validateMobileNumber(mobileNumber);
			});

		this.serviceProviderService
			.isSubmitting()
			.pipe(takeUntil(this.destroy$))
			.subscribe(isSubmitting => {
				this.isSubmitting = isSubmitting;
			});

		this.serviceProviderService
			.accountCompletion()
			.pipe(takeUntil(this.destroy$))
			.subscribe(acc => {
				this.accountCompletion.set(acc.profile ?? false);
			});

		this.serviceProviderService
			.serviceProvider()
			.pipe(takeUntil(this.destroy$))
			.subscribe(sp => {
				let mobileNumber: number | undefined;
				let regionCode: string | undefined;
				if (sp?.mobileNumber) {
					const countryCode = PHONE_LIB.parse(
						sp.mobileNumber
					).getCountryCode();
					regionCode = PHONE_LIB.getRegionCodeForCountryCode(
						countryCode ?? 27
					);
					if (regionCode) {
						this.selectedCountry = CountryList.findOneByCountryCode(
							regionCode ?? 'ZA'
						);
						mobileNumber = PHONE_LIB.parse(
							sp.mobileNumber
						).getNationalNumber();
					}
				}

				this.personalInfoForm.patchValue({
					firstName: sp?.firstName ?? '',
					lastName: sp?.lastName ?? '',
					email: sp?.email ?? '',
					mobileNumber: mobileNumber ?? ''
				});

				this.personalInfoForm.markAsPristine();
				this.personalInfoForm.markAsUntouched();
			});
	}

	onSubmit() {
		if (this.personalInfoForm.valid && this.personalInfoForm.dirty) {
			const updateServiceProvider: Partial<PersonalInfo> = {};
			Object.keys(this.personalInfoForm.controls).forEach(key => {
				if (this.personalInfoForm.controls[key].dirty) {
					if (key === 'country') return;
					if (key === 'mobileNumber') {
						updateServiceProvider[key] =
							`${this.selectedCountry?.dialCode}${this.personalInfoForm.controls[key].value}`;
						this.verifiedMobileNumber =
							updateServiceProvider[key] ?? null;
					} else {
						updateServiceProvider[key as keyof PersonalInfo] =
							this.personalInfoForm.controls[key].value;
					}
				}
			});

			if (
				this.verifiedMobileNumber !== null &&
				!this.isMobileNumberVerified
			) {
				this.verifingMobileNumber = true;
				return;
			}

			this.serviceProviderService.updateProfile(updateServiceProvider);
		}
	}

	mobileNumberVerfied(verified: boolean) {
		this.isMobileNumberVerified = verified;
		this.verifingMobileNumber = false;
		this.onSubmit();
	}

	validateMobileNumber(mobileNumber: string) {
		let valid = true;
		try {
			const region = this.selectedCountry?.code ?? 'ZA';
			const number = PHONE_LIB.parse(`${mobileNumber}`, region);
			if (!PHONE_LIB.isValidNumber(number)) {
				throw new Error('Invalid number');
			}

			valid = PHONE_LIB.isValidNumberForRegion(number, region);
		} catch (error) {
			valid = false;
		}
		if (!valid) {
			this.personalInfoForm.controls['mobileNumber'].setErrors({
				invalid: true
			});
		} else {
			this.isMobileNumberVerified = false;
			this.personalInfoForm.controls['mobileNumber'].setErrors(null);
		}
	}
}
