@if (label().length > 0) {
	<label class="input-label"
		>{{ label() }}
		@if (showRequiredStar()) {
			<span class="text-red-700">*</span>
		}
	</label>
}
<div class="relative mb-2">
	<div
		class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
	>
		<svg
			class="w-4 h-4 text-gray-500 dark:text-gray-400"
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
			/>
		</svg>
	</div>
	<input
		autocomplete="off"
		[formControl]="ngControl"
		[disabled]="disabled()"
		[ngClass]="{
			'input-error': control().invalid && control().dirty
		}"
		type="text"
		class="block w-full ps-10 form-input focus:outline-none focus:ring-1 focus:ring-primary-100 focus:border-transparent"
		[placeholder]="placeholder()"
		(focus)="setFocus(true)"
	/>
	<div
		[ngClass]="{
			hidden:
				!ngControl.value || searchResults().length === 0 || !isFocused()
		}"
		class="absolute z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-full"
	>
		<ul class="text-sm text-gray-700" aria-labelledby="dropdownInput">
			@for (searchResult of searchResults(); track $index) {
				<li (click)="selected(searchResult)" class="cursor-pointer">
					<p class="block px-4 py-2 hover:bg-gray-100">
						{{ transformer()(searchResult) }}
					</p>
				</li>
			}
		</ul>
	</div>
</div>
<div class="flex flex-wrap gap-1.5">
	@for (selection of allSelections(); track $index) {
		<div class="py-1.5 px-3 bg-gray-200 rounded-full inline-block">
			<div class="flex items-center gap-1">
				<p class="text-gray-900 text-xs font-light">
					{{ transformer()(selection) }}
				</p>
				<div
					(click)="removeSuggestion(selection)"
					class="bg-gray-600 w-3 h-3 rounded-full p-0.5 cursor-pointer"
				>
					<fixify-icon
						name="x-close"
						class="w-3 h-3 text-gray-200 stroke-2"
					></fixify-icon>
				</div>
			</div>
		</div>
	}
</div>

