import { Component } from '@angular/core';
import { ModalComponent } from '../modal.service';
import { AgencyInvite } from '../../../data-access/agency/agency.types';
import { ModalCardComponent } from '../../modal-card/modal-card.component';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-invite-to-agency',
	standalone: true,
	imports: [ModalCardComponent, AsyncPipe],
	templateUrl: './invite-to-agency.component.html',
	styleUrl: './invite-to-agency.component.css'
})
export class InviteToAgencyComponent extends ModalComponent<
	AgencyInvite,
	boolean
> {
	declineInvitation() {
		this.close(false);
	}

	acceptInvitation() {
		this.close(true);
	}
}
