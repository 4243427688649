import { Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResidentialService } from '../../shared/data-access/residential/residential.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	Agency,
	createAJobRequest,
	createAPropertyRequest,
	residentialSearchUserRequest
} from '../../shared/data-access/residential/residential.types';
import { Stakeholder } from '../../shared/data-access/agency/agency.types';

export interface Address {
	city: string | null;
	coordinates: [number, number] | null;
	formatted: string | null;
	geohash?: string | null;
	postalCode: string | null;
	province: string | null;
	street: {
		name: string;
		number: string | null;
	};
	suburb: string | null;
	unitNumber: string | null;
	buildingName: string | null;
}

export interface Property {
	address: Address;
	id: string;
	vacant: boolean;
	landlords: Stakeholder;
	occupants: Stakeholder;
	trustees: Stakeholder;
}

export interface Estate extends Property {
	name: string;
}

export interface Category {
	id: string;
	name: string;
}

export interface PropertyDetails extends Property {
	estateId: string | null;
	estateAddress: Address | null;
	estateName: string | null;
	availableCategories: Array<Category>;
}

export type Selection = 'new' | 'existing' | null;

@Injectable()
export class CreatorPortalService {
	agencyId = new BehaviorSubject<string | null>(null);
	propertyId = new BehaviorSubject<string | null>(null);

	selection = signal<Selection>(null);
	landing = signal<boolean>(true);
	showJobDetails = signal<boolean>(false);
	searching = signal<boolean>(false);
	showLinkedProperties = signal<boolean>(false);
	isSearchModalOpen = signal<boolean>(false);
	createJob = signal<boolean>(false);
	jobCreated = signal<boolean>(false);
	linkedProperty = signal<PropertyDetails | null>(null);
	linkedProperties = signal<Array<Property>>([]);
	availableEstates = signal<Array<Estate>>([]);
	canInterchangeProperty = signal<boolean>(true);
	noAgency = signal<boolean>(false);
	searcher: residentialSearchUserRequest = {};

	agency: Observable<Agency | null> = this.residentialService.agency();

	constructor(
		private route: ActivatedRoute,
		private residentialService: ResidentialService
	) {
		this.route.params.subscribe(params => {
			const agencyIdParamValue = params['agencyId'];
			const propertyIdParamValue = params['propertyId'];
			this.residentialService.fetchAgencyDetails(agencyIdParamValue);
			this.fetchAgency(agencyIdParamValue);
			this.agencyId.next(agencyIdParamValue);
			if (!agencyIdParamValue) {
				this.noAgency.set(true);
			}
			this.propertyId.next(propertyIdParamValue);
			if (propertyIdParamValue) {
				this.directlyToProperty();
			}
		});
	}

	async fetchAgency(agencyId: string) {
		this.residentialService.fetchAgencyDetails(agencyId).subscribe({
			next: data => {
				const agency = data.body as Agency;
				this.residentialService.setAgency(agency);
				if (!agency) {
					this.noAgency.set(true);
				}
			},
			error: () => {
				this.noAgency.set(true);
			}
		});
	}

	async directlyToProperty() {
		this.searching.set(true);
		this.canInterchangeProperty.set(false);
		this.residentialService
			.fetchPropertyDetails(this.agencyId.value, this.propertyId.value)
			.subscribe({
				next: data => {
					const property = data.body as PropertyDetails;
					this.linkedProperty.set(property);
					this.createJob.set(true);
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.searching.set(false);
					this.landing.set(false);
				}
			});
	}

	async selectProperty(property: Property) {
		this.searching.set(true);
		this.fetchPropertyDetails(property.id);
	}

	async fetchPropertyDetails(propertyId: string) {
		this.residentialService
			.fetchPropertyDetails(this.agencyId.value, propertyId)
			.subscribe({
				next: data => {
					const property = data.body as PropertyDetails;
					const searchedProperty = this.linkedProperties().find(
						p => p.id === property.id
					);
					if (searchedProperty) {
						property.occupants = searchedProperty.occupants;
						property.landlords = searchedProperty.landlords;
						property.trustees = searchedProperty.trustees;
					}
					this.linkedProperty.set(property);
					this.createJob.set(true);
					this.propertyId.next(property.id);
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.searching.set(false);
					this.showLinkedProperties.set(false);
					this.landing.set(false);
				}
			});
	}

	async fetchProperties(searchSettings: residentialSearchUserRequest) {
		this.searching.set(true);
		this.searcher = searchSettings;

		if (this.agencyId.value === null) {
			console.error('Agency ID is not set');
			return;
		}

		this.residentialService
			.fetchAllResidentialProperties(this.agencyId.value, searchSettings)
			.subscribe({
				next: data => {
					const properties = data.body.result as Array<Property>;
					this.linkedProperties.set(properties);
					this.showLinkedProperties.set(true);
					if (properties.length == 0) {
						this.fetchEstates();
					}
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.searching.set(false);
					this.isSearchModalOpen.set(false);
					this.selection.set(null);
					this.landing.set(false);
				}
			});
	}

	async createAJob(data: createAJobRequest) {
		this.searching.set(true);

		if (this.agencyId.value === null) {
			console.error('Agency ID is not set');
			return;
		}
		if (this.propertyId.value === null) {
			console.error('Property ID is not set');
			return;
		}

		this.residentialService
			.createAJob(this.agencyId.value, this.propertyId.value, data)
			.subscribe({
				next: () => {
					this.jobCreated.set(true);
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.searching.set(false);
				}
			});
	}

	createAProperty(data: createAPropertyRequest) {
		this.searching.set(true);

		if (this.agencyId.value === null) {
			console.error('Agency ID is not set');
			return;
		}

		this.residentialService
			.createAProperty(this.agencyId.value, data)
			.subscribe({
				next: data => {
					const property = data.body as { id: string };
					this.fetchPropertyDetails(property.id);
				},
				error: error => {
					console.error(error);
				},
				complete: () => {
					this.searching.set(false);
				}
			});
	}

	fetchEstates() {
		if (this.agencyId.value === null) {
			console.error('Agency ID is not set');
			return;
		}

		this.residentialService.fetchEstates(this.agencyId.value).subscribe({
			next: date => {
				const estates = date.body as Array<Estate>;
				this.availableEstates.set(estates);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	reset() {
		this.selection.set(null);
		this.landing.set(true);
		this.searching.set(false);
		this.createJob.set(false);
		this.linkedProperty.set(null);
		this.isSearchModalOpen.set(false);
		this.showLinkedProperties.set(false);
		this.jobCreated.set(false);
	}
}
