import { Component } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';

@Component({
	selector: 'fixify-job-already-taken-modal',
	standalone: true,
	imports: [ModalCardComponent],
	templateUrl: './job-already-taken-modal.component.html',
	styleUrl: './job-already-taken-modal.component.css'
})
export class JobAlreadyTakenModalComponent extends ModalComponent<void, void> {
	constructor() {
		super();
	}
}
