import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownComponent } from '../../../shared/ui/dropdown/dropdown.component';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import { Location } from '@angular/common';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { Role, roles } from '../../../shared/models/agent';
import {
	nameToInitials,
	titleCase
} from '../../../shared/utils/string-manipulation';
import { getCountryCodeFromNumber } from '../../../shared/utils/phone';
import { AvatarComponent } from '../../../shared/ui/avatar/avatar.component';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import { emailValidator } from '../../../shared/validators';
import { ManagedAgent } from '../../../shared/data-access/agency/agency.types';
import { ToastService } from '../../../shared/data-access/toast.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '../../../shared/ui/modals/modal.service';
import { DeleteModalComponent } from '../../../shared/ui/modals/delete/delete.component';
import { AgencyAgentsService } from '../../../shared/data-access/agency/agency.agents.service';

@Component({
	selector: 'app-edit-agent',
	standalone: true,
	imports: [
		DropdownComponent,
		TextInputComponent,
		ReactiveFormsModule,
		AvatarComponent,
		IconComponent
	],
	templateUrl: './edit-agent.component.html',
	styleUrl: './edit-agent.component.css'
})
export class EditAgentComponent implements OnInit, OnDestroy {
	agentInfoForm: FormGroup;
	agentCountry = '';
	selectedRole = signal<{ name: string; value: string } | null>(null);
	roles = roles.map(role => ({ name: titleCase(role), value: role }));
	loading = false;

	destroy$ = new Subject<void>();
	formTouched = signal<boolean>(false);
	formValid = signal<boolean>(false);

	agent = signal<ManagedAgent | null>(null);
	initials = computed(() => {
		if (!this.agent()) {
			return '--';
		}

		return nameToInitials(
			this.agent()?.firstName + ' ' + this.agent()?.lastName
		);
	});

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private agencyAgentsService: AgencyAgentsService,
		private toastService: ToastService,
		private modalService: ModalService
	) {
		const { agentId } = this.route.snapshot.params;

		this.agentInfoForm = new FormGroup({
			firstName: new FormControl('', [Validators.required]),
			lastName: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required, emailValidator]),
			role: new FormControl<Role | ''>('', [])
		});
		if (agentId) {
			this.loadAgent(agentId);
		} else {
			this.router.navigate(['/agency/agents']);
		}
	}
	ngOnDestroy(): void {
		this.destroy$.next();
	}
	ngOnInit(): void {
		this.agentInfoForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.formTouched.set(this.agentInfoForm.controls['role'].dirty);
				this.formValid.set(this.agentInfoForm.controls['role'].valid);
			});
	}

	goBack() {
		this.location.back();
	}

	loadAgent(agentId: string) {
		this.agencyAgentsService.fetchAgent(agentId).subscribe({
			next: data => {
				const agent = data.body as ManagedAgent;
				this.agent.set(agent);

				this.agentCountry =
					getCountryCodeFromNumber(agent.mobileNumber) ?? '';
				this.agentInfoForm.patchValue(agent);
			},
			error: error => {
				console.error(error);
				this.toastService.add(
					error.error.body.errorMessage,
					5000,
					'error'
				);
			}
		});
	}

	onSubmit() {
		if (this.agentInfoForm.controls['role'].valid) {
			const role = this.agentInfoForm.value?.role;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.agencyAgentsService.updateAgent(this.agent()!.id, role);
			this.agentInfoForm.markAsPristine();
		}
	}

	showDeleteAgentModal() {
		if (!this.agent()) {
			return;
		}

		this.modalService
			.showModal(DeleteModalComponent, {
				title:
					'Delete ' +
					this.agent()?.firstName +
					' ' +
					this.agent()?.lastName +
					'?',
				body: 'This action cannot be undone. Are you sure you want to delete this agent?',
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(async result => {
				if (result) {
					this.agencyAgentsService.deleteAgent(
						this.agent()?.id ?? ''
					);
					this.router.navigate(['/agency/agents']);
				}
			});
	}
}
