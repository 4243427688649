<div class="flex py-4 px-6 border-gray-100 border-2 rounded gap-4">
	<div class="flex w-1/4 py-2">
		<div class="w-full h-20 overflow-hidden items-center">
			<fixify-property-avatar
				[url]="property?.imageUrl ?? ''"
			></fixify-property-avatar>
		</div>
	</div>

	<div class="w-3/4">
		<div class="py-2 flex flex-col gap-1">
			<p class="text-base font-normal text-gray-900 line-clamp-1">
				{{ title() }}
			</p>
			<p class="text-base font-normal text-gray-900 line-clamp-1">
				{{ property?.address?.formatted }}
			</p>
		</div>
		@if (job().common) {
			<div class="mt-1 inline-block">
				<fixify-status-pill [color]="'primary'">
					Complex/Estate
				</fixify-status-pill>
			</div>
		}
	</div>
</div>

