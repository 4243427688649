import { Component } from '@angular/core';
import { TextInputComponent } from '../../shared/ui/text-input/text-input.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { UserServiceFactory } from '../../shared/factories/user.type.factory';

@Component({
	selector: 'app-support',
	standalone: true,
	imports: [TextInputComponent, ReactiveFormsModule],
	templateUrl: './support.component.html',
	styleUrl: './support.component.css'
})
export class SupportComponent {
	supportForm: FormGroup;

	constructor(private userService: UserServiceFactory) {
		this.supportForm = new FormGroup({
			message: new FormControl('', Validators.required)
		});
	}

	onSubmit() {
		if (this.supportForm.valid) {
			this.userService
				.currentlyInjectedService()
				.requestSupport(this.supportForm.value.message)
				.subscribe({
					next: () => {
						this.supportForm.reset();
					},
					error: error => {
						console.log('Error', error);
					}
				});
		}
	}
}
