import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'fixify-job-reject-detail-item',
	standalone: true,
	imports: [DatePipe, IconComponent],
	templateUrl: './job-reject-detail-item.component.html',
	styleUrl: './job-reject-detail-item.component.css'
})
export class JobRejectDetailItemComponent {
	rejectedDate = input<Date | null>(null);
	reason = input<string>('None provided');
	title = input<string>('Job rejected');
}
