export const environment = {
	production: true,
	env: 'prod',
	useEmulators: {
		auth: false,
		firestore: false,
		functions: false,
		storage: false
	},
	apiUrl: 'https://europe-west1-fixify-9637a.cloudfunctions.net/api',
	termsAndConditions:
		'https://firebasestorage.googleapis.com/v0/b/fixify-9637a.appspot.com/o/termsAndConditions%2FService%20Providers%20Terms%20of%20Service.pdf?alt=media&token=fd4c7c1b-1a66-4f71-a4db-981a0c3cbb83',
	firebase: {
		projectId: 'fixify-9637a',
		appId: '1:223651978220:web:2e4d8e7cfc6f288ab9fec9',
		storageBucket: 'fixify-9637a.appspot.com',
		apiKey: 'AIzaSyDYAYChuDlwcnltHKwHCqsAKM_uQ0GIZBw',
		authDomain: 'fixify-9637a.firebaseapp.com',
		messagingSenderId: '223651978220',
		measurementId: 'G-CTDKZJ3S2E'
	},
	mapApiKey: 'AIzaSyB9FF2ROzf6qhWy2SGSzSn-vAyo0Z8s4OA'
};
