import { NgClass } from '@angular/common';
import { Component, Input, OnInit, input } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ReactiveFormsModule
} from '@angular/forms';

@Component({
	selector: 'fixify-text-input',
	standalone: true,
	imports: [ReactiveFormsModule, NgClass],
	templateUrl: './text-input.component.html',
	styleUrl: './text-input.component.css'
})
export class TextInputComponent implements OnInit {
	@Input() control!: AbstractControl;
	errorText = input<string>('Invalid input');
	showErrorMessage = input<boolean>(true);
	inputType = input<string>('text');
	placeholder = input<string>('Enter text');
	showRequiredStar = input<boolean>(false);
	label = input<string>('');
	disabled = input<boolean>(false);
	id = input<string>('text-input');

	ngControl!: FormControl;

	ngOnInit() {
		this.ngControl = this.control as FormControl;
	}
}
