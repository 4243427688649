import { Component, signal } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ServiceProvider } from '../../../../../shared/data-access/service-provider/service-provider.types';
import { Observable } from 'rxjs';
import { GenericSearchInputComponent } from '../../../../../shared/ui/generic-search-input/generic-search-input.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'app-add-favourite-sp',
	standalone: true,
	imports: [
		ModalCardComponent,
		IconComponent,
		GenericSearchInputComponent,
		ReactiveFormsModule
	],
	templateUrl: './add-favourite-sp.component.html',
	styleUrl: './add-favourite-sp.component.css'
})
export class AddFavouriteSpComponent extends ModalComponent<
	Array<ServiceProvider>,
	Array<ServiceProvider>
> {
	spSearchForm = new FormGroup({
		search: new FormControl('')
	});
	focus = signal<boolean>(false);
	allSelections = signal<Array<ServiceProvider>>([]);

	protected override onDataSet(): void {
		this.allSelections.set(this.data ?? []);
	}

	onSave() {
		this.close(this.allSelections());
	}

	removeFocus() {
		this.focus.set(false);
	}

	genericSpTest(input: string): Observable<Array<ServiceProvider>> {
		// TODO: BE integration
		return new Observable<Array<ServiceProvider>>(observer => {
			setTimeout(() => {
				observer.next([
					{
						name: 'John Doe',
						firstName: 'John',
						lastName: 'Doe',
						mobileNumber: '123-456-7890',
						categories: {},
						companyDetails: {
							companyLogo: 'logo1.png',
							companyName: 'Doe Services',
							country: 'USA',
							entityType: 'LLC',
							province: 'California',
							registeredName: 'Doe Services LLC',
							registrationNumber: '123456789',
							tradingName: 'Doe Services',
							vatNumber: '987654321'
						},
						userId: 'user123',
						verified: true,
						termsAccepted: 1622548800,
						id: 'sp1',
						email: 'john.doe@example.com'
					},
					{
						name: 'Jane Doe',
						firstName: 'Jane',
						lastName: 'Doe',
						mobileNumber: '123-456-7890',
						categories: {},
						companyDetails: {
							companyLogo: 'logo2.png',
							companyName: 'Doe Services',
							country: 'USA',
							entityType: 'LLC',
							province: 'California',
							registeredName: 'Doe Services LLC',
							registrationNumber: '123456789',
							tradingName: 'Doe Services',
							vatNumber: '987654321'
						},
						userId: 'user123',
						verified: true,
						termsAccepted: 1622548800,
						id: 'sp2',
						email: 'jane.doe@example.com'
					}
				]);
				observer.complete();
			}, 200);
		});
	}

	genericSpTransformer(item: ServiceProvider) {
		return [item.firstName, item.lastName].join(' ');
	}

	uniquenessFunction(item: ServiceProvider) {
		return item.id;
	}
}
