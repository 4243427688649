<fixify-modal size="md">
	<div class="flex justify-end">
		<fixify-icon
			class="w-6 h-6 text-gray-500"
			name="x-close"
			(click)="close('view-all')"
		></fixify-icon>
	</div>
	<div class="mb-6">
		<img
			class="w-32 h-32 mx-auto"
			src="assets/svg/Social 04.svg"
			alt="Job Submitted"
		/>
	</div>
	<div class="mb-6">
		<p class="text-xl font-semibold text-gray-900 mb-1">Job submitted</p>
		<p class="text-base font-normal text-gray-500">
			This job is now live and visible to service providers. You'll be
			notified when it's accepted. View or edit details below.
		</p>
	</div>
	<div class="flex justify-end gap-2">
		<button (click)="close('view-all')" class="btn btn-outline">
			View all jobs
		</button>
		<button (click)="close('view-job')" class="btn btn-primary">
			View job details
		</button>
	</div>
</fixify-modal>

