import { Injectable, signal } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject } from 'rxjs';
import { ManagedServiceProvider } from './agency.types';
import { PaginatedResponse } from '../../utils/pagination';

type ManagedServiceProviderFilters = {
	search?: string;
	page?: number;
	limit?: number;
	category?: string;
	coordinates?: string;
	verified?: string;
	favourited?: string;
	sort?: string;
	sortBy?: string;
};
@Injectable({
	providedIn: 'root'
})
export class AgencyServiceProvidersService {
	private isFetching$ = new BehaviorSubject<boolean>(false);
	private serviceProviders$ =
		new BehaviorSubject<PaginatedResponse<ManagedServiceProvider> | null>(
			null
		);

	constructor(private apiService: ApiService) {}

	private paramSignal = signal<ManagedServiceProviderFilters>({});

	fetchServiceProviders(_params?: ManagedServiceProviderFilters) {
		this.isFetching$.next(true);
		this.setParamSignal(_params);
		const params = this.apiService.buildFilterQueryParams({
			...this.paramSignal()
		});
		this.apiService.getData(`agency/service-providers${params}`).subscribe({
			next: data => {
				const paginatedData =
					data.body as PaginatedResponse<ManagedServiceProvider>;

				this.serviceProviders$.next(paginatedData);
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isFetching$.next(false);
			}
		});
	}

	getParams() {
		return this.paramSignal();
	}

	setParamSignal(params?: ManagedServiceProviderFilters) {
		if (!params) {
			return this.clearParams();
		}
		this.paramSignal.set(params);
	}

	clearParams() {
		this.paramSignal.set({});
	}

	searching() {
		return this.isFetching$.asObservable();
	}

	serviceProviders() {
		return this.serviceProviders$.asObservable();
	}

	clearServiceProviders() {
		this.serviceProviders$.next(null);
	}
}
