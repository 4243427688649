<div class="max-w px-12 md:px-22 lg:px-44 xl:px-72">
	<div
		(click)="goBack()"
		class="flex gap-2 items-center cursor-pointer mb-1.5"
	>
		<fixify-icon
			class="w-4 h-4 text-gray-800"
			name="arrow-left"
		></fixify-icon>
		<p class="text-sm font-medium text-gray-800">Back</p>
	</div>
	<div class="flex justify-start items-center">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl font-outfit pr-2 py-2.5">
				New maintenance job
			</h1>
		</div>
	</div>
	<div class="rounded bg-stone py-4 px-6 mt-6">
		<div class="flex items-center justify-between">
			<div class="flex-grow mr-6">
				<p class="text-sm font-normal text-gray-500 mb-0.5">
					Property address
				</p>
				<p class="text-base font-normal text-gray-900">
					{{ selectedProperty()?.address?.formatted }}
				</p>
			</div>

			<div (click)="changeAddress()" class="px-3 py-2 cursor-pointer">
				<p class="text-primary-500">Edit</p>
			</div>
		</div>
		@if (selectedProperty()?.estateName) {
			<div class="mt-4">
				<p class="text-sm font-normal text-gray-500">Complex/Estate</p>
				<p class="text-base font-normal text-gray-900">
					{{ selectedProperty()?.estateName ?? 'N/A' }}
				</p>
			</div>
		}
	</div>
	<form [formGroup]="jobDetailsForm">
		<div class="my-8">
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		</div>
		@if (selectedProperty()?.estateId) {
			<div id="complex-estate">
				<p class="text-xl font-medium text-gray-900 mb-0.5">
					Where's the issue?
				</p>
				<p class="text-sm font-normal text-gray-700 mb-8">
					Specify whether this maintenance is needed on private or
					common property.
				</p>

				@if (invalidControl('propertyType')) {
					<div class="mb-4">
						<fixify-validation-message
							text="Select an option to continue"
						></fixify-validation-message>
					</div>
				}

				<div class="gap-4 flex w-full">
					<fixify-request-card
						class="flex-1"
						titleSizeOverride="!text-xl"
						(changeCardSelect)="
							changePropertyType(PropertyType.PRIVATE)
						"
						imgUrl="assets/svg/private-property.svg"
						[selected]="
							jobDetailsForm.controls['propertyType'].value ===
							'private'
						"
						value="private"
						text="Private property"
						subText="A space that is owned and used exclusively."
						[borderError]="invalidControl('propertyType')"
					></fixify-request-card>
					<fixify-request-card
						class="flex-1"
						titleSizeOverride="!text-xl"
						(changeCardSelect)="
							changePropertyType(PropertyType.COMMON)
						"
						imgUrl="assets/svg/common-property.svg"
						[selected]="
							jobDetailsForm.controls['propertyType'].value ===
							'common'
						"
						value="common"
						text="Common property"
						subText="A shared area that everyone in a building or complex can use."
						[borderError]="invalidControl('propertyType')"
					></fixify-request-card>
				</div>
				<div
					[class.hidden]="
						jobDetailsForm.controls['propertyType'].value !==
						'common'
					"
					class="mt-6"
				>
					<p class="font-sm font-medium font-gray-900 mb-2">
						Add specific location/landmark
					</p>
					<fixify-text-input
						[control]="jobDetailsForm.controls['landmark']"
						inputType="text"
						placeholder="Add any landmarks you think might be useful"
					></fixify-text-input>
				</div>
				<div class="my-8">
					<fixify-divider class="!bg-gray-100"></fixify-divider>
				</div>
			</div>
		}

		<p class="text-xl font-medium text-gray-900 mb-0.5">
			What maintenance do you need?
		</p>
		<p class="text-sm font-normal text-gray-700">Select one category.</p>
		<div class="pt-8">
			@if (invalidControl('category')) {
				<fixify-validation-message
					text="Select 1 category"
				></fixify-validation-message>
			}

			<p class="text-sm font-medium text-gray-700 mb-4">
				Our top categories
			</p>
			<div class="grid grid-cols-3 gap-2">
				@for (topCategory of availableCategories; track $index) {
					<div
						[ngClass]="{
							'border-primary-500':
								topCategory ===
								jobDetailsForm.controls['category'].value,
							'!border-red-700': invalidControl('category')
						}"
						(click)="selectCategory(topCategory)"
						class="flex items-center p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
					>
						<img
							class="mr-3 max-h-5 max-w-5"
							src="assets/svg/calendar-heart-dark.svg"
						/>
						<p class="text-sm font-medium text-gray-900">
							{{ topCategory.name }}
						</p>
					</div>
				}
			</div>
		</div>
		<div class="my-8">
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		</div>
		<p class="text-xl font-medium text-gray-900 mb-0.5">
			Let's get into the details
		</p>
		<p class="text-sm font-normal text-gray-700">
			Provide us with more information about this job.
		</p>
		<div class="mt-8 mb-6">
			@if (invalidControl('details')) {
				<fixify-validation-message
					text="Provide a short description"
				></fixify-validation-message>
			}
			<p class="font-sm font-medium font-gray-900 mb-2">
				What are the details?
			</p>
			<fixify-text-input
				[control]="jobDetailsForm.controls['details']"
				inputType="textarea"
				placeholder="Enter the job details"
				[showErrorMessage]="false"
			></fixify-text-input>
		</div>
		<div
			[class.hidden]="
				jobDetailsForm.controls['propertyType'].value !== 'private'
			"
			class="mb-6"
		>
			<p class="font-sm font-medium font-gray-900 mb-2">Extra details</p>
			<fixify-text-input
				[control]="jobDetailsForm.controls['extra']"
				inputType="text"
				placeholder="Add additional instuctions for entry or access"
			></fixify-text-input>
		</div>
		@if (invalidControl('images')) {
			<fixify-validation-message
				text="Add at least 1 image"
			></fixify-validation-message>
		}
		<div>
			<p class="text-xl font-medium text-gray-900 mb-2">Add images</p>
			<div class="grid grid-cols-6 gap-3">
				@for (upload of uploadStateArray(); track upload.fileName) {
					<div class="relative w-full h-full aspect-square">
						<img
							[src]="upload.url || upload.fileSrc"
							class="w-full h-full object-cover rounded-lg"
						/>

						@if (upload.status === 'complete') {
							<button
								(click)="deleteMedia(upload)"
								class="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 border-2 border-white rounded-full"
							>
								<img src="assets/svg/remove-image-cross.svg" />
							</button>
						}

						@if (upload.status === 'uploading') {
							<div
								class="absolute inset-0 bg-black/50 rounded-lg flex items-center justify-center"
							>
								<div class="w-3/4">
									<div class="h-2 bg-gray-200 rounded">
										<div
											class="h-2 bg-primary-500 rounded transition-all duration-300"
											[style.width.%]="upload.progress"
										></div>
									</div>
								</div>
							</div>
						}

						@if (upload.status === 'error') {
							<div
								class="absolute inset-0 bg-red-500/50 rounded-lg flex items-center justify-center"
							>
								<p class="text-white text-sm">Upload failed</p>
							</div>
						}
					</div>
				}
				<div
					(click)="selectFilesFromDevice()"
					[ngClass]="{
						'border-red-700 border-2': invalidControl('images')
					}"
					class="justify-center items-center bg-gray-100 w-full h-full rounded-lg cursor-pointer"
				>
					<div class="aspect-square flex justify-center items-center">
						<img
							class="w-10 h-10"
							src="assets/svg/plus-primary.svg"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="my-8">
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		</div>
		<p class="text-xl font-medium text-gray-900 mb-0.5">
			Add contact details
		</p>
		<p class="text-sm font-normal text-gray-700">
			We will send you email updates on the progress of this job.
		</p>
		<div class="mt-8">
			@if (invalidControl('commsUpdateTo')) {
				<fixify-validation-message
					text="Select an option to continue"
				></fixify-validation-message>
			}
			<p class="mb-4 text-sm font-medium text-gray-900">
				<span [class.text-primary-500]="whoText() !== 'Who'"
					>{{ whoText() }}
				</span>
				should receive updates{{whoText() === 'Who' ? '?' : ''}}
			</p>
			<div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2">
				@for (comm of comms; track $index) {
					<div
						[ngClass]="{
							'border-primary-500':
								jobDetailsForm.controls[
									'commsUpdateTo'
								].value.includes(comm),
							'!border-red-700': invalidControl('commsUpdateTo')
						}"
						(click)="selectComms(comm)"
						class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
					>
						<img
							class="mr-3 max-h-5 max-w-5"
							src="assets/svg/{{ comm.icon }}.svg"
						/>
						<p class="text-sm font-medium text-gray-900">
							{{ comm.name | titlecase }}
						</p>
					</div>
				}
			</div>
		</div>
		<div class="my-8">
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		</div>
		<p class="text-xl font-medium text-gray-900 mb-0.5">
			Assign service providers
		</p>
		<p class="text-sm font-normal text-gray-700 mb-8">
			First, choose how you want to receive quotes from service providers:
		</p>
		@if (invalidControl('quoteType')) {
			<div class="mb-4">
				<fixify-validation-message
					text="Select an option to continue"
				></fixify-validation-message>
			</div>
		}
		<div class="gap-4 flex w-full">
			<fixify-request-card
				class="flex-1"
				titleSizeOverride="!text-xl"
				(changeCardSelect)="changeQuoteType(QuoteType.ACCURATE)"
				imgUrl="assets/svg/file-plus-02.svg"
				[selected]="
					jobDetailsForm.controls['quoteType'].value === 'accurate'
				"
				value="accurate"
				text="Accurate quote"
				subText="A site visit will be scheduled for service providers."
				[borderError]="invalidControl('quoteType')"
			></fixify-request-card>
			<fixify-request-card
				class="flex-1"
				titleSizeOverride="!text-xl"
				(changeCardSelect)="changeQuoteType(QuoteType.FAST)"
				imgUrl="assets/svg/clock-plus.svg"
				[selected]="
					jobDetailsForm.controls['quoteType'].value === 'fast'
				"
				value="fast"
				text="Fastest quote"
				subText="No site visit necessary. Get quotes at no cost."
				[borderError]="invalidControl('quoteType')"
			></fixify-request-card>
		</div>
		<div class="mt-6">
			<p class="font-sm font-medium font-gray-900 mb-2">
				How many quotes would you like to receive?
			</p>
			<fixify-text-input
				[control]="jobDetailsForm.controls['quoteAmount']"
				inputType="number"
				placeholder="Maximum of 5"
				[errorText]="'Please enter a number between 1 and 5'"
			></fixify-text-input>
		</div>
		<p class="my-8 text-base font-normal text-gray-700">
			Next, assign service providers:
		</p>
		@if (invalidControl('assignTo')) {
			<fixify-validation-message
				text="Select an option to continue"
			></fixify-validation-message>
		}
		<div class="grid grid-cols-1 md:grid-cols-2 gap-2">
			<div
				[ngClass]="{
					'border-primary-500':
						jobDetailsForm.controls['assignTo'].value == 'all',
					'!border-red-700': invalidControl('assignTo')
				}"
				(click)="selectAssignTo('all')"
				class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
			>
				<fixify-icon
					class="w-5 h-5 stroke-2 mr-3"
					name="users-plus"
				></fixify-icon>
				<p class="text-sm font-medium text-gray-900">
					Send to all available providers
				</p>
			</div>
			<div
				[ngClass]="{
					'border-primary-500':
						jobDetailsForm.controls['assignTo'].value == 'specific',
					'!border-red-700': invalidControl('assignTo')
				}"
				(click)="selectAssignTo('specific')"
				class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
			>
				<fixify-icon
					class="w-5 h-5 stroke-2 mr-3"
					name="user-plus-01"
				></fixify-icon>
				<p class="text-sm font-medium text-gray-900">
					Select specific providers
				</p>
			</div>
		</div>
		@if (jobDetailsForm.controls['assignTo'].value === 'specific') {
			<div class="mt-3 px-5 py-4 bg-stone relative">
				<div
					class="absolute -top-2 left-3/4 -translate-x-1/2 w-0 h-0 border-x-8 border-x-transparent border-b-8 border-b-stone"
				></div>
				<p class="text-sm font-normal text-gray-700">
					This allows you to target providers you prefer or have
					worked with before. The first
					<strong>{{ quoteAmount() }}</strong> providers to accept
					your request will provide a quote.
				</p>
			</div>
			<div class="mt-6">
				<fixify-generic-search-input
					[control]="jobDetailsForm.controls['spSearch']"
					placeholder="Search ..."
					[genericSearchFunction]="searchServiceProviders"
					[transformer]="genericSpTransformer"
					(selectedResult)="onSelectedServiceProvider($event)"
				></fixify-generic-search-input>
			</div>
			<div class="mt-4 pr-4 max-h-96 overflow-auto flex flex-col gap-4">
				@if (isSearchingServiceProviders) {
					<div class="flex justify-center items-center">
						<app-loader [loadingState]="true"></app-loader>
					</div>
				} @else {
					@for (sp of availableServiceProviders; track $index) {
						<fixify-sp-info-card
							companyName="{{
								sp.companyDetails?.companyName ?? 'Unknown'
							}}"
							imageUrl="{{
								sp.companyDetails?.companyLogo ?? ''
							}}"
							[restrictSize]="false"
							secondaryText="{{
								sp.totalCompletedJobs ?? '0'
							}} completed jobs"
							[selectable]="true"
							[isFavourite]="sp.favourited ?? false"
							[selected]="selectedServiceProviders.includes(sp)"
							(selectedChange)="
								onSelectChangeServiceProvider($event, sp)
							"
						></fixify-sp-info-card>
					}
				}
			</div>
			@if (selectedServiceProviders.length > 0) {
				<div
					class="mt-4 pr-4 max-h-96 overflow-auto flex flex-col gap-4"
				>
					<p class="text-sm font-medium text-gray-900">
						Selected service providers - SP to Quote amount ratio:
						<span
							[ngClass]="{
								'text-red-500':
									selectedServiceProviders.length <
									quoteAmount(),
								'text-green-500':
									selectedServiceProviders.length >
									quoteAmount(),
								'text-primary-500':
									selectedServiceProviders.length ==
									quoteAmount()
							}"
							>{{ selectedServiceProviders.length }} /
							{{ quoteAmount() }}</span
						>
					</p>
					<div class="flex flex-wrap gap-2">
						@for (sp of selectedServiceProviders; track $index) {
							<fixify-status-pill [color]="'primary'">
								<div class="flex gap-1 items-center">
									<p class="p-1 text-xs font-medium">
										{{
											sp.companyDetails?.companyName ??
												'Unknown'
										}}
									</p>
									<div
										(click)="
											removeSelectedServiceProvider(sp)
										"
										class="w-4 h-4 text-primary-800 cursor-pointer"
									>
										<fixify-icon
											name="x-circle"
										></fixify-icon>
									</div>
								</div>
							</fixify-status-pill>
						}
					</div>
				</div>
			}
		}
		<div class="my-8">
			<fixify-divider class="!bg-gray-100"></fixify-divider>
		</div>
		<p class="text-xl font-medium text-gray-900 mb-0.5">
			Select payment responsibility
		</p>
		<p class="text-sm font-normal text-gray-700">
			Select who will be responsible for paying for this maintenance
			request, which may include a callout fee or full labour costs.
		</p>
		<div class="mt-8">
			@if (invalidControl('paymentResponsibility')) {
				<fixify-validation-message
					text="Select an option to continue"
				></fixify-validation-message>
			}
			<div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2">
				@for (comm of comms; track $index) {
					<div
						[ngClass]="{
							'border-primary-500':
								jobDetailsForm.controls['paymentResponsibility']
									.value == comm,
							'!border-red-700': invalidControl(
								'paymentResponsibility'
							)
						}"
						(click)="selectResponsibility(comm)"
						class="flex p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
					>
						<img
							class="mr-3 max-h-5 max-w-5"
							src="assets/svg/{{ comm.icon }}.svg"
						/>
						<p class="text-sm font-medium text-gray-900">
							{{ comm.name | titlecase }}
						</p>
					</div>
				}
			</div>
		</div>
		<div class="mt-12 flex gap-2">
			<button class="w-full btn btn-outline">Cancel request</button>
			<button (click)="submit()" class="w-full btn btn-primary">
				Submit job
			</button>
		</div>
	</form>
</div>

