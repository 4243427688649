import { Component } from '@angular/core';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { ComplexEstatesTableComponent } from './ui/tables/complex-estates-table/complex-estates-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EstateMetrics } from '../../shared/data-access/agency/agency.types';
import { Subject, takeUntil } from 'rxjs';
import { AgencyEstatesService } from '../../shared/data-access/agency/agency.estates.service';
import { LoaderComponent } from '../../shared/ui/loader/loader.component';

@Component({
	selector: 'app-complex-estates',
	standalone: true,
	imports: [
		IconComponent,
		DashboardCardComponent,
		ComplexEstatesTableComponent,
		LoaderComponent
	],
	templateUrl: './complex-estates.component.html',
	styleUrl: './complex-estates.component.css'
})
export class ComplexEstatesComponent {
	destroy$ = new Subject<void>();
	metrics: EstateMetrics | null = null;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private agencyEstatesService: AgencyEstatesService
	) {
		this.agencyEstatesService
			.estateMetrics()
			.pipe(takeUntil(this.destroy$))
			.subscribe(propertyMetrics => {
				this.metrics = propertyMetrics;
			});
	}

	addComplexEstate() {
		this.router.navigate(['add'], { relativeTo: this.route });
	}
}
