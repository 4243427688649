<div class="max-w px-12 md:px-22 xl:px-44">
	<div
		(click)="goBack()"
		class="flex gap-2 items-center cursor-pointer mb-1.5"
	>
		<fixify-icon
			class="w-4 h-4 text-gray-800"
			name="arrow-left"
		></fixify-icon>
		<p class="text-sm font-medium text-gray-800">Back</p>
	</div>
	<div class="flex justify-start items-center">
		<div class="grow flex items-center">
			<h1 class="font-medium text-2xl font-outfit pr-2 py-2.5">
				{{ propertyId() ? 'Edit' : 'Add' }} property
			</h1>
		</div>
		<div class="flex gap-3">
			@if (propertyId()) {
				<button
					(click)="toggleDeletePropertyModal()"
					type="button"
					class="grow-0 btn btn-outline danger"
				>
					Delete
				</button>
			}
			<button
				(click)="save()"
				type="button"
				class="grow-0 btn btn-primary"
			>
				Save
			</button>
		</div>
	</div>

	<div class="h-8"></div>
	@if (fetchingProperty()) {
		<div class="flex justify-center">
			<app-loader
				class="mt-8"
				[loadingState]="true"
			></app-loader>
		</div>
	} @else {
		<form
		#formElement="ngForm"
		[formGroup]="propertyForm"
		class="grid gap-x-6 lg:gap-x-12 gap-y-6 grid-cols-1 md:grid-cols-2 gap-6 pt-6"
	>
		<div id="col-1">
			<div class="mb-6">
				<p class="text-sm font-normal text-gray-700 mb-4">
					Address Details
				</p>
				<fixify-divider></fixify-divider>
			</div>
			@if (
				(propertyForm.get('address')?.statusChanges | async) ===
					'INVALID' && showErrors()
			) {
				<div class="mb-4">
					<fixify-validation-message
						class="mb-6"
						text="Add address information"
					></fixify-validation-message>
				</div>
			}

			<div class="flex flex-col gap-6 mb-8">
				<div>
					<fixify-search-places
						[control]="propertyForm.get('address.streetAddress')!"
						placeholder="Enter street address"
						[(ignoreNextSearchChange)]="ignoreNextSearchChange"
						[(searchResults)]="searchResults"
						(searchingForAddresses)="searchAddresses($event)"
						label="Street Address"
						(address)="onAddress($event)"
					></fixify-search-places>
					@if (searchingForAddresses()) {
						<div class="flex justify-center">
							<app-loader
								class="mt-8"
								[loadingState]="true"
							></app-loader>
						</div>
					}
				</div>
				<div
					class="flex flex-col gap-6"
					[class.hidden]="!addressSelected()"
				>
					<fixify-text-input
						[control]="propertyForm.get('address.unitNumber')!"
						id="unitNumber"
						inputType="text"
						label="Apt, Unit, Building number (if applicable)"
						placeholder="Enter unit number"
						[showErrorMessage]="false"
					></fixify-text-input>
					<fixify-text-input
						[control]="propertyForm.get('address.suburb')!"
						id="suburb"
						inputType="text"
						label="Suburb"
						placeholder="Enter suburb"
						[showErrorMessage]="false"
					></fixify-text-input>
					<fixify-text-input
						[control]="propertyForm.get('address.city')!"
						id="city"
						inputType="text"
						label="City"
						placeholder="Enter city"
						[showErrorMessage]="false"
					></fixify-text-input>
					<fixify-dropdown
						[control]="propertyForm.get('address.province')!"
						[items]="provinces"
						[defaultSelectable]="false"
						defaultText="-- Select Province --"
						label="Province"
					></fixify-dropdown>
					<fixify-text-input
						[control]="propertyForm.get('address.postalCode')!"
						id="postalCode"
						inputType="text"
						label="Postal Code"
						placeholder="Enter postal code"
						[showErrorMessage]="false"
					></fixify-text-input>
					<fixify-text-input
						[control]="propertyForm.get('address.buildingName')!"
						id="buildingName"
						inputType="text"
						label="Building Name (if applicable)"
						placeholder="Enter building name"
						[showErrorMessage]="false"
					></fixify-text-input>
					@if (mapUrl) {
						<div class="max-h-80">
							<label class="input-label">Map</label>
							<img
								[src]="mapUrl"
								class="rounded-md object-contain"
							/>
						</div>
					}
					<fixify-text-input
						[control]="propertyForm.get('address.coordinates')!"
						id="coordinates"
						inputType="text"
						label="Coordinates"
						placeholder="Enter coordinates"
						[showErrorMessage]="false"
					></fixify-text-input>
					<div>
						<label class="input-label">Linked Complex/Estate</label>
						<div
							class="w-full flex items-center gap-2 justify-between"
						>
							@if (linkedEstate()) {
								<div class="flex">
									<p
										class="text-sm text-gray-500 font-normal"
									>
										{{linkedEstate()?.name}}
									</p>

									<div
										class="ml-2 w-5 h-5 p-0.5 bg-primary-100 rounded"
									>
										<fixify-icon
											class="text-primary-900 w-4 h-4"
											name="home-05"
											symbol="filled"
										></fixify-icon>
									</div>
								</div>
							}
							<button
								(click)="linkOrUnlinkEstate()"
								[ngClass]="{
									'btn-secondary': !linkedEstate(),
									'btn-outline border-gray-200':
										linkedEstate()?.name
								}"
								class="small"
							>
								{{
									!linkedEstate()
										? 'Link to complex/estate'
										: 'Unlink'
								}}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="mb-6">
				<p class="text-sm font-normal text-gray-700 mb-4">
					Other Details
				</p>
				<fixify-divider></fixify-divider>
			</div>
			<div class="mb-8">
				<fixify-text-input
					[control]="propertyForm.get('additionalInformation')!"
					label="Additional Information (optional)"
					id="additionalInformation"
					inputType="textarea"
					placeholder="Add a short description of the property"
				></fixify-text-input>
			</div>
			<div class="mb-6">
				<p class="text-sm font-normal text-gray-700 mb-4">
					Landlords/Trustees
				</p>
				<fixify-divider></fixify-divider>
			</div>
			<div formArrayName="landlordTrustees" class="mb-8">
				@for (
					landlordTrusteeForm of allFormGroups('landlordTrustees');
					track landlordTrusteeForm;
					let idx = $index;
					let cnt = $count
				) {
					@if (noLandlordTrusteeSelected(landlordTrusteeForm)) {
						<div class="mb-4">
							<fixify-validation-message
								text="Select an option"
							></fixify-validation-message>
						</div>
					}

					<div
						[formGroup]="landlordTrusteeForm"
						class="flex flex-col gap-6 mb-4"
					>
						<div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
							<fixify-selectable-grid-item
								(click)="
									setLandlordTrustee(
										landlordTrusteeForm,
										'Landlord'
									)
								"
								[selected]="
									landlordTrusteeForm.get('landlordTrustee')
										?.value === 'Landlord'
								"
								[error]="
									noLandlordTrusteeSelected(
										landlordTrusteeForm
									)
								"
							>
								<fixify-icon
									name="user-circle"
									class="mr-3 min-h-5 min-w-5 stroke-2"
								></fixify-icon>
								<p class="text-sm font-medium text-gray-900">
									Landlord
								</p>
							</fixify-selectable-grid-item>
							<fixify-selectable-grid-item
								(click)="
									setLandlordTrustee(
										landlordTrusteeForm,
										'Trustee'
									)
								"
								[selected]="
									landlordTrusteeForm.get('landlordTrustee')
										?.value === 'Trustee'
								"
								[error]="
									noLandlordTrusteeSelected(
										landlordTrusteeForm
									)
								"
							>
								<fixify-icon
									name="user-02"
									class="mr-3 min-h-5 min-w-5 stroke-2"
								></fixify-icon>
								<p class="text-sm font-medium text-gray-900">
									Trustee
								</p>
							</fixify-selectable-grid-item>
						</div>
						@if (
							(landlordTrusteeForm.statusChanges | async) ===
								'INVALID' && showErrors()
						) {
							<div class="mb-[-16px]">
								<fixify-validation-message
									text="Add valid contact information"
								></fixify-validation-message>
							</div>
						}
						<fixify-text-input
							[control]="landlordTrusteeForm.get('name')!"
							id="landlordTrusteeName"
							inputType="text"
							placeholder="Enter full name"
							[showErrorMessage]="false"
						></fixify-text-input>
						<fixify-text-input
							[control]="landlordTrusteeForm.get('email')!"
							id="landlordTrusteeEmail"
							inputType="email"
							placeholder="Enter email address"
							[showErrorMessage]="false"
						></fixify-text-input>
						<fixify-phone-input
							[control]="landlordTrusteeForm.get('phone')!"
							id="landlordTrusteePhone"
							placeholder="Enter phone number"
							[showErrorMessage]="false"
						></fixify-phone-input>
					</div>
					@if (cnt > 1) {
						<div class="mb-6">
							<button
								(click)="removeLandlordTrusteeForm(idx)"
								class="my-0 btn-outline border-0 danger small flex gap-2"
							>
								<fixify-icon
									name="trash-01"
									class="w-4 h-4 text-red-700 stroke-2"
								></fixify-icon>

								Remove
								{{
									landlordTrusteeForm.get('landlordTrustee')
										?.value ?? ''
								}}
							</button>
						</div>
						<fixify-divider
							class="!bg-gray-100 mb-6"
						></fixify-divider>
					}
				}
				<div>
					<button
						(click)="addLandlordTrusteeForm()"
						class="btn-outline small border-gray-200"
					>
						Add another
					</button>
				</div>
			</div>

			<div class="mb-6">
				<p class="text-sm font-normal text-gray-700 mb-4">Occupants</p>
				<fixify-divider></fixify-divider>
			</div>
			<div class="my-6 flex gap-2 items-center">
				<input
					type="checkbox"
					class="checkbox-secondary"
					formControlName="vacant"
				/>
				<p class="leading-none text-sm font-medium">
					This is a vacant property
				</p>
			</div>
			<div formArrayName="occupants" class="mb-8">
				@for (
					occupantForm of allFormGroups('occupants');
					track occupantForm;
					let index = $index;
					let count = $count
				) {
					@if (
						(occupantForm.statusChanges | async) === 'INVALID' &&
						showErrors()
					) {
						<div class="mb-4">
							<fixify-validation-message
								text="Add valid contact information"
							></fixify-validation-message>
						</div>
					}
					<div
						[id]="'occupant-' + index"
						[formGroup]="occupantForm"
						class="flex flex-col gap-6 mb-4"
					>
						<fixify-text-input
							[control]="occupantForm.get('name')!"
							[id]="'occupantName' + index"
							inputType="text"
							placeholder="Enter full name"
							[showErrorMessage]="false"
						></fixify-text-input>
						<fixify-text-input
							[control]="occupantForm.get('email')!"
							[id]="'occupantEmail' + index"
							inputType="email"
							placeholder="Enter email address"
							[showErrorMessage]="false"
						></fixify-text-input>
						<fixify-phone-input
							[control]="occupantForm.get('phone')!"
							[id]="'occupantPhone' + index"
							placeholder="Enter phone number"
							[showErrorMessage]="false"
						></fixify-phone-input>
					</div>
					@if (count > 1) {
						<div class="mb-6">
							<button
								(click)="removeOccupantForm(index)"
								class="my-0 btn-outline border-0 danger small flex gap-2"
							>
								<fixify-icon
									name="trash-01"
									class="w-4 h-4 text-red-700 stroke-2"
								></fixify-icon>

								Remove occupant
							</button>
						</div>
						<fixify-divider
							class="!bg-gray-100 mb-6"
						></fixify-divider>
					}
				}
				@if (!vacant()) {
					<div>
						<button
							(click)="addOccupantForm()"
							class="btn-outline small border-gray-200"
						>
							Add another occupant
						</button>
					</div>
				}
			</div>
			<div class="mb-24">
				<p class="text-sm font-normal text-gray-700 mb-4">
					Managing Agent
				</p>
				<fixify-divider class="mb-6"></fixify-divider>
				<app-alert
					[showAlert]="propertyId() !== null"
					[type]="'grey'"
					class="my-6"
					>Updating the agent will remove this property and all
					related jobs from this agent’s portfolio.</app-alert
				>
				@if (selectedAgent() === null && showErrors()) {
					<div class="mb-4">
						<fixify-validation-message
							text="Select an agent"
						></fixify-validation-message>
					</div>
				}

				<fixify-generic-search-input
					[control]="propertyForm.get('managingAgent')!"
					placeholder="Search for agent ..."
					[genericSearchFunction]="searchAgents"
					label="Assigned Agent"
					[transformer]="genericAgentTransformer"
					(selectedResult)="onSelectedAgent($event)"
				></fixify-generic-search-input>
			</div>
		</div>

		<div id="col-2" class="flex flex-col">
			<div class="flex flex-row gap-1 items-center mb-4">
				<label class="input-label mb-0">Property image</label>
				<fixify-icon
					(click)="openImageGuidelineModal()"
					name="info-circle"
					class="w-4 h-4 text-gray-400 cursor-pointer"
				></fixify-icon>
			</div>
			<fixify-upload
				[url]="this.propertyImageUrl()"
				[(uploading)]="uploadState.uploading"
				[(percentage)]="uploadState.percentage"
				(uploadFile)="uploadMedia($event)"
				(removeFile)="deleteMedia()"
			></fixify-upload>
			<fixify-divider class="my-4"></fixify-divider>
			<div class="flex flex-row gap-1 items-center mb-4">
				<label class="input-label mb-0"
					>Favourite service providers</label
				>
				<fixify-icon
					(click)="openSpFavouritingModal()"
					name="info-circle"
					class="w-4 h-4 text-gray-400 cursor-pointer"
				></fixify-icon>
			</div>
			@if (serviceProviders().length == 0) {
				<img class="self-center mb-4" src="assets/svg/no-users.svg" />
				<p class="text-sm font-medium text-gray-900 self-center mb-1">
					No favourited service providers
				</p>
				<p class="text-sm font-normal text-gray-500 self-center">
					Favouriting makes it easier to assign during jobs.
				</p>
				<div class="flex justify-center mt-4">
					<button
						(click)="openFavouriteServiceProvidersModal()"
						class="btn btn-primary small w-min"
					>
						Add
					</button>
				</div>
			} @else {
				<div class="flex flex-col gap-2">
					@for (sp of serviceProviders(); track $index) {
						<fixify-sp-info-card
							companyName="{{ sp.name }}"
							imageUrl="{{ sp.companyDetails?.companyLogo }}"
							[restrictSize]="false"
							actionText="Remove"
							(actionPress)="removeServiceProvider(sp)"
						></fixify-sp-info-card>
					}
				</div>
				<div class="mt-4">
					<button
						(click)="openFavouriteServiceProvidersModal()"
						class="btn btn-secondary small"
					>
						Add another
					</button>
				</div>
			}
		</div>
	</form>
	}
	
</div>

