@if (!managedFulfilment().job.common) {
	@if (occupants().length) {
		<div>
			<p class="text-lg font-medium text-gray-900 mb-4">Tenant details</p>
			@if (managedFulfilment().jobStatus === 'new') {
				<div class="flex gap-2">
					<fixify-icon
						class="w-5 h-5 text-gray-500"
						name="phone-call-01"
					></fixify-icon>
					<p class="text-base font-normal text-gray-900">
						Details are hidden until you accept this request
					</p>
				</div>
			} @else {
				<div class="flex flex-col gap-4">
					@for (occupant of occupants(); track $index) {
						<fixify-job-user-detail-tile
							[stakeholder]="occupant"
							type="tenant"
						></fixify-job-user-detail-tile>
					}
				</div>
			}
		</div>
		<fixify-divider class="my-8"></fixify-divider>
	}
	@if (landlords().length || trustees().length) {
		<div class="mb-8">
			<p class="text-lg font-medium text-gray-900 mb-4">
				Landlord details
			</p>
			@if (managedFulfilment().jobStatus === 'new') {
				<div class="flex gap-2">
					<fixify-icon
						class="w-5 h-5 text-gray-500"
						name="phone-call-01"
					></fixify-icon>
					<p class="text-base font-normal text-gray-900">
						Details are hidden until you accept this request
					</p>
				</div>
			} @else {
				<div class="flex flex-col gap-4">
					@for (landlord of landlords(); track $index) {
						<fixify-job-user-detail-tile
							[stakeholder]="landlord"
							type="landlord"
						></fixify-job-user-detail-tile>
					}
				</div>
				<div class="flex flex-col gap-4">
					@for (trustee of trustees(); track $index) {
						<fixify-job-user-detail-tile
							[stakeholder]="trustee"
							type="trustee"
						></fixify-job-user-detail-tile>
					}
				</div>
			}
		</div>
		<fixify-divider class="my-8"></fixify-divider>
	}
} @else {
	<div class="mb-8">
		<p class="text-lg font-medium text-gray-900 mb-4">
			Estate manager details
		</p>
		@if (managedFulfilment().jobStatus === 'new') {
			<div class="flex gap-2">
				<fixify-icon
					class="w-5 h-5 text-gray-500"
					name="phone-call-01"
				></fixify-icon>
				<p class="text-base font-normal text-gray-900">
					Details are hidden until you accept this request
				</p>
			</div>
		} @else {
			<div class="flex flex-col gap-4">
				@for (estateManager of estateManagers(); track $index) {
					<fixify-job-user-detail-tile
						[stakeholder]="estateManager"
						type="estate manager"
					></fixify-job-user-detail-tile>
				}
			</div>
		}
	</div>
	<fixify-divider class="my-8"></fixify-divider>
}

