import { Observable } from 'rxjs';
import { UploadedDocuments } from '../../data-access/service-provider/service-provider.types';
import {
	StorageReference,
	getDownloadURL,
	uploadBytesResumable
} from '@angular/fire/storage';

export function uploadMedia(
	file: File,
	storageRef: StorageReference
): Observable<UploadedDocuments> {
	const uploadTask = uploadBytesResumable(storageRef, file);

	return new Observable<UploadedDocuments>(observer => {
		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				observer.next({
					url: '',
					percentage: Math.round(progress),
					fileName: file.name
				});
			},
			error => {
				console.error('Upload failed:', error);
				observer.error(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
					observer.next({
						url: downloadURL,
						percentage: 100,
						fileName: file.name
					});
					observer.complete();
				});
			}
		);
	});
}
