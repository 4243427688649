import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
const phoneLib = PhoneNumberUtil.getInstance();

/**
 * Formats a given phone number into its international format.
 *
 * This function takes a phone number and its associated country code, then formats
 * the phone number into the international format using the `phoneLib` library. Spaces
 * in the formatted phone number are removed. If the phone number cannot be formatted,
 * the function logs the error and returns `null`.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @param {string} countryCode - The ISO 3166-1 alpha-2 country code associated with the phone number.
 * @returns {string | null} The internationally formatted phone number without spaces, or `null` if formatting fails.
 */
export const internationalisePhoneNumber = (
	phoneNumber: string,
	countryCode: string
): string | null => {
	try {
		const internationallyFormattedNumber = phoneLib
			.format(
				phoneLib.parse(phoneNumber, countryCode),
				PhoneNumberFormat.INTERNATIONAL
			)
			.replace(/\s/g, '');

		return internationallyFormattedNumber;
	} catch (error) {
		console.error(error);
	}

	return null;
};

/**
 * Formats a given phone number into its national format.
 *
 * This function takes a phone number and its associated country code, then formats
 * the phone number into the national format using the `phoneLib` library. Spaces in
 * the formatted phone number are removed. If the phone number cannot be formatted, the
 * function logs the error and returns `null`.
 *
 * @param {string} phoneNumber - The phone number to be formatted.
 * @param {string} countryCode - The ISO 3166-1 alpha-2 country code associated with the phone number.
 * @returns {string | null} The nationally formatted phone number without spaces, or `null` if formatting fails.
 */
export const nationalPhoneNumber = (
	phoneNumber: string,
	countryCode: string
): string | null => {
	try {
		const nationallyFormattedNumber = phoneLib
			.format(
				phoneLib.parse(phoneNumber, countryCode),
				PhoneNumberFormat.NATIONAL
			)
			.replace(/\s/g, '');
		return nationallyFormattedNumber;
	} catch (error) {
		console.error(error);
	}

	return null;
};

/**
 * Extracts the country code from a given phone number.
 *
 * This function takes a phone number and uses the `phoneLib` library to parse the
 * number and extract the country code. If the country code cannot be extracted, the
 * function logs the error and returns `null`.
 *
 * @param {string} phoneNumber - The phone number from which to extract the country code.
 * @returns {string | null} The ISO 3166-1 alpha-2 country code extracted from the phone number, or `null` if extraction fails.
 */
export const getCountryCodeFromNumber = (
	phoneNumber: string | undefined
): string | null => {
	try {
		if (!phoneNumber) return null;
		const number = phoneLib.parse(phoneNumber);
		return phoneLib.getRegionCodeForNumber(number) ?? null;
	} catch (error) {
		console.error(error);
	}

	return null;
};
