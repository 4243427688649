import { Component, model } from '@angular/core';
import { IconItemComponent } from '../../../../shared/ui/icon-item/icon-item.component';
import { IconComponent } from '../../../../shared/ui/icon/icon.component';
import { ModalService } from '../../../../shared/ui/modals/modal.service';
import { JobDateTimeComponent } from '../modals/job-date-time/job-date-time.component';
import { DatePipe } from '@angular/common';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited
} from '../../../../shared/data-access/service-provider/service-provider.types';
import { ServiceProviderJobsService } from '../../../../shared/data-access/service-provider/service-provider.jobs.service';
import { ToastService } from '../../../../shared/data-access/toast.service';

@Component({
	selector: 'fixify-service-provider-job-overview',
	standalone: true,
	imports: [IconItemComponent, IconComponent, DatePipe],
	templateUrl: './service-provider-job-overview.component.html',
	styleUrl: './service-provider-job-overview.component.css'
})
export class ServiceProviderJobOverviewComponent {
	managedFulfilment = model.required<
		ManagedFulfilmentDetails | ManagedFulfilmentLimited
	>();

	constructor(
		private modalService: ModalService,
		private serviceProviderJobsService: ServiceProviderJobsService,
		private toastService: ToastService
	) {}

	jobDateTime() {
		this.modalService
			.showModal(JobDateTimeComponent, {
				title: 'Job date and time'
			})
			.then(date => {
				if (date) {
					const fulfilmentId = this.managedFulfilment()?.id;
					this.serviceProviderJobsService
						.setJobDate(fulfilmentId, date.getTime())
						.subscribe({
							next: data => {
								const { scheduledJobDate } = data.body;
								this.managedFulfilment.update(job => {
									job.scheduledJobDate = scheduledJobDate;
									return job;
								});

								this.toastService.add(
									'Job date set 🎉',
									5000,
									'success'
								);
							}
						});
				}
			});
	}

	siteVisitDateTime() {
		this.modalService
			.showModal(JobDateTimeComponent, {
				title: 'Site visit date and time'
			})
			.then(date => {
				if (date) {
					const fulfilmentId = this.managedFulfilment()?.id;
					this.serviceProviderJobsService
						.setSiteVisitDate(fulfilmentId, date.getTime())
						.subscribe({
							next: data => {
								const { scheduledQuoteDate } = data.body;
								this.managedFulfilment.update(job => {
									job.scheduledQuoteDate = scheduledQuoteDate;
									return job;
								});

								this.toastService.add(
									'Site Visit date set 🎉',
									5000,
									'success'
								);
							}
						});
				}
			});
	}
}
