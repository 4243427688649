<div>
	<div class="flex gap-1 items-center">
		<p class="text-xs font-light text-gray-900">
			{{ stars() ? stars() + '/5' : '' }}
		</p>
		@for (star of starItemArray(); track $index) {
			<fixify-icon
				(click)="onStarClick($index)"
				class="w-6 h-6 cursor-pointer"
				[ngClass]="{
					'text-yellow-300': star,
					'text-gray-200': !star
				}"
				name="star-01"
				symbol="filled"
			></fixify-icon>
		}
	</div>
</div>

