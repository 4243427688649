<div class="w-full rounded bg-gray-100 p-8">
	<div class="flex gap-2 items-center mb-1">
		<p class="text-xl text-gray-900 font-medium">{{ title() }}</p>
		<fixify-icon
			class="w-5 h-5 text-red-500 stroke-2"
			name="x-circle"
		></fixify-icon>
	</div>
	@if (rejectedDate()) {
		<p class="text-base text-gray-700 font-normal mb-4">
			Cancelled on {{ rejectedDate() | date: 'dd MMM, yyyy' }} at
			{{ rejectedDate() | date: 'HH:mm' }}
		</p>
	}
	<p class="mb-2 text-sm text-gray-900 font-medium">Reason</p>
	<p class="mb-2 text-base text-gray-900 font-normal">
		{{ reason() }}
	</p>
</div>

