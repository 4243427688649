import { Component } from '@angular/core';
import { ModalComponent } from '../../../../../../shared/ui/modals/modal.service';
import { ManagedServiceProvider } from '../../../../../../shared/data-access/agency/agency.types';
import { ITestRating } from '../../to-do/to-do.component';
import { TextInputComponent } from '../../../../../../shared/ui/text-input/text-input.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { IconComponent } from '../../../../../../shared/ui/icon/icon.component';
import { ModalCardComponent } from '../../../../../../shared/ui/modal-card/modal-card.component';
import { AvatarComponent } from '../../../../../../shared/ui/avatar/avatar.component';
import { InteractiveRatingStarsComponent } from '../../interactive-rating-stars/interactive-rating-stars.component';

@Component({
	selector: 'fixify-rate-service-provider-modal',
	standalone: true,
	imports: [
		TextInputComponent,
		ReactiveFormsModule,
		IconComponent,
		ModalCardComponent,
		AvatarComponent,
		InteractiveRatingStarsComponent
	],
	templateUrl: './rate-service-provider-modal.component.html',
	styleUrl: './rate-service-provider-modal.component.css'
})
export class RateServiceProviderModalComponent extends ModalComponent<
	ManagedServiceProvider,
	ITestRating
> {
	reviewForm: FormGroup;

	constructor() {
		super();

		this.reviewForm = new FormGroup({
			qualityOfWork: new FormControl(null),
			qualityOfQuote: new FormControl(null),
			communication: new FormControl(null),
			reliability: new FormControl(null),
			review: new FormControl('', [Validators.required])
		});
	}

	submit() {
		if (this.reviewForm.valid) {
			const result: ITestRating = {
				communication: this.reviewForm.value.communication,
				qualityOfQuote: this.reviewForm.value.qualityOfQuote,
				qualityOfWork: this.reviewForm.value.qualityOfWork,
				reliability: this.reviewForm.value.reliability
			};
			this.close(result);
		} else {
			this.reviewForm.markAllAsTouched();
		}
	}
}
