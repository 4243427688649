<div class="mb-8">
	<p class="text-lg font-medium text-gray-900 mb-4">Location</p>
	<p class="text-base font-normal text-gray-900">
		{{
			namedAddress()
		}}
	</p>
	@if (mapUrl()) {
		<div class="max-h-80 mt-4">
			<img [src]="mapUrl()" class="rounded-md object-contain" />
		</div>
	}
	@if (managedFulfilment().jobStatus === 'new') {
		<p class="text-sm font-light text-gray-500 mt-4">
			Note: The exact address will be provided after accepting this job
		</p>
	}
</div>

