import { Component, signal } from '@angular/core';
import { MenuItem, menuItems } from '../../utils/menu-items/menu-items';
import {
	Event,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RouterLink,
	RouterLinkActive
} from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { initDropdowns } from 'flowbite';

@Component({
	selector: 'fixify-bottom-nav',
	standalone: true,
	imports: [RouterLink, RouterLinkActive, IconComponent],
	templateUrl: './bottom-nav.component.html',
	styleUrl: './bottom-nav.component.css'
})
export class BottomNavComponent {
	menuItems: MenuItem[] = [];
	userType = signal<'service-providers' | 'agency'>('service-providers');

	constructor(private router: Router) {}

	ngAfterViewInit() {
		initDropdowns();
	}

	ngOnInit() {
		const urlPart = this.router.url.split('/')[1];
		if (urlPart === 'agency') {
			this.userType.set('agency');
		}

		this.menuItems = menuItems[this.userType()];

		this.handleNavigation(this.router.url);

		// Subscribe to future navigation events
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				this.handleNavigation(event.url);
			}

			if (event instanceof NavigationEnd) {
				// Hide loading indicator
			}

			if (event instanceof NavigationError) {
				console.log(event.error);
			}
		});
	}

	itemsToShow() {
		return this.menuItems.filter(item => item.showInBottomNav);
	}

	itemsToHide() {
		return this.menuItems.filter(item => !item.showInBottomNav);
	}

	private handleNavigation(url: string) {
		this.menuItems.forEach(item => {
			item.active = false;
			if (url.startsWith(item.route)) {
				item.active = true;
			}
		});
	}
}
