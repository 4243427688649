<div class="relative z-50" role="dialog" aria-modal="true">
	<div
		class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
		aria-hidden="true"
	></div>

	<div
		(click)="onBackdropClicked()"
		class="fixed inset-0 z-10 w-screen overflow-y-auto"
	>
		<div class="flex min-h-full justify-center p-4 items-center">
			<div
				(click)="onModalClicked($event)"
				[ngClass]="{
					'max-w-lg': size() === 'lg',
					'max-w-md': size() === 'md',
					'max-w-sm': size() === 'sm',
					'max-w-xl': size() === 'xl',
					'max-w-2xl': size() === '2xl',
					'max-w-4xl': size() === '4xl'
				}"
				class="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all my-8 w-full p-8"
			>
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>

