<div
	(click)="select()"
	[ngClass]="{
		'border-primary-500': selected(),
		'!border-red-700': borderError()
	}"
	class="w-full h-full rounded-lg border-2 border-gray-100 p-6 cursor-pointer"
>
	<div class="flex justify-between">
		<img class="mb-2" [src]="imgUrl()" />
		<div class="flex items-center mb-4">
			<input
				[value]="value()"
				[checked]="selected()"
				type="radio"
				class="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 focus:ring-transparent"
			/>
		</div>
	</div>
	<p
		class="{{
			titleSizeOverride()
		}} text-2xl leading-normal font-medium text-gray-900"
	>
		{{ text() }}
	</p>
	<p
		[ngClass]="{ 'mb-1': subText() != '' }"
		class="text-sm font-normal text-gray-500"
	>
		{{ subText() }}
	</p>
</div>

