import { Component, computed, model, signal } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { ManagedProperty } from '../../../../../shared/data-access/agency/agency.types';
import { PropertyTableComponent } from '../../../../properties/ui/tables/property-table/property-table.component';
import { Router } from '@angular/router';

@Component({
	selector: 'fixify-select-job-address',
	standalone: true,
	imports: [IconComponent, PropertyTableComponent],
	templateUrl: './select-job-address.component.html',
	styleUrl: './select-job-address.component.css'
})
export class SelectJobAddressComponent {
	selectedProperties = signal<Array<ManagedProperty>>([]);
	singleProperty = model<Pick<
		ManagedProperty,
		'id' | 'estateId' | 'estateName' | 'address'
	> | null>(null);
	canContinue = computed(() => this.selectedProperties().length > 0);

	constructor(private router: Router) {}

	continue() {
		this.singleProperty.set(this.selectedProperties().pop() ?? null);
	}

	goBack() {
		this.router.navigate(['/agency/jobs']);
	}

	selectedPropertiesChange(properties: Array<ManagedProperty>) {
		this.selectedProperties.set(properties);
	}
}
