<fixify-modal size="md">
	<p class="text-xl font-semibold text-gray-900 mb-0.5">Reject quote</p>
	<p class="text-base font-normal text-gray-700 mb-8">
		Provide a reason for rejection and whether to assign a new service
		provider or cancel the job.
	</p>
	<div [formGroup]="reasonFormGroup" class="mb-4">
		<fixify-validation-message
			[class.hidden]="
				reasonFormGroup.controls['reason'].valid ||
				reasonFormGroup.controls['reason'].pristine
			"
			text="Enter a reason to continue"
		>
		</fixify-validation-message>
		<fixify-text-input
			[control]="reasonFormGroup.controls['reason']"
			inputType="textarea"
			placeholder="Enter reason"
			label="Reason for rejection"
			[showErrorMessage]="false"
		></fixify-text-input>
	</div>
	<div class="mb-6">
		<p class="text-sm text-gray-900 font-medium mb-2">
			Quick Actions - What would you like to do next?
		</p>
		<div class="flex gap-2">
			@for (option of options; track $index) {
				<div
					[ngClass]="{
						'border-primary-500':
							option === reasonFormGroup.controls['option'].value
					}"
					(click)="selectOption(option)"
					class="flex flex-1 gap-3 items-center p-4 rounded-lg border-2 border-gray-100 cursor-pointer"
				>
					<fixify-icon
						class="w-5 h-5 text-gray-900 stroke-2"
						[name]="option.iconName"
					></fixify-icon>
					<p class="text-sm font-medium text-gray-900">
						{{ option.name }}
					</p>
				</div>
			}
		</div>
	</div>
	<div class="flex justify-end gap-2">
		<button class="btn btn-outline border-gray-200" (click)="close()">
			Cancel
		</button>
		<button
			[disabled]="reasonFormGroup.invalid"
			class="btn btn-primary danger"
			(click)="reject()"
		>
			Reject quote{{ optionText() }}
		</button>
	</div>
</fixify-modal>

