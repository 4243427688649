<div class="px-8 flex flex-col">
	<div (click)="goBack()" class="flex gap-2 items-center cursor-pointer">
		<fixify-icon
			class="w-4 h-4 text-gray-800"
			name="arrow-left"
		></fixify-icon>
		<p class="text-sm font-medium text-gray-800">Back</p>
	</div>
	<div class="my-6">
		<p class="text-3xl font-medium text-gray-900">
			Select an address to continue
		</p>
	</div>
	<div>
		<fixify-agency-property-table
			[singleSelect]="true"
			(selectedPropertiesChange)="selectedPropertiesChange($event)"
		></fixify-agency-property-table>
	</div>
	<div class="flex gap-2 justify-center mt-4 mb-8 items-end">
		<button class="w-1/4 btn btn-outline">Cancel</button>
		<button
			(click)="continue()"
			[disabled]="!canContinue()"
			class="w-1/4 btn btn-primary"
		>
			Continue
		</button>
	</div>
</div>

