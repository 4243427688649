import { Component, computed, input } from '@angular/core';

export type StatusPillColor =
	| 'green'
	| 'primary'
	| 'bright-orange'
	| 'red'
	| 'yellow'
	| 'purple'
	| 'dark-gray'
	| '';
@Component({
	selector: 'fixify-status-pill',
	standalone: true,
	imports: [],
	templateUrl: './status-pill.component.html',
	styleUrl: './status-pill.component.css'
})
export class StatusPillComponent {
	color = input<StatusPillColor>('');
	classText = computed(() => {
		switch (this.color()) {
			case 'green':
				return 'bg-green-100 text-green-800';
			case 'primary':
				return 'bg-primary-100 text-primary-800';
			case 'bright-orange':
				return 'bg-orange-500 text-white';
			case 'red':
				return 'bg-red-100 text-red-800';
			case 'yellow':
				return 'bg-yellow-100 text-yellow-800';
			case 'purple':
				return 'bg-purple-100 text-purple-800';
			case 'dark-gray':
				return 'bg-gray-500 text-white';
			default:
				return 'bg-gray-100 text-gray-800';
		}
	});
}
