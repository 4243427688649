import { Component } from '@angular/core';
import { TextInputComponent } from '../../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../../shared/ui/icon/icon.component';
import { ModalCardComponent } from '../../../../../../shared/ui/modal-card/modal-card.component';
import { ValidationMessageComponent } from '../../../../../../creator-portal/ui/validation-message/validation-message.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { ModalComponent } from '../../../../../../shared/ui/modals/modal.service';
import { NgClass } from '@angular/common';
import { titleCase } from '../../../../../../shared/utils/string-manipulation';
import { ToastService } from '../../../../../../shared/data-access/toast.service';
import { AgencyJobsService } from '../../../../../../shared/data-access/agency/agency.jobs.service';
import {
	Document,
	DocumentView,
	UploadedDocument
} from '../../../../../../shared/data-access/agency/agency.types';

type IOptionName = 'Assign new service provider' | 'Cancel job';

interface IOption {
	name: IOptionName;
	iconName: string;
}

@Component({
	selector: 'app-reject-quote-modal',
	standalone: true,
	imports: [
		TextInputComponent,
		IconComponent,
		NgClass,
		ModalCardComponent,
		ValidationMessageComponent,
		ReactiveFormsModule
	],
	templateUrl: './reject-quote-modal.component.html',
	styleUrl: './reject-quote-modal.component.css'
})
export class RejectQuoteModalComponent extends ModalComponent<
	{ jobId: string; doc: DocumentView },
	{ quote: Partial<Document>; option: IOptionName | null }
> {
	reasonFormGroup: FormGroup;

	options: Array<IOption> = [
		{
			name: 'Assign new service provider',
			iconName: 'users-plus'
		},
		{
			name: 'Cancel job',
			iconName: 'x-circle'
		}
	];

	constructor(
		private toastService: ToastService,
		private agencyJobsService: AgencyJobsService
	) {
		super();
		this.reasonFormGroup = new FormGroup({
			reason: new FormControl('', [Validators.required]),
			option: new FormControl<IOption | null>(null, [])
		});
	}

	optionText() {
		const optionName: string | null =
			this.reasonFormGroup.controls['option'].value?.name ?? null;

		if (optionName) {
			return ` & ${titleCase(optionName.split(' ')[0])}`;
		}

		return '';
	}

	reject() {
		const reason = this.reasonFormGroup.controls['reason'].value;

		if (!reason) {
			this.toastService.add('Reason required', 3000, 'error');
			return;
		}

		const doc = this.data?.doc as DocumentView;

		if (!doc.createdAt || !doc.serviceProviderId || !doc.name) {
			this.toastService.add('Could not identify quote', 3000, 'error');
			return;
		}

		const quoteDoc: UploadedDocument = {
			date: doc.createdAt,
			name: doc.name,
			serviceProviderId: doc.serviceProviderId,
			reason: reason,
			url: doc.url,
			status: 'reject'
		};

		this.agencyJobsService
			.manageQuote(this.data?.jobId as string, quoteDoc)
			.subscribe({
				next: data => {
					const quote = data.body.quote as Partial<Document>;
					this.close({
						quote,
						option:
							this.reasonFormGroup.controls['option']?.value
								?.name ?? null
					});
				},
				error: error => {
					console.error(error);
				}
			});
	}

	selectOption(option: IOption) {
		if (this.reasonFormGroup.controls['option'].value == option) {
			this.reasonFormGroup.controls['option'].setValue(null);
		} else {
			this.reasonFormGroup.controls['option'].setValue(option);
		}
	}
}
