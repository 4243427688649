import { Component, signal } from '@angular/core';
import { NewJobComponent } from './ui/new-job/new-job.component';
import { SelectJobAddressComponent } from './ui/select-job-address/select-job-address.component';
import {
	ManagedJobs,
	ManagedProperty
} from '../../../shared/data-access/agency/agency.types';
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyJobsService } from '../../../shared/data-access/agency/agency.jobs.service';
import { ToastService } from '../../../shared/data-access/toast.service';
import { LoaderComponent } from '../../../shared/ui/loader/loader.component';

@Component({
	selector: 'fixify-agency-create-job',
	standalone: true,
	imports: [NewJobComponent, SelectJobAddressComponent, LoaderComponent],
	templateUrl: './create-job.component.html',
	styleUrl: './create-job.component.css'
})
export class CreateJobComponent {
	selectedProperty = signal<Pick<
		ManagedProperty,
		'id' | 'estateName' | 'estateId' | 'address' | 'imageUrl'
	> | null>(null);
	job = signal<ManagedJobs | null>(null);
	fetchingJobDetails = true;

	queryParamPropertyId: string | null = null;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private agencyJobsService: AgencyJobsService,
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		const { jobId } = this.route.snapshot.params;

		if (jobId) {
			this.agencyJobsService.fetchJob(jobId, true).subscribe({
				next: data => {
					const job = data.body as ManagedJobs;
					if (job?.status != 'new') {
						this.router.navigate([`/agency/jobs/track/${jobId}`]);
					}
					if (job?.property.status == 'pending') {
						const propertyId = job?.property.id;
						this.toastService.add(
							'Property submitted through creator portal, details require confirmation',
							8000,
							'info'
						);
						this.router.navigate(
							[`/agency/properties/edit/${propertyId}`],
							{ queryParams: { jobId } }
						);
					} else {
						this.selectedProperty.set({ ...job.property });
						this.job.set({ ...job });
						this.fetchingJobDetails = false;
					}
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
					this.router.navigate(['/agency/jobs']);
				}
			});
		} else {
			this.fetchingJobDetails = false;
		}
	}
}
