import { Component } from '@angular/core';
import { TableColumnDirective } from '../../../../../shared/ui/table/directives/table-column.directive';
import { TableComponent } from '../../../../../shared/ui/table/table.component';
import { PaginationComponent } from '../../../../../shared/ui/table/pagination/pagination.component';
import { StatusPillComponent } from '../../../../../shared/ui/status-pill/status-pill.component';
import {
	ITabItem,
	TabsComponent
} from '../../../../../shared/ui/tabs/tabs.component';
import { TextInputComponent } from '../../../../../shared/ui/text-input/text-input.component';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { ManagedFulfilment } from '../../../../../shared/data-access/service-provider/service-provider.types';
import { ServiceProviderJobsService } from '../../../../../shared/data-access/service-provider/service-provider.jobs.service';
import { Router } from '@angular/router';
import { JobStatus } from '../../../../../shared/data-access/agency/agency.types';

interface StatusCount {
	all: number;
	new: number;
	ongoing: number;
	completed: number;
	cancelled: number;
}
@Component({
	selector: 'fixify-service-provider-jobs-table',
	standalone: true,
	imports: [
		TableColumnDirective,
		TableComponent,
		PaginationComponent,
		// PropertyAvatarComponent,
		StatusPillComponent,
		TabsComponent,
		TextInputComponent,
		IconComponent,
		TitleCasePipe,
		ReactiveFormsModule,
		DatePipe
	],
	templateUrl: './service-provider-jobs-table.component.html',
	styleUrl: './service-provider-jobs-table.component.css'
})
export class ServiceProviderJobsTableComponent {
	itemsPerPage = 10;
	currentPage = 1;
	totalCount = 0;
	totalPages = 0;
	filterForm: FormGroup;

	private destroy$ = new Subject<void>();

	barFilters: Array<ITabItem> = [
		{
			label: 'all',
			value: '-',
			active: true
		},
		{
			label: 'new',
			value: '-',
			active: false
		},
		{
			label: 'ongoing',
			value: '-',
			active: false
		},
		{
			label: 'completed',
			value: '-',
			active: false
		},
		{
			label: 'claimed',
			value: '-',
			active: false
		},
		{
			label: 'rejected',
			value: '-',
			active: false
		},
		{
			label: 'cancelled',
			value: '-',
			active: false
		}
	];

	jobs: Array<ManagedFulfilment> | null = null;

	constructor(
		private serviceProviderJobsService: ServiceProviderJobsService,
		private router: Router
	) {
		this.filterForm = new FormGroup({
			search: new FormControl(''),
			status: new FormControl<JobStatus | 'all'>('all')
		});

		this.filterForm.valueChanges
			.pipe(debounceTime(250), takeUntil(this.destroy$))
			.subscribe({
				next: value => {
					this.serviceProviderJobsService.fetchJobs({
						search: value.search,
						filter: value.status ? { status: value.status } : {}
					});
				}
			});

		this.serviceProviderJobsService
			.fulfilments()
			.pipe(takeUntil(this.destroy$))
			.subscribe(fulfilments => {
				this.jobs =
					fulfilments?.result.map(job => {
						return {
							...job,
							selected: false
						};
					}) ?? null;
				this.totalPages = fulfilments?.totalPages ?? 0;
				this.currentPage = fulfilments?.page ?? 0;
				this.totalCount = fulfilments?.totalCount ?? 0;
				const filterMetaData = fulfilments?.filterMetaData?.[
					'statusCount'
				] as StatusCount;
				if (filterMetaData) {
					this.barFilters = Object.entries(filterMetaData).map(
						([key, value]) => ({
							label: key,
							value: value.toString(),
							active:
								key ===
								(this.filterForm.get('status')?.value ?? 'all')
						})
					);
				}
			});
	}

	getColor(status: string) {
		switch (status) {
			case 'new':
				return 'green';
			case 'ongoing':
				return 'primary';
			case 'completed':
				return '';
			case 'cancelled':
				return 'red';
			case 'rejected':
				return 'red';
			case 'archived':
				return 'yellow';
			default:
				return '';
		}
	}

	onPageChange(page: number) {
		this.currentPage = page;
		this.currentPage = page;
		this.jobs = null;

		const search = this.filterForm.get('search')?.value;
		const status = this.filterForm.get('status')?.value;
		let filter = {};
		if (status != 'all') filter = { status };
		this.serviceProviderJobsService.fetchJobs({ page, search, filter });
	}

	dateAndTime(fulfilment: ManagedFulfilment) {
		return (
			fulfilment.scheduledJobDate ??
			fulfilment.scheduledQuoteDate ??
			fulfilment.createdOn
		);
	}

	onRowClicked(job: ManagedFulfilment) {
		this.router.navigate(['/service-provider/jobs/track', job.id]);
	}

	onTabItemChange(item: ITabItem) {
		this.filterForm
			.get('status')
			?.setValue(item.label === 'all' ? 'all' : item.label);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
