import {
	Component,
	computed,
	inject,
	input,
	output,
	signal
} from '@angular/core';
import { DividerComponent } from '../../../shared/ui/divider/divider.component';
import { TextInputComponent } from '../../../shared/ui/text-input/text-input.component';
import {
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { NgClass } from '@angular/common';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import {
	catchError,
	forkJoin,
	Observable,
	of,
	Subject,
	takeUntil,
	tap
} from 'rxjs';
import CountryList, { Country } from 'country-list-with-dial-code-and-flag';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { RequestCardComponent } from '../request-card/request-card.component';
import {
	Category,
	PropertyDetails
} from '../../service/creator-portal.service';
import {
	UploadState,
	UploadedDocuments
} from '../../../shared/data-access/service-provider/service-provider.types';
import { ref, Storage, deleteObject } from '@angular/fire/storage';
import {
	createAJobRequest,
	PropertyType,
	RequestorType
} from '../../../shared/data-access/residential/residential.types';
import { emailValidator } from '../../../shared/validators';
import { uploadMedia } from '../../../shared/utils/media/upload';
import { ToastService } from '../../../shared/data-access/toast.service';

interface Responsibility {
	name: RequestorType;
	icon: string;
}

const PHONE_LIB = PhoneNumberUtil.getInstance();
const DEFAULT_COUNTRY = 'ZA';

@Component({
	selector: 'fixify-new-job',
	standalone: true,
	imports: [
		DividerComponent,
		DividerComponent,
		TextInputComponent,
		ReactiveFormsModule,
		NgClass,
		ValidationMessageComponent,
		RequestCardComponent
	],
	templateUrl: './new-job.component.html',
	styleUrl: './new-job.component.css'
})
export class NewJobComponent {
	[x: string]: unknown;
	responsibilities: Array<Responsibility> = [
		{
			name: RequestorType.OCCUPANT,
			icon: 'user-square'
		},
		{
			name: RequestorType.LANDLORD,
			icon: 'user-circle'
		},
		{
			name: RequestorType.TRUSTEE,
			icon: 'users-dark'
		},
		{
			name: RequestorType.OTHER,
			icon: 'user-edit-dark'
		}
	];

	linkedProperty = input.required<PropertyDetails>();
	propertyId = input.required<string | null>();
	agencyId = input.required<string | null>();
	canInterchangeProperty = input.required<boolean>();
	countries: Array<Country> = [];
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	selectedCountry = CountryList.findOneByCountryCode(DEFAULT_COUNTRY)!;
	detailsForm: FormGroup;
	occupantForm: FormGroup;
	primaryContactForm: FormGroup;
	images = signal<Array<UploadedDocuments>>([]);
	uploadState = signal<Record<string, UploadState>>({});
	uploadStateArray = computed(() => Object.values(this.uploadState()));
	selectedCategory = signal<Category | null>(null);
	propertyType = signal<PropertyType | null>(null);
	onEditAddress = output<boolean>();
	createJob = output<createAJobRequest>();
	personalResponsibility = signal<Responsibility | null>(null);
	missingImages = signal<boolean>(false);
	missingDetails = signal<boolean>(false);
	missingOccupant = signal<boolean>(false);
	missingPrimaryContact = signal<boolean>(false);
	missingCategory = signal<boolean>(false);
	missingResponsibility = signal<boolean>(false);
	missingPropertyType = signal<boolean>(false);
	canSubmit = computed(() => {
		const invalidPropertyType =
			this.missingPropertyType() ||
			this.linkedProperty().estateId != null;
		return (
			// !this.missingImages() &&
			!this.missingDetails() &&
			!this.missingOccupant() &&
			!this.missingCategory() &&
			!this.missingResponsibility() &&
			!invalidPropertyType
		);
	});
	notATenant = computed(() => {
		return (
			this.personalResponsibility()?.name !== RequestorType.OCCUPANT &&
			this.personalResponsibility()
		);
	});
	showReference = computed(() => {
		return this.personalResponsibility()?.name === 'other';
	});

	topCategories: Array<Category> = [];
	destroy$ = new Subject<void>();

	uploadObservables = signal(Array<Observable<UploadedDocuments | null>>());
	storage = inject(Storage);
	PropertyType = PropertyType;

	constructor(private toastService: ToastService) {
		this.primaryContactForm = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [Validators.required, emailValidator]),
			phone: new FormControl('', [Validators.required]),
			country: new FormControl(this.selectedCountry.code, [
				Validators.required
			]),
			reference: new FormControl({ value: '', disabled: true }, [
				Validators.required
			])
		});

		this.detailsForm = new FormGroup({
			details: new FormControl('', Validators.required),
			extra: new FormControl(''),
			landmark: new FormControl({ value: '', disabled: true })
		});

		this.occupantForm = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl('', [Validators.required, emailValidator]),
			country: new FormControl(this.selectedCountry.code, [
				Validators.required
			]),
			phone: new FormControl('', [Validators.required]),
			isVacant: new FormControl({ value: false, disabled: true })
		});

		this.detailsForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(status => {
				if (status === 'VALID') {
					this.missingDetails.set(false);
				}
			});

		this.occupantForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(status => {
				if (status === 'VALID') {
					this.missingOccupant.set(false);
				}
			});

		this.primaryContactForm.statusChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(status => {
				if (status === 'VALID') {
					this.missingPrimaryContact.set(false);
				}
			});
	}

	ngOnInit() {
		if (this.linkedProperty().estateName == null) {
			this.changePropertyType(PropertyType.PRIVATE);
		}
		this.countries = CountryList.getAll();

		this.occupantForm.controls['country'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((countryCode: string) => {
				this.selectedCountry =
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					CountryList.findOneByCountryCode(countryCode)!;

				this.occupantForm.controls['phone'].updateValueAndValidity();
			});

		this.occupantForm.controls['phone'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((phone: string) => {
				let valid = true;
				try {
					valid = PHONE_LIB.isValidNumberForRegion(
						PHONE_LIB.parse(phone, this.selectedCountry.code),
						this.selectedCountry.code
					);
				} catch (error) {
					valid = false;
				}

				if (!valid) {
					this.occupantForm.controls['phone'].setErrors({
						invalid: true
					});
				} else {
					this.occupantForm.controls['phone'].setErrors(null);
				}
			});

		this.occupantForm.controls['isVacant'].setValue(
			this.linkedProperty().vacant
		);

		this.occupantForm.controls['isVacant'].valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe((isVacant: boolean) => {
				this.occupantForm.controls['name'].reset();
				this.occupantForm.controls['email'].reset();
				this.occupantForm.controls['phone'].reset();
				if (isVacant) {
					this.occupantForm.controls['name'].disable();
					this.occupantForm.controls['email'].disable();
					this.occupantForm.controls['phone'].disable();
				} else {
					this.occupantForm.controls['name'].enable();
					this.occupantForm.controls['email'].enable();
					this.occupantForm.controls['phone'].enable();
				}
			});

		if (
			this.linkedProperty().landlords ||
			this.linkedProperty().trustees ||
			this.linkedProperty().occupants
		) {
			const name =
				this.linkedProperty().landlords?.fullName ||
				this.linkedProperty().trustees?.fullName ||
				this.linkedProperty().occupants?.fullName;
			const email =
				this.linkedProperty().landlords?.email ||
				this.linkedProperty().trustees?.email ||
				this.linkedProperty().occupants?.email;
			const phone =
				this.linkedProperty().landlords?.mobileNumber ||
				this.linkedProperty().trustees?.mobileNumber ||
				this.linkedProperty().occupants?.mobileNumber;

			this.primaryContactForm.controls['name'].setValue(name);
			this.primaryContactForm.controls['email'].setValue(email);
			this.primaryContactForm.controls['phone'].setValue(phone);
		}
	}

	selectFilesFromDevice() {
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = 'image/*';
		input.multiple = true;
		input.click();

		input.onchange = () => {
			const files = Array.from(input.files ?? []);

			const uploads = files.map(file => {
				const fileSrcUrl = URL.createObjectURL(file);
				// Initialize state
				this.uploadState.update(state => ({
					...state,
					[file.name]: {
						fileName: file.name,
						progress: 0,
						status: 'pending',
						fileSrc: fileSrcUrl
					}
				}));

				if (!this.agencyId()) {
					this.toastService.add(
						`Couldn't identify agency, please reload.`,
						5000,
						'error'
					);
					return;
				}
				const filePath = `residentialProperties/${this.agencyId()}/properties/${this.propertyId()}/${file.name}`;
				const storageRef = ref(this.storage, filePath);
				return uploadMedia(file, storageRef).pipe(
					tap(progress => {
						this.uploadState.update(state => ({
							...state,
							[file.name]: {
								...state[file.name],
								progress: progress.percentage,
								status: progress.url ? 'complete' : 'uploading',
								url: progress.url,
								fileSrc: fileSrcUrl
							}
						}));

						if (progress.url) {
							this.images.set([
								...this.images(),
								{
									url: progress.url,
									percentage: progress.percentage,
									fileName: file.name
								}
							]);
						}
					}),
					catchError(error => {
						this.uploadState.update(state => ({
							...state,
							[file.name]: {
								...state[file.name],
								status: 'error',
								error: error.message
							}
						}));
						return of(null);
					})
				);
			});

			forkJoin(uploads).pipe(takeUntil(this.destroy$)).subscribe();
		};
	}

	async deleteMedia(media: UploadState | null) {
		if (!media) {
			return;
		}

		const storageRef = ref(this.storage, media.url);
		await deleteObject(storageRef);

		this.images.set(this.images().filter(i => i.url !== media.url));
		this.uploadState.update(state => {
			const newState = { ...state };
			delete newState[media.fileName];
			return newState;
		});
	}

	changeAddress() {
		this.onEditAddress.emit(true);
	}

	changePropertyType(type: PropertyType) {
		this.propertyType.set(type);
		this.missingPropertyType.set(false);

		if (type == 'common') {
			this.detailsForm.controls['landmark'].enable();
		} else {
			this.detailsForm.controls['landmark'].disable();
		}
	}

	hideContactInformationRequirements() {
		return (
			this.occupantForm.controls['isVacant'].value && this.notATenant()
		);
	}

	removeImage(url: string) {
		this.images.set(this.images().filter(i => i.url !== url));
	}

	selectCategory(category: Category) {
		this.selectedCategory.set(category);
		this.missingCategory.set(false);
	}

	selectResponsibility(responsibility: Responsibility) {
		this.personalResponsibility.set(responsibility);
		this.missingResponsibility.set(false);

		if (responsibility.name === 'other') {
			this.primaryContactForm.controls['reference'].enable();
		} else {
			this.primaryContactForm.controls['reference'].reset();
			this.primaryContactForm.controls['reference'].disable();
		}

		switch (responsibility.name) {
			case RequestorType.LANDLORD:
				this.occupantForm.controls['isVacant'].enable();
				this.primaryContactForm.controls['name'].enable();
				this.primaryContactForm.controls['email'].enable();
				this.primaryContactForm.controls['phone'].enable();
				this.primaryContactForm.controls['name'].reset();
				this.primaryContactForm.controls['email'].reset();
				this.primaryContactForm.controls['phone'].reset();
				if (this.linkedProperty().landlords) {
					this.primaryContactForm.controls['name'].setValue(
						this.linkedProperty().landlords?.fullName
					);
					this.primaryContactForm.controls['email'].setValue(
						this.linkedProperty().landlords?.email
					);
					this.primaryContactForm.controls['phone'].setValue(
						this.linkedProperty().landlords?.mobileNumber
					);
				}
				break;

			case RequestorType.TRUSTEE:
				this.occupantForm.controls['isVacant'].enable();
				this.primaryContactForm.controls['name'].enable();
				this.primaryContactForm.controls['email'].enable();
				this.primaryContactForm.controls['phone'].enable();
				this.primaryContactForm.controls['name'].reset();
				this.primaryContactForm.controls['email'].reset();
				this.primaryContactForm.controls['phone'].reset();
				if (this.linkedProperty().trustees) {
					this.primaryContactForm.controls['name'].setValue(
						this.linkedProperty().trustees?.fullName
					);
					this.primaryContactForm.controls['email'].setValue(
						this.linkedProperty().trustees?.email
					);
					this.primaryContactForm.controls['phone'].setValue(
						this.linkedProperty().trustees?.mobileNumber
					);
				}
				break;
			case RequestorType.OTHER:
				this.occupantForm.controls['isVacant'].enable();
				this.primaryContactForm.controls['name'].enable();
				this.primaryContactForm.controls['email'].enable();
				this.primaryContactForm.controls['phone'].enable();
				this.primaryContactForm.controls['name'].reset();
				this.primaryContactForm.controls['email'].reset();
				this.primaryContactForm.controls['phone'].reset();
				break;
			case RequestorType.OCCUPANT:
				this.occupantForm.controls['isVacant'].reset();
				this.occupantForm.controls['isVacant'].disable();
				this.primaryContactForm.controls['name'].reset();
				this.primaryContactForm.controls['email'].reset();
				this.primaryContactForm.controls['phone'].reset();
				this.primaryContactForm.controls['name'].disable();
				this.primaryContactForm.controls['email'].disable();
				this.primaryContactForm.controls['phone'].disable();
				if (this.linkedProperty().occupants) {
					this.occupantForm.controls['name'].setValue(
						this.linkedProperty().occupants?.fullName
					);
					this.occupantForm.controls['email'].setValue(
						this.linkedProperty().occupants?.email
					);
					this.occupantForm.controls['phone'].setValue(
						this.linkedProperty().occupants?.mobileNumber
					);
				}
				break;
		}
	}

	submitJob() {
		this.missingImages.set(this.images().length === 0);
		this.missingDetails.set(this.detailsForm.invalid);
		this.missingOccupant.set(this.occupantForm.invalid);
		this.missingCategory.set(!this.selectedCategory());
		this.missingResponsibility.set(!this.personalResponsibility());
		this.missingPropertyType.set(
			!this.propertyType() && this.linkedProperty().estateAddress != null
		);
		this.missingPrimaryContact.set(
			this.personalResponsibility()?.name !== RequestorType.OCCUPANT &&
				this.primaryContactForm.invalid
		);

		if (this.detailsForm.invalid) {
			Object.keys(this.detailsForm.controls).forEach(key => {
				const control = this.detailsForm.controls[key];
				if (control.enabled) {
					control.markAsTouched();
					control.markAsDirty();
				}
			});
		}

		if (this.occupantForm.invalid) {
			Object.keys(this.occupantForm.controls).forEach(key => {
				const control = this.occupantForm.controls[key];
				if (control.enabled) {
					control.markAsTouched();
					control.markAsDirty();
				}
			});
		}

		const propertyType = this.propertyType();
		const categoryId = this.selectedCategory()?.id;

		if (!propertyType || !categoryId) {
			return;
		}

		let primaryMobileNumber = '';

		if (
			this.primaryContactForm.controls['phone'].value &&
			this.primaryContactForm.controls['country'].value
		) {
			const pMobileNumber = PHONE_LIB.parse(
				this.primaryContactForm.controls['phone'].value,
				this.primaryContactForm.controls['country'].value
			);

			primaryMobileNumber =
				`+${pMobileNumber.getCountryCode()}` +
				pMobileNumber.getNationalNumber();
		}

		const jobRequest: createAJobRequest = {
			propertyType: propertyType,
			categoryId: categoryId,
			description: this.detailsForm.controls['details'].value,
			notes: this.detailsForm.controls['extra'].value,
			images: this.images().map(image => image.url),
			requestorType: this.personalResponsibility()?.name as RequestorType,
			requestorInfo: {
				fullName: this.primaryContactForm.controls['name'].value ?? '',
				email: this.primaryContactForm.controls['email'].value ?? '',
				mobileNumber: primaryMobileNumber,
				reference:
					this.primaryContactForm.controls['reference'].value ?? ''
			},
			vacant: this.occupantForm.controls['isVacant'].value ?? false,
			common: this.propertyType() === PropertyType.COMMON,
			landmark: this.detailsForm.controls['landmark'].value ?? null,
			urgent: false
		};

		if (this.personalResponsibility()?.name == RequestorType.OCCUPANT) {
			jobRequest.requestorInfo = {
				fullName: this.occupantForm.controls['name'].value,
				email: this.occupantForm.controls['email'].value,
				mobileNumber: primaryMobileNumber
			};
		}
		if (
			this.occupantForm.controls['phone'].value &&
			this.occupantForm.controls['country'].value
		) {
			const oMobileNumber = PHONE_LIB.parse(
				this.occupantForm.controls['phone'].value,
				this.occupantForm.controls['country'].value
			);

			const occupantMobileNumber =
				`+${oMobileNumber.getCountryCode()}` +
				oMobileNumber.getNationalNumber();

			jobRequest.occupantInfo = {
				fullName: this.occupantForm.controls['name'].value,
				email: this.occupantForm.controls['email'].value,
				mobileNumber: occupantMobileNumber
			};

			if (this.personalResponsibility()?.name == RequestorType.OCCUPANT) {
				jobRequest.requestorInfo.mobileNumber = occupantMobileNumber;
			}
		}

		this.createJob.emit(jobRequest);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
