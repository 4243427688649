<fixify-modal size="lg">
	<div class="flex justify-center mb-6">
		<img [src]="data?.logoUrl ?? 'assets/svg/logo.svg'" class="max-h-36" />
	</div>
	<div class="text-center">
		<h3 class="text-xl font-semibold text-gray-900 mb-4">
			You've been invited!
		</h3>

		<p class="text-gray-600 mb-6">
			{{ data?.agencyName }} would like you to join their workspace as an
			{{ data?.role }}.
		</p>
		<div class="space-y-3">
			<button
				(click)="acceptInvitation()"
				class="w-full px-5 py-2.5 bg-black text-white rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-300"
			>
				Accept invitation
			</button>
			<button
				(click)="declineInvitation()"
				class="w-full px-5 py-2.5 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300"
			>
				Decline invitation
			</button>
		</div>
		<p class="mt-4 text-sm text-gray-500">
			Want to create your own workspace instead? Decline this invitation
			and start fresh from your dashboard.
		</p>
	</div>
</fixify-modal>

