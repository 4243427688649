import { Component } from '@angular/core';
import { IconComponent } from '../../shared/ui/icon/icon.component';
import { DashboardCardComponent } from '../../shared/ui/dashboard-card/dashboard-card.component';
import { AgencyServiceProvidersTableComponent } from './ui/tables/agency-service-providers-table/agency-service-providers-table.component';
import { ModalService } from '../../shared/ui/modals/modal.service';
import { InviteServiceProvidersModalComponent } from './ui/modals/invite-service-providers-modal/invite-service-providers-modal.component';

@Component({
	selector: 'app-service-providers',
	standalone: true,
	imports: [
		IconComponent,
		DashboardCardComponent,
		AgencyServiceProvidersTableComponent
	],
	templateUrl: './service-providers.component.html',
	styleUrl: './service-providers.component.css'
})
export class ServiceProvidersComponent {
	constructor(private modalService: ModalService) {}

	inviteServiceProviders() {
		this.modalService
			.showModal(InviteServiceProvidersModalComponent)
			.then(emails => {
				if (emails) {
					// TODO: Send emails to the backend
				}
			});
	}
}
