<ul
	class="flex flex-wrap text-sm font-normal text-center text-gray-500 border-b border-gray-200"
>
	@for (tabItem of tabItems(); track $index) {
		<li class="cursor-pointer" (click)="onTabItemClicked(tabItem)">
			<p
				[ngClass]="{
					'border-primary-500 border-b-2 text-gray-900':
						tabItem.active
				}"
				class="inline-block px-4 py-3"
			>
				{{ tabItem.label | titlecase}}
				{{ tabItem.value ? '(' + tabItem.value + ')' : '' }}
			</p>
		</li>
	}
</ul>

