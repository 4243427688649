<div class="flex flex-col justify-center items-center">
	<img class="mb-4 w-36 h-36" src="assets/svg/no-locations.svg" />
	<p class="text-base font-semibold text-gray-700 mb-1">No locations added</p>
	<p class="text-sm font-normal text-gray-500 mb-4">
		{{ subtext() }}
	</p>
	@if (showAddLocation()) {
		<button (click)="addLocationClicked()" class="btn-primary">
			Add locations
		</button>
	}
</div>

