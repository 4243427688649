import { Component, computed, input } from '@angular/core';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
	selector: 'fixify-rating-item',
	standalone: true,
	imports: [IconComponent, NgClass],
	templateUrl: './rating-item.component.html',
	styleUrl: './rating-item.component.css'
})
export class RatingItemComponent {
	title = input.required<string>();
	stars = input.required<number | null>();
	totalStars = input.required<number>();

	starItemArray = computed(() =>
		Array.from(
			{ length: this.totalStars() },
			(_, i) => i < (this.stars() ?? 0)
		)
	);
}
