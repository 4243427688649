import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, input, output } from '@angular/core';

export interface ITabItem {
	label: string;
	value: string | number;
	active: boolean;
}

@Component({
	selector: 'fixify-tabs',
	standalone: true,
	imports: [NgClass, TitleCasePipe],
	templateUrl: './tabs.component.html',
	styleUrl: './tabs.component.css'
})
export class TabsComponent {
	tabItems = input.required<Array<ITabItem>>();
	onTabItemChange = output<ITabItem>();

	onTabItemClicked(item: ITabItem) {
		this.tabItems().forEach(tabItem => {
			tabItem.active = tabItem === item;
		});
		this.onTabItemChange.emit(item);
	}
}
