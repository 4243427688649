import { Component, inject, input, output } from '@angular/core';
import {
	FormArray,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { atLeastOneActiveTask } from '../../pipes/one-task-active.pipe';
import { Subject, takeUntil } from 'rxjs';
import { initModals } from 'flowbite';
import { DeleteModalComponent } from '../../../../../shared/ui/modals/delete/delete.component';
import { ServiceProviderService } from '../../../../../shared/data-access/service-provider/service-provider.service';
import { CategoryFormComponent } from '../category-form/category-form.component';
import {
	UploadedDocuments,
	Category,
	ServiceProviderCategory
} from '../../../../../shared/data-access/service-provider/service-provider.types';
import { ModalService } from '../../../../../shared/ui/modals/modal.service';

export interface CategoryForm {
	id: FormControl<string | null>;
	name: FormControl<string | null>;
	tasks: FormArray<FormGroup<ITaskCheckbox>>;
	supportingDocuments: FormControl<Array<UploadedDocuments> | null>;
}

export interface ITaskCheckbox {
	selected: FormControl<boolean | null>;
	name: FormControl<string | null>;
}

@Component({
	selector: 'fixify-edit-category',
	standalone: true,
	imports: [ReactiveFormsModule, CategoryFormComponent],
	templateUrl: './edit-category.component.html',
	styleUrl: './edit-category.component.css'
})
export class EditCategoryComponent {
	serviceProviderService = inject(ServiceProviderService);
	categories = input.required<Array<Category>>();
	unavailableCategoryIds = input.required<Array<string>>();
	spCategory = input.required<ServiceProviderCategory>();
	stopEdit = output<void>();
	categoryForm: FormGroup<CategoryForm>;
	selectedCategory = '';
	destroy$ = new Subject<void>();

	constructor(private modalService: ModalService) {
		this.categoryForm = new FormGroup({
			id: new FormControl<string | null>(null),
			name: new FormControl<string | null>('', Validators.required),
			tasks: new FormArray<FormGroup<ITaskCheckbox>>(
				[],
				[atLeastOneActiveTask()]
			),
			supportingDocuments: new FormControl<Array<UploadedDocuments>>([])
		});
	}

	ngOnInit() {
		// Required due to the modal component
		initModals();
		this.selectedCategory = this.spCategory()?.name ?? '';
		const categoryDetails = this.categories()?.find(
			c => c.id === this.spCategory()?.id
		);

		categoryDetails?.tasks.forEach(task => {
			const taskGroup = new FormGroup({
				name: new FormControl(task),
				selected: new FormControl(
					this.spCategory()?.tasks.includes(task) ?? false
				)
			});
			(this.categoryForm.get('tasks') as FormArray).push(taskGroup);
		});

		// patch the form
		this.categoryForm.patchValue({
			name: this.spCategory()?.name,
			supportingDocuments: this.spCategory().supportingDocuments ?? null
		});

		this.categoryForm.controls.name.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(newValue => {
				const tasks = this.retrieveTasks(newValue ?? '');
				this.categoryForm.controls.tasks.clear();
				tasks.forEach(task => {
					const taskFormGroup = new FormGroup<ITaskCheckbox>({
						selected: new FormControl<boolean>(false),
						name: new FormControl<string>(task)
					});

					this.categoryForm.controls.tasks.push(taskFormGroup);
				});
			});
	}

	allTasksForFormGroup() {
		return this.categoryForm.controls.tasks as FormArray<
			FormGroup<ITaskCheckbox>
		>;
	}

	categoryInUse(categoryId: string) {
		return (
			this.unavailableCategoryIds().includes(categoryId) &&
			this.spCategory()?.id !== categoryId
		);
	}

	deleteCategory() {
		// TODO: Send the category ID to the sp Service to delete the category - the update within the service will immediately update the UI
		// because the categories are being observed in the parent component
		this.serviceProviderService.deleteCategory(this.spCategory()?.id ?? '');
		this.stopEdit.emit();
	}

	isCategoryChosen(categoryName: string) {
		return categoryName === this.selectedCategory;
	}

	openDeleteModal() {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Delete category?',
				body: `You won't receive any ${this.spCategory()?.name} jobs after this. To re-add ${this.spCategory()?.name}, you'll need to go through the verification process again.`,
				deleteButtonText: 'Delete',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.deleteCategory();
				}
			});
	}

	saveAndStopEdit() {
		const editedCategory: ServiceProviderCategory = {
			id: this.spCategory()?.id ?? '',
			name: this.categoryForm.controls.name.value ?? '',
			tasks: this.allTasksForFormGroup()
				.value.filter(task => task.selected)
				.map(task => task.name ?? ''),
			supportingDocuments:
				this.categoryForm.controls.supportingDocuments.value ?? [],
			status: this.spCategory()?.status ?? 'deactivated'
		};

		this.serviceProviderService.editCategory({
			supportingDocuments: editedCategory.supportingDocuments.map(
				doc => doc.url
			),
			name: editedCategory.name,
			tasks: editedCategory.tasks,
			id: editedCategory.id
		});

		this.stopEditing();
	}

	stopEditing() {
		this.stopEdit.emit();
	}

	supportingDocs() {
		return this.categoryForm.controls.supportingDocuments.value;
	}

	// selectFileFromDevice() {
	// 	const input = document.createElement('input');
	// 	input.type = 'file';
	// 	input.accept = '.pdf, image/*';
	// 	input.multiple = true;
	// 	input.click();

	// 	input.onchange = () => {
	// 		const files = Array.from(input.files ?? []);
	// 		const fileNames = files.map(file => file.name);

	// 		const currentFiles =
	// 			this.categoryForm.controls.supportingDocuments.value ?? [];
	// 		// this.categoryForm.controls.supportingDocuments.setValue(
	// 		// 	currentFiles.concat(fileNames)
	// 		// );
	// 	};
	// }

	retrieveTasks(cagtegoryName: string) {
		return (
			this.categories()?.find(category => category.name === cagtegoryName)
				?.tasks ?? []
		);
	}
}
