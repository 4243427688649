<div class="mb-8">
	<p class="text-lg font-medium text-gray-900 mb-4">Agency details</p>
	@if (managedFulfilment().jobStatus === 'new') {
		<div>
			<p class="text-base font-normal text-gray-500 mb-2">Agency</p>
			<div class="flex gap-2 items-center">
				<fixify-avatar
					class="w-8 h-8"
					[source]="managedFulfilment().agency.logoUrl"
					placeholder="HR"
				></fixify-avatar>
				<p class="text-base font-normal text-gray-900">
					{{ managedFulfilment().agency.name }}
				</p>
			</div>
		</div>
		<div class="mt-4">
			<p class="text-base font-normal text-gray-500 mb-2">Agent</p>
			<p class="text-base font-normal text-gray-900">
				{{ managedFulfilment().agent.firstName }}
				{{ managedFulfilment().agent.lastName }}
			</p>
		</div>
	} @else {
		<div class="flex flex-row gap-3">
			<div class="flex-1 flex flex-col gap-6">
				<fixify-icon-item
					iconName="user-01"
					title="Managing agent name"
					[subtitle]="managedFulfilment().agent.firstName + ' ' + managedFulfilment().agent.lastName"
				></fixify-icon-item>
				<fixify-icon-item
					iconName="mail-01"
					title="Email address"
					[subtitle]="agentEmail()"
				></fixify-icon-item>
				<fixify-icon-item
					iconName="phone"
					title="Contact number"
					[subtitle]="agentMobileNumber()"
				></fixify-icon-item>
			</div>
			<div class="flex-1 flex flex-col justify-around">
				<p class="text-xl font-medium text-gray-900">
					{{ managedFulfilment().agency.name }}
				</p>
				<div class="aspect-square w-32 h-32">
					<div class="relative w-full h-full">
						<div
							class="flex-none h-full w-full rounded-lg bg-gray-100 flex flex-col items-center justify-center"
						>
							<div
								class="flex items-center justify-center w-full h-full absolute top-0 left-0"
							>
								<fixify-icon
									class="text-gray-400 w-4 h-4"
									name="image-03"
								></fixify-icon>
							</div>
						</div>

						<img
							class="absolute rounded-lg top-0 object-cover w-full h-full"
							[src]="managedFulfilment().agency.logoUrl"
						/>
					</div>
				</div>
			</div>
		</div>
	}
</div>

