import { Component, input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'fixify-icon-item',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './icon-item.component.html',
	styleUrl: './icon-item.component.css'
})
export class IconItemComponent {
	iconName = input.required<string>();
	title = input.required<string>();
	subtitle = input<string | null>();
	subsubtitle = input<string>('');
	images = input<Array<string>>([]);

	view(image: string) {
		window.open(image, '_blank');
	}
}
