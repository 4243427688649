import { Component, computed, signal } from '@angular/core';
import { ModalComponent } from '../../../../../shared/ui/modals/modal.service';
import { ModalCardComponent } from '../../../../../shared/ui/modal-card/modal-card.component';
import { ManagedProperty } from '../../../../../shared/data-access/agency/agency.types';
import { IconComponent } from '../../../../../shared/ui/icon/icon.component';
import { PropertyTableComponent } from '../../../../properties/ui/tables/property-table/property-table.component';

@Component({
	selector: 'fixify-link-properties-modal',
	standalone: true,
	imports: [ModalCardComponent, IconComponent, PropertyTableComponent],
	templateUrl: './link-properties.component.html',
	styleUrl: './link-properties.component.css'
})
export class LinkPropertiesComponent extends ModalComponent<
	void,
	Array<ManagedProperty>
> {
	selectedProperties = signal<Array<ManagedProperty>>([]);

	canLink = computed(() => this.selectedProperties().length > 0);

	selectedPropertiesChange(properties: Array<ManagedProperty>) {
		this.selectedProperties.set(properties);
	}

	link() {
		this.close(this.selectedProperties());
	}
}
