<fixify-modal size="xl">
	<input
		accept="image/*, application/pdf"
		id="file-input"
		class="hidden"
		type="file"
		multiple
		(change)="onFileSelected($event)"
	/>
	<div class="self-start h-8 w-8 p-2 rounded-md bg-primary-50 mb-5">
		<fixify-icon
			class="text-primary-500 stroke-2"
			name="upload-cloud-01"
		></fixify-icon>
	</div>
	<p class="text-xl font-semibold text-gray-900 mb-1">
		{{ data?.title ?? 'Upload document' }}
	</p>
	<p class="text-sm font-normal text-gray-500 mb-6">
		{{ data?.subtitle ?? 'Upload a document to attach to this job' }}
	</p>
	<!-- Upload area -->
	@if (uploads().length === 0) {
		<div
			[ngClass]="{ 'bg-stone': dragOver() }"
			class="flex flex-col gap-4 justify-between items-center rounded-lg border-2 border-dashed border-gray-300 p-8"
			(dragover)="onDragOver($event)"
			(dragleave)="onDragLeave($event)"
			(drop)="onDrop($event)"
		>
			<fixify-icon
				class="text-gray-500 h-8 w-8"
				name="upload-cloud-01"
			></fixify-icon>
			<div class="flex flex-col items-center gap-1">
				<p class="text-sm font-medium text-gray-900">Upload</p>
				<p class="text-sm font-normal text-gray-500">
					Click to browse or drag & drop files here
				</p>
			</div>
			<button (click)="triggerFileSelect()" class="btn btn-secondary">
				Choose files
			</button>
		</div>
	}

	<!-- Files list -->
	@if (uploads().length > 0) {
		<div class="space-y-3">
			@for (upload of uploads(); track upload.file.name) {
				<div
					class="bg-gray-100 p-3 rounded-lg"
					[ngClass]="{ 'bg-red-100': upload.error }"
				>
					<!-- Progress bar -->
					@if (upload.progress < 100 && !upload.error) {
						<div class="w-full mb-4 relative">
							<div
								class="w-full h-[6px] rounded-full bg-white"
							></div>
							<div
								class="h-[6px] absolute top-0 left-0 rounded-full bg-primary-500 transition-all duration-100 ease-out"
								[style]="'width: ' + upload.progress + '%'"
							></div>
						</div>
					}

					<!-- File info and actions -->
					<div class="flex gap-2 justify-between items-center">
						<div>
							<p class="text-sm font-medium text-gray-900 mb-1">
								{{ upload.file.name }}
							</p>
							<p class="text-sm font-normal text-gray-500">
								{{
									(upload.file.size / 1024 / 1024).toFixed(2)
								}}
								MB
							</p>
						</div>

						@if (upload.progress < 100 && !upload.error) {
							<p class="text-sm text-gray-400 italic">
								Uploading
							</p>
						} @else if (upload.url) {
							<button
								(click)="remove(upload.url)"
								class="btn btn-outline danger border-none bg-transparent"
							>
								Delete
							</button>
						} @else if (upload.error) {
							<button
								(click)="retry(upload.file.name)"
								class="btn btn-outline danger border-none bg-transparent"
							>
								Retry
							</button>
						}
					</div>
				</div>
			}
		</div>

		<!-- Add more files button -->
		<button
			(click)="triggerFileSelect()"
			class="btn btn-secondary mt-4 w-full"
		>
			Add more files
		</button>
	}
	<div class="flex justify-end gap-2 mt-6">
		<button
			[disabled]="cannotCancel()"
			(click)="close()"
			class="btn btn-outline border-gray-200"
		>
			Cancel
		</button>
		<button
			(click)="submit()"
			[disabled]="cannotSubmit()"
			class="btn btn-primary"
		>
			@if (loading()) {
				<div class="flex justify-center items-center">
					<app-loader [loadingState]="true"></app-loader>
				</div>
			} @else {
				Submit
			}
		</button>
	</div>
</fixify-modal>

