import { Component, model, signal } from '@angular/core';
import {
	ITabItem,
	TabsComponent
} from '../../../../shared/ui/tabs/tabs.component';
import { DatePipe, NgClass, TitleCasePipe } from '@angular/common';
import { IconComponent } from '../../../../shared/ui/icon/icon.component';
import {
	StatusPillColor,
	StatusPillComponent
} from '../../../../shared/ui/status-pill/status-pill.component';
import { ModalService } from '../../../../shared/ui/modals/modal.service';
import { ToastService } from '../../../../shared/data-access/toast.service';
import { DeleteModalComponent } from '../../../../shared/ui/modals/delete/delete.component';
import { JobUploadDocumentComponent } from '../modals/job-upload-document/job-upload-document.component';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited,
	ServiceProviderManageDocument
} from '../../../../shared/data-access/service-provider/service-provider.types';
import {
	DocumentStatus,
	DocumentView
} from '../../../../shared/data-access/agency/agency.types';
import { ServiceProviderJobsService } from '../../../../shared/data-access/service-provider/service-provider.jobs.service';
import { LoaderComponent } from '../../../../shared/ui/loader/loader.component';

@Component({
	selector: 'fixify-service-provider-documents',
	standalone: true,
	imports: [
		TabsComponent,
		NgClass,
		IconComponent,
		StatusPillComponent,
		DatePipe,
		TitleCasePipe,
		LoaderComponent
	],
	templateUrl: './service-provider-documents.component.html',
	styleUrl: './service-provider-documents.component.css'
})
export class ServiceProviderDocumentsComponent {
	documentFilters: Array<ITabItem> = [
		{
			label: 'Quotes',
			value: '-',
			active: true
		},
		{
			label: 'Photos',
			value: '-',
			active: false
		},
		{
			label: 'Invoices',
			value: '-',
			active: false
		}
	];

	documents = signal<Array<DocumentView>>([]);

	documentsToView = signal<Array<DocumentView>>([]);
	managedFulfilment = model.required<
		ManagedFulfilmentDetails | ManagedFulfilmentLimited
	>();
	loading = false;

	constructor(
		private modalService: ModalService,
		private toastService: ToastService,
		private serviceProviderJobsService: ServiceProviderJobsService
	) {}

	ngOnInit() {
		this.setDocuments();
	}

	setDocuments() {
		this.documents.set([
			...this.managedFulfilment().quotes.map(quote => {
				return {
					createdAt: quote.date,
					name: quote.name,
					rejectedReason: quote.reason,
					type: 'quote' as const,
					status: quote.status as DocumentStatus,
					url: quote.url
				};
			}),
			...this.managedFulfilment().invoices.map(invoice => {
				return {
					type: 'invoice' as const,
					status: invoice.status as DocumentStatus,
					createdAt: invoice.date,
					name: invoice.name,
					rejectedReason: invoice.reason,
					url: invoice.url
				};
			}),
			...this.managedFulfilment().supportingMediaUrls.map(url => {
				return {
					type: 'photo' as const,
					url
				};
			})
		]);

		this.documentFilters[0].value =
			this.managedFulfilment().quotes.length ?? 0;
		this.documentFilters[1].value =
			this.managedFulfilment().supportingMediaUrls.length ?? 0;
		this.documentFilters[2].value =
			this.managedFulfilment().invoices.length ?? 0;

		switch (this.managedFulfilment().jobStatus) {
			case 'completed':
				this.onDocumentItemChange(this.documentFilters[2]);
				break;
			case 'cancelled':
				this.onDocumentItemChange(this.documentFilters[0]);
				break;
		}

		this.documentsToView.set(
			this.documents().filter(
				doc =>
					doc.type ===
					(this.managedFulfilment().jobStatus === 'completed'
						? 'invoice'
						: 'quote')
			)
		);

		const activeTab = this.activeTabItem();

		this.onDocumentItemChange(activeTab ?? this.documentFilters[0]);
	}

	activeTabItem() {
		return this.documentFilters.find(filter => filter.active);
	}

	getColor(status: DocumentStatus): StatusPillColor {
		switch (status) {
			case 'approved':
				return 'green';
			case 'rejected':
				return 'red';
			case 'outdated':
				return 'bright-orange';
			case 'pending':
				return 'primary';
			case 'requote':
				return 'yellow';
			default:
				return '';
		}
	}

	documentText() {
		const label = this.documentFilters.find(filter => filter.active)?.label;

		switch (label) {
			case 'Quotes':
				return 'quotes';
			case 'Photos':
				return 'photos';
			case 'Invoices':
				return 'invoices';
			default:
				return 'documents';
		}
	}

	markOutdated(doc: DocumentView) {
		this.modalService
			.showModal(DeleteModalComponent, {
				title: 'Mark this quote as outdated?',
				body: 'This quote will still be visible, but it is not valid for acceptance. You won’t be able to undo this action.',
				deleteButtonText: 'Yes, outdate quote',
				enableCancel: true
			})
			.then(result => {
				if (result) {
					this.loading = true;
					this.serviceProviderJobsService
						.manageQuote(this.managedFulfilment().id, {
							date: doc.createdAt as number,
							name: doc.name as string,
							reason: null,
							status: 'outdate'
						})
						.subscribe({
							next: data => {
								const { quote } = data.body;
								doc.status = quote.status;
							},
							complete: () => {
								this.loading = false;
							}
						});
				}
				const activeTab = this.activeTabItem();

				this.onDocumentItemChange(activeTab ?? this.documentFilters[0]);
			});
	}

	showPill(document: DocumentView) {
		switch (document.type) {
			case 'quote':
				return document.status !== 'pending';
			case 'photo':
				return false;
			case 'invoice':
				return document.status;
			default:
				return false;
		}
	}

	onDocumentItemChange(item: ITabItem) {
		this.documentFilters.forEach(filter => {
			filter.active = filter.label === item.label;
		});

		this.documentsToView.set(
			this.documents().filter(doc => {
				switch (item.label) {
					case 'Quotes':
						return doc.type === 'quote';
					case 'Photos':
						return doc.type === 'photo';
					case 'Invoices':
						return doc.type === 'invoice';
					default:
						return true;
				}
			})
		);
	}

	openImage(url: string) {
		window.open(url, '_blank');
	}

	uploadDocument() {
		const activeTab = this.activeTabItem();

		if (!activeTab) {
			return;
		}

		const type = activeTab.label.toLocaleLowerCase().slice(0, -1) as
			| 'quote'
			| 'photo'
			| 'invoice';

		this.modalService
			.showModal(JobUploadDocumentComponent, {
				type: type,
				fulfilment: this.managedFulfilment(),
				title: `Upload ${type}`,
				subtitle: `Upload a ${type} for this job`
			})
			.then(documents => {
				if (documents === undefined) return;
				switch (type) {
					case 'quote':
						this.managedFulfilment.update(current => ({
							...current,
							quotes: [
								...(documents as ServiceProviderManageDocument[])
							]
						}));
						break;
					case 'invoice':
						this.managedFulfilment.update(current => ({
							...current,
							invoices: [
								...(documents as ServiceProviderManageDocument[])
							]
						}));
						break;
					case 'photo':
						this.managedFulfilment.update(current => ({
							...current,
							supportingMediaUrls: [...(documents as string[])]
						}));
						break;
					default:
						this.toastService.add('TODO: update UI locally');
						break;
				}
				this.setDocuments();
			});
	}
}
