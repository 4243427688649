import {
	Injectable,
	ComponentRef,
	ViewContainerRef,
	Type,
	OnDestroy
} from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';

interface ModalInstance<T, R> {
	componentRef: ComponentRef<ModalComponent<T, R>>;
	closePromise: Promise<R | undefined>;
}

@Injectable()
export abstract class ModalComponent<T, R> implements OnDestroy {
	private closeSubject = new Subject<R | undefined>();
	closeObservable: Observable<R | undefined> =
		this.closeSubject.asObservable();

	protected data?: T;

	setData(data: T): void {
		this.data = data;
		this.onDataSet();
	}

	close(result?: R): void {
		this.closeSubject.next(result);
	}

	ngOnDestroy(): void {
		this.closeSubject.complete();
	}

	// This method can be overridden in child classes if needed
	protected onDataSet(): void {
		return;
	}
}

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private modals: ModalInstance<any, any>[] = [];
	private viewContainerRef: ViewContainerRef | null = null;

	setViewContainerRef(vcr: ViewContainerRef) {
		this.viewContainerRef = vcr;
	}

	async showModal<T, R>(
		component: Type<ModalComponent<T, R>>,
		data?: T
	): Promise<R | undefined> {
		if (!this.viewContainerRef) {
			throw new Error(
				'ViewContainerRef not set. Make sure to use the modalHost directive in your template.'
			);
		}

		const componentRef = this.viewContainerRef.createComponent(component);

		if (data && componentRef.instance.setData) {
			componentRef.instance.setData(data);
		}

		componentRef.location.nativeElement.classList.add('no-doc-scroll');

		const closePromise = firstValueFrom(
			componentRef.instance.closeObservable
		);

		const modalInstance: ModalInstance<T, R> = {
			componentRef,
			closePromise
		};

		this.modals.push(modalInstance);

		const result = await closePromise;
		this.hideModal(modalInstance);
		return result;
	}

	private hideModal<T, R>(modalInstance: ModalInstance<T, R>) {
		const index = this.modals.indexOf(modalInstance);
		if (index > -1) {
			this.modals.splice(index, 1);
			modalInstance.componentRef.destroy();
		}
	}

	closeAllModals() {
		this.modals.forEach(modal => {
			modal.componentRef.instance.close();
		});
	}

	getModalCount(): number {
		return this.modals.length;
	}
}
