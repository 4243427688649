import { Address } from '../../../creator-portal/service/creator-portal.service';

export interface Agency {
	logoUrl: string;
	name: string;
}

export interface residentialSearchUserRequest {
	mobileNumber?: string;
	email?: string;
}

export enum PropertyType {
	PRIVATE = 'private',
	COMMON = 'common'
}

export enum RequestorType {
	LANDLORD = 'landlord',
	OCCUPANT = 'tenant',
	TRUSTEE = 'trustee',
	ESTATE_MANAGER = 'estate manager',
	OTHER = 'other'
}

interface User {
	fullName: string;
	email: string;
	mobileNumber: string;
	reference?: string;
}

export interface createAJobRequest {
	propertyType: PropertyType;
	categoryId: string;
	description: string;
	notes: string;
	images: Array<string>;
	requestorType: RequestorType;
	requestorInfo: User;
	occupantInfo?: User;
	landmark: string | null;
	common: boolean;
	vacant: boolean;
	urgent: boolean;
}

export interface createAPropertyRequest {
	address: Address;
	estateId: string | null;
	estateName: string | null;
}
