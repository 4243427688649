import { Injectable, signal } from '@angular/core';
import { ApiService } from '../api-service.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ManagedProperty, PropertyMetrics } from './agency.types';
import { ToastService } from '../toast.service';
import { PaginatedResponse } from '../../utils/pagination';

type ManagedPropertiesFilters = {
	search?: string;
	page?: number;
	limit?: number;
	coordinates?: string;
	sort?: string;
	sortBy?: string;
};
@Injectable({
	providedIn: 'root'
})
export class AgencyPropertiesService {
	private isSubmitting$ = new BehaviorSubject<boolean>(false);
	private isFetching$ = new BehaviorSubject<boolean>(false);

	private properties$ =
		new BehaviorSubject<PaginatedResponse<ManagedProperty> | null>(null);
	private propertyMetrics$ = new BehaviorSubject<PropertyMetrics | null>(
		null
	);

	private paramSignal = signal<ManagedPropertiesFilters>({});

	constructor(
		private apiService: ApiService,
		private toastService: ToastService
	) {
		this.fetchProperties();
		this.fetchPropertiesMetrics();
	}

	fetchProperties(_params?: ManagedPropertiesFilters) {
		this.isFetching$.next(true);
		this.setParamSignal(_params);
		const params = this.apiService.buildFilterQueryParams({
			...this.paramSignal()
		});
		this.apiService.getData(`agency/properties${params}`).subscribe({
			next: data => {
				const paginatedData =
					data.body as PaginatedResponse<ManagedProperty>;
				paginatedData.result = paginatedData.result.map(property => {
					if (property.managingAgent) {
						if (
							property.managingAgent.firstName &&
							property.managingAgent.lastName
						)
							property.managingAgent.name = `${property.managingAgent.firstName.charAt(0)}. ${property.managingAgent.lastName}`;
					}

					return property;
				});
				this.properties$.next(paginatedData);
			},
			error: error => {
				console.error(error);
			},
			complete: () => {
				this.isFetching$.next(false);
			}
		});
	}

	getParams() {
		return this.paramSignal();
	}

	setParamSignal(params?: ManagedPropertiesFilters) {
		if (!params) {
			return this.clearParams();
		}
		this.paramSignal.set(params);
	}

	clearParams() {
		this.paramSignal.set({});
	}

	fetchProperty(propertyId: string) {
		if (
			this.properties$.value?.result.some(
				property => property.id === propertyId
			)
		) {
			return new Observable(subscriber => {
				subscriber.next({
					body: this.properties$.value?.result.find(
						property => property.id === propertyId
					)
				});
				subscriber.complete();
			});
		}
		return this.apiService.getData(`agency/property/${propertyId}`);
	}

	fetchPropertiesMetrics() {
		return this.apiService.getData('agency/properties/metrics').subscribe({
			next: data => {
				const metrics = data.body as PropertyMetrics;
				this.propertyMetrics$.next(metrics);
			},
			error: error => {
				console.error(error);
			}
		});
	}

	deleteProperty(propertyId: string) {
		this.isSubmitting$.next(true);
		this.apiService.deleteData(`agency/property/${propertyId}`).subscribe({
			next: () => {
				this.fetchProperties();
			},
			error: error => {
				console.error(error);
				this.toastService.add(
					error.error.body.errorMessage,
					5000,
					'error'
				);
			},
			complete: () => {
				this.isSubmitting$.next(false);
				this.toastService.add(
					'Property has been successfully deleted',
					5000,
					'success'
				);
			}
		});
	}

	deleteProperties(propertyIds: string[]) {
		this.isSubmitting$.next(true);
		this.apiService
			.deleteData('agency/properties', { propertyIds })
			.subscribe({
				next: () => {
					this.fetchProperties();
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
					this.toastService.add(
						'Properties have been successfully deleted',
						5000,
						'success'
					);
				}
			});
	}

	updateProperty(propertyId: string, property: Partial<ManagedProperty>) {
		return this.apiService.putData(`agency/property/${propertyId}`, {
			...property
		});
	}

	createProperty(property: Partial<ManagedProperty>) {
		this.isSubmitting$.next(true);
		return this.apiService
			.postData(`agency/create-property`, { ...property })
			.subscribe({
				next: data => {
					this.fetchProperties();
					this.toastService.add(
						'Property has been successfully created',
						5000,
						'success'
					);
					return new Observable(subscriber => {
						subscriber.next({
							id: data.body.id
						});
						subscriber.complete();
					});
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	assignAgentToProperties(
		managingAgentId: string | null,
		propertyIds: string[]
	) {
		this.isSubmitting$.next(true);
		this.apiService
			.postData('agency/properties/assign-agent', {
				managingAgentId,
				propertyIds
			})
			.subscribe({
				next: () => {
					this.fetchProperties();
					this.toastService.add(
						'Properties have been successfully assigned',
						5000,
						'success'
					);
				},
				error: error => {
					console.error(error);
					this.toastService.add(
						error.error.body.errorMessage,
						5000,
						'error'
					);
				},
				complete: () => {
					this.isSubmitting$.next(false);
				}
			});
	}

	properties(): Observable<PaginatedResponse<ManagedProperty> | null> {
		return this.properties$.asObservable();
	}

	propertyMetrics(): Observable<PropertyMetrics | null> {
		return this.propertyMetrics$.asObservable();
	}
}
