import { Component, input } from '@angular/core';
import { JobUserDetailTileComponent } from '../../../../agency/agency-jobs/track-job/ui/job-user-detail-tile/job-user-detail-tile.component';
import { IconComponent } from '../../../../shared/ui/icon/icon.component';
import { DividerComponent } from '../../../../shared/ui/divider/divider.component';
import {
	ManagedFulfilmentDetails,
	ManagedFulfilmentLimited,
	RefinedStakeholder
} from '../../../../shared/data-access/service-provider/service-provider.types';

@Component({
	selector: 'fixify-service-provider-customers',
	standalone: true,
	imports: [JobUserDetailTileComponent, IconComponent, DividerComponent],
	templateUrl: './service-provider-customers.component.html',
	styleUrl: './service-provider-customers.component.css'
})
export class ServiceProviderCustomersComponent {
	managedFulfilment = input.required<
		ManagedFulfilmentDetails | ManagedFulfilmentLimited
	>();

	estateManagers(): Array<RefinedStakeholder> {
		const fulfilment = this.managedFulfilment();
		return 'estateManagers' in fulfilment ? fulfilment.estateManagers : [];
	}

	landlords(): Array<RefinedStakeholder> {
		const fulfilment = this.managedFulfilment();
		return 'landlords' in fulfilment ? fulfilment.landlords : [];
	}

	occupants(): Array<RefinedStakeholder> {
		const fulfilment = this.managedFulfilment();
		return 'occupants' in fulfilment ? fulfilment.occupants : [];
	}

	trustees(): Array<RefinedStakeholder> {
		const fulfilment = this.managedFulfilment();
		return 'trustees' in fulfilment ? fulfilment.trustees : [];
	}
}
