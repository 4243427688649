import { Component, computed, input, output } from '@angular/core';
import {
	StatusPillColor,
	StatusPillComponent
} from '../status-pill/status-pill.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';

@Component({
	selector: 'fixify-track-information-card',
	standalone: true,
	imports: [StatusPillComponent, IconComponent, NgClass, LoaderComponent],
	templateUrl: './track-information-card.component.html',
	styleUrl: './track-information-card.component.css'
})
export class TrackInformationCardComponent {
	title = input.required<string>();
	titleIcon = input<string>('');
	subtitle = input.required<string>();
	statusText = input<string>('');
	statusColor = input<StatusPillColor>('');
	primaryActionText = input<string>('');
	secondaryActionText = input<string>('');
	tertiaryActionText = input<string>('');
	primaryPress = output();
	secondaryPress = output();
	tertiaryPress = output();
	topBarText = input<string>('');
	topBarError = input<boolean>(false);
	loading = input<boolean>(false);
	anyActionText = computed(() => {
		return (
			this.primaryActionText() ||
			this.secondaryActionText() ||
			this.tertiaryActionText()
		);
	});
}
